export class JobModel {
    id: number = 0;
    hospitalId: number;
    title: string;
    jobDetails: string;
    jobTemplate: any;
    licenseTypeId: number;
    specialityId: any;
    contactIds: string;
    publishJob: boolean;
    deletedDocumentIds: string;
    documents: any;
    clinicianJobDays: any;
    credentialSet: any;
    jobDaysIds: any;
    status: any;
    graduationType: any;
    graduationTypeEnum: any;
    shiftTimeIds: any;
    marketId: any;
    hasThriveHealthJob: any;
    companyId: any;
    preferredShiftTime: any;
    shiftTimes: any;
}

export enum ClinicianJobDays {
    All = 1,
    Sunday = 2,
    Monday = 3,
    Tuesday = 4,
    Wednesday = 5,
    Thurseday = 6,
    Friday = 7,
    Saturday = 8,
}
export enum Status {
    All = 0,
    Open = 1,
}
export enum JobEMR {
    Epic = 1,
    Cerner = 2,
    Meditech = 3,
}

export enum JobStatus {
    Draft = 1,
    Open = 2,
    Closed = 3,
    Canceled = 4,
}

export enum PendingApplicationsEnum {
    Yes = 1,
    No = 2,
}

export class JobsModel {
    id: number;
    title: string;
    hospitalId: number;
    hospitalName: string;
    hospitalLogo: any;
    licenseTypeId: number;
    licenseTypeName: string;
    postingDate: any;
    activeNurses: any;
    pendingApplications: any;
    status: string;
    statusType: number;
    licenseTypeChipBackgroundColor: any;
    licenseTypeChipFontColor: any;
    licenseTypeAcronym: string;
    specialities: string;
}

export class Document {
    id: number;
    jobId: number;
    title: any;
    fileUrl: any;
    fileName: any;
    thumbnail: any;
    formType: any;
    reUploadedByNurse: boolean;
    electronicallySignedByNurse: boolean;
}

export enum DocumentType {
    License = 1,
    BackgroundCheck = 2,
    Credential = 3,
    Education = 4,
}

export enum ScreeningStatus {
    InProgress = 1,
    Approved = 2,
    Reproved = 3,
    Unverified = 4,
}

export enum GraduationType {
    ADN = 1,
    BSN = 2,
    DNP = 5,
    PhD = 4,
    MSN = 3,
}

export const GraduationTypeEnum: any[] = [];

for (const key in GraduationType) {
    if (isNaN(Number(key))) {
        GraduationTypeEnum.push({
            id: GraduationType[key as keyof typeof GraduationType],
            name: getName(GraduationType[key as keyof typeof GraduationType]),
            shortName: key,
        });
    }
}

function getName(id: number): string {
    switch (id) {
        case GraduationType.ADN:
            return 'Associate Degree in Nursing (ADN)';
        case GraduationType.BSN:
            return 'Bachelor of Science in Nursing (BSN)';
        case GraduationType.DNP:
            return 'Doctor of Nursing Practice (DNP)';
        case GraduationType.PhD:
            return 'Doctor of Philosophy (PhD)';
        case GraduationType.MSN:
            return 'Master of Science in Nursing (MSN)';
        default:
            return '';
    }
}

export enum Type {
    All = 1,
    License = 2,
    BackgroundCheck = 3,
    Credential = 4,
    Document = 5,
    Education = 6,
}
