export class ShiftModel {
    id: number = 0;
    jobId: number;
    licenseTypeId: any;
    specialityId: any;
    credentialSet: any;
    shiftTimeId: any;
    lateCallBonus: any;
    instructionId: any;
    fromTime: any;
    toTime: any;
    breakTimeId: string;
    date: any;
    unitId: any;
    hospitalId: any;
    numberOfPosition: any;
    shiftTimes: any;
    isActive: boolean;
    weekDay: any;
    isBreakTimePaid: any;
    instructions: any;
    marketId: any;
    level1Price: any;
    level2Price: any;
    level3Price: any;
    level3BillRate: any;
    clinicianId: any;
    nurseLevel: any;
}

export enum ShiftType {
    AM = 1,
    PM = 2,
    NOC = 3,
}

export class InviteModel {
    id: number;
    hospitalId: any;
    unitId: any;
    shiftId: any;
    clinicianId: any;
    orentationClinicianId: any;
    isTravelar: boolean;
}

export class ShiftTimeModel {
    id: number;
    hospitalId: any;
    unitId: any;
    shiftType: any;
    fromTime: string;
    toTime: string;
    isCreatedByFacility: boolean;
    IsCreatedAllUnit: boolean;
}

export enum Status {
    Pending = 1,
    Approved = 2,
    Rejected = 3,
    PositionClosed = 4,
    NotAvailable = 5,
    InProgress = 6,
    Decline = 7,
    Drop = 8,
    Cancel = 9,
}
export enum filterStatus {
    PendingApproval = 6,
    Approved = 2,
    Cancelled = 9,
}

export enum PayRateChangeStatus {
    Pending = 1,
    Approved = 2,
    Decline = 3,
}

export enum ClinicianTimeSheetStatus {
    InReview = 1,
    Approved = 2,
    Rejected = 3,
}

export enum TimeSheetStatus {
    InReview = 1,
    Approved = 2,
    Dispute = 3,
}
export class BulkShiftModel {
    id: number = 0;
    licenseTypeId: any;
    specialityId: any;
    credentialSet: any;
    instructionId: any;
    hospitalId: any;
    shiftTime: any;
}

export enum Shift {
    OpenShift = 1,
    Scheduled = 2,
    InProgress = 3,
    Completed = 4,
    CalledOut = 5,
    NCNS = 6,
    Released = 7,
    Cancel = 8,
    Unschedule = 9,
    MissingClockIn = 10,
    MissingClockOut = 11,
    LateCall = 12,
    Overtime = 13,
    InviteSent = 14,
    InviteDeclined = 15,
    InviteWithdraw = 16,
    OpenShiftLateCallCalledOut = 17,
    OpenShiftLateCallNCNS = 18,
}

export enum AuditLog {
    'General Data' = 1,
    'Map Specialty' = 2,
    Policy = 3,
    User = 4,
    'Credential Set' = 5,
    'Shift Time' = 6,
    'Break Time' = 7,
    Template = 8,
    Instruction = 9,
    'Price Rate' = 10,
    Job = 11,
    Shift = 12,
    Timesheet = 13,
    Note = 14,
    Clinician = 15,
    State = 16,
    City = 17,
    County = 18,
    'Unit Type' = 19,
    'Credential Category' = 20,
    'Public Holiday' = 21,
    'License Type' = 22,
    'Credential Type' = 23,
    'Background Check Type' = 24,
    Specialty = 25,
    Role = 26,
    Region = 27,
}

export enum HealthcareAuditLog {
    'General Data' = 1,
    'Map Specialty' = 2,
    Policy = 3,
    User = 4,
    'Credential Set' = 5,
    'Shift Time' = 6,
    'Break Time' = 7,
    Template = 8,
    Instruction = 9,
    'Price Rate' = 10,
    Job = 11,
    Shift = 12,
    Timesheet = 13,
    Note = 14,
    Clinician = 15,
}

export enum ShiftHistoryMonths {
    '1 Month' = 1,
    '3 Months' = 3,
    '6 Months' = 6,
    'All Time' = 0,
}

export enum ShiftCalendarStatus {
    'Open Shift' = 1,
    'Need Attention' = 2,
    'In Progress' = 3,
    'Scheduled' = 4,
}
