import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_CLINICIAN, API_SHIFT } from '../constants/api';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TimeSheetService {
    constructor(private apiService: ApiService) {}

    updateStatus(id: any, res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.PUT_SHIFT_ATTENDANCE_TIMESHEET + id, res);
    }

    getTimesheetById(id: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_ATTENDANCE_TIMESHEET_BY_ID + id);
    }

    getDisputeReasonSelectList(): Observable<any> {
        return this.apiService.get(API_CLINICIAN.GET_DISPUTE_SELECTED_LIST);
    }

    getDisputeTimesheetReason(type: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.GET_DISPUTE_TIMESHEET_REASON, type);
    }

    getTimeSheetDetailsById(id: any) {
        return this.apiService.get(API_SHIFT.GET_TIMESHEET_DETAILS_BY_ID + id);
    }

    updateTimeSheetStatus(id: any, res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.PUT_TIMESHIFT_STATUS + id + '/Status', res);
    }

    shiftAttendanceTimesheet(id: any, res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.PUT_TIMESHIFT_STATUS + id + '/Adjust', res);
    }

    shiftCancelTimesheet(id: any, res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.PUT_TIMESHIFT_STATUS + id + '/Cancel', res);
    }

    getTimeShiftFeedback(shiftClinicianId: any, companyId: any) {
        const params = new HttpParams().set('ShiftClinicianId', shiftClinicianId).set('CompanyId', companyId);
        return this.apiService.get(API_SHIFT.GET_SHIFT_FEEDBACK, { params });
    }
}
