import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API_JOB_PROFILE_SUMMARY, API_UNIT_TYPE } from 'src/app/constants/api';
import { JobService } from 'src/app/service/job.service';
import { DeleteModelComponent } from 'src/app/shared/components/delete-model/delete-model.component';
import { UpdateDocumentationStatusModelComponent } from './update-documentation-status-model/update-documentation-status-model.component';
import moment from 'moment';
import { JobApplicantDocumentationStatus } from 'src/app/model/clinicianProfile.model';
import { LocalDateTimePipe } from 'src/app/pipes/local-date-time.pipe';
import { UpdateVerficationModelComponent } from '../update-verfication-model/update-verfication-model.component';
import { ViewVerficationModelComponent } from '../view-verfication-model/view-verfication-model.component';

@Component({
    selector: 'app-documentation-verification-model',
    templateUrl: './documentation-verification-model.component.html',
    styleUrl: './documentation-verification-model.component.scss',
    providers: [LocalDateTimePipe],
})
export class DocumentationVerificationModelComponent implements OnInit {
    @Input() data: any;
    @Input() id: any;
    @Input() jobApplicantId: any;
    @Input() status: any;
    @Input() callingFrom: any;
    apiUrl: any;
    documentationList: any[] = [];
    skip: number = 0;
    take: number = 50;
    isLoading: boolean = false;
    allDataLoaded: boolean = false;
    moment: any = moment;
    isShowActionButtons: boolean = true;
    employeementFormList: any[] = [];
    noteMessage = 'Not found any W4 & I9 Document. Please Coordinate with nurse for to upload W4 and I9 Document.';

    constructor(public modal: NgbActiveModal, private modalService: NgbModal, public jobService: JobService) {}

    ngOnInit(): void {
        this.getDocumentationModel();
    }

    getDocumentationModel() {
        let obj = {
            Skip: this.skip,
            Take: this.take,
            searchText: '',
            clinicianId: this.data.clinicianId,
            jobId: this.data.jobId,
            jobApplicantId: this.jobApplicantId,
        };
        this.getDocumentationList(obj);
    }

    getDocumentationList(model: any) {
        this.jobService.getDocumentationList(model).subscribe(
            (response: any) => {
                if (response.result) {
                    var clinicianDocuments: any = response.result;
                    this.documentationList = clinicianDocuments.documents[0]?.clinicianDocuments ?? [];
                    this.employeementFormList = clinicianDocuments?.forms;

                    if (this.documentationList.length > 0) {
                        if (this.documentationList.some((x) => x.status == 'Declined')) {
                            this.isShowActionButtons = false;
                        }
                        if (
                            this.data?.status === 'Declined' ||
                            this.data?.status === 'Withdraw' ||
                            this.data?.status === 'Rejected' ||
                            this.data?.jobStatus == 'Canceled' ||
                            this.data?.jobStatus == 'Released' ||
                            this.data?.jobStatus == 'Declined' ||
                            this.data?.jobStatus == 'Withdraw'
                        ) {
                            this.isShowActionButtons = false;
                        }
                    }
                    if (this.callingFrom) {
                        this.employeementFormList = [clinicianDocuments?.forms.find((x: any) => this.data.id == x.id)];
                    }
                }
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
                // Handle error
            }
        );
    }

    close() {
        this.modal.close();
    }
    onDocumentShow(data: any) {
        window.open(data);
    }

    validDocument(id: any, status: boolean, type?: any) {
        var documentVerificationModel = {
            id: id,
            jobId: this.data.jobId,
            clinicianId: this.data.clinicianId,
            status: 'Approved',
            isVerified: true,
            verifiedDate: new Date().toISOString(),
            jobApplicantId: this.jobApplicantId,
        };
        const modalRef = this.modalService.open(DeleteModelComponent);
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.moduleName = 'Document';
        modalRef.componentInstance.moduleURL =
            type === 'employeementForm'
                ? API_JOB_PROFILE_SUMMARY.UPDATE_CLINICIAN_EMPLOYMENT_FORM_STATUS + id
                : API_JOB_PROFILE_SUMMARY.UPDATE_CLINICIAN_DOCUMENTATION_STATUS;
        modalRef.componentInstance.data = documentVerificationModel;
        modalRef.componentInstance.isDelete = false;
        modalRef.componentInstance.status = status;

        modalRef.componentInstance.jobApplicantId = this.jobApplicantId;
        modalRef.result.then(() => {
            this.documentationList = [];
            this.employeementFormList = [];
            this.getDocumentationModel();
        });
    }

    sendBackDocument(id: any, type?: any) {
        this.statusUpdate(id, JobApplicantDocumentationStatus.SentBack, type);
    }

    declineDocument(id: any, type?: any) {
        this.statusUpdate(id, JobApplicantDocumentationStatus.Declined, type);
    }

    statusUpdate(id: any, status: JobApplicantDocumentationStatus, type?: any) {
        const modalRef = this.modalService.open(UpdateDocumentationStatusModelComponent, {
            size: 'xl',
            backdrop: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.data = this.data;
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.moduleURL =
            type === 'employeementForm'
                ? API_JOB_PROFILE_SUMMARY.UPDATE_CLINICIAN_EMPLOYMENT_FORM_STATUS + id
                : API_JOB_PROFILE_SUMMARY.UPDATE_CLINICIAN_DOCUMENTATION_STATUS;
        modalRef.componentInstance.status = status;
        modalRef.componentInstance.jobApplicantId = this.jobApplicantId;
        modalRef.componentInstance.label =
            status == JobApplicantDocumentationStatus.SentBack
                ? 'Documentation - Send back'
                : 'Documentation - Decline';
        modalRef.componentInstance.type = type;
        modalRef.result.then(
            () => {
                this.documentationList = [];
                this.getDocumentationModel();
            },
            () => {}
        );
    }

    onUpdateFormStatus(data: any) {
        const modalRef = this.modalService.open(UpdateVerficationModelComponent, {
            size: 'xl',
            backdrop: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.data = this.data;
        modalRef.componentInstance.id = data.id;
        modalRef.componentInstance.moduleURL = API_JOB_PROFILE_SUMMARY.UPDATE_CLINICIAN_EMPLOYMENT_FORM_STATUS;
        modalRef.componentInstance.label = 'Validate Employment Form';
        modalRef.componentInstance.type = 'EmployementForm';
        modalRef.componentInstance.displayFor = 'renew';
        modalRef.componentInstance.jobApplicantId = this.jobApplicantId;

        modalRef.result.then(
            (res: any) => {
                if (res.isSuccess == true) {
                    this.documentationList = [];
                    this.getDocumentationModel();
                    this.employeementFormList = [];
                }
            },
            () => {}
        );
    }

    onViewEmployementForm(data: any) {
        const modalRef = this.modalService.open(ViewVerficationModelComponent, {
            size: 'xl',
            backdrop: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.id = data.id;
        modalRef.componentInstance.label = 'Employment Form';
        modalRef.componentInstance.type = 'EmployementForm';
        modalRef.componentInstance.data = this.data;
    }

    formatNumber(number: any) {
        return number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }
}
