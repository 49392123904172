import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/model/clinicianProfile.model';
import { NotesModel } from 'src/app/model/notes.model';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { HospitalService } from 'src/app/service/hospital.service';
import { JobTemplateService } from 'src/app/service/jobTemplate.service';
import { RegionService } from 'src/app/service/region.service';
import { StaffService } from 'src/app/service/staff.service';

@Component({
    selector: 'app-add-view-note',
    templateUrl: './add-view-note.component.html',
    styleUrls: ['./add-view-note.component.scss'],
    providers: [DatePipe],
})
export class AddViewNoteComponent {
    noteDetailsForm: FormGroup;
    @Input() model: any;
    @Input() isAddNoteMode: boolean = true;
    @Input() hospitalId: any;
    @Input() marketId: any;
    @Input() companyId: any;
    @Input() clinicianId: any;
    firstName: any;
    lastName: any;
    setUtcDate: any;
    currentDateTime: any;
    categoryList: any[] = [];
    selectedCategoryId: any;
    selectedFacilityId: any;
    contactId: any;
    noteDetails: any;
    public notesModel: any = new NotesModel();
    facilityList = [];
    isSelectDisable: boolean = false;

    constructor(
        public modal: NgbActiveModal,
        private datePipe: DatePipe,
        private fb: FormBuilder,
        public dateUtilService: DateUtilService,
        private jobTemplateService: JobTemplateService,
        public toastrService: ToastrService,
        private hospitalService: HospitalService,
        private staffService: StaffService
    ) {}

    ngOnInit() {
        this.getFacilitylist();
        this.firstName = sessionStorage.getItem('userFirstName');
        this.lastName = sessionStorage.getItem('userLastName');
        const currentDate = new Date();
        this.currentDateTime = this.datePipe.transform(currentDate, "MMM dd, yyyy 'at' h:mm a");

        this.categoryList = this.getEnumList(Category);
        this.contactId = sessionStorage.getItem('contactId');
        this.initForm();

        if (!this.isAddNoteMode) {
            this.viewNote();
        }
        if (this.hospitalId) {
            this.isSelectDisable = true;
            this.selectedFacilityId = Number(this.hospitalId);
            this.noteDetailsForm.controls.hospitalId.setValue(this.hospitalId);
        }
    }

    getEnumList(enumType: any): { id: number; name: string }[] {
        return Object.keys(enumType)
            .filter((key) => isNaN(Number(key)))
            .map((key) => ({ id: enumType[key], name: key }));
    }

    initForm() {
        this.noteDetailsForm = this.fb.group({
            id: [this.notesModel?.id],
            hospitalId: [this.notesModel.hospitalId, Validators.required],
            clinicianId: [this.clinicianId],
            contactId: [this.contactId],
            category: [this.notesModel.category],
            note: [this.notesModel.note, Validators.required],
            referenceId: [null],
            date: [this.currentDateTime],
        });
    }

    close() {
        this.modal.close();
    }

    onCategoryChange(event: any) {
        this.selectedCategoryId = event;
        this.noteDetailsForm.controls.category.setValue(event);
    }

    get f() {
        return this.noteDetailsForm.controls;
    }

    save() {
        if (this.noteDetailsForm.invalid) {
            return;
        }

        const result = Object.keys(this.f).reduce((acc: any, key) => {
            acc[key] = this.f[key].value;
            return acc;
        }, {});

        // Convert local date to UTC
        if (result.date) {
            result.date = this.dateUtilService.convertCurrentDateToUTC();
        }

        const notesModel = new NotesModel();
        notesModel.setSatetData(result);

        if (this.isAddNoteMode) {
            this.addNote(notesModel);
        }
    }

    addNote(notesModel: any) {
        this.jobTemplateService.addNote(notesModel).subscribe(
            (response: any) => {
                if (response.result != null) {
                    this.modal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
            },
            (err) => this.toastrService.error(err.error.message)
        );
    }

    viewNote() {
        this.jobTemplateService.viewNote(this.model.id).subscribe(
            (response: any) => {
                if (response.result) {
                    this.noteDetails = response.result;
                    // Convert UTC to local time
                    const localDate = moment.utc(this.noteDetails.date).local().format('MMM DD, yyyy [at] h:mm a');

                    this.noteDetails.date = localDate;
                } else {
                    this.toastrService.error(response.message);
                }
            },
            (err) => this.toastrService.error(err.error.message)
        );
    }

    getFacilitylist() {
        let clinicianId = this.clinicianId;

        this.staffService
            .getHirecClinicianHospitalList(clinicianId, '', this.companyId, '')
            .subscribe((response: any) => {
                this.facilityList = response.result;
            });
    }

    onFacilityChange(event: any) {
        this.selectedFacilityId = event;
        this.noteDetailsForm.controls.hospitalId.setValue(event);
    }
}
