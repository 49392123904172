import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'convertToUtcPipe',
})
export class ConvertToUtcPipe implements PipeTransform {
    transform(localDate: any, localTime: any, timezone: any): any {
        // Combine date and time strings into a single datetime string
        const localDateTimeString = localDate + 'T' + localTime;

        // Create a date object in the local timezone
        const localDateTime = new Date(localDateTimeString);

        // Get the offset in minutes for the specified timezone
        const timeZoneOffsetInMinutes = new Date(
            localDateTime.toLocaleString('en-US', { timeZone: timezone })
        ).getTimezoneOffset();

        // Calculate the UTC time
        const utcTime = new Date(localDateTime.getTime() + timeZoneOffsetInMinutes * 60000);

        return utcTime; // Returns the UTC time in ISO format
    }
}

@Pipe({
    name: 'convertLocalTimeToUtc',
})
export class LocalTimeToUtcPipe implements PipeTransform {
    transform(input: any): any {
        // Check if the input is just a time string in HH:mm or HH:mm:ss format
        if (/^\d{2}:\d{2}(:\d{2})?$/.test(input)) {
            // Handle HH:mm and HH:mm:ss formats
            if (/^\d{2}:\d{2}$/.test(input)) {
                return moment(input, 'HH:mm').utc().format('HH:mm:00');
            } else if (/^\d{2}:\d{2}:\d{2}$/.test(input)) {
                return moment(input, 'HH:mm:ss').utc().format('HH:mm:00');
            }
        } else {
            // Assuming the input is a full date with time
            return moment.utc(input).format('HH:mm:00');
        }
    }
}
