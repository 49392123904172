import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeSheetService } from 'src/app/service/timesheet.service';

@Component({
    selector: 'app-nurse-rating',
    templateUrl: './nurse-rating.component.html',
    styleUrls: ['./nurse-rating.component.scss'],
})
export class NurseRatingComponent {
    constructor(public modal: NgbActiveModal, public timeSheetService: TimeSheetService) {}
    title = 'Nurse Rating';
    @Input() model: any;
    feedback: any = [];

    ngOnInit() {
        this.getNurseRating();
    }

    getNurseRating() {
        this.timeSheetService
            .getTimeShiftFeedback(this.model.shiftClinicianId, this.model.companyId)
            .subscribe((res) => {
                if (res.result) {
                    this.feedback = res.result;
                }
            });
    }
}
