<div class="profile-modal-content modal-content fixwidth">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <h4>Nurse Profile</h4>
    </div>
    <form
        [formGroup]="nurseRestrictionForm"
        class="form form-label-right"
        novalidate="novalidate"
        id="kt_category_form"
        [appUnsavedChanges]="nurseRestrictionForm"
        [hasFormChanged]="hasFormChanged.bind(this)"
    >
        <div class="modal-body p-3 pt-0">
            <div class="profile-model-box">
                <div class="profile-model-img">
                    <figure>
                        <img *ngIf="model?.clinicianProfileUrl != null" src="{{ model.clinicianProfileUrl }}" alt="" />
                        <img *ngIf="model?.clinicianProfileUrl == null" src="./assets/images/others/blank.png" />
                    </figure>
                </div>
                <div class="profile-model-info">
                    <h4>
                        {{ ((model?.firstName | titlecase) ?? '') + ' ' + ((model?.lastName | titlecase) ?? '') }}
                    </h4>
                    <p class="text-primary">
                        {{
                            (model?.clinicianCityName ? model?.clinicianCityName + ', ' : '') +
                                (model?.clinicianStateName ?? '')
                        }}
                    </p>
                    <div class="label-list">
                        <label
                            class="badge fw-bolder"
                            [ngStyle]="{
                                'background-color': type?.chipBackgroundColor,
                                color: type?.chipFontColor
                            }"
                            *ngFor="let type of model?.clinicianLicences"
                            >{{ type?.acronym ?? '' }}</label
                        >
                    </div>
                </div>
            </div>
            <div class="profile-model-nav">
                <div class="mb-3" *ngIf="type != 'active'">
                    <label class="form-label required"
                        >{{
                            type === 'inactive'
                                ? 'Inactivation Type'
                                : type === 'terminate'
                                ? 'Terminate Type'
                                : 'Restriction type'
                        }}
                    </label>
                    <div class="mt-2">
                        <div *ngFor="let restrictionType of restrictionTypeList">
                            <div class="form-check form-check-inline mt-2">
                                <input
                                    type="radio"
                                    name="restrictionType"
                                    class="form-check-input cursor-pointer"
                                    formControlName="restrictionType"
                                    [checked]="restrictionType.id === selectedRestrictiontype"
                                    [value]="restrictionType.id"
                                    (change)="onRestrictionTypeChange(restrictionType.id)"
                                />
                                <label
                                    class="form-check-label fw-normal ms-1 d-flex justify-content-center align-items-center"
                                >
                                    {{ restrictionType.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-12 dropdown-box mb-2"
                    style="z-index: 6"
                    *ngIf="
                        (selectedRestrictiontype == 2 ||
                            selectedRestrictiontype == 4 ||
                            selectedRestrictiontype == 6 ||
                            selectedRestrictiontype == 10 ||
                            type == 'active') &&
                        (isCompanyLevelLogin || isFacilityLevelLogin || isAgencyLevelLogin)
                    "
                >
                    <div class="form-group">
                        <app-selectbox
                            [selectItems]="hospitalList"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select the facility"
                            [isSearchable]="true"
                            [selectedFieldId]="selectedHospitalIds"
                            (onChangeSelect)="onHospitalSelectionChange($event)"
                            requiredLableName="Select the facilities to {{
                                selectedRestrictiontype == 3 || selectedRestrictiontype == 4
                                    ? 'suspend'
                                    : type == 'terminate'
                                    ? 'Terminate'
                                    : type == 'active'
                                    ? 'active'
                                    : selectedRestrictiontype == 6
                                    ? 'inactive'
                                    : 'DNR'
                            }}"
                            [isRequired]="true"
                            [isMultiple]="true"
                            [closeOnSelect]="false"
                            lableName="Select the facilities to {{
                                selectedRestrictiontype == 3 || selectedRestrictiontype == 4
                                    ? 'suspend'
                                    : type == 'terminate'
                                    ? 'Terminate'
                                    : type == 'active'
                                    ? 'active'
                                    : selectedRestrictiontype == 6
                                    ? 'inactive'
                                    : 'DNR'
                            }}"
                            controlName="hospitalIds"
                            formControlName="hospitalIds"
                            [isSelectDisable]="isSelectDisable"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div
                    class="col-md-12 dropdown-box mb-2"
                    style="z-index: 6"
                    *ngIf="
                        (selectedRestrictiontype == 2 ||
                            selectedRestrictiontype == 4 ||
                            selectedRestrictiontype == 6 ||
                            selectedRestrictiontype == 10 ||
                            type == 'active') &&
                        isThriveLevelLogin
                    "
                >
                    <div class="form-group">
                        <app-selectbox
                            [selectItems]="companyList"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select the healthcare"
                            [isSearchable]="true"
                            [selectedFieldId]="selectedCompanyIds"
                            (onChangeSelect)="onCompanySelectionChange($event)"
                            requiredLableName="Select the healthcare systems to {{
                                selectedRestrictiontype == 3 || selectedRestrictiontype == 4
                                    ? 'suspend'
                                    : type == 'terminate'
                                    ? 'Terminate'
                                    : type == 'active'
                                    ? 'active'
                                    : selectedRestrictiontype == 6
                                    ? 'inactive'
                                    : 'DNR'
                            }}"
                            [isRequired]="true"
                            [isMultiple]="true"
                            lableName="Select the healthcare systems to {{
                                selectedRestrictiontype == 3 || selectedRestrictiontype == 4
                                    ? 'suspend'
                                    : type == 'terminate'
                                    ? 'Terminate'
                                    : type == 'active'
                                    ? 'active'
                                    : selectedRestrictiontype == 6
                                    ? 'inactive'
                                    : 'DNR'
                            }}"
                            controlName="companyIds"
                            formControlName="companyIds"
                            [isSelectDisable]="isSelectDisable"
                            [closeOnSelect]="false"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="(selectedRestrictiontype == 3 || selectedRestrictiontype == 4) && !type">
                    <div class="col-md-6 mb-2">
                        <div class="form-group date-picker-control">
                            <app-datepicker
                                controlName="suspendedFrom"
                                fieldName="suspendedFrom"
                                fieldId="suspendedFrom"
                                [selectedFieldDate]="selectedDate"
                                (onDateSelect)="onDateSelectChange($event, 'from')"
                                requiredLableName="Suspended from"
                                lableName="Suspended from"
                                [isRequired]="true"
                                [formName]="nurseRestrictionForm"
                            >
                            </app-datepicker>
                        </div>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="form-group date-picker-control">
                            <app-datepicker
                                controlName="suspendedTill"
                                fieldName="suspendedTill"
                                fieldId="suspendedTill"
                                [selectedFieldDate]="selectedDate"
                                (onDateSelect)="onDateSelectChange($event, 'till')"
                                requiredLableName="Suspended until"
                                lableName="Suspended until"
                                [isRequired]="true"
                                [formName]="nurseRestrictionForm"
                            >
                            </app-datepicker>
                        </div>
                    </div>
                </div>

                <div class="dropdown-box mb-3" *ngIf="type != 'active'">
                    <div class="form-group">
                        <app-selectbox
                            [selectItems]="restrictionReasonList"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select Reason"
                            [isSearchable]="true"
                            [selectedFieldId]="selectedRestrictReason"
                            (onChangeSelect)="onRestrictionReasonChange($event)"
                            requiredLableName="Reason"
                            [isRequired]="true"
                            [isMultiple]="false"
                            lableName="Reason"
                            controlName="reasonId"
                            formControlName="reasonId"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div class="form-group">
                    <app-textarea
                        label="{{
                            type != null ? 'Additional information' : 'Additional information about the suspension'
                        }}"
                        [fieldName]="'Additional information about the suspension'"
                        [placeholder]="'Please add additional information here'"
                        [isRequired]="
                            type != null ? (selectedRestrictReason == 31 || type == 'active' ? true : false) : true
                        "
                        [rows]="5"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="nurseRestrictionForm"
                        formControlName="description"
                    >
                    </app-textarea>
                </div>
                <div class="mt-2" *ngIf="isShowWarning">
                    <span class="warning fw-bolder">
                        {{ (model?.firstName | titlecase) + ' ' + (model?.lastName | titlecase) }} is not part of any
                        {{ isThriveLevelLogin ? 'healthcare' : 'facility' }} so no need of
                        {{ [1, 2].includes(selectedRestrictiontype) ? 'DNR' : 'Suspend' }} operation.
                    </span>
                </div>
                <div *ngIf="attentionRecordsList.length > 0">
                    <h5 class="mb-2 mt-3">Future Shifts</h5>
                    <table class="table table-bordered">
                        <tbody class="mt-2 table-content">
                            <tr>
                                <th>Date</th>
                                <th>From Time</th>
                                <th>To Time</th>
                                <th>Status</th>
                                <th>License</th>
                                <th>Specialty</th>
                            </tr>
                            <tr *ngFor="let item of attentionRecordsList">
                                <td class="w-20 text-secondary">
                                    {{ item.date | localDateTime : 'ordinal' }}
                                </td>
                                <td class="w-15 text-secondary">
                                    {{ item.fromTime | localDateTime : 'shortTime' }}
                                </td>
                                <td class="w-15 text-secondary">
                                    {{ item.toTime | localDateTime : 'shortTime' }}
                                </td>

                                <td class="w-15 text-secondary">
                                    {{ item.statusName }}
                                </td>
                                <td class="w-15 text-secondary">
                                    {{ item.licenseName }}
                                </td>
                                <td class="w-15 text-secondary">
                                    {{ item.specialtyName }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer p-3">
            <button type="button" class="btn btn-outline-primary fw-semibold px-4 px-md-5 ms-3'" (click)="onClose()">
                Close
            </button>
            <button
                type="button"
                class="btn btn-primary fw-semibold px-4 px-md-5"
                (click)="onRestriction()"
                [disabled]="nurseRestrictionForm.invalid || !hasFormChanged()"
            >
                Save
            </button>
        </div>
    </form>
</div>
