import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PaginationService {
    private localStorageKey = 'userPageSize';
    private defaultPageSize: number = environment.paging.defaultPageSize;

    constructor() {}

    // Retrieve the page size (from localStorage or fallback to default)
    getRecordsPerPage(): number {
        const storedPageSize = localStorage.getItem(this.localStorageKey);
        return storedPageSize != null ? parseInt(storedPageSize, 10) : this.defaultPageSize;
    }

    // Save the user's selected page size
    setRecordsPerPage(pageSize: number): void {
        localStorage.setItem(this.localStorageKey, pageSize?.toString());
    }
}
