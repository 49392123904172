import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StatusColorService {
    private statusColors: any = {
        'Open Shift': {
            background: '#EEEEEE',
            text: '#4E4D4D',
            border: '#9D9D9D',
        },
        'In Progress': {
            background: '#EBFBFF',
            text: '#074B68',
            border: '#3ADCFF',
        },
        Scheduled: {
            background: '#FEF2FF',
            text: '#653069',
            border: '#E586ED',
        },
        Overtime: {
            background: '#F00000',
            text: '#FFFFFF',
            border: '#65130D',
        },
        Completed: {
            background: '#EDFFF4',
            text: '#162B1E',
            border: '#69C68E',
        },
        'Level 1': {
            background: '#FFF6EF',
            text: '#4E4D4D',
            border: '#B5A40E',
        },
        'Level 2': {
            background: '#FFD8B4',
            text: '#4E4D4D',
            border: '#B5A40E',
        },
        'Level 3': {
            background: '#FFB673',
            text: '#4E4D4D',
            border: '#B5A40E',
        },
        'Invite Sent': {
            background: '#EEEEEE',
            text: '#4E4D4D',
            border: '#9D9D9D',
        },
        default: {
            background: '#FFF2F2',
            text: '#3D0E0E',
            border: '#FF5045',
        },
    };

    constructor() {}

    getStatusColor(status: string, type: 'background' | 'text' | 'border'): string {
        const trimmedStatus = status?.trim();
        const statusColor = this.statusColors[trimmedStatus] || this.statusColors['default'];
        return statusColor[type] || this.statusColors['default'][type];
    }
}
