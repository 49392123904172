import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CustomRendererService {
    constructor() {}

    getUsernameRenderer() {
        return (data: any, type: any, full: any) => {
            return `<span class="custom-prefix">User:</span> ${data}`;
        };
    }

    getIconRenderer() {
        return (data: any, type: any, row: any) => {
            if (row?.logo) {
                return (
                    '<div class="mr-5"><span class=""><img alt="" src="' +
                    row?.logo +
                    '" class="h-100 align-self-center" action-type="preview" style="cursor: pointer;height: 50px !important;" /></span></div>'
                );
            } else {
                return '';
            }
        };
    }
}
