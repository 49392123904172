import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobTemplateService } from 'src/app/service/jobTemplate.service';
import { ShiftService } from 'src/app/service/shift.service';
import { ConfirmationModelComponent } from '../confirmation-model/confirmation-model.component';
import { InspectorUpdateStatusComponent } from './inspector-update-status/inspector-update-status.component';
import { Subject } from 'rxjs';
import {
    comparePricesWithBillRatesValidator,
    noWhitespaceValidator,
    onlyNumberAndAfterDotTwoDigitValidator,
    removeSpaces,
} from 'src/app/utill/validator';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { API_SHIFT } from 'src/app/constants/api';
import { StatusColorService } from 'src/app/service/statusColor.service';
import { ViewProfileModelComponent } from '../../job/view-profile-model/view-profile-model.component';
import { StaffService } from 'src/app/service/staff.service';
import { ShiftHistoryMonths } from 'src/app/model/shift.model';
import { PriceService } from 'src/app/service/price.service';
import { DatePipe } from '@angular/common';
import { BaseMasterFormComponent } from 'src/app/shared/components/base-master-form.component';

@Component({
    selector: 'app-shift-inspector-details',
    templateUrl: './shift-inspector-details.component.html',
    styleUrls: ['./shift-inspector-details.component.css'],
})
export class ShiftInspectorDetailsComponent extends BaseMasterFormComponent implements OnInit {
    @Input() data: any;
    @Input() date: any;
    @Input() hospitalName: any = null;

    @Input() hospitalId: any;
    @Input() companyId: any;
    @Input() marketId: any;

    inspectorDetails: any;
    shiftTypePosition: any = null;
    selectedDate: any;
    selectedShiftTime: any;
    shiftTimeList: any[] = [];
    shiftDetailsForm: FormGroup;
    title = 'Shift Details';
    unsubscribe$ = new Subject<void>();
    rescheduleShiftForm: FormGroup;
    shiftDate: any;
    fromTime: any;
    toTime: any;
    status: any[] = [
        { id: false, name: 'Approve the call out excuse' },
        { id: true, name: 'Decline the call out excuse' },
    ];
    selectedStatus: any;
    contactId: any;
    isAgencyLevelLogin: any;
    isFacilityOperatingAllLevel: any;
    commonValidation: any = Validators.compose([
        Validators.required,
        Validators.maxLength(6),
        onlyNumberAndAfterDotTwoDigitValidator,
    ]);
    hours: any;
    selectedMonth: any = 6;
    shiftHistoryMonths: any[] = [];
    priceList: any[] = [];
    holidaysList: any;
    isHoliday: any;
    isWeekend: any;
    matchedPricesList: any[] = [];

    constructor(
        private shiftService: ShiftService,
        private jobTemplateService: JobTemplateService,
        public activeModal: NgbActiveModal,
        private modal: NgbModal,
        private fb: FormBuilder,
        public toastrService: ToastrService,
        public dateUtilService: DateUtilService,
        private statusColorService: StatusColorService,
        private cdr: ChangeDetectorRef,
        private staffService: StaffService,
        public priceService: PriceService,
        private datePipe: DatePipe,
        confirmationModalService: NgbModal
    ) {
        super(confirmationModalService);
        this.contactId = sessionStorage.getItem('contactId');

        let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
        this.isAgencyLevelLogin = Boolean(JSON.parse(isAgencyLevelLogin));
    }

    ngOnInit() {
        this.getPriceRateByHospital();
        this.initForm();
        this.shiftHistoryMonths = this.getShiftHistoryMonths(ShiftHistoryMonths);
        if (
            [
                'Open Shift',
                'Open Shift, Late Call',
                'Open Shift, Called Out',
                'Open Shift, Late Call, Called Out',
                'Open Shift, NCNS',
                'Open Shift, Late Call, NCNS',
            ].includes(this.data.status)
        ) {
            this.getShiftInspectionOpenPositionDetail();
        } else if (
            [
                'Missing Clock-in',
                'Completed',
                'In Progress',
                'Scheduled',
                'Missing Clock-out',
                'Overtime, Scheduled',
                'Invite Sent',
                'Invite Withdraw',
                'Invite Declined',
                'Cancel',
            ].includes(this.data.status)
        ) {
            this.getShiftInspectorCompletedDetail();
        } else if (this.data.status == 'NCNS' || this.data.status == 'Released' || this.data.status == 'Unschedule') {
            this.getShiftInspectionNCNCDetail();
        } else {
            this.getInspectorCallOutDetails();
        }

        this.getShiftTimeSelectList('', this.data.hospitalId, this.data.companyId ? this.data.companyId : ''); 
        this.storeInitialFormState();      
    }

    initForm() {
        this.shiftDetailsForm = this.fb.group({
            date: [null],
            shiftTimeId: [null],
            level1Price: ['', Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator])],
            level2Price: ['', Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator])],
            level3Price: ['', Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator])],
            level1LateCallBonus: [
                '',
                Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator]),
            ],
            level2LateCallBonus: [
                '',
                Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator]),
            ],
            level3LateCallBonus: [
                '',
                Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator]),
            ],
            publicComment: ['', Validators.compose([])],
            comment: ['', Validators.compose([])],
            isDeclineExcuse: ['', Validators.compose([])],
            level3BillRate: ['', Validators.compose([Validators.maxLength(6), onlyNumberAndAfterDotTwoDigitValidator])],
            toTime: [''],
            fromTime: [''],
        });        
    }

    getInspectorCallOutDetails() {
        let reqObj = {
            id: this.data.shiftPositionId,
            marketId: !this.marketId ? null : this.marketId,
            companyId: !this.companyId ? null : this.companyId,
            hospitalId: !this.hospitalId ? null : this.hospitalId,
            clinicianId: this.data.clinicianId,
        };
        this.shiftService.getShiftInspectorCallOutDetails(reqObj).subscribe((response: any) => {
            if (response.result) {
                this.inspectorDetails = response.result;
                this.setRequiredValidators();
            }
        });
    }

    setRequiredValidators() {
        // Helper function to clear and update validators for a list of controls
        const clearAndRefreshValidators = (controlNames: string[]) => {
            controlNames.forEach((controlName) => {
                const control = this.shiftDetailsForm.controls[controlName];
                control.clearValidators();
                control.updateValueAndValidity();
            });
        };

        // List of control names to clear validators for
        const fieldsToClear = [
            'level1Price',
            'level2Price',
            'level3Price',
            'level1LateCallBonus',
            'level2LateCallBonus',
            'level3LateCallBonus',
        ];

        // Clear validators for specific fields
        clearAndRefreshValidators(fieldsToClear);

        // Set required validators for other fields
        this.shiftDetailsForm.controls.comment.setValidators([
            Validators.required,
            noWhitespaceValidator,
            removeSpaces,
        ]);
        this.shiftDetailsForm.controls.publicComment.setValidators([
            Validators.required,
            noWhitespaceValidator,
            removeSpaces,
        ]);
        this.shiftDetailsForm.controls.isDeclineExcuse.setValidators([Validators.required]);

        // Update the value and validity of the form controls that had validators set
        ['comment', 'publicComment', 'isDeclineExcuse'].forEach((controlName) => {
            this.shiftDetailsForm.controls[controlName].updateValueAndValidity();
        });
    }

    getShiftInspectorCompletedDetail() {
        let reqObj = {
            id: this.data.shiftPositionId,
            marketId: !this.marketId ? null : this.marketId,
            companyId: !this.companyId ? null : this.companyId,
            hospitalId: !this.hospitalId ? null : this.hospitalId,
            clinicianId: this.data.clinicianId,
        };
        this.shiftService.getShiftInspectionDetail(reqObj).subscribe((response: any) => {
            if (response.result) {
                this.inspectorDetails = response.result;
            }
        });
    }

    getShiftInspectionOpenPositionDetail() {
        this.isFacilityOperatingAllLevel = null;
        this.shiftService
            .getShiftInspectionOpenPositionDetail(0, this.data.shiftPositionId)
            .subscribe((response: any) => {
                if (response.result) {
                    this.inspectorDetails = response.result;
                }
            });
    }

    getShiftInspectionNCNCDetail() {
        let reqObj = {
            id: this.data.shiftPositionId,
            marketId: !this.marketId ? null : this.marketId,
            companyId: !this.companyId ? null : this.companyId,
            hospitalId: !this.hospitalId ? null : this.hospitalId,
            clinicianId: this.data.clinicianId,
        };
        this.shiftService.getShiftInspectionNCNSDetail(reqObj).subscribe((response: any) => {
            if (response.result) {
                this.inspectorDetails = response.result;
            }
        });
    }

    dismissModel() {
        this.dismissModal(this.activeModal); 
    }

    onShiftPositionChange(value: any) {
        this.selectedShiftTime = null;
        this.selectedDate = null;

        if (value) {
            this.initForm();
        }
        this.shiftTypePosition = value;

        if (value == 2) {
            this.shiftDetailsForm.controls.date.setValidators(Validators.required);
            this.shiftDetailsForm.controls.shiftTimeId.setValidators(Validators.required);
            this.shiftDetailsForm.controls.date.updateValueAndValidity();
            this.shiftDetailsForm.controls.shiftTimeId.updateValueAndValidity();
        }

        if (value == 3 || value == 4) {
            this.initForm();
            if (value == 3) {
                this.shiftDetailsForm.controls.date.setValidators(Validators.required);
                this.shiftDetailsForm.controls.shiftTimeId.setValidators(Validators.required);
            } else {
                this.shiftDetailsForm.controls.date.clearValidators();
                this.shiftDetailsForm.controls.shiftTimeId.clearValidators();
            }
            this.shiftDetailsForm.controls.date.updateValueAndValidity();
            this.shiftDetailsForm.controls.shiftTimeId.updateValueAndValidity();

            // Custom validator for any of the prices and bonuses
            const anyPriceBonusRequired: any = () => {
                return (formGroup: FormGroup) => {
                    const controls = [
                        formGroup.controls.level1Price,
                        formGroup.controls.level2Price,
                        formGroup.controls.level3Price,
                        formGroup.controls.level1LateCallBonus,
                        formGroup.controls.level2LateCallBonus,
                        formGroup.controls.level3LateCallBonus,
                        formGroup.controls.level3BillRate,
                    ];
                    const anyFilled = controls.some((control) => !!control.value);
                    return anyFilled ? null : { required: true };
                };
            };

            this.shiftDetailsForm.setValidators([
                comparePricesWithBillRatesValidator('level3Price', 'level3BillRate'),
                anyPriceBonusRequired(),
            ]);
        }
        if (value !== 1) {
            this.isFacilityOperatingAllLevel = this.inspectorDetails?.isFacilityOperatingAllLevel;
            this.shiftDetailsForm.controls.level1Price.setValue(
                this.isFacilityOperatingAllLevel ? this.inspectorDetails?.level1Price : null
            );
            this.shiftDetailsForm.controls.level2Price.setValue(
                this.isFacilityOperatingAllLevel ? this.inspectorDetails?.level2Price : null
            );
            this.shiftDetailsForm.controls.level3Price.setValue(this.inspectorDetails?.level3Price);
            this.shiftDetailsForm.controls.level3BillRate.setValue(this.inspectorDetails?.level3BillRate);
            if (this.inspectorDetails?.level3Price !== null || this.inspectorDetails?.level3BillRate !== null) {
                this.shiftDetailsForm.controls.level3BillRate.setValidators(this.commonValidation);
                this.shiftDetailsForm.controls.level3Price.setValidators(this.commonValidation);
                this.shiftDetailsForm.controls.level3Price.updateValueAndValidity();
                this.shiftDetailsForm.controls.level3BillRate.updateValueAndValidity();
            }
        }

        this.shiftDetailsForm.updateValueAndValidity();          
        this.storeInitialFormState();   
    }

    onPriceBonusChanges(value: any, event: any) {
        if (value == 'level1Price') {
            this.shiftDetailsForm.controls.level1Price.setValue(event.target.value);
        }
        if (value == 'level2Price') {
            this.shiftDetailsForm.controls.level2Price.setValue(event.target.value);
        }
        if (value == 'level3Price') {
            if (event.target.value?.length > 0) {
                this.shiftDetailsForm.controls.level3BillRate.setValidators(this.commonValidation);
            } else {
                this.shiftDetailsForm.controls.level3BillRate.clearValidators();
            }
            this.shiftDetailsForm.controls.level3Price.setValue(event.target.value);
            this.shiftDetailsForm.controls.level3BillRate.updateValueAndValidity();
        }
        if (value == 'level1LateCallBonus') {
            this.shiftDetailsForm.controls.level1LateCallBonus.setValue(event.target.value);
        }
        if (value == 'level2LateCallBonus') {
            this.shiftDetailsForm.controls.level2LateCallBonus.setValue(event.target.value);
        }
        if (value == 'level3LateCallBonus') {
            this.shiftDetailsForm.controls.level3LateCallBonus.setValue(event.target.value);
        }
        if (value == 'level3BillRate') {
            if (event.target.value.length > 0) {
                this.shiftDetailsForm.controls.level3Price.setValidators(this.commonValidation);
            } else {
                this.shiftDetailsForm.controls.level3Price.clearValidators();
            }
            this.shiftDetailsForm.controls.level3BillRate.setValue(event.target.value);
            this.shiftDetailsForm.controls.level3Price.updateValueAndValidity();
        }

        this.shiftDetailsForm.updateValueAndValidity();
    }

    onDateSelectChange(event: any) {
        this.shiftDate = event;
        this.selectedDate = this.dateUtilService.parseDateString(this.shiftDate);

        // Determine if the selected date is a weekend (assuming Sunday=0 and Saturday=6)
        const dayOfWeek = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day).getDay();
        this.isWeekend = dayOfWeek === 0 || dayOfWeek === 6;

        this.checkIfHoliday(event); // Check if selected date is a holiday

        // Set the date in the form control
        this.shiftDetailsForm.controls.date.setValue(this.shiftDate);
        this.getRateByDay();
        this.getLocalToUtcTime();
    }

    checkIfHoliday(date: Date) {
        // date = new Date(date);
        const dateString = this.datePipe.transform(date, 'yyyy-MM-dd'); // Format the date as "YYYY-MM-DD"
        this.isHoliday = this.holidaysList.some((holiday: any) => {
            const holidayDate = this.datePipe.transform(holiday.date, 'yyyy-MM-dd');
            return holidayDate == dateString;
        });
    }

    getRateByDay() {
        if (this.matchedPricesList && this.matchedPricesList.length > 0) {
            this.matchedPricesList.forEach((element) => {
                const getPrice = () => {
                    if (this.isHoliday) {
                        if (this.isWeekend && element.weekendHolidayPrice) {
                            return element.weekendHolidayPrice; // Specific price for holidays that fall on weekends
                        }
                        return element.holidayPrice; // Price for holidays
                    } else if (this.isWeekend) {
                        return element.weekendPrice; // Price for weekends
                    } else {
                        return element.regularPrice; // Regular price for weekdays
                    }
                };

                const getBillRate = () => {
                    if (this.isHoliday) {
                        if (this.isWeekend && element.billRateWeekendHolidayPrice) {
                            return element.billRateWeekendHolidayPrice; // Specific bill rate for holidays that fall on weekends
                        }
                        return element.billRateHolidayPrice; // Bill rate for holidays
                    } else if (this.isWeekend) {
                        return element.billRateWeekendPrice; // Bill rate for weekends
                    } else {
                        return element.billRateRegularPrice; // Regular bill rate for weekdays
                    }
                };

                // Applying prices and bill rates based on the level
                switch (element.level) {
                    case 1:
                        this.shiftDetailsForm.controls.level1Price.patchValue(getPrice());
                        break;
                    case 2:
                        this.shiftDetailsForm.controls.level2Price.patchValue(getPrice());
                        break;
                    case 3:
                        this.shiftDetailsForm.controls.level3Price.patchValue(getPrice());
                        this.shiftDetailsForm.controls.level3BillRate.patchValue(getBillRate()); // Correctly set bill rate for level 3
                        break;
                }
            });
        } else {
            // Clear rates if no prices are matched
            this.shiftDetailsForm.controls.level1Price.clearValidators();
            this.shiftDetailsForm.controls.level2Price.clearValidators();
        }
    }

    onShiftTimeChange(event: any) {
        if (event) {
            this.selectedShiftTime = event;
            this.shiftDetailsForm.controls.shiftTimeId.setValue(this.selectedShiftTime);
            this.getLocalToUtcTime();
            if (this.selectedShiftTime == 'custom') {
                // Mark for change detection
                this.cdr.detectChanges();
                this.shiftDetailsForm.controls.toTime.setValidators(Validators.required);
                this.shiftDetailsForm.controls.fromTime.setValidators(Validators.required);
            } else {
                this.shiftDetailsForm.controls.toTime.clearValidators();
                this.shiftDetailsForm.controls.fromTime.clearValidators();
            }
            this.shiftDetailsForm.controls.toTime.updateValueAndValidity();
            this.shiftDetailsForm.controls.fromTime.updateValueAndValidity();
        }
    }

    getLocalToUtcTime() {
        if (this.selectedShiftTime && this.selectedShiftTime !== 'custom') {
            const result = this.dateUtilService.getLocalToUtcTime(
                this.shiftDate,
                this.shiftTimeList,
                this.selectedShiftTime
            );

            this.toTime = result.toTime;
            this.fromTime = result.fromTime;
        }
    }

    getShiftTimeSelectList(templateId: any, hospitalId: any, companyId: any) {
        this.shiftTimeList = [{ id: 'custom', shiftTime: 'Custom' }];
        this.jobTemplateService.getShiftTimeSelectList(templateId, hospitalId, companyId).subscribe((response: any) => {
            if (response.result) {
                this.shiftTimeList = [{ id: 'custom', shiftTime: 'Custom' }, ...response.result];
                // Start the loop from index 1
                for (let i = 1; i < this.shiftTimeList?.length; i++) {
                    const element = this.shiftTimeList[i];

                    // Parse time strings into Date objects
                    element.fromTime = this.dateUtilService.convertUtcToLocalTime(element.fromTime);
                    element.toTime = this.dateUtilService.convertUtcToLocalTime(element.toTime);
                    element.shiftTime = `${element.fromTime} - ${element.toTime}`;
                }
            }
        });
    }

    confirm() {
        let reqPayload = {
            id: this.inspectorDetails.shiftPositionId,
            contactId: this.contactId,
            statusUpdatedDate: this.dateUtilService.convertCurrentDateToUTC(),
        };
        let result: any;
        if (this.selectedShiftTime === 'custom') {
            // Function to extract time from a Date object in HH:mm format
            if (this.hours < 4 && this.hours >= 0) {
                this.toastrService.error('The select time contains less than 4 Hours');
                return;
            }
            let toTimeConvert = null;
            let fromTimeConvert = null;
            let shiftFromTime = null;

            shiftFromTime = this.dateUtilService.mergeDateAndTime(
                this.shiftDate,
                this.shiftDetailsForm.controls.fromTime.value
            );
            fromTimeConvert = this.dateUtilService.convertToUtc(
                this.dateUtilService.mergeDateAndTime(this.shiftDate, this.shiftDetailsForm.controls.fromTime.value)
            );
            toTimeConvert = this.dateUtilService.onTimeChangeGetLocalToUtcTime(
                shiftFromTime,
                this.dateUtilService.mergeDateAndTime(this.shiftDate, this.shiftDetailsForm.controls.toTime.value)
            ).toTime;
            result = {
                fromTime: fromTimeConvert,
                toTime: toTimeConvert,
            };
        }

        let requestObj = {
            id: this.inspectorDetails.shiftPositionId,
            date: this.selectedShiftTime === 'custom' ? result?.fromTime || null : this.fromTime || null,
            fromTime: this.selectedShiftTime === 'custom' ? result?.fromTime || null : this.fromTime || null,
            toTime: this.selectedShiftTime === 'custom' ? result?.toTime || null : this.toTime || null,
            weekDay: this.selectedDate
                ? new Date(
                      this.selectedDate.year,
                      this.selectedDate.month - 1,
                      this.selectedDate.day
                  ).toLocaleDateString('en-US', { weekday: 'long' })
                : null,
            priceAndBonus: {
                id: this.inspectorDetails.shiftPositionId,
                level1Price: this.shiftDetailsForm.controls.level1Price.value
                    ? Number(this.shiftDetailsForm.controls.level1Price.value)
                    : null,
                level2Price: this.shiftDetailsForm.controls.level2Price.value
                    ? Number(this.shiftDetailsForm.controls.level2Price.value)
                    : null,
                level3Price: this.shiftDetailsForm.controls.level3Price.value
                    ? Number(this.shiftDetailsForm.controls.level3Price.value)
                    : null,
                level1LateCallBonus: this.shiftDetailsForm.controls.level1LateCallBonus.value
                    ? Number(this.shiftDetailsForm.controls.level1LateCallBonus.value)
                    : null,
                level2LateCallBonus: this.shiftDetailsForm.controls.level2LateCallBonus.value
                    ? Number(this.shiftDetailsForm.controls.level2LateCallBonus.value)
                    : null,
                level3LateCallBonus: this.shiftDetailsForm.controls.level3LateCallBonus.value
                    ? Number(this.shiftDetailsForm.controls.level3LateCallBonus.value)
                    : null,
                level3BillRate: this.shiftDetailsForm.controls.level3BillRate.value
                    ? Number(this.shiftDetailsForm.controls.level3BillRate.value)
                    : null,
            },
        };

        let modalRef = this.modal.open(ConfirmationModelComponent, {
            size: 'l',
            backdrop: false,
            windowClass: 'my-custom-confirm-modal',
        });
        modalRef.componentInstance.title =
            this.shiftTypePosition == 1
                ? 'Shift Position Removal'
                : this.shiftTypePosition == 2
                ? 'Shift Position Rescheduling'
                : this.shiftTypePosition == 3
                ? 'Confirm Rescheduling and Boost this shift'
                : 'Confirm Boost this shift';
        modalRef.componentInstance.message =
            this.shiftTypePosition == 1
                ? 'Are you sure you want to remove this shift ?'
                : this.shiftTypePosition == 2
                ? 'Are you sure you want to reschedule this shift?'
                : this.shiftTypePosition == 3
                ? 'Are you sure you want to confirm the reschedule and boost this shift?'
                : 'Are you sure you want to confirm the boost this shift?';
        modalRef.componentInstance.shiftTypePosition = this.shiftTypePosition;
        modalRef.componentInstance.inspectorDetails = this.inspectorDetails;
        modalRef.componentInstance.requestModal = this.shiftTypePosition == 1 ? reqPayload : requestObj;

        if (this.shiftTypePosition == 1) {
            modalRef.componentInstance.type = 'Cancel';
        }

        modalRef.componentInstance.endPoint =
            this.shiftTypePosition == 1
                ? API_SHIFT.REMOVE_SHIFT_POSITION
                : this.shiftTypePosition == 2
                ? API_SHIFT.RESCHEDULE_SHIFT_POSITION
                : this.shiftTypePosition == 3
                ? API_SHIFT.RESCHEDULE_WITH_PRICE_BONUS_SHIFT_POSITION
                : API_SHIFT.UPDATE_PRICE_BONUS_POSITION;
        modalRef.result.then((res: any) => {
            if (res) {
                this.activeModal.close();
            }
        });
    }

    updateStatus(type: any) {
        let modalRef = this.modal.open(InspectorUpdateStatusComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.inspectorDetails = this.inspectorDetails;
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.marketId = this.marketId;
        modalRef.componentInstance.companyId = this.companyId ?? '';
        modalRef.componentInstance.hospitalId = this.hospitalId ?? '';
        modalRef.result.then((res: any) => {
            // this.modal.dismissAll();
            this.activeModal.close();
        });
    }

    // Clock In and Clock Out manually

    onClockInAndOutManually(type: any) {
        let modalRef = this.modal.open(ConfirmationModelComponent, {
            size: 'l',
            backdrop: false,
            windowClass: 'my-custom-confirm-modal',
        });
        let utcDate = this.dateUtilService.convertCurrentDateToUTC();
        let reqModel = {
            shiftClinicianId: this.inspectorDetails.shiftPositionId,
            date: utcDate,
            address: null,
            lat: null,
            long: null,
        };
        modalRef.componentInstance.title = type == 'In' ? 'Manual Clock-in' : 'Manual Clock-out';
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.message =
            type == 'In'
                ? 'Are you sure you want to do the clock-in using the current time?'
                : 'Are you sure you want to do the manual clock-out using the current time?';
        modalRef.componentInstance.endPoint = type == 'In' ? API_SHIFT.MISSING_CLOCK_IN : API_SHIFT.MISSING_CLOCK_OUT;
        modalRef.componentInstance.requestModal = reqModel;

        modalRef.result.then((res: any) => {
            if (res == true) {
                this.activeModal.close(true);
            } else {
                return;
            }
        });
    }

    onOpenFile(fileUrl: any) {
        window.open(fileUrl);
    }

    onExcuse() {
        if (this.shiftDetailsForm.invalid) {
            return;
        }

        let obj = {
            id: this.data.shiftPositionId,
            isDeclineExcuse: this.shiftDetailsForm.controls.isDeclineExcuse.value,
            comment: this.shiftDetailsForm.controls.comment.value,
            publicComment: this.shiftDetailsForm.controls.publicComment.value,
            verifiedById: Number(sessionStorage.getItem('contactId')),
            verifiedOn: new Date().toISOString(),
        };
        let url: any = this.inspectorDetails?.shiftPositionId + API_SHIFT.DECLINE_EXCUSE + '?isDeclineExcuse=true';
        this.shiftService.onDeclineExcuse(obj).subscribe((response: any) => {
            if (response.result) {
                this.activeModal.close(true);
                this.toastrService.success(response.message);
            } else {
                this.toastrService.error(response.message);
            }
        });
    }

    getTimeDifference(time: any) {
        let conertUTCToLocal = this.dateUtilService.convertUtcToLocalDate(time);

        // Convert time strings to Date objects
        const start = new Date(conertUTCToLocal);
        const end = new Date();

        // If the end time is earlier in the day than the start time, add 24 hours to the end time
        if (end < start) {
            end.setDate(end.getDate() + 1);
        }

        // Calculate the difference in milliseconds
        const diffMilliseconds = end.getTime() - start.getTime();

        // Convert milliseconds to hours, minutes, and seconds
        const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        return diffHours > 0 ? `${diffHours} hours, ${diffMinutes} minutes` : `${diffMinutes} minutes`;
    }

    getStatusColor(status: string, type: 'background' | 'text' | 'border'): string {
        return this.statusColorService.getStatusColor(status.trim(), type);
    }

    onWithdrawInvite() {
        let requestObj = {
            id: this.inspectorDetails.shiftPositionId,
            statusUpdatedDate: this.dateUtilService.convertCurrentDateToUTC(),
        };
        let modalRef = this.modal.open(ConfirmationModelComponent, {
            size: 'l',
            backdrop: false,
            windowClass: 'my-custom-confirm-modal',
        });
        modalRef.componentInstance.title = 'Draw Invite';
        modalRef.componentInstance.message = 'Are you sure you want to draw the invite?';
        modalRef.componentInstance.type = 'invite';
        modalRef.componentInstance.endPoint = API_SHIFT.WITHDRAW_INVITE;

        modalRef.componentInstance.requestModal = requestObj;

        modalRef.result.then((res: any) => {
            if (res) {
                this.activeModal.close();
            }
        });
    }

    viewProfile(model: any, type?: any) {
        // const modalRef = this.modal.open(ViewProfileModelComponent, {
        //     size: 'xl',
        //     backdrop: false
        //     windowClass: 'my-custom-modal',
        // });
        // modalRef.componentInstance.id = model?.nurseInformation?.clinicianId;
        // modalRef.componentInstance.type = type;
        // modalRef.componentInstance.shiftId = model?.id;
        // modalRef.componentInstance.marketId = this.marketId ?? '';
        // modalRef.componentInstance.companyId = this.companyId ?? '';
        // modalRef.componentInstance.hospitalId = this.hospitalId ?? '';

        const profileData = {
            id: model?.nurseInformation?.clinicianId,
            hospitalId: this.hospitalId ?? '',
            companyId: this.companyId ?? '',
            marketId: this.marketId,
            type: type,
            shiftId: model?.id,
        };

        sessionStorage.setItem('profileData', JSON.stringify(profileData));
        window.open('nurse-profile', '_blank');
    }

    //callout
    onStatusChange(value: any) {
        this.selectedStatus = value;
        this.shiftDetailsForm.controls.isDeclineExcuse.setValue(value);
    }

    selectedTime() {
        let startTimeValue = this.shiftDetailsForm.get('fromTime')?.value;
        let endTimeValue = this.shiftDetailsForm.get('toTime')?.value;
        this.hours = this.dateUtilService.checkShiftHoursCalculation(startTimeValue, endTimeValue);
    }

    getShiftHistoryMonths(enumType: any): { id: number; name: string }[] {
        return Object.keys(enumType)
            .filter((key) => isNaN(Number(key)))
            .map((key) => ({ id: enumType[key], name: key }));
    }

    selectShiftMonth(id: any) {
        this.selectedMonth = id;
        this.getShiftHistory();
    }

    getShiftHistory() {
        let reqObject = {
            id: this.data.shiftPositionId,
            marketId: !this.marketId ? null : this.marketId,
            companyId: !this.companyId ? null : this.companyId,
            hospitalId: !this.hospitalId ? null : this.hospitalId,
            clinicianId: this.data.clinicianId,
            shiftHistoryMonth: this.selectedMonth,
        };

        this.staffService.getShiftHistory(reqObject).subscribe((response: any) => {
            if (response.result) {
                this.inspectorDetails.shiftHistory = response.result;
            }
        });
    }

    getPriceRateByHospital() {
        this.isFacilityOperatingAllLevel = null;
        this.priceService.getPriceList(this.data.hospitalId).subscribe((response: any) => {
            if (response.result) {
                this.isFacilityOperatingAllLevel = response.result.hospital.isThriveHealthLevel;
                this.priceList = response.result.priceRates;
                this.holidaysList = response.result.holidays;
                if (this.data.licenseTypeId && this.data.specialityId) {
                    this.matchedPricesList = [];
                    this.matchedPricesList = this.priceList.filter((element: any) => {
                        return (
                            element.specialityId === this.data.specialityId &&
                            element.licenseTypeId === this.data.licenseTypeId
                        );
                    });
                }
            }
        });
    }

    get form() {
        return { shiftDetailsForm :  this.shiftDetailsForm};
    }
}
