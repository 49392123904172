<div class="modal-content small-model fixwidth">
    <div class="modal-header border-bottom border-secondary">
        <div class="modal-title h4 text-secondary" id="example-modal-sizes-title-lg">
            <ng-container>{{ title }}</ng-container>
        </div>
    </div>
    <div class="form form-label-right modal-body" novalidate="novalidate" id="kt_category_form">
        <div
            *ngFor="let item of feedback.shiftClinicianFeedbacks"
            class="nurse-rating-box d-flex align-item-center justify-content-between mb-4"
        >
            <span>{{ item.feedbackFormInfoName }}</span>
            <span class="stars">
                <i
                    class="fa fa-star ms-1 star-icon"
                    *ngFor="let star of [1, 2, 3, 4, 5]"
                    [ngClass]="{ active: star <= item.rank }"
                ></i>
            </span>
        </div>
        <div class="mt-4" *ngIf="feedback.comment">
            <h5 class="mb-2">Additional Comments</h5>
            <p>{{ feedback.comment }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="rating-model-btn btn btn-orange apply-btn" (click)="modal.dismiss()">Close</button>
    </div>
</div>
