import { Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from 'src/app/core/guard/unsavedChanges.guard';
import { ConfirmationModelUnSavedChangesComponent } from 'src/app/shared/components/Confirmation-model-UnSavedChanges/Confirmation-model-UnSavedChanges.component';


export abstract class BaseMasterFormComponent implements CanComponentDeactivate {

    constructor(protected confirmationModalService: NgbModal) {}

    // Abstract getter for the forms (an object containing multiple forms)
    abstract get form(): { [key: string]: FormGroup };
    private initialFormState: { [key: string]: any } = {}; // Store initial states for multiple forms
    private externalStates: { [key: string]: any } = {};
    private initialExternalStates: { [key: string]: any } = {};

    // Logic to check if any form is dirty or has real changes
    canDeactivate(): boolean | Promise<boolean> {
        if (this.hasFormChanged()) {
            return confirm('You have unsaved changes. Do you really want to leave?');
            //return this.openUnsavedChangesModal();
        }
        return true;
    }

    async openUnsavedChangesModal(): Promise<boolean> {
        const modalRef = this.confirmationModalService.open(ConfirmationModelUnSavedChangesComponent, {
            backdrop: false,
        });
        modalRef.componentInstance.message = 'You have unsaved changes. Do you want to discard them?';
        modalRef.result.then((res: any) => {
            return res;
        });

        return false; // Confirm or dismiss action
    }

    // Store the initial states of all forms
    storeInitialFormState(): void {
        if (this.form) {
            Object.keys(this.form).forEach((formKey) => {
                const form = this.form[formKey];
                if (form) {
                    this.initialFormState[formKey] = JSON.parse(JSON.stringify(form.getRawValue())); // Deep copy
                    console.log(`Stored Initial State for ${formKey}:`, this.initialFormState[formKey]);
                }
            });
        }

        Object.keys(this.externalStates).forEach((key) => {
            this.initialExternalStates[key] = JSON.parse(JSON.stringify(this.externalStates[key]));
        });
    }

    // Compare the current states of all forms with their initial states
    hasFormChanged(): boolean {
        if (!this.form || Object.keys(this.form).length === 0) {
            return false;
        }

        const hasChanges = Object.keys(this.form).some((formKey) => {
            const form = this.form[formKey];
            const initialState = this.initialFormState[formKey];
            if (!form || !initialState) {
                return false;
            }

            const currentState = JSON.parse(JSON.stringify(form.getRawValue())); // Deep copy current state
            const initialStateCopy = JSON.parse(JSON.stringify(initialState)); // Deep copy initial state

            // Normalize and compare states
            return !this.areStatesEqual(currentState, initialStateCopy);
        });

        // Check external state changes
        const externalStateChanged = Object.keys(this.externalStates).some((key) => {
            const currentState = JSON.stringify(this.externalStates[key]);
            const initialState = JSON.stringify(this.initialExternalStates[key]);
            return currentState !== initialState;
        });


        return hasChanges || externalStateChanged;
    }

    // Add or update external states to track
    setExternalState(key: string, value: any): void {
        this.externalStates[key] = value;
    }

    // Utility method to compare states
    private areStatesEqual(state1: any, state2: any): boolean {
        const allKeys = new Set([...Object.keys(state1), ...Object.keys(state2)]);

        const normalize = (obj: any) => {
            const normalized: any = {};
            allKeys.forEach((key) => {
                let value = obj[key] == null ? '' : obj[key];

                // Convert stringified numbers to actual numbers for consistent comparison
                if (typeof value === 'string' && !isNaN(Number(value))) {
                    value = Number(value); // Convert string to number
                }

                normalized[key] = value;
            });
            return normalized;
        };

        const normalizedState1 = normalize(state1);
        const normalizedState2 = normalize(state2);

        console.log('Comparing States:', normalizedState1, normalizedState2);

        return JSON.stringify(normalizedState1) === JSON.stringify(normalizedState2);
    }

    // Abstract method to initialize the forms (to be implemented in child components)
    abstract initForm(): void;

    // Utility method for dismissing the modal
    dismissModal(modal: any): void {
        if (this.canDeactivate()) {
            modal.dismiss();
        }
    }

     // Utility method for dismissing the modal
     canNavigate() {
        return this.canDeactivate()
    }

    closeModal(modal: any, response?: any): void {
        if (this.canDeactivate()) {
            if (response != null && response != undefined) {
                modal.close(response);
            } else {
                modal.close();
            }
        }
    }
}
