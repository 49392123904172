import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModelComponent } from '../../confirmation-model/confirmation-model.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiftService } from 'src/app/service/shift.service';
import { JobTemplateService } from 'src/app/service/jobTemplate.service';
import {
    noWhitespaceValidator,
    onlyNumberAndAfterDotTwoDigitValidator,
    onlyNumberValidator,
    removeSpaces,
} from 'src/app/utill/validator';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { API_SHIFT } from 'src/app/constants/api';
import { LocalDateTimePipe } from 'src/app/pipes/local-date-time.pipe';
import { StatusColorService } from 'src/app/service/statusColor.service';
import { ViewProfileModelComponent } from '../../../job/view-profile-model/view-profile-model.component';
import { ToastrService } from 'ngx-toastr';
import { BaseMasterFormComponent } from 'src/app/shared/components/base-master-form.component';

@Component({
    selector: 'app-inspector-update-status',
    templateUrl: './inspector-update-status.component.html',
    styleUrl: './inspector-update-status.component.scss',
    providers: [LocalDateTimePipe],
})
export class InspectorUpdateStatusComponent extends BaseMasterFormComponent  implements OnInit {
    @Input() inspectorDetails: any;
    @Input() type: any;
    @Input() hospitalId: any;
    @Input() companyId: any;
    @Input() marketId: any;

    selectedDate: any;
    selectedShiftTime: any;
    selectedReason: any;
    shiftTypePosition: any;
    shiftTimeList: any[] = [];
    shiftDetailsForm: FormGroup;
    shiftDate: any;
    toTime: any;
    fromTime: any;
    contactId: any;
    selectedValue: any;
    hours: any;

    constructor(
        public activeModal: NgbActiveModal,
        private modal: NgbModal,
        private fb: FormBuilder,
        private shiftService: ShiftService,
        private jobTemplateService: JobTemplateService,
        public dateUtilService: DateUtilService,
        private statusColorService: StatusColorService,
        private cdr: ChangeDetectorRef,
        public toastrService: ToastrService,
        confirmationModalService: NgbModal
    ) {
        super(confirmationModalService);
    }

    reasonsList: any = [];

    unschedulingReasonsList: any = [];

    ngOnInit(): void {
        this.contactId = sessionStorage.getItem('contactId');
        this.initForm();
        if (this.inspectorDetails.status == 'In Progress') {
            this.getReleaseReasonSelectList();
        }
        if (this.inspectorDetails.status == 'Scheduled' || this.inspectorDetails.status == 'Overtime, Scheduled') {
            this.getUnscheduleReasonSelectList();
        }
        this.getShiftTimeList(
            '',
            this.inspectorDetails.hospitalId,
            this.inspectorDetails.companyId ? this.inspectorDetails.companyId : ''
        );
        this.storeInitialFormState();
    }

    getShiftTimeList(templateId: any, hospitalId: any, companyId: any) {
        this.shiftTimeList = [{ id: 'custom', shiftTime: 'Custom' }];
        this.jobTemplateService.getShiftTimeSelectList(templateId, hospitalId, companyId).subscribe((response: any) => {
            if (response.result) {
                this.shiftTimeList = response.result;
                this.shiftTimeList = [{ id: 'custom', shiftTime: 'Custom' }, ...response.result];

                // Start the loop from index 1
                for (let i = 1; i < this.shiftTimeList?.length; i++) {
                    const element = this.shiftTimeList[i];
                    // Parse time strings into Date objects
                    element.fromTime = this.dateUtilService.convertUtcToLocalTime(element.fromTime);
                    element.toTime = this.dateUtilService.convertUtcToLocalTime(element.toTime);
                    element.shiftTime = `${element.fromTime} - ${element.toTime}`;
                }
            }
        });
    }

    getReleaseReasonSelectList() {
        this.shiftService.getReleaseReasonSelectList().subscribe((response) => {
            if (response.result) {
                this.reasonsList = response.result;
            }
        });
    }

    getUnscheduleReasonSelectList() {
        this.shiftService.getUnscheduleReasonSelectList().subscribe((response) => {
            if (response.result) {
                this.unschedulingReasonsList = response.result;
            }
        });
    }

    close() {
        this.dismissModal(this.activeModal); 
    }

    get form() {
        return { shiftDetailsForm :  this.shiftDetailsForm};
    }

    initForm() {
        this.shiftDetailsForm = this.fb.group({
            extra: [''],
            id: [this.inspectorDetails.shiftPositionId],
            date: [''],
            shiftTimeId: [''],
            publicComment: [''],
            comment: [''],
            reasonsId: [''],
            extendedMinutes: [''],
            extendedBonus: [''],
            isPositionOpen: [''],
            isPositionCancel: [''],
            toTime: [''],
            fromTime: [''],
        });

        if (
            this.inspectorDetails.status == 'Missing Clock-in' ||
            this.inspectorDetails.status == 'In Progress' ||
            this.inspectorDetails.status == 'Scheduled' ||
            this.inspectorDetails.status == 'Overtime, Scheduled'
        ) {
            if (this.type == 'NcncAndReschedule' || this.type == 'ReleaseReschedule') {
                this.shiftDetailsForm.controls.date.setValidators(Validators.required);
                this.shiftDetailsForm.controls.shiftTimeId.setValidators(Validators.required);
            }
            if (
                this.type == 'ReleaseCancel' ||
                this.type == 'ReleaseReschedule' ||
                this.inspectorDetails.status == 'Scheduled' ||
                this.inspectorDetails.status == 'Overtime, Scheduled'
            ) {
                this.shiftDetailsForm.controls.reasonsId.setValidators(Validators.required);
            }
            if (this.type == 'Scheduled') {
                this.shiftDetailsForm.controls.isPositionOpen.setValidators(Validators.required);
                this.shiftDetailsForm.controls.isPositionCancel.setValidators(Validators.required);
            }
            if (this.type == 'ExtendShift') {
                this.shiftDetailsForm.controls.extendedBonus.setValidators(
                    Validators.compose([
                        Validators.max(999),
                        Validators.maxLength(6),
                        onlyNumberAndAfterDotTwoDigitValidator,
                    ])
                );
                this.shiftDetailsForm.controls.extendedMinutes.setValidators(
                    Validators.compose([
                        Validators.required,
                        noWhitespaceValidator,
                        removeSpaces,
                        Validators.minLength(1),
                        Validators.maxLength(3),
                        onlyNumberValidator,
                    ])
                );
            }
            if (this.type != 'ExtendShift' && this.type == 'Scheduled') {
                this.shiftDetailsForm.controls.publicComment.setValidators(
                    Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                );
                this.shiftDetailsForm.controls.comment.setValidators(
                    Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                );
            }
        }
        // Update validity of the controls after setting/clearing validators
        this.shiftDetailsForm.controls.date.updateValueAndValidity();
        this.shiftDetailsForm.controls.extendedBonus.updateValueAndValidity();
        this.shiftDetailsForm.controls.extendedMinutes.updateValueAndValidity();
        this.shiftDetailsForm.controls.reasonsId.updateValueAndValidity();
        this.shiftDetailsForm.controls.shiftTimeId.updateValueAndValidity();
        this.shiftDetailsForm.controls.publicComment.updateValueAndValidity();
        this.shiftDetailsForm.controls.comment.updateValueAndValidity();
    }

    onConfirm() {
        let shiftDetailsModel: any = this.shiftDetailsForm.value;
        let requestModal: any;
        if (this.selectedShiftTime && this.selectedShiftTime != 'custom') {
            this.getLocalToUtcTime();
        }
        if (this.type == 'ExtendShift') {
            requestModal = {
                id: this.inspectorDetails.shiftPositionId,
                extendedMinutes: Number(this.shiftDetailsForm.controls.extendedMinutes.value),
                extendedBonus: this.shiftDetailsForm.controls.extendedBonus.value
                    ? Number(this.shiftDetailsForm.controls.extendedBonus.value)
                    : null,
            };
        } else if (this.selectedValue == 2) {
            requestModal = {
                id: this.inspectorDetails.shiftPositionId,
                contactId: this.contactId,
                statusUpdatedDate: this.dateUtilService.convertCurrentDateToUTC(),
                unscheduleReason:
                    this.selectedReason?.length > 0 || this.selectedReason != null ? this.selectedReason : null,
            };
        } else {
            let result: any;
            if (this.selectedShiftTime === 'custom') {
                // Function to extract time from a Date object in HH:mm format
                if (this.hours < 4 && this.hours >= 0) {
                    this.toastrService.error('The select time contains less than 4 Hours');
                    return;
                }
                let toTimeConvert = null;
                let fromTimeConvert = null;
                let shiftFromTime = null;

                shiftFromTime = this.dateUtilService.mergeDateAndTime(
                    this.shiftDate,
                    this.shiftDetailsForm.controls.fromTime.value
                );
                fromTimeConvert = this.dateUtilService.convertToUtc(
                    this.dateUtilService.mergeDateAndTime(this.shiftDate, this.shiftDetailsForm.controls.fromTime.value)
                );
                toTimeConvert = this.dateUtilService.onTimeChangeGetLocalToUtcTime(
                    shiftFromTime,
                    this.dateUtilService.mergeDateAndTime(this.shiftDate, this.shiftDetailsForm.controls.toTime.value)
                ).toTime;
                result = {
                    fromTime: fromTimeConvert,
                    toTime: toTimeConvert,
                };
            }

            let utcDate = this.dateUtilService.convertCurrentDateToUTC();
            requestModal = {
                id: this.inspectorDetails.shiftPositionId,
                date: this.selectedShiftTime === 'custom' ? result?.fromTime || null : this.fromTime || null,
                fromTime: this.selectedShiftTime === 'custom' ? result?.fromTime || null : this.fromTime || null,
                toTime: this.selectedShiftTime === 'custom' ? result?.toTime || null : this.toTime || null,
                weekDay: this.selectedDate
                    ? new Date(
                          this.selectedDate.year,
                          this.selectedDate.month - 1,
                          this.selectedDate.day
                      ).toLocaleDateString('en-US', { weekday: 'long' })
                    : null,
                isPositionOpen: this.shiftDetailsForm.controls.isPositionOpen.value,
                isPositionCancel: this.shiftDetailsForm.controls.isPositionCancel.value,
                statusUpdatedDate:
                    this.inspectorDetails.status == 'Scheduled'
                        ? this.shiftDetailsForm.controls.isPositionOpen.value === true
                            ? utcDate
                            : null
                        : utcDate,
                rescheduleAdditionalInfo: {
                    comment: shiftDetailsModel.comment ? shiftDetailsModel.comment.trim() : null,
                    publicComment: shiftDetailsModel.publicComment ? shiftDetailsModel.publicComment.trim() : null,
                    releaseReason:
                        this.selectedReason?.length > 0 || this.selectedReason != null
                            ? this.selectedReason.toString()
                            : null,
                },
            };
        }

        this.updateStatus(requestModal);
    }

    updateStatus(requestModal: any) {
        let modalRef = this.modal.open(ConfirmationModelComponent, {
            size: 'l',
            backdrop: false,
            windowClass: 'my-custom-confirm-modal',
        });
        modalRef.componentInstance.inspectorDetails = this.inspectorDetails;
        modalRef.componentInstance.requestModal = requestModal;
        if (this.type == 'NcncAndReschedule') {
            modalRef.componentInstance.title = 'No Call No Show (NCNS)';
            modalRef.componentInstance.message =
                'Are you sure you want to NCNS this nurse and reschedule this shift position?';
            modalRef.componentInstance.endPoint = API_SHIFT.NCNS_RESCHEDULE_SHIFT_POSITION;
        } else if (this.type == 'NcnsOnly') {
            modalRef.componentInstance.title = 'No Call No Show (NCNS)';
            modalRef.componentInstance.message = 'Are you sure you want to NCNS this nurse?';
            modalRef.componentInstance.endPoint = API_SHIFT.NCNS_SHIFT_POSITION;
        } else if (this.type == 'ReleaseReschedule') {
            modalRef.componentInstance.title = 'Nurse Removal';
            modalRef.componentInstance.message =
                'Are you sure you want to release this nurse and reschedule this position?';
            modalRef.componentInstance.endPoint = API_SHIFT.RELEASE_RESCHEDULE_SHIFT_POSITION;
        } else if (this.type == 'ReleaseCancel') {
            modalRef.componentInstance.title = 'Nurse Removal';
            modalRef.componentInstance.message =
                'Are you sure you want to release this nurse and remove this position?';
            modalRef.componentInstance.endPoint = API_SHIFT.RELEASE_CANCEL_SHIFT_POSITION;
        } else if (this.type == 'Scheduled') {
            if (this.selectedValue == 1) {
                modalRef.componentInstance.title = 'Nurse Unscheduling';
                modalRef.componentInstance.message = 'Are you sure you want to unschedule  this nurse?';
                modalRef.componentInstance.endPoint = API_SHIFT.UNSCHEDULE_SHIFT_POSITION;
            } else {
                modalRef.componentInstance.title = 'Nurse Unscheduling';
                modalRef.componentInstance.message = 'Are you sure you want to unschedule  this nurse?';
                modalRef.componentInstance.type = 'Cancel';
            }
        } else if (this.type == 'ExtendShift') {
            modalRef.componentInstance.title = 'Extend Shift';
            modalRef.componentInstance.message = 'Are you sure you want to extend  shift time?';
            modalRef.componentInstance.endPoint = API_SHIFT.EXTEND_SHIFT_POSITION;
        }

        modalRef.result.then((res) => {
            if (res == true) {
                this.activeModal.close(true);
            }
        });
    }

    onDateSelectChange(event: any) {
        this.shiftDetailsForm.controls.date.setValue(event);
        this.shiftDate = event;
        this.selectedDate = this.dateUtilService.parseDateString(this.shiftDate);
    }

    onShiftTimeChange(event: any) {
        if (event) {
            this.selectedShiftTime = event;
            this.shiftDetailsForm.controls.shiftTimeId.setValue(event);
            if (this.selectedShiftTime == 'custom') {
                // Mark for change detection
                this.cdr.detectChanges();
                this.shiftDetailsForm.controls.toTime.setValidators(Validators.required);
                this.shiftDetailsForm.controls.fromTime.setValidators(Validators.required);
            } else {
                this.shiftDetailsForm.controls.toTime.clearValidators();
                this.shiftDetailsForm.controls.fromTime.clearValidators();
            }
            this.shiftDetailsForm.controls.toTime.updateValueAndValidity();
            this.shiftDetailsForm.controls.fromTime.updateValueAndValidity();
        } else {
            this.selectedShiftTime = null;
            this.shiftDetailsForm.controls.shiftTimeId.setValue(null);
        }
    }

    getLocalToUtcTime() {
        const result = this.dateUtilService.getLocalToUtcTime(
            this.shiftDate,
            this.shiftTimeList,
            this.selectedShiftTime
        );
        this.toTime = result.toTime;
        this.fromTime = result.fromTime;
    }

    onReasonsIdChange(event: any) {
        if (event) {
            this.selectedReason = event;
            this.shiftDetailsForm.controls.reasonsId.setValue(event);
        } else {
            this.selectedReason = [];
            this.shiftDetailsForm.controls.reasonsId.setValue(null);
        }
    }

    onUnschedulingReasonsIdChange(event: any) {
        if (event) {
            this.selectedReason = event;
            this.shiftDetailsForm.controls.reasonsId.setValue(event);
        } else {
            this.selectedReason = [];
            this.shiftDetailsForm.controls.reasonsId.setValue(null);
        }
    }

    onShiftPositionChange(value: any) {
        this.selectedValue = value;
        if (value == 1) {
            this.shiftDetailsForm.controls.isPositionOpen.setValue(true);
            this.shiftDetailsForm.controls.isPositionCancel.setValue(false);
            this.shiftDetailsForm.controls.publicComment.setValidators(
                Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
            );
            this.shiftDetailsForm.controls.comment.setValidators(
                Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
            );
            this.shiftDetailsForm.controls.publicComment.updateValueAndValidity();
            this.shiftDetailsForm.controls.comment.updateValueAndValidity();
        } else {
            this.shiftDetailsForm.controls.isPositionOpen.setValue(false);
            this.shiftDetailsForm.controls.isPositionCancel.setValue(true);
            this.shiftDetailsForm.controls.publicComment.clearValidators();
            this.shiftDetailsForm.controls.publicComment.updateValueAndValidity();
            this.shiftDetailsForm.controls.comment.clearValidators();
            this.shiftDetailsForm.controls.comment.updateValueAndValidity();
        }
    }

    getTimeDifference(time: any) {
        let conertUTCToLocal = this.dateUtilService.convertUtcToLocalDate(time);

        // Convert time strings to Date objects
        const start = new Date(conertUTCToLocal);
        const end = new Date();

        // If the end time is earlier in the day than the start time, add 24 hours to the end time
        if (end < start) {
            end.setDate(end.getDate() + 1);
        }

        // Calculate the difference in milliseconds
        const diffMilliseconds = end.getTime() - start.getTime();

        // Convert milliseconds to hours, minutes, and seconds
        const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        return diffHours > 0 ? `${diffHours} hours, ${diffMinutes} minutes` : `${diffMinutes} minutes`;
    }

    getStatusColor(status: string, type: 'background' | 'text' | 'border'): string {
        return this.statusColorService.getStatusColor(status, type);
    }

    viewProfile(model: any, type?: any) {
        const modalRef = this.modal.open(ViewProfileModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.id = model?.nurseInformation?.clinicianId;
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.shiftId = model?.id;
        modalRef.componentInstance.marketId = this.marketId ?? '';
        modalRef.componentInstance.companyId = this.companyId ?? '';
        modalRef.componentInstance.hospitalId = this.hospitalId ?? '';
    }

    selectedTime() {
        let startTimeValue = this.shiftDetailsForm.get('fromTime')?.value;
        let endTimeValue = this.shiftDetailsForm.get('toTime')?.value;
        this.hours = this.dateUtilService.checkShiftHoursCalculation(startTimeValue, endTimeValue);
    }
}
