import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GraduationTypeEnum } from 'src/app/model/job.model';
import { ClinicianDetailService } from 'src/app/service/clinicianDetail.service';
import { ClinicianProfileService } from 'src/app/service/clinicianProfile.service';

@Component({
    selector: 'app-view-verfication-model',
    templateUrl: './view-verfication-model.component.html',
    styleUrl: './view-verfication-model.component.scss',
})
export class ViewVerficationModelComponent {
    @Input() data: any;
    @Input() id: any;
    @Input() type: any;
    @Input() label: any;
    @Input() displayFor: any;

    applicantDetails: any;

    restrictionList: any;
    selectedStatus: any;
    selectedRestriction: any;
    specialities: any;

    constructor(
        public modal: NgbActiveModal,
        private clinicianProfileService: ClinicianProfileService,
        private clinicianDetailService: ClinicianDetailService
    ) {}

    ngOnInit(): void {
        this.setFields(this.data);

        this.restrictionList = [
            { id: 1, name: 'Nurse can not apply again for this job' },
            { id: 2, name: 'Nurse can apply again for this job at any time' },
            { id: 3, name: 'Nurse can apply again only after this date:' },
        ];
        this.getApplicatDetails();
    }

    getApplicatDetails() {
        if (this.type == 'license') {
            this.clinicianProfileService.getClinicianLicenseDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.applicantDetails = res.result;
                }
            });
        } else if (this.type == 'education') {
            this.clinicianProfileService.getClinicianEducationDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.applicantDetails = res.result;
                    this.applicantDetails.statusName = res.result.status;
                    if (this.applicantDetails?.graduationTypeName) {
                        const graduationType = GraduationTypeEnum.find(
                            (item) => item.shortName === this.applicantDetails?.graduationTypeName
                        );
                        this.applicantDetails.graduationTypeName = graduationType ? graduationType.name : '';
                    }
                }
            });
        } else if (this.type == 'backgroundCheck') {
            this.clinicianProfileService.getClinicianBackgroundCheckDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.applicantDetails = res.result;
                }
            });
        } else if (this.type == 'credential') {
            this.clinicianProfileService.getClinicianCredentialDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.applicantDetails = res.result;
                }
            });
        } else if (this.type == 'experience') {
            this.clinicianProfileService.getClinicianExperienceDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.applicantDetails = res.result;
                    this.applicantDetails.statusName = res.result.status;

                    const specialityNames = this.applicantDetails.clinicianExperienceSpecialities.map(
                        (item: any) => item.specialityName
                    );

                    // Concatenate them with commas
                    this.specialities = specialityNames.join(', ');
                }
            });
        } else if (this.type == 'EmployementForm') {
            this.clinicianProfileService.getClinicianEmpolyeementFormDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.applicantDetails = res.result;
                }
            });
        }
    }

    onOpenFile(fileUrl: any) {
        window.open(fileUrl);
    }

    formatNumber(number: any) {
        return number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }

    setFields(obj: any) {
        this.data = this.clinicianDetailService.setFields(obj);
    }
}
