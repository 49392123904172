import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TreeRefreshService {
    private refreshTreeSubject = new Subject<void>();
    private selectedCompanySubject = new BehaviorSubject<number | null>(null);
    private selectedNodeSubject = new BehaviorSubject<number | null>(null);
    private selectedTableIdsSubject = new BehaviorSubject<number | null>(null);
    private marketName = new BehaviorSubject<string | null>(null);

    constructor() {}

    // Call this to emit an event
    triggerRefreshTree() {
        this.refreshTreeSubject.next();
    }

    // This is what the tree component will subscribe to
    getRefreshTreeObservable() {
        return this.refreshTreeSubject.asObservable();
    }

    setSelectedCompany(
        companyId: number,
        facilityId: number | null = null,
        unitId: number | null = null,
        selectedNodeLevel: number | null = null,
        size: number | null = null
    ) {
        let obj: any = {
            companyId: companyId,
            facilityId: facilityId,
            unitId: unitId,
            selectedNodeLevel: selectedNodeLevel,
            size: size,
        };
        this.selectedCompanySubject.next(obj);
    }

    getSelectedCompanyObservable() {
        return this.selectedCompanySubject.asObservable();
    }

    resetSelectedCompany() {
        this.selectedCompanySubject.next(null); // Reset the company
    }

    setMarketName(name: string) {
        this.marketName.next(name);
    }

    getMarketName() {
        return this.marketName.asObservable();
    }

    setSelectedNode(data: any) {
        this.selectedNodeSubject.next(data);
    }

    getSelectedNodeObservable() {
        return this.selectedNodeSubject.asObservable();
    }

    resetSelectedNode() {
        this.selectedNodeSubject.next(null); // Reset the Node
    }

    setSelectedTableIds(data: any) {
        this.selectedTableIdsSubject.next(data);
    }

    getSelectedTableIdsObservable() {
        return this.selectedTableIdsSubject.asObservable();
    }

    resetSelectedableIds() {
        this.selectedTableIdsSubject.next(null); // Reset the Node
    }
}
