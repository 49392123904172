import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { JobTemplateService } from 'src/app/service/jobTemplate.service';
import { StaffService } from 'src/app/service/staff.service';

@Component({
    selector: 'app-add-view-restriction',
    templateUrl: './add-view-restriction.component.html',
    styleUrls: ['./add-view-restriction.component.scss'],
})
export class AddViewRestrictionComponent {
    restrictDetailsForm: FormGroup;
    isUpdateRestrictedNurse: boolean = false;
    @Input() model: any;
    @Input() marketId: any;
    @Input() hospitalId: any;
    @Input() companyId: any;
    selectedSuspendedFromDate: any;
    selectedSuspendedtillDate: any;
    restrictionDetails: any = [];
    isDisabled: any;
    restrictionTypeList: any;
    clinicianLicences: any = [];

    constructor(
        public modal: NgbActiveModal,
        public toastrService: ToastrService,
        private dateUtilService: DateUtilService,
        private fb: FormBuilder,
        private jobTemplateService: JobTemplateService,
        private ngxSpinnerService: NgxSpinnerService,
        private staffService: StaffService
    ) {}

    ngOnInit() {
        this.getRestrictionDetails();
        this.initForm();
    }

    initForm() {
        this.restrictDetailsForm = this.fb.group({
            id: [this.model.hospitalClinicianId],
            suspendedFrom: ['', Validators.compose([Validators.required])],
            suspendedTill: ['', Validators.compose([Validators.required])],
            description: [null],
            verifiedById: [''],
            verifiedOn: [''],
        });
    }

    close() {
        this.modal.close();
    }

    getRestrictionDetails() {
        this.jobTemplateService.getRestrictionDetails(this.model.id).subscribe((response) => {
            this.restrictionDetails = response.result;
            this.clinicianLicences = response.result.clinicianLicences;
        });
    }

    onCheckboxChange(event: any) {
        this.isUpdateRestrictedNurse = event;
        const currentTime = new Date();
        const selectedSuspendedFromDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedFrom);
        this.restrictDetailsForm.controls?.suspendedFrom.setValue(selectedSuspendedFromDate);
        selectedSuspendedFromDate.setHours(currentTime.getHours());
        selectedSuspendedFromDate.setMinutes(currentTime.getMinutes());
        selectedSuspendedFromDate.setSeconds(currentTime.getSeconds());
        this.selectedSuspendedFromDate = {
            year: selectedSuspendedFromDate.getFullYear(),
            month: selectedSuspendedFromDate.getMonth() + 1,
            day: selectedSuspendedFromDate.getDate(),
        };
        const selectedSuspendedtillDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedTill);
        this.restrictDetailsForm.controls.suspendedTill.setValue(selectedSuspendedtillDate);
        selectedSuspendedtillDate.setHours(currentTime.getHours());
        selectedSuspendedtillDate.setMinutes(currentTime.getMinutes());
        selectedSuspendedtillDate.setSeconds(currentTime.getSeconds());
        this.selectedSuspendedtillDate = {
            year: selectedSuspendedtillDate.getFullYear(),
            month: selectedSuspendedtillDate.getMonth() + 1,
            day: selectedSuspendedtillDate.getDate(),
        };
        this.isDisabled = true;
    }

    onDateSelectChange(event: any, type: any) {
        if (type == 'till') {
            this.restrictDetailsForm.controls.suspendedTill.setValue(event);
        } else {
            this.restrictDetailsForm.controls.suspendedFrom.setValue(event);
        }
        const selectedSuspendedFromDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedFrom);
        const selectedSuspendedtillDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedTill);
        if (
            selectedSuspendedFromDate.toDateString() !=
                this.restrictDetailsForm.controls.suspendedFrom.value.toDateString() ||
            selectedSuspendedtillDate.toDateString() !=
                this.restrictDetailsForm.controls.suspendedTill.value.toDateString() ||
            this.restrictDetailsForm.controls.description.value
        ) {
            this.isDisabled = false;
        } else {
            this.isDisabled = true;
        }
    }

    onDescriptionChange(event: any) {
        if (event.target.value?.length > 0) {
            this.restrictDetailsForm.controls.description.setValue(event.target.value);
            this.isDisabled = false;
        } else {
            this.isDisabled = true;
        }
    }

    onUpdateRestrictionNurse() {
        let nurseReastrictionModel = this.restrictDetailsForm.value;
        const suspendedTillDate = moment(nurseReastrictionModel.suspendedTill, 'YYYY-MM-DD'); // adjust format as necessary
        const suspendedFromDate = moment(nurseReastrictionModel.suspendedFrom, 'YYYY-MM-DD'); // adjust format as necessary

        if (suspendedTillDate.isBefore(suspendedFromDate)) {
            this.toastrService.error('Suspended until Date should be greater than Suspended from Date');
            return;
        }

        nurseReastrictionModel.verifiedOn = new Date().toISOString();
        nurseReastrictionModel.verifiedById = Number(sessionStorage.getItem('contactId'));

        if (nurseReastrictionModel.suspendedTill) {
            nurseReastrictionModel.suspendedTill = this.dateUtilService.convertDateCurrentTimeToUTC(
                nurseReastrictionModel.suspendedTill
            );
        }
        if (nurseReastrictionModel.suspendedFrom) {
            nurseReastrictionModel.suspendedFrom = this.dateUtilService.convertDateCurrentTimeToUTC(
                nurseReastrictionModel.suspendedFrom
            );
        }
        this.updateRestrictionNurse(nurseReastrictionModel);
    }

    updateRestrictionNurse(nurseReastrictionModel: any) {
        this.staffService.updateRestrictedNurse(nurseReastrictionModel).subscribe(
            (response: any) => {
                if (response.result) {
                    this.modal.close(true);
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err: any) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.message);
            }
        );
    }
}
