import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataService {
    private dataSubject = new BehaviorSubject<any>(null);
    data$ = this.dataSubject.asObservable();
 
    private jobDataSubject = new BehaviorSubject<any>(null);
    jobData$ = this.jobDataSubject.asObservable();
 
    setData(data: any) {
        this.dataSubject.next(data);
    }
 
    setJobData(data: any) {
        this.jobDataSubject.next(data);
    }
}
