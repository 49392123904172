import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    parse(value: any): any {
        if (value) {
            const parts = value.trim().split('-');
            return {
                month: +parts[0],
                day: +parts[1],
                year: +parts[2],
            };
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        return date ? `${this.pad(date.month)}-${this.pad(date.day)}-${date.year}` : '';
    }

    private pad(number: number): string {
        return number < 10 ? '0' + number : '' + number;
    }
}
