import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditCityModelComponent } from './add-edit-city-model/add-edit-city-model.component';
import { CityRoutingModule } from './city-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CityComponent } from './city.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { LayoutModule } from 'src/app/views/layout/layout.module';

@NgModule({
    declarations: [CityComponent, AddEditCityModelComponent],
    imports: [
        CityRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatSelectModule,
        MatFormFieldModule,
        MatMenuModule,
        MatAutocompleteModule,
        NgSelectModule,
        LayoutModule,
    ],
})
export class CityModule {}
