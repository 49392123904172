<div class="modal-content fixwidth">
    <form
        [formGroup]="noteDetailsForm"
        class="form form-label-right overflow-auto"
        #contactFormDirective="ngForm"
        (ngSubmit)="save()"
        [appUnsavedChanges]="noteDetailsForm"
        [hasFormChanged]="hasFormChanged.bind(this)"
    >
        <div class="modal-body p-3">
            <div class="add-note" *ngIf="isAddNoteMode">
                <h4>Add a Note</h4>

                <div class="row g-3 mb-3 mt-2 align-items-end dropdown-box" style="z-index: 8">
                    <div class="form-group">
                        <app-selectbox
                            class="dropdown-box"
                            formControlName="hospitalId"
                            [selectItems]="facilityList"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Facility"
                            [isSearchable]="true"
                            [selectedFieldId]="selectedFacilityId"
                            [formName]="noteDetailsForm"
                            (onChangeSelect)="onFacilityChange($event)"
                            requiredLableName="Facility"
                            [isRequired]="true"
                            controlName="hospitalId"
                            [isMultiple]="false"
                            lableName="Facility"
                            [isSelectDisable]="isSelectDisable"
                        >
                        </app-selectbox>
                    </div>
                </div>

                <div class="row g-3 mb-3 mt-1 align-items-end">
                    <div class="form-group">
                        <app-selectbox
                            class="dropdown-box"
                            formControlName="category"
                            [selectItems]="categoryList"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Category"
                            [isSearchable]="true"
                            [selectedFieldId]="selectedCategoryId"
                            [formName]="noteDetailsForm"
                            (onChangeSelect)="onCategoryChange($event)"
                            requiredLableName="Category"
                            [isRequired]="false"
                            controlName="category"
                            [isMultiple]="false"
                            lableName="Category"
                        >
                        </app-selectbox>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-4 mt-3">
                        <h5>Note date</h5>
                        <p class="text-break description mt-2">
                            {{ currentDateTime }}
                        </p>
                    </div>
                    <div class="col-4 mt-3">
                        <h5>Author</h5>
                        <p class="text-break description mt-2">
                            {{ firstName }} {{ lastName == 'null' ? ' ' : lastName }}
                        </p>
                    </div>
                </div>

                <div class="form-group mt-4">
                    <app-textarea
                        label="Note Description"
                        [fieldName]="'Note Description'"
                        [placeholder]="'Description'"
                        [isRequired]="true"
                        [rows]="10"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="noteDetailsForm"
                        formControlName="note"
                    >
                    </app-textarea>
                </div>
            </div>

            <div class="view-note" *ngIf="!isAddNoteMode">
                <h4>Note</h4>

                <div class="row mt-3">
                    <div class="col-6 mt-3">
                        <h5>Category</h5>
                        <p class="text-break description mt-2">{{ noteDetails?.categoryName }}</p>
                    </div>
                    <div class="col-6 mt-3">
                        <h5>Facility</h5>
                        <p class="text-break description mt-2">{{ noteDetails?.hospitalName }}</p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6 mt-3">
                        <h5>Date</h5>
                        <p class="text-break description mt-2">
                            {{ noteDetails?.date }}
                        </p>
                    </div>
                    <div class="col-6 mt-3">
                        <h5>Author</h5>
                        <p class="text-break description mt-2">{{ noteDetails?.author }}</p>
                    </div>
                </div>

                <div class="mt-4">
                    <h5>Note Description</h5>
                    <p class="text-break description mt-2">{{ noteDetails?.note }}</p>
                </div>
            </div>
        </div>

        <div class="modal-footer p-3">
            <button
                class="btn btn-outline-primary fw-semibold px-4 px-md-5 mr-3 mr-md-4"
                type="button"
                (click)="close()"
            >
                Close
            </button>
            <button
                *ngIf="isAddNoteMode"
                class="btn btn-primary fw-semibold px-4 px-md-5 ms-2"
                [disabled]="noteDetailsForm.invalid || !hasFormChanged()"
                type="submit"
            >
                Save
            </button>
        </div>
    </form>
</div>
