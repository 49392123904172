import { FormGroup } from '@angular/forms';

export class DataTableModel {
    columns: Array<{}>;
    displayBlock: DisplayBlock;
    getUrl: any;
    isServerSidePagination: boolean = false;
    isAllowPaging: boolean = true;
    sortOrder: any = [];
    isShowPaginationInfo: boolean = true;
    isLoadData: boolean = false;
    isSearching: boolean = false;
    isActive: boolean;
    isShiftActive: boolean;
    isInherit: any;
    queryparam: any;
    searchText: any;
    isQueryParam: boolean = false;
    isRequestObject?: boolean = false;
    isShiftData: true;
    data: any[];
    allData: any[];
    filterValue: any;
    screenStatus: any;
    requestObject?: any;
    requestType?: any;
}

export class InputModel {
    inpuType: string;
    formControlName: any;
    inputFormName: FormGroup;
    inputFormDirective: any;
    requriedFieldName: any;
    isRequried: boolean = false;
    isWhitespace: boolean = false;
    inputPlaceholder: string;
    inputClassName: string;
    fieldName: string;
}

export interface DataOptions {
    search?: boolean;
    export?: boolean;
    add?: boolean;
    delete?: boolean;
    clear?: boolean;
    view?: boolean;
    active?: boolean;
    // documentTypes?: LookupCode[];
}

export type IFormItemType =
    | 'number'
    | 'date'
    | 'time'
    | 'email'
    | 'text'
    | 'picklist'
    | 'group-picklist'
    | 'user-picklist'
    | 'name'
    | 'phone'
    | 'country'
    | 'address'
    | 'connected-entity'
    | 'multi-select-picklist'
    | 'icon'
    | 'keyvalue'
    | 'html'
    | 'currency'
    | 'button'
    | 'alert'
    | 'validation-display'
    | 'status'
    | 'level'
    | 'bgColor'
    | 'modifiedBy'
    | 'website'
    | 'action'
    | 'full-name'
    | 'base-location'
    | 'updatedBy'
    | 'inherited'
    | 'totalHour'
    | 'pipeline-status'
    | 'candidate-status'
    | 'document-status'
    | 'interview-status'
    | 'dateUTCToLocal'
    | 'interview-time'
    | 'rate'
    | 'timeFormat'
    | 'position'
    | 'multi-licenses'
    | 'timeFormatForPunched'
    | 'timeFormatForShiftTime'
    | 'timesheet-status'
    | 'restricted-until'
    | 'timeFormatForShiftTime'
    | 'job-action'
    | 'dateformat'
    | 'candidate-action'
    | 'restriction-action'
    | 'shift-action'
    | 'shift-position'
    | 'dateFormat'
    | 'multi-credential'
    | 'bulk-rate'
    | 'break-time'
    | 'year-month'
    | 'nurse-action'
    | 'shift-rate'
    | 'notes'
    | 'credential-status'
    | 'checkbox-action'
    | 'city-state'
    | 'facility'
    | 'audit-action'
    | 'document'
    | 'nurse-email'
    | 'license-speciality'
    | 'timesheet-action'
    | 'renewal-action'
    | 'lead-action'
    | 'local-date'
    | 'hospital-name'
    | 'hiring-info'
    | 'shift-info';
export interface DisplayElement {
    type: IFormItemType;
    name: string;
    serviceKey?: string;
    conversionKey?: string;
    label?: string;
    noLabel?: boolean;
    bold?: boolean;
    description?: string;
    parent?: string;
    required?: boolean;
    hidden?: boolean; //| DisplayElementFn;
    displayMode?: DisplayModeType;
    link?: (data: any, moment?: any, loggedInUserId?: any) => string;
    format?: (data: any, moment?: any) => string;
    value?: (data: any, moment?: any) => string;
    lookupCodeType?: string;
    dataSourceUrl?: string;
    displayOrder?: number;
    editable?: boolean;
    removable?: boolean;
    pipe?: string;
    multiple?: boolean;
    maxLength?: string;
    // conditionalIcons?: DisplayIcon[];
    style?: DisplayElementStyle;
    validationKey?: string;
    colSize?: string;
    sorting?: boolean;
    wordWrap?: boolean;
    dateFormat?: string;
    visible?: boolean;
    options?: DataOptions;
    visibleFor?: any;
    actions?: {
        isEdit?: boolean;
        isDeactivate?: boolean;
        isDelete?: boolean;
        isFeedBackSend?: boolean;
        isApproveReject?: boolean;
        isView?: boolean;
        isViewMore?: boolean;
        isExpiryDate?: boolean;
        isDownloadUnitTemplate?: boolean;
        isUnitUpload?: boolean;
        isCopyNew?: boolean;
        isChat?: boolean;
        isExternalLink?: boolean;
        isMeeting?: boolean;
        isShiftCancel?: boolean;
        isInvited?: boolean;
        isDispute?: boolean;
        isMarketLevelView?: boolean;
        isTimesheetView?: boolean;
        isProfileTimesheetView?: boolean;
        isProfileShiftView?: boolean;
        isCalloutView?: boolean;
        isRestrictionView?: boolean;
        isViewDocument?: boolean;
        isActive?: boolean;
        isPublicholiday?: boolean;
    };
}

export enum DisplayModeType {
    edit = 'edit',
    view = 'view',
    both = 'both',
}

export interface DisplayElementStyle {
    /** Can be used to pass in a css style string */
    styleString?: string;
    /** Can be used to buttons to pass in a color name */
    color?: string;
    /** Can be used store an icon name */
    icon?: string;
    /** Can be used to store a class names */
    classNames?: string;
    /** If the item is an upload related item */
    upload?: boolean;
    /** If the upload item should show the upload file name */
    showUploadFileName?: boolean;
    /** styling specific for the upload file name. */
    uploadFileNameStyleString?: string;
}

export interface DisplayBlock {
    key?: string;
    title?: string;
    prefix?: string;
    editable?: boolean;
    serviceKey?: string;
    conversionKey?: string;
    loadOnStartUp?: boolean;
    options?: DataOptions;
    // bottomActions?: ActionOption[];
    listen?: {
        readAll?: boolean;
        my?: boolean;
        readOne?: boolean;
        create?: boolean;
        update?: boolean;
        delete?: boolean;
    };
    // publish?: (data: any) => DisplayElementUserGroup;
    rowRecordId?: string;
    rows?: DisplayElement[];
    /** Validation service key mapping for display Elements */
    validationKey?: string;
    /** Validation blocks mapped to service keys */
    // validation?: { [name: string]: BlockValidation };

    getUrl?: string;
    isServerSidePagination?: boolean;
    isQueryParam?: boolean;
    isActive?: boolean;
    queryparam?: string;

    isAllowPaging?: boolean;
    sortOrder?: any;
    isShowPaginationInfo?: boolean;
    isLoadData?: boolean;
    isSearching?: boolean;
    searchText?: any;
    isShiftData?: true;
    data?: any[];
    allData?: any[];
    filterValue?: any;
    isInherit?: any;
    screenStatus?: any;
    requestType?: any;
    isRequestObject?: any;
    requestObject?: any;
}
