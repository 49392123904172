<div class="main-wrapper">
    <!-- partial:navbar -->
    <app-navbar></app-navbar>
    <!-- <app-sidebar></app-sidebar> -->

    <!-- partial -->

    <div [ngClass]="pageWrapper" class="main">
        <app-sidebar
            (pageWrapper)="setdivclass($event)"
            class="main-sidebar"
            *ngIf="getUrl != 'thrive-user-profile' && getUrl != 'edit-profile'"
        ></app-sidebar>
        <div [ngClass]="pageContent">
            <!-- Spinner for lazyload modules -->
            <div *ngIf="isLoading" class="spinner-wrapper">
                <div class="spinner">Loading...</div>
            </div>

            <div contentAnimate *ngIf="!isLoading" class="h-100">
                <router-outlet></router-outlet>
            </div>
        </div>

        <!-- partial:footer -->
        <app-footer></app-footer>
        <!-- partial -->
    </div>
</div>
