import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {
    API_CLINICIAN,
    API_COMPANIES,
    API_CREDENTIAL,
    API_JOB,
    API_SHIFT,
    API_SHIFT_TIME,
    API_UNITS,
} from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class JobTemplateService {
    constructor(private apiService: ApiService) {}

    addTemplate(res: any): Observable<any> {
        return this.apiService.post(API_JOB.POST_TEMPLATE, res);
    }

    updateTemplate(id: any, res: any): Observable<any> {
        return this.apiService.put(API_JOB.PUT_TEMPLATE + id, res);
    }

    getJobTypeList(): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB__SELECTED_LIST);
    }

    getJobTypeListByHospital(hospitalId: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_SELECTED_LIST_BY_HOSPITAL + '?hospitalId=' + hospitalId);
    }

    getJobSpecialityListbyId(id: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_LICENSE_TYPE_BY_ID + id);
    }

    getJobSpecialityList(): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_SPECIALITIES_SELECTED_LIST);
    }

    getCredentialList(companyId: any, hospitalId: any): Observable<any> {
        if (companyId != null && hospitalId == 'null') {
            return this.apiService.get(API_CREDENTIAL.GET_CREDENTIAL_TEM_SELECTED_LIST + '?companyId=' + companyId);
        } else {
            return this.apiService.get(
                API_CREDENTIAL.GET_CREDENTIAL_TEM_SELECTED_LIST +
                    '?companyId=' +
                    companyId +
                    '&hospitalId=' +
                    hospitalId
            );
        }
    }

    getUnitlList(hospitalId: any): Observable<any> {
        return this.apiService.get(API_UNITS.GET_UNITS_SELECTED_LIST + '?hospitalId=' + hospitalId);
    }

    getJobTemplateList(): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_TEMPLATE_SELECTED_LIST);
    }

    getJobTemplatebyId(id: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_TEMPLATE_BY_ID + id);
    }

    updateTemplateBasedInherit(res: any): Observable<any> {
        return this.apiService.put(API_JOB.INHERITE_TEMPLATE, res);
    }

    getShiftTimelList(companyId: any, hospitalId: any): Observable<any> {
        if (companyId != null && hospitalId == null) {
            return this.apiService.get(API_SHIFT_TIME.GET_SHIFT_TIME + '/' + companyId + '/dropdown');
        } else {
            return this.apiService.get(
                API_SHIFT_TIME.GET_SHIFT_TIME + '/' + companyId + '/dropdown?hospitalId=' + hospitalId
            );
        }
    }
    getGraduationTypeList(): Observable<any> {
        return this.apiService.get(API_JOB.GET_GRADUATION_LIST);
    }

    getShiftTimeSelectList(templateId: any, hospitalId: any, companyId: any) {
        return this.apiService.get(
            API_SHIFT_TIME.TEMPLATE_SHIFT_TIME_SELECT_LIST +
                '?templateId=' +
                templateId +
                '&hospitalId=' +
                hospitalId +
                '&companyId=' +
                companyId
        );
    }

    getBreakTimeSelectList(hospitalId: any, companyId: any) {
        return this.apiService.get(
            API_COMPANIES.GET_BREAK_TIME_SELECT_LIST + '?companyId=' + companyId + '&hospitalId=' + hospitalId
        );
    }

    getAuthorList(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.POST_AUTHORSELECTLIST, res);
    }

    addNote(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.POST_NOTES, res);
    }

    viewNote(id: any): Observable<any> {
        return this.apiService.get(API_CLINICIAN.GET_NOTE + id);
    }

    getRestrictionDetails(id: any): Observable<any> {
        return this.apiService.get(API_CLINICIAN.BASE_URL_CLINICIAN + '/' + id + API_CLINICIAN.POST_RESTRICTIONDETAILS);
    }

    getRestrictedFacility(res: any) {
        return this.apiService.post(API_CLINICIAN.GET_RESTRICTEDFACILITY, res);
    }

    savedSearchList(req: any): Observable<any> {
        return this.apiService.post(API_SHIFT.POST_SAVEDSEARCH, req);
    }

    addSearch(res: any) {
        return this.apiService.post(API_SHIFT.POST_ADDSHIFT_SAVEDSEARCH, res);
    }

    deleteSearch(id: any) {
        return this.apiService.get(API_SHIFT.DELETE_SHIFT_SAVEDSEARCH + '?id=' + id);
    }

    updateSearch(req: any) {
        return this.apiService.put(API_SHIFT.PUT_UPDATE_SEARCH_CRITERIA, req);
    }

    getNurseRestrictionDetails(id: any): Observable<any> {
        return this.apiService.get(
            API_CLINICIAN.BASE_URL_CLINICIAN + API_CLINICIAN.GET_NURSE_RESTRICTION_DETAILS + '?id=' + id
        );
    }

    getTemplatePreferredShiftTimeList(companyId: any, hospitalId: any): Observable<any> {
        if (companyId != null && hospitalId == null) {
            return this.apiService.get(
                API_SHIFT_TIME.TEMPLATE_PREFERRED_SHIFT_TIME + '/' + companyId + '/TemplatePreferredShiftTime'
            );
        } else {
            return this.apiService.get(
                API_SHIFT_TIME.TEMPLATE_PREFERRED_SHIFT_TIME +
                    '/' +
                    companyId +
                    '/TemplatePreferredShiftTime?hospitalId=' +
                    hospitalId
            );
        }
    }

    getJobPreferredShiftTimeList(companyId: any, hospitalId: any, jobId: any): Observable<any> {
        if (companyId != null && hospitalId == null && jobId == null) {
            return this.apiService.get(API_JOB.GET_JOB_BY_ID + companyId + '/JobPreferredShiftTime');
        } else if (companyId && hospitalId && jobId == null) {
            return this.apiService.get(
                API_JOB.GET_JOB_BY_ID + companyId + '/JobPreferredShiftTime?hospitalId=' + hospitalId
            );
        } else {
            return this.apiService.get(
                API_JOB.GET_JOB_BY_ID +
                    companyId +
                    '/JobPreferredShiftTime?hospitalId=' +
                    hospitalId +
                    '&jobId=' +
                    jobId
            );
        }
    }

    getUnitSelectList(hospitalId: any, companyId: any) {
        if (companyId != null && hospitalId == null) {
            return this.apiService.get(API_UNITS.GET_Facility_UNITS_SELECTED_LIST + '?companyId=' + companyId);
        } else {
            return this.apiService.get(
                API_UNITS.GET_Facility_UNITS_SELECTED_LIST + '?hospitalId=' + hospitalId + '&companyId=' + companyId
            );
        }
    }
}
