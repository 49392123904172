<div class="row align-items-center">
    <div class="col-lg-6 col-md-4">
        <app-header label="US Cities"></app-header>
    </div>
</div>
<div class="position-responsive">
    <div class="filter-create-btn d-flex justify-content-end">
        <app-titlebar title="US City" (create)="create()"></app-titlebar>
    </div>

    <app-datatable
        [configDataTable]="dataTableModel"
        (rowClickHandler)="rowCallback($event)"
        [isLoadData]="dataTableModel.isLoadData"
        [isActive]="dataTableModel.isActive"
    >
    </app-datatable>
</div>
