export class ShiftTimeModel {
    constructor() {
        this.id = 0;
        this.fromTime = null;
        this.toTime = null;
        this.shiftType = null;
        this.companyId = 0;
        this.hospitalId = 0;
        this.totalHours = null;
        this.isActive = false;
    }

    id: number;
    fromTime: any;
    toTime: any;
    shiftType: any;
    companyId: number;
    hospitalId: number;
    totalHours: any;
    isActive: boolean;

    setSatetData(data: any) {
        this.id = data.id;
        this.fromTime = data.fromTime;
        this.isActive = data.isActive;
        this.toTime = data.toTime;
        this.shiftType = data.shiftType;
        this.totalHours = data.totalHours;
        this.companyId = data.companyId;
        this.hospitalId = data.hospitalId;
    }
}

export enum ShiftType {
    Day = 1,
    Night = 2,
    Evening = 3,
    AM = 4,
    PM = 5,
    Custom = 6,
}

export enum TimeSheetDisputeInformation {
    SendBackToNurse = 1,
    Approved = 2,
    Rejected = 3,
}

export enum AdminDecision {
    'Proceed with rejection' = 1,
    'Decline rejection (send timesheet back to facility)' = 2,
}
