import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MediaUploadService {
    constructor(private http: HttpClient) {}

    mediaUpload(file: any, fileDriveName: any): Observable<any> {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('fileDriveName', fileDriveName);
        return this.http.post(environment.API + 'media/upload', formData);
    }
}
