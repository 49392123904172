import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AssestsService } from 'src/app/service/assests.service';
import { NotificationService } from 'src/app/service/notification.service';
import { DateAgoPipe } from 'src/app/pipes/date-ago.pipe';
import { DataService } from 'src/app/service/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from '../../pages/chat/chat.component';
import { ReceiveNotificationComponent } from '../../pages/receive-notification/receive-notification.component';
import { NavigationService } from 'src/app/service/navigation.service';
import { TreeRefreshService } from 'src/app/service/treeRefresh.service';
import { getFacilityId } from 'src/app/providers/utils';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [DateAgoPipe],
})
export class NavbarComponent implements OnInit {
    isShowNotification: any;
    menuItems: any[] = [];
    level1MenuItems: any[] = [];
    level2MenuItems: any[] = [];
    level3MenuItems: any[] = [];
    level4MenuItems: any[] = [];
    roleName: any;
    roleId: any;
    setIcon: any;
    firstName: any;
    lastName: any;
    username: string;
    email: string | null;
    profileUrl: string | null;
    id: any;
    showCircleIndicator: boolean = false;
    notifications: any[] = [];
    profileId: string | null;
    displayText: any;
    other_content: boolean = false;
    other_content1: boolean = false;
    role: any;
    defaultAssetsUrl: any;
    isThriveLevelLogin: any;
    isCompanyLevelLogin: any;
    isFacilityLevelLogin: any;
    unReadNotificationCount: any;
    isShowChildMenus: boolean = true;
    companyId: any;
    hospitalId: any;
    isAgencyLevelLogin: any;
    /**
     * Fixed header menu on scroll
     */
    @HostListener('window:scroll', ['$event']) getScrollHeight() {
        if (window.matchMedia('(min-width: 992px)').matches) {
            let header: HTMLElement = document.querySelector('.horizontal-menu') as HTMLElement;
            if (window.pageYOffset >= 1) {
                header.parentElement!.classList.add('fixed-on-scroll');
            } else {
                header.parentElement!.classList.remove('fixed-on-scroll');
            }
        }
    }
    avtarLogoPic: any = './assets/images/others/blank.png';
    isNavigationServiceCalled: boolean = false;
    isNurseProfile: Boolean = false;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        public assestService: AssestsService,
        private notificationService: NotificationService,
        public dataService: DataService,
        private assestsService: AssestsService,
        private modalService: NgbModal,
        private navigationService: NavigationService,
        private treeRefreshService: TreeRefreshService
    ) {
        this.roleName = sessionStorage.getItem('roleName');
        this.companyId = sessionStorage.getItem('companyId');
        this.companyId = JSON.parse(this.companyId);
        this.companyId = this.companyId == null ? '' : this.companyId;
    }

    ngOnInit(): void {
        this.navigationService.currentActiveMenu.subscribe((data: any) => {
            if (['nurse-profile'].includes(data.menu)) {
                this.isNurseProfile = true;
                return;
            }
        });
        this.roleId = sessionStorage.getItem('roleId');
        this.getMenuAssestsByRoleId();

        /**
         * closing the header menu after route change in tablet/mobile devices
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
            this.router.events.forEach((event) => {
                if (event instanceof NavigationEnd) {
                    document.querySelector('.horizontal-menu .bottom-navbar')!.classList.remove('header-toggled');
                }
            });
        }
        this.firstName = sessionStorage.getItem('userFirstName');
        this.lastName = sessionStorage.getItem('userLastName');
        this.username = this.firstName + ' ' + this.lastName;
        this.email = sessionStorage.getItem('email');
        this.profileUrl = sessionStorage.getItem('profileUrl');
        let data: any = sessionStorage.getItem('userDetail');
        this.isShowNotification = sessionStorage.getItem('roleName');
        let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');
        this.isThriveLevelLogin = Boolean(JSON.parse(isThriveLevelLogin));
        let isCompanyLevelLogin: any = sessionStorage.getItem('isCompanyLevelLogin');
        this.isCompanyLevelLogin = Boolean(JSON.parse(isCompanyLevelLogin));
        let isFacilityLevelLogin: any = sessionStorage.getItem('isFacilityLevelLogin');
        this.isFacilityLevelLogin = Boolean(JSON.parse(isFacilityLevelLogin));
        this.displayText = sessionStorage.getItem('displayText');
        this.defaultAssetsUrl = sessionStorage.getItem('defaultAssetsUrl');
        this.unReadNotificationCount = sessionStorage.getItem('unReadNotificationCount');
        let hospitalId = getFacilityId();
        this.hospitalId = hospitalId ? hospitalId : this.hospitalId;

        if (this.isCompanyLevelLogin == true) {
            this.profileId = sessionStorage.getItem('companyId');
        } else if (this.isFacilityLevelLogin == true) {
            this.profileId = sessionStorage.getItem('hospitalId');
        } else if (this.isShowNotification == 'Department Admin') {
            this.profileId = sessionStorage.getItem('departmentId');
        }
        let userInfo = JSON.parse(data);
        let userId = userInfo.userId;
        this.id = userId;

        sessionStorage.setItem('isAssetLoaded', 'true');
        this.isNavigationServiceCalled = false;
        // Subscribe to the currentActiveMenu observable to get updates
        this.navigationService.currentActiveMenu.subscribe((data: any) => {
            if (['Pipelines', 'Schedule'].includes(data.menu)) {
                this.isNavigationServiceCalled = true;
                if (this.isNavigationServiceCalled) {
                    this.hasItems('', data.menu, data.ids);
                }
            }
        });

        this.navigationService.currentNotificationCount.subscribe((data: any) => {
            this.getNotificationCount();
        });
    }

    isAdminLink(): string | any[] | null | undefined {
        return this.defaultAssetsUrl;
    }

    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(menu?: any, selectionMenu?: any, ids?: any) {
        if (this.isNavigationServiceCalled) {
            if (selectionMenu === 'Pipelines') {
                menu = this.level1MenuItems.find((item) => item.name === 'Pipelines');
            } else if (selectionMenu === 'Schedule') {
                menu = this.level1MenuItems.find((item) => item.name === 'Schedule');
            }
        }
        sessionStorage.removeItem('isThriveHealth');

        sessionStorage.setItem('defaultAssetsId', menu.id);
        sessionStorage.setItem('defaultAssetsName', menu.name);
        sessionStorage.setItem('defaultAssetsUrl', menu.url);
        // sessionStorage.setItem('realDataAssetName', '');
        this.isShowChildMenus = false;
        this.level1MenuItems.forEach((item) => {
            if (item.name !== menu.name) {
                item.setDefault = false;
            }
        });
        const index = this.level1MenuItems.findIndex((item) => item.name === menu.name);

        if (index !== -1) {
            // If found, update the existing item
            this.level1MenuItems[index] = { ...menu, setDefault: true };
        }
        this.level2MenuItems = menu.childAssets.filter((x: any) => x.displayText);
        if (this.level2MenuItems) {
            // Find the index of "Shift List" in the child assets
            const shiftListIndex = menu.childAssets.findIndex((item: any) => item.name === 'Shift List');
            if (this.isNavigationServiceCalled && selectionMenu === 'Schedule' && shiftListIndex !== -1) {
                this.parentMenu(this.level2MenuItems[shiftListIndex], selectionMenu, ids);
            } else {
                this.parentMenu(this.level2MenuItems[0], selectionMenu, ids);
            }
        }
    }

    parentMenu(subMenu: any, selectionMenu?: any, ids?: any) {
        // Iterate through level2MenuItems and set setDefault to false for other items
        this.level2MenuItems.forEach((item) => {
            if (item.name !== subMenu.name) {
                item.setDefault = false;
            }
        });

        // Check if an item with the same name already exists in the array
        const index = this.level2MenuItems.findIndex((item) => item.name === subMenu.name);

        if (index !== -1) {
            // If found, update the existing item
            this.level2MenuItems[index] = { ...subMenu, setDefault: true };
        }
        sessionStorage.removeItem('isThriveHealth');
        let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');

        if (isThriveLevelLogin == 'true') {
            sessionStorage.removeItem('companyId');
        }

        sessionStorage.setItem('secondLevelAssetId', subMenu.id);
        sessionStorage.setItem('secondLevelAssetName', subMenu.name);
        sessionStorage.setItem('secondLevelAssetUrl', subMenu.url);
        sessionStorage.setItem('isItComingFromRealData', subMenu.isItComingFromRealData);
        // sessionStorage.setItem('realDataAssetName', '');
        if (subMenu.childAssets.length > 0) {
            this.dataService.setData(subMenu.childAssets);
            this.router.navigate([subMenu?.childAssets[0]?.childAssets[0]?.url]);
        } else if (subMenu.isItComingFromRealData) {
            if (subMenu.realDataAssets) {
                sessionStorage.setItem('thridLevelAssetName', '');
                sessionStorage.setItem('thridLevelAssetUrl', '');
                sessionStorage.setItem('fourthLevelAssetName', '');
                sessionStorage.setItem('fourthLevelAssetUrl', '');
                this.dataService.setJobData(subMenu.realDataAssets);

                let isLoadRealData = sessionStorage.getItem('isLoadRealData');

                if (isLoadRealData) {
                    this.dataService.jobData$.subscribe((data) => {});
                }

                this.dataService.setData(null);
                this.dataService.setJobData(subMenu.realDataAssets);
                this.router.navigate([subMenu.url]);
                // sessionStorage.setItem('jobdata'  ,subMenu.realDataAssets)
                if (this.isNavigationServiceCalled) {
                    if (selectionMenu == 'Pipelines') {
                        this.router.navigate(['all-jobs/view-job']);
                        this.treeRefreshService.setSelectedTableIds(ids);
                    } else if (selectionMenu == 'Schedule') {
                        this.router.navigate(['/shift/view-shift']);
                        this.treeRefreshService.setSelectedTableIds(ids);
                    }
                }
            }
        } else {
            this.dataService.setData(subMenu);
            if (subMenu.url == 'audit-trail') {
                this.router.navigate(['audit-trail/report']);
            } else {
                this.router.navigate(['credential-report']);
            }
        }
    }

    /**
     * Logout
     */
    onLogout() {
        sessionStorage.clear();
        this.router.navigate(['auth/login']);
    }

    /**
     * Toggle header menu in tablet/mobile devices
     */
    toggleHeaderMenu() {
        document.querySelector('.horizontal-menu .bottom-navbar')!.classList.toggle('header-toggled');
    }
    getMenuAssestsByRoleId() {
        this.level1MenuItems = [];
        this.level2MenuItems = [];
        this.level3MenuItems = [];
        let defaultActiveUrl = sessionStorage.getItem('defaultAssetsUrl');
        let secondLevelAssetName = sessionStorage.getItem('secondLevelAssetName');
        let defaultActiveId = sessionStorage.getItem('defaultAssetsId');
        let defaultActiveName = sessionStorage.getItem('defaultAssetsName');
        let isItComingFromRealData = sessionStorage.getItem('isItComingFromRealData');

        this.assestService.getAssestsRoleId(this.roleId).subscribe((response) => {
            let setFirstLevelDefaultAssigned = false;
            let setSecondLevelDefaultAssigned = false;
            
            response.result.forEach((element: any) => {
                let level2: any = [];
                //level 1
                if (element.assetLevel === 1) {
                    // Push the item with setDefault to level1MenuItems
                    this.level1MenuItems = response.result.map((item: any) => {
                        if (item.name == defaultActiveName) {
                            setFirstLevelDefaultAssigned = true;
                            return { ...item, setDefault: true };
                        } else return { ...item, setDefault: false };
                    });

                     // Fallback to AssetId: 1 (Setting) if no default was set
                    if (!setFirstLevelDefaultAssigned) {
                        this.level1MenuItems = this.level1MenuItems.map(item => ({
                            ...item,
                            setDefault: item.id == 1
                        }));
                        defaultActiveName = response.result.find((item: any) => item.id === 1)?.name;
                        defaultActiveId = response.result.find((item: any) => item.id === 1)?.id;
                        secondLevelAssetName = "Facility Management";
                    }

                }

                //level 2
                if (defaultActiveName == element.name) {
                    let level2Assets = element.childAssets.filter((x: any) => x.parentAssetsId == defaultActiveId);
                    this.level2MenuItems = level2Assets.map((secondelement: any) => {                        
                        if (secondelement.name == secondLevelAssetName) {
                            setSecondLevelDefaultAssigned = true;
                            return { ...secondelement, setDefault: true };
                        } else return { ...secondelement, setDefault: false };                      
                    });

                      // Fallback to AssetId: 2 (Facility Setting) if no default was set
                    if (!setSecondLevelDefaultAssigned) {
                        this.level2MenuItems = this.level2MenuItems.map(item => ({
                            ...item,
                            setDefault: item.id == 2
                        }));
                    }

                    //level 3
                    this.level2MenuItems.forEach((element: any) => {
                        this.level3MenuItems.push(element.childAssets);
                    });

                    this.level2MenuItems.forEach((subElement) => {
                        if (subElement.name === secondLevelAssetName) {
                            // Pass the childAssets of the modified object to DataService
                            this.dataService.setData(subElement?.childAssets);
                            sessionStorage.setItem('data', JSON.stringify(subElement.childAssets)); // Convert to JSON string
                        }
                        if (subElement.isItComingFromRealData) {
                            sessionStorage.setItem('jobdata', JSON.stringify(subElement.realDataAssets.markets)); // Convert to JSON string
                            this.dataService.setJobData(subElement.realDataAssets);
                        }
                    });

                    if (isItComingFromRealData) {
                        // this.getAssetsListByRealData();
                    }
                }

                const menuItem = {
                    label: element.displayText,
                    icon: element.iconClass,
                    subMenus: level2,
                    link: element.url,
                    title: element.isLable,
                    active: element.url === defaultActiveUrl,
                };

                if (menuItem.label == 'Dashboard') {
                    delete menuItem.label;
                }
                this.menuItems.push(menuItem);
            });
        });
    }

    updateNotificationStatus(notificationIds: any) {
        this.notificationService.updateNotificationStatus(notificationIds).subscribe((response) => {
            sessionStorage.setItem('unReadNotificationCount', response.result.unReadNotificationCount);
            this.unReadNotificationCount = sessionStorage.getItem('unReadNotificationCount');
        });
    }

    hideCircle() {
        this.showCircleIndicator = false;
    }
    capitalizeFirstLetter(value: string) {
        if (!value) return '';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    onImgError(event: any) {
        this.other_content = true;
    }

    onImgLogoError(event: any) {
        this.other_content1 = true;
    }

    openChatWindow() {
        // this.router.navigate(['chat']);
        const modalRef = this.modalService.open(ChatComponent, {
            size: 'lg',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
    }

    openNotificationWindow() {
        const modalRef = this.modalService.open(ReceiveNotificationComponent, {
            size: 'lg',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
    }

    getNotificationCount() {
        let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
        isAgencyLevelLogin = JSON.parse(isAgencyLevelLogin);

        this.unReadNotificationCount = 0;
        let requestModal = {
            fromUserId: null,
            callingFrom: 'web',
            role: isAgencyLevelLogin
                ? 'AgencyLevel'
                : this.isCompanyLevelLogin
                ? 'HealthcareLevel'
                : this.isFacilityLevelLogin
                ? 'FacilityLevel'
                : 'ThriveLevel',
            companyId: this.companyId || null,
            hospitalId: this.hospitalId || null,
        };
        this.notificationService.getNotificationCount(requestModal).subscribe((response) => {
            if (response.result) {
                this.unReadNotificationCount = response.result;
            }
        });
    }

    windowClose() {
        window.close();
    }
}
