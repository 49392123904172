<div class="card shadow-none border-0 mt-3">
    <div class="card-body">
        <!-- begin:Table -->
        <div class="table-responsive angular-bootstrap-table">
            <!-- App DataTable Start -->
            <table datatable id="tblCustom" [dtOptions]="dtOptions" class="table-hover break-all table w-100"></table>
            <!-- App DataTable Finish -->
        </div>
        <!-- end: Table -->
    </div>
</div>
