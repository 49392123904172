import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { url } from 'inspector';

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
    isLoading: boolean;
    pageWrapper: any = 'page-wrapper';
    pageContent: any = 'page-content';
    secondLevelAssetUrl: any;
    getUrl: any;
    constructor(private router: Router) {
        this.secondLevelAssetUrl = sessionStorage.getItem('secondLevelAssetUrl');
        // Spinner for lazyload modules
        router.events.forEach((event) => {
            let secondLevelAssetName = sessionStorage.getItem('secondLevelAssetName');
            let sidebarState = sessionStorage.getItem('sidebarState');

            let getUrl = window.location.pathname.split('/')[1];
            this.getUrl = getUrl;
            let getUrl1 = window.location.pathname.split('/')[2];

            if (
                getUrl === 'lead-nurse' ||
                getUrl === 'restricted-nurse' ||
                getUrl === 'active-nurse' ||
                getUrl === 'nurse-profile' ||
                getUrl === 'add-nurse' ||
                getUrl === 'former-nurses' ||
                getUrl === 'thrive-user-profile' ||
                getUrl === 'edit-profile'
            ) {
                this.pageWrapper = 'page-wrapper-shift';
                this.pageContent = 'page-content-shift';
            } else {
                this.pageContent = 'page-content';
                if (
                    getUrl1 === 'shift-list' ||
                    secondLevelAssetName === 'Shift List' ||
                    secondLevelAssetName ||
                    getUrl === 'healthcare-system'
                ) {
                    this.pageWrapper =
                        (getUrl1 === 'shift-list' ||
                            getUrl ||
                            getUrl1 === 'view-shift' ||
                            getUrl1 ||
                            getUrl1 === 'bulk-shift' ||
                            getUrl === 'healthcare-system') &&
                        sidebarState == 'in'
                            ? 'page-wrapper-main'
                            : 'page-wrapper';
                } else {
                    this.pageWrapper = 'page-wrapper';
                }
            }
        });
    }

    ngOnInit(): void {}

    setdivclass(className: any) {
        let getUrl = window.location.pathname.split('/')[1];

        if (className == 'page-wrapper-main') {
            // this.pageContent = 'page-content-main';
            this.pageWrapper = 'page-wrapper-main';
        } else if (className == 'page-wrapper') {
            // this.pageContent = 'page-content';
            this.pageWrapper = 'page-wrapper';
        } else {
            // this.pageWrapper = 'page-wrapper-shift';
            this.pageContent = 'page-content-shift';
        }
    }
}
