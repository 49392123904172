<label *ngIf="!hideLabel" class="form-label {{ requiredClassName }}">{{ lableName }}</label>

<ng-select
    [items]="selectItems"
    [bindLabel]="fieldName"
    [bindValue]="fieldId"
    [searchable]="isSearchable"
    [(ngModel)]="selectedFieldId"
    [placeholder]="placeholder"
    (change)="onChange($event)"
    [id]="formField"
    [multiple]="isMultiple"
    [readonly]="isSelectDisable"
    [closeOnSelect]="closeOnSelect"
>
</ng-select>

<ng-container
    *ngIf="isRequired && !isFocus"
    [ngTemplateOutlet]="formError"
    [ngTemplateOutletContext]="{
        validation: 'required',
        message: requiredLableName + ' is required',
        control: formName.controls[controlName]
    }"
></ng-container>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.invalid" class="error-message">
        <div class="fv-plugins-message-container">
            <div class="input-error">
                {{ message }}
            </div>
        </div>
    </ng-container>
</ng-template>
