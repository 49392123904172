export class NotesModel {
    constructor() {
        this.id = 0;
        this.hospitalId = 0;
        this.clinicianId = 0;
        this.contactId = 0;
        this.category = null;
        this.note = null;
        this.referenceId = 0;
        this.date = null;
    }

    id: number;
    hospitalId: number;
    clinicianId: number;
    category: any;
    contactId: number;
    note: any;
    referenceId: number;
    date: any;

    setSatetData(data: any) {
        this.id = data.id;
        this.hospitalId = data.hospitalId;
        this.clinicianId = data.clinicianId;
        this.contactId = data.contactId;
        this.category = data.category;
        this.note = data.note;
        this.referenceId = data.referenceId;
        this.date = data.date;
    }
}
