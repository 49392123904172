<div class="modal-content">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container> Menus</ng-container>
        </div>
    </div>
    <form
        class="form form-label-right"
        novalidate="novalidate"
        id="kt_category_form"
        [formGroup]="assetsForm"
        #assetsFormDirective="ngForm"
        (ngSubmit)="submit()"
    >
        <div class="modal-body">
            <div class="row mt-3">
                <div class="col-sm-4 position-relative" style="z-index: 3">
                    <div class="form-group dropdown-box">
                        <app-selectbox
                            formControlName="assetLevel"
                            [selectItems]="assetLevels"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select Asset Level"
                            [isSearchable]="false"
                            [selectedFieldId]="selectedAssetLevel"
                            [formName]="assetsForm"
                            (onChangeSelect)="onAssetLevelChange($event)"
                            requiredLableName="Asset Level"
                            controlName="assetLevel"
                            [isMultiple]="false"
                            lableName="Asset Level"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <app-inputbox
                            type="text"
                            formControlName="name"
                            [parentForm]="assetsForm"
                            label="Menu Name"
                            fieldName="name"
                            [isRequired]="true"
                        ></app-inputbox>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <app-inputbox
                            type="text"
                            formControlName="displayText"
                            [parentForm]="assetsForm"
                            label="Display Text"
                            fieldName="displayText"
                            [isRequired]="true"
                        ></app-inputbox>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div *ngIf="isParentAssetVisible" class="col-sm-4 position-relative" style="z-index: 2">
                    <div class="form-group dropdown-box">
                        <app-selectbox
                            formControlName="parentAssetsId"
                            [selectItems]="assets"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select Parent Assets"
                            [isSearchable]="false"
                            [selectedFieldId]="selectedParentAssetsId"
                            [formName]="assetsForm"
                            (onChangeSelect)="onAssetsChange($event)"
                            requiredLableName="Parent Assets"
                            controlName="parentAssetsId"
                            [isMultiple]="false"
                            lableName="Parent Menu Name"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div class="col-sm-4 position-relative" style="z-index: 1">
                    <div class="form-group">
                        <app-inputbox
                            type="text"
                            formControlName="url"
                            [parentForm]="assetsForm"
                            label="URL"
                            [isWhiteSpace]="true"
                            [min]="1"
                            fieldName="url"
                            [isRequired]="true"
                        ></app-inputbox>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="row">
                        <!-- <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="iconClass"
                                        >Icon Class &ensp;
                                        <p style="color: #dc3545; display: inline">*</p></label
                                    >
                                    <mat-form-field class="d-block" appearance="outline">
                                        <mat-select
                                            formControlName="iconClass"
                                            placeholder="Select Icon"
                                            (selectionChange)="onIconClassChange($event)"
                                        >
                                            <mat-select-trigger>
                                                <div
                                                    *ngIf="assetsForm.get('iconClass')?.value"
                                                    [innerHTML]="getFeatherIconPath(assetsForm.get('iconClass')?.value)"
                                                ></div>
                                            </mat-select-trigger>
                                            <mat-option
                                                class="horizontal-options"
                                                *ngFor="let icon of iconList"
                                                [value]="icon"
                                            >
                                                <span [innerHTML]="getFeatherIconPath(icon)"></span>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div> -->
                        <div class="form-group">
                            <app-inputbox
                                type="number"
                                formControlName="position"
                                [parentForm]="assetsForm"
                                label="Position"
                                [isWhiteSpace]="true"
                                [min]="1"
                                fieldName="position"
                                [isRequired]="true"
                                [isPattern]="true"
                                (keyup)="position()"
                            ></app-inputbox>
                        </div>
                        <!-- <div class="col-sm-4">
                            </div> -->
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-4 position-relative" style="z-index: 1">
                    <div class="form-group dropdown-box">
                        <app-selectbox
                            formControlName="menuFor"
                            [selectItems]="menuFor"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select Menu For"
                            [isSearchable]="false"
                            [selectedFieldId]="selectedMenuFor"
                            [formName]="assetsForm"
                            (onChangeSelect)="onMenuForChange($event)"
                            requiredLableName="Menu For"
                            controlName="menuFor"
                            [isMultiple]="false"
                            lableName="Menu For"
                        >
                        </app-selectbox>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <app-inputbox
                                    id="formSwitch1"
                                    formControlName="isActive"
                                    [parentForm]="assetsForm"
                                    fieldName="isActive"
                                    label="Is Active"
                                    type="checkbox"
                                    [isInputCheckbox]="true"
                                    [checkbBoxValue]="isActive"
                                    (onChangeSelect)="onCheckboxChange($event)"
                                ></app-inputbox>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <div class="form-group">
                                <app-inputbox
                                    id="formSwitch1"
                                    formControlName="isLabel"
                                    [parentForm]="assetsForm"
                                    fieldName="isLabel"
                                    label="Is Lable"
                                    type="checkbox"
                                    [isInputCheckbox]="true"
                                    [checkbBoxValue]="isLabel"
                                    (onChangeSelect)="onLabelCheckboxChange($event)"
                                ></app-inputbox>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <app-inputbox
                                    id="formSwitch1"
                                    formControlName="isItComingFromRealData"
                                    [parentForm]="assetsForm"
                                    fieldName="isItComingFromRealData"
                                    label="Is It Coming From Real Data"
                                    type="checkbox"
                                    [isInputCheckbox]="true"
                                    [checkbBoxValue]="isItComingFromRealData"
                                    (onChangeSelect)="onIsItComingFromRealDataCheckboxChange($event)"
                                ></app-inputbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="descrption" class="d-flex align-items-center mb-2"> Description </label>
                        <textarea
                            placeholder="Description"
                            class="form-control border-secondary border-opacity-10"
                            id="descrption"
                            rows="4"
                            formControlName="descrption"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-4">
            <button type="button" class="btn btn-outline-primary fw-semibold mr-2" (click)="dismissModel()">
                Cancel
            </button>

            <ng-container>
                <button type="submit" [disabled]="assetsForm.invalid" class="btn btn-orange px-5">Save</button>
            </ng-container>
        </div>
    </form>
</div>
