import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { API_JOB_PROFILE_SUMMARY } from 'src/app/constants/api';
import { VerficationModel, applicantPipelineVerificationStatus } from 'src/app/model/clinicianProfile.model';
import { GraduationTypeEnum } from 'src/app/model/job.model';
import { BackgroundCheckTypeService } from 'src/app/service/backgroundCheckType.service';
import { ClinicianDetailService } from 'src/app/service/clinicianDetail.service';
import { ClinicianProfileService } from 'src/app/service/clinicianProfile.service';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { MediaUploadService } from 'src/app/service/mediaUpload.service';
import { StatusChangeService } from 'src/app/service/statusChange.service';
import { BaseMasterFormComponent } from 'src/app/shared/components/base-master-form.component';
import { noWhitespaceValidator, removeSpaces } from 'src/app/utill/validator';

@Component({
    selector: 'app-update-verfication-model',
    templateUrl: './update-verfication-model.component.html',
    styleUrl: './update-verfication-model.component.scss',
})
export class UpdateVerficationModelComponent extends BaseMasterFormComponent implements OnInit {
    @Input() data: any;
    @Input() id: any;
    @Input() jobApplicantId: any;
    @Input() jobId: any;
    @Input() type: any;
    @Input() label: any;
    @Input() moduleURL: any;
    @Input() displayFor: any = null;
    @Input() isCallingFromRenewal: boolean = false;
    status: any[] = [
        { id: applicantPipelineVerificationStatus.InProgress, name: 'In Progress' },
        { id: applicantPipelineVerificationStatus.Approved, name: 'Approved' },
        { id: applicantPipelineVerificationStatus.Declined, name: 'Declined' },
    ];
    restrictionList: any;
    selectedStatus: any = null;
    selectedRestriction: any;
    isRestrictionShow: boolean = false;
    verficationForm: FormGroup;
    verificationModel: any = new VerficationModel();
    issuedDate: any;
    expiryDate: any;
    isDisable: boolean = false;
    selectedReApplyDate: any;
    specialities: any;
    @ViewChild('fileInput') fileInput: ElementRef;
    File: any;
    fileUrl: any;
    fileName: any;
    fileSize: any = null;
    fileType: any;
    uploadedFile: any;

    constructor(
        public clinicianProfileService: ClinicianProfileService,
        public modal: NgbActiveModal,
        public fb: FormBuilder,
        private statusChangeService: StatusChangeService,
        private toastrService: ToastrService,
        public mediaUploadService: MediaUploadService,
        private dateUtilService: DateUtilService,
        private clinicianDetailService: ClinicianDetailService,
        private backgroundCheckTypeService: BackgroundCheckTypeService,
        confirmationModalService: NgbModal
    ) {
        super(confirmationModalService);
    }

    ngOnInit(): void {
        this.setFields(this.data);

        this.restrictionList = [
            { id: 1, name: 'Nurse can not apply again for this job' },
            { id: 2, name: 'Nurse can apply again for this job at any time' },
            { id: 3, name: 'Nurse can apply again only after this date:' },
        ];
        this.getApplicatDetails();

        this.initForm();
    }

    initForm() {
        if (this.type == 'EmployementForm') {
            this.verficationForm = this.fb.group({
                id: [this.id],
                status: [this.selectedStatus, Validators.compose([Validators.required])],
                comment: [this.verificationModel?.comment],
                verifiedOn: [''],
                verifiedById: [''],
                jobId: [this.data.jobId],
                jobApplicantId: [this.jobApplicantId],
            });
        } else {
            this.verficationForm = this.fb.group({
                id: [this.id],
                jobId: [this.data.jobId ?? this.jobId],
                jobApplicantId: [this.jobApplicantId],
                clinicianId: [this.data.clinicianId],
                status: [
                    this.displayFor == 'nurse' ? applicantPipelineVerificationStatus.Approved : this.selectedStatus,
                    Validators.compose([Validators.required]),
                ],
                canApply: [this.verificationModel?.canApply],
                reApplyDate: [this.verificationModel?.reApplyDate],
                feedback: [this.verificationModel?.feedback],
                comment: [this.verificationModel?.comment],
                fileUrl: [this.verificationModel?.fileUrl],
                verificationFileUrl: [this.verificationModel?.verificationFileUrl],
                verificationFileName: [this.verificationModel?.verificationFileName],
            });
        }
        this.storeInitialFormState();
    }

    getApplicatDetails() {
        if (this.type == 'license') {
            this.clinicianProfileService.getClinicianLicenseDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.verificationModel = res.result;
                    if (this.verificationModel.statusName == 'In Progress') {
                        this.selectedStatus = 1;
                    }
                    this.initForm();
                }
            });
        } else if (this.type == 'education') {
            this.clinicianProfileService.getClinicianEducationDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.verificationModel = res.result;
                    if (this.verificationModel?.graduationTypeName) {
                        const graduationType = GraduationTypeEnum.find(
                            (item) => item.shortName === this.verificationModel?.graduationTypeName
                        );
                        this.verificationModel.graduationTypeName = graduationType ? graduationType.name : '';
                    }
                    if (this.verificationModel.status == 'In Progress') {
                        this.selectedStatus = 1;
                    }
                    this.initForm();
                }
            });
        } else if (this.type == 'backgroundCheck') {
            // if (this.displayFor == 'nurse') {
            //     this.backgroundCheckTypeService.getBackgroundCheckType(this.id).subscribe((res: any) => {
            //         if (res.result) {
            //             this.verificationModel = res.result;
            //             this.fileName = this.verificationModel.fileName;
            //             if (this.verificationModel.statusName == 'In Progress') {
            //                 this.selectedStatus = 1;
            //             }
            //             this.initForm();

            //             this.verficationForm.controls.canApply.setValue(false);
            //             this.verficationForm.controls.status.setValue(applicantPipelineVerificationStatus.Approved);
            //         }
            //     });
            // } else {
            this.clinicianProfileService.getClinicianBackgroundCheckDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.verificationModel = res.result;
                    this.fileName = this.verificationModel.fileName;
                    if (this.verificationModel.statusName == 'In Progress') {
                        this.selectedStatus = 1;
                    }
                    this.initForm();
                }
                if (this.displayFor == 'nurse') {
                    this.initForm();
                    this.verficationForm.controls.canApply.setValue(false);
                    this.verficationForm.controls.status.setValue(applicantPipelineVerificationStatus.Approved);
                }
            });
            // }
        } else if (this.type == 'credential') {
            this.clinicianProfileService.getClinicianCredentialDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.verificationModel = res.result;
                    if (this.verificationModel.statusName == 'In Progress') {
                        this.selectedStatus = 1;
                    }
                    this.initForm();
                }
            });
        } else if (this.type == 'experience') {
            this.clinicianProfileService.getClinicianExperienceDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.verificationModel = res.result;
                    if (this.verificationModel.statusName == 'In Progress') {
                        this.selectedStatus = 1;
                    }
                    this.initForm();
                    const specialityNames = this.verificationModel.clinicianExperienceSpecialities.map(
                        (item: any) => item.specialityName
                    );

                    // Concatenate them with commas
                    this.specialities = specialityNames.join(', ');
                }
            });
        } else if (this.type == 'EmployementForm') {
            this.clinicianProfileService.getClinicianEmpolyeementFormDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.verificationModel = res.result;
                    if (this.verificationModel.statusName == 'In Progress') {
                        this.selectedStatus = 1;
                    }

                    this.initForm();
                }
            });
        }
    }

    onStatusChange(id: any) {
        this.selectedStatus = id;
        this.verficationForm.controls.comment?.setValue(null);
        this.verficationForm.controls.feedback?.setValue(null);
        this.verficationForm.controls.reApplyDate?.setValue(null);
        this.selectedRestriction = null;
        if (this.displayFor != 'renew') {
            if (applicantPipelineVerificationStatus.Declined == this.selectedStatus) {
                this.isRestrictionShow = true;
                this.verficationForm.controls.canApply.setValidators(Validators.compose([Validators.required]));
                this.verficationForm.controls.canApply.setValue(null);
                this.verficationForm.controls.comment.setValidators(
                    Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                );
                this.verficationForm.controls.feedback.setValidators(
                    Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                );
            } else {
                if (applicantPipelineVerificationStatus.InProgress == this.selectedStatus) {
                    this.verficationForm.controls.comment.clearValidators();
                    this.verficationForm.controls.feedback.clearValidators();
                    this.verficationForm.controls.verificationFileUrl.setValidators([]);
                    this.verficationForm.controls.verificationFileName.setValidators([]);
                    this.verficationForm.controls.comment.updateValueAndValidity();
                    this.verficationForm.controls.feedback.updateValueAndValidity();
                } else {
                    if (this.displayFor != 'nurse') {
                        this.verficationForm.controls.comment.setValidators(
                            Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                        );
                        this.verficationForm.controls.feedback.setValidators(
                            Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                        );
                    }
                }
                this.isRestrictionShow = false;
                this.verficationForm.controls.canApply.setValidators(Validators.compose([]));
                this.verficationForm.controls.canApply.setValue(false);
            }
        }

        this.verficationForm.controls.status.setValue(id);
        this.verficationForm.updateValueAndValidity();
    }

    onRestrictionChange(value: any) {
        this.selectedRestriction = value;
        if (this.selectedRestriction === 1) {
            this.verficationForm.controls.canApply.setValue(false);
            this.verficationForm.controls.reApplyDate.setValidators(Validators.compose([]));
            this.verficationForm.controls.reApplyDate.setValue(null);
        } else if (this.selectedRestriction === 2) {
            this.verficationForm.controls.canApply.setValue(true);
            this.verficationForm.controls.reApplyDate.setValidators(Validators.compose([]));
            this.verficationForm.controls.reApplyDate.setValue(null);
        } else {
            this.verficationForm.controls.canApply.setValue(true);
            this.verficationForm.controls.reApplyDate.setValidators(Validators.compose([Validators.required]));
            this.verficationForm.controls.reApplyDate.setValue(null);
        }
        this.verficationForm.updateValueAndValidity();
    }

    submit() {
        if (!this.fileName && (this.type == 'backgroundCheck' || this.type == 'license') && this.selectedStatus != 1) {
            return;
        }
        if (this.fileSize > 15000000) {
            return;
        }
        let verficationModel = this.verficationForm.value as VerficationModel;
        if (verficationModel?.reApplyDate) {
            verficationModel.reApplyDate = this.dateUtilService.convertDateCurrentTimeToUTC(
                verficationModel.reApplyDate
            );
        }
        if (this.selectedRestriction == 2) {
            verficationModel.reApplyDate = new Date().toISOString();
        }

        verficationModel.isCallingFromRenewal = this.isCallingFromRenewal ? true : false;
        verficationModel.verifiedById = Number(sessionStorage.getItem('contactId'));
        this.type == 'EmployementForm' || this.displayFor == 'nurse'
            ? (verficationModel.verifiedOn = new Date().toISOString())
            : (verficationModel.verifiredon = new Date().toISOString());
        if (this.File && this.type) {
            this.isDisable = true;
            this.imageUploadProfile(this.type);
        } else {
            this.isDisable = true;
            this.updateVerificationStatus(verficationModel);
        }
    }

    updateVerificationStatus(verficationModel: any) {
        this.moduleURL = this.type == 'EmployementForm' ? this.moduleURL + verficationModel.id : this.moduleURL;

        if (this.displayFor == 'nurse') {
            this.moduleURL = API_JOB_PROFILE_SUMMARY.UPDATE_CLINICIAN_BACKGROUND;
            verficationModel.jobId = this.jobId;
            verficationModel.jobApplicantId = this.jobApplicantId;
            verficationModel.clinicianId = this.data.clinicianId;

            this.statusChangeService.addStatus(this.moduleURL, verficationModel).subscribe(
                (response: any) => {
                    if (response.result != null) {
                        let isDecline = this.selectedStatus == 3 ? true : false;
                        let obj = {
                            isSuccess: true,
                            isDeclined: isDecline,
                        };
                        this.modal.close(obj);
                        // this.toastrService.success(response.message);
                    } else {
                        this.isDisable = false;
                        this.toastrService.error(response.message);
                    }
                },
                (err: any) => {
                    this.isDisable = false;
                    this.toastrService.error(err.error.message);
                }
            );
        } else {
            verficationModel.clinicianId = this.data.clinicianId;
            this.statusChangeService.updateStatus(this.moduleURL, verficationModel).subscribe(
                (response: any) => {
                    if (response.result == true) {
                        let isDecline = this.selectedStatus == 3 ? true : false;
                        let obj = {
                            isSuccess: true,
                            isDeclined: isDecline,
                        };
                        this.modal.close(obj); 
                        // this.toastrService.success(response.message);
                    } else {
                        this.isDisable = false;
                        this.toastrService.error(response.message);
                    }
                },
                (err: any) => {
                    this.isDisable = false;
                    this.toastrService.error(err.error.message);
                }
            );
        }
    }

    close() {
        let obj = {
            isSuccess: true,
        };
        this.closeModal(this.modal,obj); 
    }

    onReApplyDateSelectChange(event: any) {
        this.verficationForm.controls.reApplyDate.setValue(event);
    }

    onOpenFile(fileUrl: any) {
        window.open(fileUrl);
    }

    onSelectFile(event: any) {
        const file = event.target.files[0];
        this.fileSize = file?.size;

        this.fileName = file.name;
        this.fileType = file.type;

        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.fileUrl = e.target.result;

            this.fileInput.nativeElement.value = null;

            var blob = this.dataURItoBlob(reader.result);
            this.File = new File([blob], this.fileType == 'application/pdf' ? '.pdf' : '.doc', {
                type: blob.type,
            });
        };

        reader.readAsDataURL(file);
        this.verficationForm.controls['fileUrl'].setValue(this.fileUrl);
        this.verficationForm.controls['fileUrl'].updateValueAndValidity();
    }

    dataURItoBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
        else byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    imageUploadProfile(type: any) {
        let fileDriveName =
            type == 'backgroundCheck'
                ? 'BackgroundCheckDocument'
                : type == 'license'
                ? 'LicenseDocument'
                : type == 'credential'
                ? 'CredentialDocument'
                : 'educationDocument';

        this.mediaUploadService.mediaUpload(this.File, fileDriveName).subscribe(
            (response) => {
                if (response.result.isSuccess) {
                    this.uploadedFile = response.result.fileUrl;
                    let data = this.verficationForm.value as VerficationModel;
                    if (type == 'backgroundCheck') {
                        data.fileName = this.fileName;
                        data.thumbnail = response.result.thumbnail ? response.result.thumbnail : '';
                        data.fileUrl = response.result.fileUrl;
                    } else {
                        data.verificationFileName = this.fileName;
                        data.verificationFileUrl = response.result.fileUrl;
                    }
                    this.updateVerificationStatus(data);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            },
            (err) => {
                this.isDisable = false;
                this.toastrService.error(err.error.message);
            }
        );
    }

    viewPdf() {
        if (this.fileUrl) {
            // if (this.fileUrl) {
            // Extract the Base64 content (remove the Data URL prefix)
            const base64Content = this.fileUrl.split(',')[1];

            // Convert the Base64 content to a Blob
            const pdfBlob = this.base64ToBlob(base64Content, this.fileType);

            // Create a Blob URL
            const pdfBlobUrl = URL.createObjectURL(pdfBlob);

            // Open the PDF in a new tab
            window.open(pdfBlobUrl, '_blank');
            // }
        } else {
            window.open(this.verificationModel?.fileUrl);
        }
    }

    base64ToBlob(base64: string, contentType: string): Blob {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    formatNumber(number: any) {
        return number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }

    setFields(obj: any) {
        this.data = this.clinicianDetailService.setFields(obj);
    }

    get form() {
        return { verficationForm :  this.verficationForm};
    }
}
