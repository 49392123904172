import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { API_ASSESTS, API_JOB } from '../constants/api';
import { ApiService } from './api.service';
import { DataService } from './data.service';  // Adjust import for DataService

interface MenuItem {
    name: string;
    displayText: string;
    iconClass?: string;
    url: string;
    assetLevel: number;
    childAssets: MenuItem[];
    isLable?: boolean;
    setDefault?: boolean;
    isItComingFromRealData?: boolean;
    realDataAssets?: any[]; // Adjust according to actual data type
}


@Injectable({
    providedIn: 'root',
})
export class AssestsService {
    private sidebarData: any[] | null = null;

    constructor(private apiService: ApiService, private dataService: DataService) {}

    getAssestsByRoleId(id: any): Observable<any> {
        return this.apiService.get(API_ASSESTS.GET_ASSETS_LIST_BY_ROLEID + '?roleId=' + id);
    }

    getAssestsRoleId(id: any): Observable<any> {
        return this.apiService.get(API_ASSESTS.GET_ASSETS_LIST_ROLEID + '?roleId=' + id);
    }

    addAssets(res: any): Observable<any> {
        return this.apiService.post(API_ASSESTS.POST_ASSETS, res);
    }

    getAssetsList(assetLevel: any): Observable<any> {
        return this.apiService.get(API_ASSESTS.GET_ASSETS_LIST + '?level=' + assetLevel);
    }

    updateAssets(id: any, res: any): Observable<any> {
        return this.apiService.put(API_ASSESTS.PUT_ASSETS, res);
    }

    deleteAssets(id: any): Observable<any> {
        return this.apiService.delete(API_ASSESTS.DELETE_ASSETS, id);
    }

    getAssetsListByRealData(companyId: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_ASSESTS + '?companyId=' + companyId);
    }

    // Method to call the sidebar API
    fetchSidebarData(companyId: any): Observable<any> {
        if (this.sidebarData) {
            // If sidebarData is already available, return it as an Observable
            return of(this.sidebarData);
        } else {
            // Otherwise, call the API and store the data
            return this.apiService.get(`${API_JOB.GET_ASSESTS}?companyId=${companyId}`).pipe(
                tap((data) => {
                    this.sidebarData = data;
                })
            );
        }
    }

    // Method to clear stored data if necessary
    clearSidebarData() {
        this.sidebarData = null;
    }


    processMenuItems(menuItems: MenuItem[], defaultActiveName: string, defaultActiveId: string, secondLevelAssetName: string, defaultActiveUrl: string, isItComingFromRealData: string) {
      const level1MenuItems: MenuItem[] = [];
      const level2MenuItems: MenuItem[] = [];
      const level3MenuItems: MenuItem[][] = [];
      const menuItemsProcessed: any[] = [];
  
      menuItems.forEach((element: MenuItem) => {
        if (element.assetLevel === 1) {
          level1MenuItems.push(...this.setDefaultMenuItems(menuItems, defaultActiveName));
        }
  
        if (defaultActiveName === element.name) {
          const level2Assets = element.childAssets.filter((x: any) => x.parentAssetsId === defaultActiveId);
          level2MenuItems.push(...this.setDefaultMenuItems(level2Assets, secondLevelAssetName));
          level3MenuItems.push(...level2MenuItems.map((item: MenuItem) => item.childAssets));
  
          level2MenuItems.forEach((subElement) => {
            if (subElement.name === secondLevelAssetName) {
              this.dataService.setData(subElement.childAssets);
            }
            if (subElement.isItComingFromRealData) {
              this.dataService.setJobData(subElement.realDataAssets);
            }
          });
  
          if (isItComingFromRealData) {
            // this.getAssetsListByRealData();
          }
        }
  
        const menuItem = {
          label: element.displayText,
          icon: element.iconClass,
          subMenus: [],
          link: element.url,
          title: element.isLable,
          active: element.url === defaultActiveUrl,
        };
  
        // if (menuItem.label === 'Dashboard') {
        //   delete menuItem.label;
        // }
        menuItemsProcessed.push(menuItem);
      });
  
      return { level1MenuItems, level2MenuItems, level3MenuItems, menuItemsProcessed };
    }
  
    setDefaultMenuItems(items: MenuItem[], defaultName: string): MenuItem[] {
      return items.map((item: MenuItem) => ({
        ...item,
        setDefault: item.name === defaultName,
      }));
    }
  
  
    
}
