<!-- Shift Details Modal -->
<div class="profile-modal-content modal-content fixwidth shift-details-modal" *ngIf="inspectorDetails">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <div class="modal-title h4">
            <ng-container> {{ title }} </ng-container>
        </div>
        <label
            class="badge status-badge fw-bolder"
            *ngIf="!inspectorDetails?.status.includes(',')"
            [ngStyle]="{
                'background-color': getStatusColor(inspectorDetails?.status, 'background'),
                color: getStatusColor(inspectorDetails?.status, 'text'),
                'border-color': getStatusColor(inspectorDetails?.status, 'border'),
                'border-style': 'solid',
                'border-width': '1px'
            }"
            >{{ inspectorDetails?.status }}</label
        >
        <div *ngIf="inspectorDetails?.status.includes(',')">
            <label
                *ngFor="let status of inspectorDetails?.status.split(',')"
                class="badge status-badge fw-bolder flex-column ms-2"
                [ngStyle]="{
                    'background-color': getStatusColor(status, 'background'),
                    color: getStatusColor(status, 'text'),
                    'border-color': getStatusColor(status, 'border'),
                    'border-style': 'solid',
                    'border-width': '1px'
                }"
                >{{ status }}</label
            >
        </div>
    </div>
    <hr class="mt-0 model-body-hr border-primary" />
    <div class="modal-body p-4 pt-0">
        <!-- Facility, Date, Shift Period -->
        <div class="row">
            <div class="my-2 col-md-5">
                <h5>Facility</h5>
                <p class="text-secondary">
                    {{ inspectorDetails?.hospitalName }}
                </p>
            </div>
            <div class="my-2 col-md-3">
                <h5>Date</h5>
                <p class="text-secondary">{{ inspectorDetails?.date | localDateTime }}</p>
            </div>
            <div class="my-2 col-md-4">
                <h5>Shift Period</h5>
                <p class="text-secondary">
                    {{ inspectorDetails?.fromTime | localDateTime : 'shortTime' }} -
                    {{ inspectorDetails?.toTime | localDateTime : 'shortTime' }}
                </p>
            </div>
        </div>
        <!-- Extend Shift, Date, Shift Period, Bonus-->
        <div class="row" *ngIf="inspectorDetails?.isExtended">
            <div class="my-2 col-md">
                <h5>Extended Time</h5>
                <p class="text-secondary">
                    {{ inspectorDetails?.extendedTime + ' minutes' }}
                </p>
            </div>
            <div class="my-2 col-md-4" *ngIf="inspectorDetails?.extendedBonus">
                <h5>Extended Bonus</h5>
                <p class="text-secondary">
                    {{
                        inspectorDetails?.extendedBonus
                            ? '$ ' + inspectorDetails?.extendedBonus
                            : inspectorDetails?.extendedBonus
                    }}
                </p>
            </div>
            <div class="my-2 col-md-4">
                <h5>New Shift Period</h5>
                <p class="text-secondary">
                    {{ inspectorDetails?.fromTime | localDateTime : 'shortTime' }} -
                    {{ inspectorDetails?.newShiftTime | localDateTime : 'shortTime' }}
                </p>
            </div>
        </div>
        <!-- License, Specialty -->
        <div class="row">
            <div [ngClass]="inspectorDetails.status == 'In Progress' ? 'my-2 col-md-2' : 'my-2 col-md-3'">
                <h5>License</h5>
                <p class="text-secondary">{{ inspectorDetails?.licenseTypeAcronym }}</p>
            </div>

            <div [ngClass]="inspectorDetails.status == 'In Progress' ? 'my-2 col-md-4' : 'my-2 col-md-4'">
                <h5>Speciality</h5>
                <p class="text-secondary">{{ inspectorDetails?.speciality }}</p>
            </div>

            <div class="my-2 col-md-4" *ngIf="inspectorDetails?.unitName != null">
                <h5>Unit</h5>
                <p class="text-secondary">{{ inspectorDetails?.unitName }}</p>
            </div>

            <div class="my-2 col-md-4 text-end" *ngIf="inspectorDetails.status == 'In Progress'">
                <button
                    class="btn btn-primary fw-semibold px-2 px-md-3 border-0"
                    type="button"
                    (click)="updateStatus('ExtendShift')"
                >
                    Extend Shift
                </button>
            </div>
        </div>

        <div class="row">
            <div class="my-2 col-md-3" *ngIf="inspectorDetails.isFacilityOperatingAllLevel">
                <h5>Level 1 Rate</h5>
                <p class="text-secondary">{{ '$' + inspectorDetails?.level1Price }}</p>
            </div>

            <div class="my-2 col-md-3" *ngIf="inspectorDetails.isFacilityOperatingAllLevel">
                <h5>Level 2 Rate</h5>
                <p class="text-secondary">{{ '$' + inspectorDetails?.level2Price }}</p>
            </div>

            <div class="my-2 col-md-3">
                <h5>Level 3 Rate</h5>
                <p class="text-secondary">{{ '$' + inspectorDetails?.level3Price }}</p>
            </div>

            <div class="my-2 col-md-3">
                <h5>Level 3 Bill Rate</h5>
                <p class="text-secondary">{{ '$' + inspectorDetails?.billRate }}</p>
            </div>
        </div>
        <!-- Nurse Information -->
        <hr class="model-body-hr border-primary" />

        <div class="row" *ngIf="data.status == 'Cancel'">
            <div class="my-2 col-md-3">
                <h5>Cancel By</h5>
                <p class="text-secondary">{{ inspectorDetails?.verifiedBy }}</p>
            </div>
            <div class="my-2 col-md-5">
                <h5>Cancel On</h5>
                <p class="text-secondary">{{ inspectorDetails?.verifiedOn | localDateTime }}</p>
            </div>
        </div>

        <div *ngIf="data.status == 'Completed'">
            <div class="row">
                <div class="my-2 col-md-4" *ngIf="data?.price">
                    <h5>Hour Rate</h5>
                    <p class="text-secondary">
                        {{ '$' + data?.price }}
                    </p>
                </div>
                <div class="my-2 col-md-4" *ngIf="data?.billRate">
                    <h5>Hour Bill Rate</h5>
                    <p class="text-secondary">
                        {{ '$' + data?.billRate }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="my-2 col-md-4" *ngIf="data?.totalPrice">
                    <h5>Total Price</h5>
                    <p class="text-secondary">{{ '$' + data?.totalPrice }}</p>
                </div>
                <div class="my-2 col-md-4" *ngIf="data?.totalBillRate">
                    <h5>Total Bill Price</h5>
                    <p class="text-secondary">{{ '$' + data?.totalBillRate }}</p>
                </div>
            </div>
        </div>

        <hr *ngIf="data.status == 'Completed'" class="model-body-hr border-primary" />

        <div
            class="row my-2"
            *ngIf="
                inspectorDetails.status != 'Open Shift' &&
                inspectorDetails.status != 'Open Shift, Late Call' &&
                inspectorDetails.status != 'Open Shift, Called Out' &&
                inspectorDetails.status != 'Open Shift, Late Call, Called Out' &&
                inspectorDetails.status != 'Open Shift, NCNS' &&
                inspectorDetails.status != 'Open Shift, Late Call, NCNS' &&
                inspectorDetails.status != 'Called Out' &&
                inspectorDetails.status != 'NCNS' &&
                inspectorDetails.status != 'Unschedule' &&
                data.status != 'Cancel'
            "
        >
            <div class="col-md-12">
                <div class="status-title">Nurse Information</div>
                <div class="row mt-3">
                    <div class="col-md-2">
                        <img
                            *ngIf="inspectorDetails?.nurseInformation?.profileUrl"
                            [src]="inspectorDetails?.nurseInformation?.profileUrl"
                            height="70px"
                            width="70px"
                            class="rounded-circle"
                        />
                        <img
                            *ngIf="!inspectorDetails?.nurseInformation?.profileUrl"
                            src="./assets/images/others/blank.png"
                            height="70px"
                            width="70px"
                            class="rounded-circle"
                        />
                    </div>
                    <div
                        [ngClass]="
                            inspectorDetails.status == 'Missing Clock-out'
                                ? 'col-md-5 d-flex align-items-center'
                                : 'col-md-5 d-flex align-items-center'
                        "
                    >
                        <div>
                            <h6 class="nurse-name">
                                {{ inspectorDetails?.nurseInformation?.firstName }}
                                {{ inspectorDetails?.nurseInformation?.lastName }}
                                <label
                                    *ngIf="inspectorDetails?.clinicianLevel"
                                    class="badge fw-bolder me-2"
                                    [ngStyle]="{
                                        'background-color': getStatusColor(
                                            inspectorDetails?.clinicianLevel,
                                            'background'
                                        ),
                                        color: getStatusColor(inspectorDetails?.clinicianLevel, 'text'),
                                        'border-color': getStatusColor(inspectorDetails?.clinicianLevel, 'border'),
                                        'border-style': 'solid',
                                        'border-width': '1px'
                                    }"
                                >
                                    {{ inspectorDetails?.clinicianLevel }}
                                </label>
                            </h6>
                            <div class="d-flex label-list">
                                <label
                                    class="badge fw-bolder"
                                    [ngStyle]="{
                                        'background-color': type?.chipBackgroundColor,
                                        color: type?.chipFontColor
                                    }"
                                    *ngFor="let type of inspectorDetails?.nurseInformation?.clinicianLicences"
                                    >{{ type?.acronym }}</label
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        [ngClass]="
                            inspectorDetails.status == 'Missing Clock-out'
                                ? 'col-md-5 d-flex flex-column align-items-end justify-content-center'
                                : 'col-md-5 d-flex flex-column align-items-end justify-content-center'
                        "
                    >
                        <label
                            *ngIf="inspectorDetails.status == 'Missing Clock-in'"
                            class="badge d-flex align-items-end justify-content-center"
                            [ngStyle]="{
                            'background-color': '#FCE9E9',
                            color: '#103558',
                        }"
                            ><i class="feather icon-clock text-danger me-2"></i>
                            <span>{{ getTimeDifference(inspectorDetails?.fromTime) + ' late' }}</span>
                        </label>
                        <label
                            *ngIf="inspectorDetails.status == 'Missing Clock-out'"
                            class="badge d-flex align-items-end justify-content-center"
                            [ngStyle]="{
                        'background-color': '#FCE9E9',
                        color: '#103558',
                    }"
                            ><i class="feather icon-clock text-danger me-2"></i
                            ><span>{{
                                getTimeDifference(inspectorDetails?.toTime) + ' late for clock-out'
                            }}</span></label
                        >
                        <label
                            *ngIf="inspectorDetails.status == 'In Progress' || inspectorDetails.status == 'Completed'"
                            class="badge d-flex align-items-end justify-content-center"
                            [ngStyle]="{
                        'background-color': '#E9FCF4',
                        color: '#103558',
                    }"
                            ><i class="feather icon-clock text-success me-2"></i>
                            <span
                                >Clocked-in at {{ inspectorDetails?.clockIn | localDateTime : 'shortTime' }}</span
                            ></label
                        >
                        <div class="mt-2" *ngIf="inspectorDetails.status == 'Completed'">
                            <label
                                class="badge d-flex align-items-end justify-content-center"
                                [ngStyle]="{
                            'background-color': '#E9FCF4',
                            color: '#103558',
                        }"
                                ><i class="feather icon-clock text-success me-2"></i>
                                <span
                                    >Clocked-out at {{ inspectorDetails?.clockOut | localDateTime : 'shortTime' }}</span
                                ></label
                            >
                        </div>
                        <div class="mt-1 d-flex justify-content-end ms-auto">
                            <button
                                class="btn btn-primary fw-semibold px-2 px-md-3"
                                type="button"
                                (click)="viewProfile(inspectorDetails)"
                            >
                                Nurse profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr
            class="model-body-hr"
            *ngIf="
                data.status != 'Open Shift' &&
                data.status != 'Open Shift, Late Call' &&
                data.status != 'Open Shift, Called Out' &&
                data.status != 'Open Shift, Late Call, Called Out' &&
                data.status != 'Open Shift, NCNS' &&
                data.status != 'Open Shift, Late Call, NCNS'
            "
        />

        <!-- Call Out Shift position -->

        <div class="mt-3" *ngIf="data.status == 'Called Out'">
            <div class="status-title">
                <ng-container *ngIf="inspectorDetails?.callOutInfo?.isShowExcusedInfo; else callOutExcuse">
                    Call out - {{ inspectorDetails?.callOutInfo?.isDeclineExcuse ? 'Not Excused' : 'Excused' }}
                </ng-container>
                <ng-template #callOutExcuse> Call out excuse </ng-template>
            </div>
            <div class="text-secondary mb-3">
                <div class="row mt-3">
                    <div class="col-5 mt-3">
                        <h5>Call out date and time</h5>
                        <p class="text-break description mt-2">
                            {{ inspectorDetails?.callOutInfo?.dateTime | localDateTime }} -
                            {{ inspectorDetails?.callOutInfo?.dateTime | localDateTime : 'shortTime' }}
                        </p>
                    </div>
                    <div
                        class="col-7 cursor-pointer"
                        *ngIf="inspectorDetails?.callOutInfo?.fileUrl"
                        (click)="onOpenFile(inspectorDetails?.callOutInfo?.fileUrl)"
                    >
                        <div class="card card-file-opload rounded-3 p-3">
                            <div class="row g-4">
                                <div class="col-3">
                                    <img src="assets/images/file-logo.jpg" class="w-100" />
                                </div>
                                <div class="col-9 ps-0">
                                    <div class="file-opload-info">
                                        <p class="text-black">Call out proof of reason</p>
                                        <p class="cursor-pointer text-muted">
                                            {{ inspectorDetails?.callOutInfo?.fileName }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="inspectorDetails?.callOutInfo?.reason">
                    <h5>Reason</h5>
                    <p class="text-break description mt-2">{{ inspectorDetails?.callOutInfo?.reason }}</p>
                </div>

                <div class="row mt-3" *ngIf="inspectorDetails?.callOutInfo?.publicComment">
                    <h5>Call out notes for the nurse</h5>
                    <p class="text-break description mt-2">{{ inspectorDetails?.callOutInfo?.publicComment }}</p>
                </div>
                <div class="row mt-3" *ngIf="inspectorDetails?.callOutInfo?.comments">
                    <h5>Call out internal notes</h5>
                    <p class="text-break description mt-2">{{ inspectorDetails?.callOutInfo?.comments }}</p>
                </div>
                <div class="row mt-3" *ngIf="inspectorDetails?.callOutInfo?.verifiedBy">
                    <div class="col-md-6">
                        <h5>{{ inspectorDetails?.callOutInfo?.isDeclineExcuse ? 'Not Excused' : 'Excused' }} date</h5>
                        <p class="text-break description mt-2">
                            {{ inspectorDetails?.callOutInfo?.verifiedOn | localDateTime : 'atDateTime' }}
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h5>Author</h5>
                        <p class="text-break description mt-2">{{ inspectorDetails?.callOutInfo?.verifiedBy }}</p>
                    </div>
                </div>

                <form
                    [formGroup]="shiftDetailsForm"
                    class="form"
                    novalidate="novalidate"
                    id="kt_category_form"
                    *ngIf="!inspectorDetails.callOutInfo.publicComment"
                >
                    <div>
                        <div class="row mt-3">
                            <div class="col-10 d-flex">
                                <div *ngFor="let status of status">
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            name="status"
                                            class="form-check-input cursor-pointer"
                                            [checked]="status.name === selectedStatus"
                                            [value]="status.name"
                                            (change)="onStatusChange(status.id)"
                                        />
                                        <label
                                            class="form-check-label fw-normal ms-1 d-flex justify-content-center align-items-center"
                                        >
                                            {{ status.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group label-normal mt-3">
                            <app-textarea
                                label="Call out notes for the nurse"
                                [fieldName]="'Call out notes for the nurse'"
                                [placeholder]="'Please add your comments for the nurse here'"
                                [isRequired]="true"
                                [rows]="5"
                                [cols]="250"
                                [min]="1"
                                [max]="1000"
                                [isShowCharacterCount]="true"
                                [parentForm]="shiftDetailsForm"
                                formControlName="publicComment"
                            >
                            </app-textarea>
                        </div>

                        <div class="form-group label-normal mt-3">
                            <app-textarea
                                label="Call out internal notes"
                                [fieldName]="'Call out internal notes'"
                                [placeholder]="'Please add your comments here'"
                                [isRequired]="true"
                                [rows]="5"
                                [cols]="250"
                                [min]="1"
                                [max]="1000"
                                [isShowCharacterCount]="true"
                                [parentForm]="shiftDetailsForm"
                                formControlName="comment"
                            >
                            </app-textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Call Out Shift position -->

        <!-- Shift History -->
        <div
            class="row"
            *ngIf="
                ![
                    'NCNS',
                    'Unschedule',
                    'Released',
                    'Open Shift',
                    'Open Shift, Late Call',
                    'Open Shift, Called Out',
                    'Open Shift, Late Call, Called Out',
                    'Open Shift, NCNS',
                    'Open Shift, Late Call, NCNS',
                    'Cancel',
                    'Completed'
                ].includes(inspectorDetails.status)
            "
        >
            <div class="col-md-12">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-4 status-title">
                        <h4>Shift History</h4>
                    </div>
                    <div class="col-md-8 container-fluid market-shifts">
                        <div
                            class="row justify-content-start align-items-center d-flex flex-nowrap shift-buttons button-row me-3"
                        >
                            <button
                                *ngFor="let item of shiftHistoryMonths"
                                [ngClass]="{
                                    'shift-history-active-btn text-color': selectedMonth === item.id
                                }"
                                class="btn btn-default shift-history-btn"
                                (click)="selectShiftMonth(item.id)"
                            >
                                {{ item.name }}
                            </button>
                        </div>
                    </div>
                </div>
                <table class="table table-bordered shift-history-table">
                    <tbody class="table-content">
                        <tr
                            *ngFor="let shiftHistory of inspectorDetails?.shiftHistory?.shiftHistoryDetail"
                            class="text-secondary"
                        >
                            <td class="p-2">{{ shiftHistory?.title }}</td>
                            <td class="p-2">
                                {{ shiftHistory?.count }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!--Open Shift position -->
        <form [formGroup]="shiftDetailsForm" class="form" novalidate="novalidate" id="kt_category_form" [appUnsavedChanges]="shiftDetailsForm"
        [hasFormChanged]="hasFormChanged.bind(this)">
            <div
                class="row me-2"
                *ngIf="
                    data.status == 'Open Shift' ||
                    data.status == 'Open Shift, Late Call' ||
                    data.status == 'Open Shift, Called Out' ||
                    data.status == 'Open Shift, Late Call, Called Out' ||
                    data.status == 'Open Shift, NCNS' ||
                    data.status == 'Open Shift, Late Call, NCNS'
                "
            >
                <div class="row">
                    <div class="col-md-2 d-flex my-2">
                        <img src="./assets/images/others/blank.png" class="rounded-circle" height="70px" />
                    </div>
                    <div class="col-md-8 d-flex align-items-center">
                        <span class="open-shift-position">Open Shift</span>
                    </div>
                </div>
                <div class="mt-5">
                    <span class="d-block">The shift is still open. What do you want to perform?</span>

                    <div class="form-check mt-3 mt-md-4">
                        <input
                            type="radio"
                            name="canReApplyForJob"
                            class="form-check-input cursor-pointer"
                            value="1"
                            (change)="onShiftPositionChange(1)"
                        />
                        <label class="form-check-label"> Remove this shift </label>
                    </div>
                    <!-- <div class="form-check mt-3 mt-md-4">
                        <input
                            type="radio"
                            name="canReApplyForJob"
                            class="form-check-input cursor-pointer"
                            value="2"
                            (change)="onShiftPositionChange(2)"
                        />
                        <label class="form-check-label"> Reschedule this shift </label>
                    </div> -->
                    <div class="form-check mt-3 mt-md-4">
                        <input
                            type="radio"
                            name="canReApplyForJob"
                            class="form-check-input cursor-pointer"
                            value="3"
                            (change)="onShiftPositionChange(3)"
                        />
                        <label class="form-check-label"> Reschedule and boost this shift </label>
                    </div>
                    <div class="form-check mt-3 mt-md-4">
                        <input
                            type="radio"
                            name="canReApplyForJob"
                            class="form-check-input cursor-pointer"
                            value="4"
                            (change)="onShiftPositionChange(4)"
                        />
                        <label class="form-check-label"> Only boost this shift </label>
                    </div>
                </div>
            </div>

            <div class="row g-2 mt-0 mb-1 mt-4" *ngIf="shiftTypePosition == 2 || shiftTypePosition == 3">
                <div class="col-xl-6 col-lg-6 col-6 mb-1">
                    <div class="form-group date-picker-control">
                        <app-datepicker
                            controlName="date"
                            fieldName="date"
                            fieldId="date"
                            [selectedFieldDate]="selectedDate"
                            (onDateSelect)="onDateSelectChange($event)"
                            requiredLableName="New Shift Date"
                            lableName="New Shift Date"
                            [isRequired]="true"
                            [formName]="shiftDetailsForm"
                        >
                        </app-datepicker>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-6 dropdown-box mb-2" style="z-index: 9">
                    <div class="form-group">
                        <app-selectbox
                            [selectItems]="shiftTimeList"
                            fieldName="shiftTime"
                            fieldId="id"
                            placeholder="Select Shift Times"
                            [isSearchable]="false"
                            [selectedFieldId]="selectedShiftTime"
                            (onChangeSelect)="onShiftTimeChange($event)"
                            requiredLableName="New Shift Time"
                            [isRequired]="true"
                            controlName="shiftTimeId"
                            [isMultiple]="false"
                            lableName="New Shift Time"
                            formControlName="shiftTimeId"
                        >
                        </app-selectbox>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="selectedShiftTime === 'custom'">
                <!-- Start Time -->
                <div class="col-lg-3 col-12 mb-1">
                    <label class="form-label fw-bolder"> Start Time</label>
                    <p-calendar
                        class="p-calendar"
                        [showTime]="true"
                        [timeOnly]="true"
                        formControlName="fromTime"
                        placeholder="Start Time"
                        hourFormat="12"
                        (onSelect)="selectedTime()"
                        (keyup)="selectedTime()"
                    ></p-calendar>
                    <div
                        *ngIf="
                            shiftDetailsForm.get('fromTime')?.invalid &&
                            (shiftDetailsForm.get('fromTime')?.dirty || shiftDetailsForm.get('fromTime')?.touched)
                        "
                    >
                        <small class="input-error">Start Time is required</small>
                    </div>
                </div>

                <!-- End Time -->
                <div class="col-lg-3 col-12 mb-1">
                    <label class="form-label fw-bolder"> End Time</label>

                    <p-calendar
                        class="p-calendar"
                        [showTime]="true"
                        [timeOnly]="true"
                        formControlName="toTime"
                        placeholder="End Time"
                        hourFormat="12"
                        (onSelect)="selectedTime()"
                        (keyup)="selectedTime()"
                    ></p-calendar>
                    <div
                        *ngIf="
                            shiftDetailsForm.get('toTime')?.invalid &&
                            (shiftDetailsForm.get('toTime')?.dirty || shiftDetailsForm.get('toTime')?.touched)
                        "
                    >
                        <small class="input-error">End Time is required</small>
                    </div>
                </div>
            </div>
            <div
                class="row mt-1 g-2 mb-3"
                *ngIf="shiftTypePosition == 2 || shiftTypePosition == 3 || shiftTypePosition == 4"
            >
                <div id="header">
                    <div class="mb-2">Regular Price(hour)</div>
                </div>
                <div class="col-md-6 form-group" *ngIf="!isAgencyLevelLogin && isFacilityOperatingAllLevel">
                    <app-inputbox
                        type="number"
                        [isRequired]="false"
                        [isWhiteSpace]="false"
                        [min]="1"
                        [max]="5"
                        [isMinimum]="true"
                        [isNumber]="true"
                        [isPattern]="false"
                        [isAlphabet]="true"
                        [placeholder]="'$ 000.00'"
                        [isRate]="true"
                        [rateMax]="3"
                        formControlName="level1Price"
                        fieldName="level1Price"
                        [parentForm]="shiftDetailsForm"
                        (keyup)="onPriceBonusChanges('level1Price', $event)"
                        label="Level 1 Pay Rate"
                    ></app-inputbox>
                </div>
                <!-- <div class="form-group wd-200 ms-3">
                            <app-inputbox
                                type="number"
                                [isRequired]="true"
                                [isWhiteSpace]="false"
                                [min]="1"
                                [max]="5"
                                [isMinimum]="true"
                                [isLabel]="true"
                                [isNumber]="true"
                                [isPattern]="false"
                                [isAlphabet]="true"
                                [placeholder]="'$ 000.00'"
                                [isRate]="true"
                                [rateMax]="3"
                                formControlName="level1LateCallBonus"
                                fieldName="level1LateCallBonus"
                                [parentForm]="shiftDetailsForm"
                                (keyup)="onPriceBonusChanges('level1LateCallBonus', $event)"
                            ></app-inputbox>
                        </div> 
                 </div> -->
                <div class="col-md-6 form-group" *ngIf="!isAgencyLevelLogin && isFacilityOperatingAllLevel">
                    <app-inputbox
                        type="number"
                        [isRequired]="false"
                        [isWhiteSpace]="false"
                        [min]="1"
                        [max]="5"
                        [isMinimum]="true"
                        [isNumber]="true"
                        [isPattern]="false"
                        [isAlphabet]="true"
                        [placeholder]="'$ 000.00'"
                        [isRate]="true"
                        [rateMax]="3"
                        formControlName="level2Price"
                        fieldName="level2Price"
                        [parentForm]="shiftDetailsForm"
                        (keyup)="onPriceBonusChanges('level2Price', $event)"
                        label="Level 2 Pay Rate"
                    ></app-inputbox>
                </div>
                <!-- <div class="form-group wd-200 ms-3">
                            <app-inputbox
                                type="number"
                                [isRequired]="true"
                                [isWhiteSpace]="false"
                                [min]="1"
                                [max]="5"
                                [isMinimum]="true"
                                [isLabel]="true"
                                [isNumber]="true"
                                [isPattern]="false"
                                [isAlphabet]="true"
                                [placeholder]="'$ 000.00'"
                                [isRate]="true"
                                [rateMax]="3"
                                formControlName="level2LateCallBonus"
                                fieldName="level2LateCallBonus"
                                [parentForm]="shiftDetailsForm"
                                (keyup)="onPriceBonusChanges('level2LateCallBonus', $event)"
                            ></app-inputbox>
                        </div>
                    </div> -->
                <div class="col-md-6 form-group">
                    <app-inputbox
                        type="number"
                        [isRequired]="isAgencyLevelLogin ? true : isFacilityOperatingAllLevel ? false : true"
                        [isWhiteSpace]="false"
                        [min]="1"
                        [max]="5"
                        [isMinimum]="true"
                        [isNumber]="true"
                        [isPattern]="false"
                        [isAlphabet]="true"
                        [placeholder]="'$ 000.00'"
                        [isRate]="true"
                        [rateMax]="3"
                        formControlName="level3Price"
                        [parentForm]="shiftDetailsForm"
                        fieldName="level3Price"
                        (keyup)="onPriceBonusChanges('level3Price', $event)"
                        label="Level 3 Pay Rate"
                    ></app-inputbox>
                </div>
                <!-- <div class="form-group wd-200 ms-3">
                            <app-inputbox
                                type="number"
                                [isRequired]="true"
                                [isWhiteSpace]="false"
                                [min]="1"
                                [max]="5"
                                [isMinimum]="true"
                                [isLabel]="true"
                                [isNumber]="true"
                                [isPattern]="false"
                                [isAlphabet]="true"
                                [placeholder]="'$ 000.00'"
                                [isRate]="true"
                                [rateMax]="3"
                                formControlName="level3LateCallBonus"
                                [parentForm]="shiftDetailsForm"
                                fieldName="level3LateCallBonus"
                                (keyup)="onPriceBonusChanges('level3LateCallBonus', $event)"
                            ></app-inputbox>
                        </div>
              </div> -->
                <div class="col-md-6 form-group">
                    <app-inputbox
                        type="number"
                        [isRequired]="isAgencyLevelLogin ? true : isFacilityOperatingAllLevel ? false : true"
                        [isWhiteSpace]="false"
                        [min]="1"
                        [max]="5"
                        [isMinimum]="true"
                        [isNumber]="true"
                        [isPattern]="false"
                        [isAlphabet]="true"
                        [placeholder]="'$ 000.00'"
                        [isRate]="true"
                        [rateMax]="3"
                        formControlName="level3BillRate"
                        [parentForm]="shiftDetailsForm"
                        fieldName="level3BillRate"
                        (keyup)="onPriceBonusChanges('level3BillRate', $event)"
                        label="Level 3 Bill Rate"
                    ></app-inputbox>
                    <!-- Custom error for billRateLessThanPrice -->
                    <div class="input-error" *ngIf="shiftDetailsForm.errors?.level3BillRateLessThanlevel3Price">
                        Level 3 Bill rate must be greater than Level 3 Pay rate.
                    </div>
                </div>
            </div>
        </form>
        <!-- Released -->
        <div class="row" *ngIf="data.status == 'Released'">
            <div class="col-md-12">
                <div class="status-title">Release Information</div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <h5>Release date and time</h5>
                    <p class="text-break description">
                        {{ inspectorDetails?.commentsDetail?.verifyDate | localDateTime }} -
                        {{ inspectorDetails?.commentsDetail?.verifyDate | localDateTime : 'shortTime' }}
                    </p>
                </div>
                <div class="col-6">
                    <h5>Reasons</h5>
                    <p>{{ inspectorDetails?.commentsDetail?.reasons }}</p>
                </div>
            </div>
        </div>
        <!-- NCNC & Released shift & Unschedule -->
        <div
            class="text-secondary"
            *ngIf="data.status == 'NCNS' || data.status == 'Released' || data.status == 'Unschedule'"
        >
            <div class="row mt-3" *ngIf="inspectorDetails?.commentsDetail?.comments">
                <h5>Comments for nurse</h5>
                <p class="text-break description">{{ inspectorDetails?.commentsDetail?.comments }}</p>
            </div>
            <div class="row mt-3" *ngIf="inspectorDetails?.commentsDetail?.internalComments">
                <h5>Internal Comments</h5>
                <p class="text-break description">{{ inspectorDetails?.commentsDetail?.internalComments }}</p>
            </div>

            <div class="row mt-3" *ngIf="data?.jobStatus != 'Canceled'">
                <div class="col-6">
                    <h5 *ngIf="data.status == 'NCNS'">NCNS at</h5>
                    <h5 *ngIf="data.status == 'Released'">Released at</h5>
                    <h5 *ngIf="data.status == 'Unschedule'">Unscheduled at</h5>
                    <p>
                        {{ inspectorDetails?.commentsDetail?.verifyDate | localDateTime }} -
                        {{ inspectorDetails?.commentsDetail?.verifyDate | localDateTime : 'shortTime' }}
                    </p>
                </div>
                <div class="col-4">
                    <h5 *ngIf="data.status == 'NCNS'">NCNS by</h5>
                    <h5 *ngIf="data.status == 'Released'">Released by</h5>
                    <h5 *ngIf="data.status == 'Unschedule'">Unscheduled by</h5>
                    <p>{{ inspectorDetails?.commentsDetail?.verifyBy }}</p>
                </div>
            </div>
        </div>
        <!-- NCNC Status -->

        <!--Overtime scheduled-->

        <!-- Shift History -->
        <div
            class="row mt-3"
            *ngIf="inspectorDetails.status == 'Overtime, Scheduled' || inspectorDetails.status == 'Invite Sent'"
        >
            <div class="col-md-12">
                <div class="mb-3 status-title">Overtime Information</div>
                <table class="table table-bordered shift-history-table">
                    <tbody class="table-content">
                        <tr
                            *ngFor="let shiftHistory of inspectorDetails?.overTimeWarning?.overTimeWarningDetail"
                            class="text-secondary"
                        >
                            <td class="p-2">{{ shiftHistory?.title }}</td>
                            <td class="p-2">
                                {{ shiftHistory?.count }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div
            *ngIf="
                (inspectorDetails.status == 'Overtime, Scheduled' || inspectorDetails.status == 'Invite Sent') &&
                inspectorDetails?.overTimeWarning.overTimeWarning
            "
            class="mt-3"
        >
            <span class="warning"
                ><b>Warning:</b> This shift is overtimed. This means that if the nurse remains scheduled and works the
                shift, the hourly rate will be multiplied by {{ inspectorDetails?.overTimeWarning.multiplier }}
            </span>
        </div>
        <div
            *ngIf="inspectorDetails.status == 'Invite Sent' && !inspectorDetails?.overTimeWarning.overTimeWarning"
            class="mt-3"
        >
            <span class="text-success"><b>This shift is not overtimed.</b> </span>
        </div>

        <!-- Shift Clinician History -->

        <div class="row mt-3" *ngIf="inspectorDetails?.shiftClinicianHistories?.length > 0">
            <div class="col-md-12">
                <div class="mb-3 status-title">Shift Audit Information</div>
                <table class="table table-bordered shift-history-table">
                    <tbody class="table-content">
                        <tr
                            *ngFor="let shiftClinicianHistory of inspectorDetails?.shiftClinicianHistories"
                            class="text-secondary"
                        >
                            <td class="p-2" style="width: 20%">
                                {{ shiftClinicianHistory?.actionOn | localDateTime }}
                                {{ shiftClinicianHistory?.actionOn | localDateTime : 'shortTime' }}
                            </td>
                            <td class="p-2" style="width: 20%">{{ shiftClinicianHistory?.actionBy }}</td>
                            <td class="p-2 text-break comment" style="width: 60%">
                                {{ shiftClinicianHistory?.comment }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Shift Clinician History -->
    </div>
    <div class="modal-footer">
        <div class="d-flex">
            <button
                class="btn btn-primary fw-semibold px-2 px-md-3 me-2"
                type="button"
                *ngIf="inspectorDetails.status == 'Missing Clock-out'"
                (click)="onClockInAndOutManually('Out')"
            >
                Clock-out manually
            </button>
            <button
                class="btn btn-outline-primary fw-semibold px-2 px-md-3 me-2"
                type="button"
                (click)="dismissModel()"
            >
                Close
            </button>
            <div class="d-flex" *ngIf="inspectorDetails.status == 'Missing Clock-in'">
                <button
                    class="btn btn-primary fw-semibold px-4 px-md-3"
                    type="button"
                    (click)="onClockInAndOutManually('In')"
                >
                    Clock-in manually
                </button>
                <button
                    class="btn btn-primary fw-semibold px-2 px-md-3 ms-2"
                    type="button"
                    (click)="updateStatus('NcncAndReschedule')"
                >
                    NCNS and Reschedule
                </button>
                <button
                    class="btn btn-primary fw-semibold px-2 px-md-3 ms-2"
                    type="button"
                    (click)="updateStatus('NcnsOnly')"
                >
                    NCNS Only
                </button>
            </div>

            <!-- In Progress -->

            <div class="d-flex" *ngIf="inspectorDetails.status == 'In Progress'">
                <button
                    class="btn btn-primary fw-semibold px-2 px-md-3 ms-2"
                    type="button"
                    (click)="updateStatus('ReleaseCancel')"
                >
                    Release and Cancel
                </button>
                <!-- <button
                    class="btn btn-primary fw-semibold px-2 px-md-3 ms-2 me-2"
                    type="button"
                    (click)="updateStatus('ReleaseReschedule')"
                >
                    Release and Reschedule
                </button> -->
            </div>

            <div
                class="d-flex"
                *ngIf="inspectorDetails.status == 'Scheduled' || inspectorDetails.status == 'Overtime, Scheduled'"
            >
                <button
                    class="btn btn-primary fw-semibold px-2 px-md-3 ms-2"
                    type="button"
                    (click)="updateStatus('Scheduled')"
                >
                    Unschedule this nurse
                </button>
            </div>
            <button
                class="btn btn-primary fw-semibold px-2 px-md-3"
                type="button"
                *ngIf="
                    inspectorDetails.status == 'Open Shift' ||
                    inspectorDetails.status == 'Open Shift, Late Call' ||
                    inspectorDetails.status == 'Open Shift, Late Call, Called Out' ||
                    inspectorDetails.status == 'Open Shift, Called Out' ||
                    inspectorDetails.status == 'Open Shift, Late Call, NCNS' ||
                    inspectorDetails.status == 'Open Shift, NCNS'
                "
                (click)="confirm()"
                [disabled]="shiftDetailsForm.invalid || shiftTypePosition == null || (!hasFormChanged() && shiftTypePosition != 1)"
            >
                Confirm
            </button>
            <button
                class="btn btn-primary fw-semibold px-2 px-md-5"
                type="button"
                [disabled]="shiftDetailsForm.invalid"
                *ngIf="inspectorDetails.status == 'Called Out' && !inspectorDetails?.callOutInfo?.isShowExcusedInfo"
                (click)="onExcuse()"
            >
                Submit
            </button>
            <button
                class="btn btn-primary fw-semibold px-2 px-md-5"
                type="button"
                *ngIf="inspectorDetails.status == 'Invite Sent'"
                (click)="onWithdrawInvite()"
            >
                Withdraw Invite
            </button>
        </div>
    </div>
</div>
