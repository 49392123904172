<div class="modal-content overflow-hidden">
    <div class="modal-header">
        <div class="modal-title h4">
            <ng-container> {{ title }} </ng-container>
        </div>
    </div>
    <form class="form form-label-right overflow-auto">
        <div class="modal-body">
            <div>
                <h6 class="mb-4">Select the sections to be added to PDF file:</h6>
                <div class="row align-items-center mt-2 mb-3" *ngFor="let item of selectedSection; let i = index">
                    <div class="col-4 d-flex align-items-center">
                        <input
                            type="checkbox"
                            [id]="'checkbox-' + i"
                            class="form-check-input mt-0"
                            (change)="onCheckboxChange($event, i)"
                        />
                        <label
                            [for]="'checkbox-' + i"
                            class="cursor-pointer form-check-label ms-2 text-secondary fw-normal"
                        >
                            {{ item.name }}
                        </label>
                    </div>

                    <div class="d-flex form-switch col-4 align-items-center ps-0" *ngIf="item.expiredfile">
                        <label class="text-primary">{{ item.expiredfile }}</label>

                        <input
                            type="checkbox"
                            class="form-check-input ms-2"
                            name="default"
                            id="formSwitch2"
                            (change)="onExpiredFileChanges($event, i)"
                            [(ngModel)]="item.isExpiredFile"
                            [disabled]="!item.isActive"
                            [ngModelOptions]="{ standalone: true }"
                        />
                    </div>
                </div>

                <h6 class="mt-4 mb-3">Select the additional documentation to be added to PDF file:</h6>

                <div class="row">
                    <div class="col">
                        <div class="row pt-3">
                            <label class="form-label">Available Documentation</label>
                        </div>
                        <ul class="document-list card-scroll ps-2">
                            <ng-container *ngIf="availableDocumentation.length > 0; else noDataPlaceholder">
                                <ng-container *ngFor="let document of availableDocumentation">
                                    <li
                                        class="document-item"
                                        (click)="onToggleSelection(document, 'available')"
                                        [ngClass]="{ selected: isSelected(document) }"
                                    >
                                        <span class="cursor"> {{ document.name }}</span>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <ng-template #noDataPlaceholder>
                                <div class="validation-message">No data available</div>
                            </ng-template>
                        </ul>
                    </div>

                    <div class="col col-1 d-flex flex-column justify-content-center">
                        <button
                            title="Add Credential Set"
                            class="btn btn-primary btn-move h-25"
                            type="button"
                            (click)="onAddDocumentSet()"
                        >
                            <em class="feather icon-chevron-right" aria-hidden="true"></em>
                        </button>
                        <button
                            title="Remove Credential Set"
                            class="btn btn-primary btn-move h-25 mt-2"
                            type="button"
                            (click)="onRemoveDocumentSet()"
                        >
                            <em class="feather icon-chevron-left" aria-hidden="true"></em>
                        </button>
                    </div>

                    <div class="col">
                        <div class="row pt-3">
                            <label class="form-label required">Documentation to be added</label>
                        </div>
                        <ul class="document-list card-scroll ps-2">
                            <ng-container *ngIf="addedDocumentation.length > 0; else noDataPlaceholder">
                                <ng-container *ngFor="let document of addedDocumentation">
                                    <li
                                        class="document-item"
                                        (click)="onToggleSelection(document, 'added')"
                                        [ngClass]="{ selected: isSelected(document) }"
                                    >
                                        <span class="cursor">{{ document.name }}</span>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <ng-template #noDataPlaceholder>
                                <div class="validation-message">No data available</div>
                            </ng-template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-outline-primary fw-semibold px-3 px-md-3" type="button" (click)="dismissModel()">
                Close
            </button>

            <ng-container>
                <button
                    type="submit"
                    class="btn btn-orange"
                    [disabled]="addedDocumentation.length === 0"
                    (click)="downloadProfile()"
                >
                    Download
                </button>
            </ng-container>
        </div>
    </form>
</div>
