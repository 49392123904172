import { Component, OnInit, Pipe } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TreeRefreshService } from 'src/app/service/treeRefresh.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    currentUrl: any;
    addEditViewUrl: any;
    type: any;
    type1: any;
    id: any;
    isBreadCrumb: boolean = false;
    iscurrentUrl: boolean = false;
    ishospitalprofile: boolean = false;
    isCompanyProfile: boolean = false;
    isThrive: boolean = false;
    isThriveEditProfile: boolean = false;
    isState: boolean = false;
    isBulkUploadHospital: boolean = false;
    enumType: any;
    jobOpeningId: any;
    applicantId: any;
    data: any;
    server: any;
    jobId: any;
    constructor(private route: ActivatedRoute, private treeRefreshService: TreeRefreshService) {}

    ngOnInit(): void {
        this.server = window.location.href.replace(environment.WEB, '');
        let currentUrl = this.server.split('/')[0];
        if (currentUrl != undefined) {
            this.currentUrl = currentUrl.split('?')[0];
        }
        let addEditUrl = this.server.split('/')[1];

        if (addEditUrl != undefined) {
            this.addEditViewUrl = addEditUrl.split('?')[0];
        }
        this.route.queryParams.subscribe((params: any) => {
            if (params.type) {
                this.type1 = params.type;
                this.type = atob(params.type);
                this.enumType = params.enumType;
            }
            this.id = params['id'];
            if (params['facilityId']) {
                this.id = params['facilityId'];
            }
            this.data = params['data'];
            this.applicantId = params['applicantId'];
            this.jobOpeningId = params['jobOpeningId'];
            this.jobId = params['jobId'];
        });
        this.conditionShow();
    }

    transform(value: string): string {
        const words = value.split('-');
        return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    conditionShow() {
        if (
            (this.currentUrl == 'company' ||
                this.currentUrl == 'facility' ||
                this.currentUrl == 'unit' ||
                this.currentUrl == 'contact' ||
                this.currentUrl == 'change-request' ||
                this.currentUrl == 'notification' ||
                this.currentUrl == 'notice-board' ||
                this.currentUrl == 'credential-template' ||
                this.currentUrl == 'job-template' ||
                this.currentUrl != 'staff-grid' ||
                this.currentUrl != 'staff-list' ||
                this.currentUrl != 'job-opening' ||
                this.currentUrl == 'job-template' ||
                this.currentUrl == 'job-creation' ||
                this.currentUrl == 'job-list' ||
                this.currentUrl == 'rate-card' ||
                this.currentUrl == 'facility' ||
                this.currentUrl == 'recieve-notification' ||
                this.currentUrl != 'time-sheet-dispute-reason' ||
                this.currentUrl != 'time-sheet') &&
            this.type != 'profile'
        ) {
            this.iscurrentUrl = true;
            this.ishospitalprofile = false;
        } else if (
            this.currentUrl != 'company' &&
            this.currentUrl != 'facility' &&
            this.currentUrl != 'state' &&
            this.currentUrl != 'city' &&
            this.currentUrl != 'unit' &&
            this.currentUrl != 'contact' &&
            this.currentUrl != 'change-request' &&
            this.currentUrl != 'notification' &&
            this.currentUrl != 'notice-board' &&
            this.currentUrl != 'edit-profile' &&
            this.currentUrl != 'credential-template' &&
            this.currentUrl != 'staff-list' &&
            this.currentUrl != 'staff-grid' &&
            this.currentUrl != 'thrive-user-profile' &&
            this.currentUrl != 'job-template' &&
            this.currentUrl == 'job-creation' &&
            this.currentUrl == 'job-list' &&
            this.currentUrl != 'job-opening ' &&
            this.currentUrl != 'rate-card' &&
            this.currentUrl != 'facility' &&
            this.currentUrl != 'recieve-notification' &&
            this.currentUrl != 'time-sheet-dispute-reason' &&
            this.currentUrl != 'time-sheet' &&
            this.type != 'profile'
        ) {
            this.iscurrentUrl = false;
            this.ishospitalprofile = false;
        } else if (this.type == 'profile' && this.currentUrl == 'facility') {
            this.ishospitalprofile = true;
            this.iscurrentUrl = false;
            this.isCompanyProfile = false;
        } else if (this.type == 'profile' && this.currentUrl == 'company') {
            this.ishospitalprofile = false;
            this.isCompanyProfile = true;
            this.iscurrentUrl = false;
        } else if (this.currentUrl == 'thrive-user-profile') {
            this.ishospitalprofile = false;
            this.isCompanyProfile = false;
            this.iscurrentUrl = false;
            this.isThrive = true;
        } else if (this.type == 'profile' && this.currentUrl == 'edit-profile') {
            this.ishospitalprofile = false;
            this.isCompanyProfile = false;
            this.iscurrentUrl = false;
            this.isThrive = false;
            this.isThriveEditProfile = true;
        } else if (this.currentUrl == 'state' || this.currentUrl == 'city') {
            this.isState = true;
        } else if (this.currentUrl == 'company' && this.addEditViewUrl == 'bulk-upload') {
            this.isBulkUploadHospital = true;
        }
    }
    goBack() {
        sessionStorage.removeItem('companySize');
        sessionStorage.removeItem('isThriveHealth');

        this.treeRefreshService.resetSelectedCompany();
    }
}
