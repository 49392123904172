import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { DatatableComponent } from './components/datatable/datatable.component';
import { DataTablesModule } from 'angular-datatables';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputboxComponent } from './components/inputbox/inputbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectboxComponent } from './components/selectbox/selectbox.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { InputFilePickerComponent } from './components/input-file-picker/input-file-picker.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { QuillModule } from 'ngx-quill';
import { MapComponent } from './components/map/map.component';
import { MatSelectModule } from '@angular/material/select';
import { FilterStatusSelectbox } from './components/filter-status-selectbox/filter-status-selectbox.component';
import { DeleteModelComponent } from './components/delete-model/delete-model.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PhoneMaskDirective } from './directive/phone-mask.directive';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ConfirmationModelComponent } from './components/Confirmation-model/Confirmation-model.component';
import { LocalDateTimePipe } from '../pipes/local-date-time.pipe';
import { ZipcodeComponent } from './components/zipcode/zipcode.component';

@NgModule({
    declarations: [
        DatatableComponent,
        ImageCropperComponent,
        ImagePreviewComponent,
        InputboxComponent,
        SelectboxComponent,
        DatepickerComponent,
        InputFilePickerComponent,
        HtmlEditorComponent,
        MapComponent,
        FilterStatusSelectbox,
        DeleteModelComponent,
        InputPasswordComponent,
        SidebarComponent,
        PhoneMaskDirective,
        TextareaComponent,
        ConfirmationModelComponent,
        ZipcodeComponent,
    ],
    providers: [TitleCasePipe, UpperCasePipe, CurrencyPipe, LowerCasePipe, LocalDateTimePipe],
    imports: [
        CommonModule,
        DataTablesModule,
        AngularCropperjsModule,
        ImageCropperModule,
        MatFormFieldModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbDatepickerModule,
        QuillModule.forRoot(),
        GoogleMapsModule,
        MatSelectModule,
        MatSidenavModule,
        MatIconModule,
        RouterModule,
        NzTreeViewModule,
        NzTreeModule,
        NgScrollbarModule,
    ],
    exports: [
        CommonModule,
        DatatableComponent,
        ImageCropperComponent,
        ImagePreviewComponent,
        InputboxComponent,
        SelectboxComponent,
        DatepickerComponent,
        InputFilePickerComponent,
        HtmlEditorComponent,
        MapComponent,
        FilterStatusSelectbox,
        DeleteModelComponent,
        InputPasswordComponent,
        SidebarComponent,
        TextareaComponent,
        ConfirmationModelComponent,
        ZipcodeComponent,
    ],
})
export class SharedModule {}
