import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
export const CUSTOMINPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPasswordComponent),
    multi: true,
};
export const CUSTOMINPUT_VALUE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputPasswordComponent),
    multi: true,
};

@Component({
    selector: 'app-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['./input-password.component.scss'],
    providers: [CUSTOMINPUT_VALUE_ACCESSOR],
})
export class InputPasswordComponent implements OnInit {
    @Input() type: string;
    @Input() parentForm: FormGroup;
    @Input() label: string;
    @Input() fieldName: string;
    @Input() isWhiteSpace: boolean = true;
    @Input() isRequired: boolean = false;
    @Output() toggleVisibility: EventEmitter<string> = new EventEmitter<string>();
    hideField: boolean = true;
    onTouched: any = () => {};
    public changed: (value: any) => void;
    value: any;

    constructor() {}
    ngOnInit(): void {}

    get formField(): FormControl {
        return this.parentForm?.get(this.fieldName) as FormControl;
    }
    writeValue(value: string): void {
        this.value = value;
    }
    public onChange(event: Event) {
        const value: string = (<HTMLInputElement>event?.target).value;
        this.changed(value);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    toggleFieldVisibility(): void {
        this.hideField = !this.hideField;
        this.type = this.hideField ? 'password' : 'text';
        this.toggleVisibility.emit(this.fieldName);
    }
}
