import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-download-profile',
    templateUrl: './download-profile.component.html',
    styleUrls: ['./download-profile.component.scss'],
})
export class DownloadProfileComponent {
    @Input() model: any;
    title = 'Download Nurse Profile';
    availableDocumentation: any[] = [];
    addedDocumentation: any[] = [];

    selectedDocuments: Set<number> = new Set();

    selectedSection = [
        {
            name: 'License',
            expiredfile: 'Include expired',
            isActive: false,
            isExpiredFile: false,
        },
        {
            name: 'Credentials',
            expiredfile: 'Include expired',
            isActive: false,
            isExpiredFile: false,
        },
        {
            name: 'Background Checks',
            isActive: false,
        },
        {
            name: 'Education',
            isActive: false,
        },
        {
            name: 'W4-I9',
            isActive: false,
        },
    ];

    constructor(public activeModal: NgbActiveModal) {}

    onToggleSelection(document: any, list: 'available' | 'added') {
        const key = document.id;
        if (this.selectedDocuments.has(key)) {
            this.selectedDocuments.delete(key);
        } else {
            this.selectedDocuments.add(key);
        }
    }

    onAddDocumentSet() {
        const selectedAvailable = this.availableDocumentation.filter((c) => this.selectedDocuments.has(c.id));
        this.addedDocumentation.push(...selectedAvailable);
        this.availableDocumentation = this.availableDocumentation.filter((c) => !this.selectedDocuments.has(c.id));
        this.selectedDocuments.clear();
    }

    onRemoveDocumentSet() {
        const selectedAdded = this.addedDocumentation.filter((c) => this.selectedDocuments.has(c.id));
        this.availableDocumentation.push(...selectedAdded);
        this.addedDocumentation = this.addedDocumentation.filter((c) => !this.selectedDocuments.has(c.id));
        this.selectedDocuments.clear();
    }

    isSelected(document: any): boolean {
        return this.selectedDocuments.has(document.id);
    }

    onCheckboxChange(event: any, index: number): void {
        const isChecked = event.target.checked;
        this.selectedSection[index].isActive = isChecked;

        if (!isChecked) {
            this.selectedSection[index].isExpiredFile = false;
            this.removeItemsBySection(this.selectedSection[index].name);
        } else {
            this.selectedSection[index].isExpiredFile = this.selectedSection[index].isExpiredFile; // Preserve the checkbox status
            this.updateDocumentations(this.selectedSection[index].name, this.selectedSection[index].isExpiredFile);
        }
    }

    updateDocumentations(sectionName: string, includeExpired: any) {
        switch (sectionName) {
            case 'License':
                this.updateAvailableDocumentationsWithLicenses(includeExpired);
                break;
            case 'Credentials':
                this.updateAvailableDocumentationWithCredentials(includeExpired);
                break;
            case 'Background Checks':
                this.updateAvailableDocumentationWithBackgroundChecks();
                break;
            case 'Education':
                this.updateAvailableDocumentationWithEducation();
                break;
            case 'W4-I9':
                this.updateAvailableDocumentationWithW4I9();
                break;
        }
    }

    removeItemsBySection(sectionName: string) {
        switch (sectionName) {
            case 'License':
                this.availableDocumentation = this.availableDocumentation.filter(
                    (doc) => !this.model.licenses.some((license: any) => license.id === doc.id)
                );
                this.addedDocumentation = this.addedDocumentation.filter(
                    (doc) => !this.model.licenses.some((license: any) => license.id === doc.id)
                );
                break;
            case 'Credentials':
                this.availableDocumentation = this.availableDocumentation.filter(
                    (doc) =>
                        !this.model.credentials
                            .flatMap((cat: any) => (cat.credentials ? cat.credentials.map((cred: any) => cred.id) : []))
                            .includes(doc.id)
                );
                this.addedDocumentation = this.addedDocumentation.filter(
                    (doc) =>
                        !this.model.credentials
                            .flatMap((cat: any) => (cat.credentials ? cat.credentials.map((cred: any) => cred.id) : []))
                            .includes(doc.id)
                );
                break;
            case 'Background Checks':
                this.availableDocumentation = this.availableDocumentation.filter(
                    (doc) => !this.model.backgroundChecks.some((check: any) => check.id === doc.id)
                );
                this.addedDocumentation = this.addedDocumentation.filter(
                    (doc) => !this.model.backgroundChecks.some((check: any) => check.id === doc.id)
                );
                break;
            case 'Education':
                this.availableDocumentation = this.availableDocumentation.filter(
                    (doc) => !this.model.educations.some((edu: any) => edu.id === doc.id)
                );
                this.addedDocumentation = this.addedDocumentation.filter(
                    (doc) => !this.model.educations.some((edu: any) => edu.id === doc.id)
                );
                break;
            case 'W4-I9':
                this.availableDocumentation = this.availableDocumentation.filter(
                    (doc) => !this.model.clinicianEmploymentForms.some((emp: any) => emp.id === doc.id)
                );
                this.addedDocumentation = this.addedDocumentation.filter(
                    (doc) => !this.model.clinicianEmploymentForms.some((emp: any) => emp.id === doc.id)
                );
                break;
        }
    }

    onExpiredFileChanges(event: any, index: number): void {
        const isChecked = event.target.checked;
        const sectionName = this.selectedSection[index].name;

        this.selectedSection[index].isExpiredFile = isChecked;

        // Update available documentation based on the checkbox status
        if (sectionName === 'License') {
            this.updateAvailableDocumentationsWithLicenses(isChecked);
        } else if (sectionName === 'Credentials') {
            this.updateAvailableDocumentationWithCredentials(isChecked);
        }

        // Define a filtering function based on section name
        const filterDocumentation = (doc: any) => {
            if (sectionName === 'License') {
                return !this.model.licenses.some((license: any) => license.id === doc.id);
            }
            if (sectionName === 'Credentials') {
                return !this.model.credentials
                    .flatMap((cat: any) => (cat.credentials ? cat.credentials.map((cred: any) => cred.id) : []))
                    .includes(doc.id);
            }
            return true; // Default return if sectionName is neither License nor Credentials
        };

        // Filter documentation based on checkbox status
        this.addedDocumentation = this.addedDocumentation.filter((doc) => filterDocumentation(doc));
    }

    updateAvailableDocumentationsWithLicenses(includeExpired: boolean): void {
        if (this.model && this.model.licenses) {
            const currentDate = new Date();

            // Filter licenses based on expiry date and checkbox status
            const filteredLicenses = this.model.licenses
                .filter((license: any) => {
                    const expiryDate = new Date(license.expiryDate);
                    const isExpired = expiryDate < currentDate;
                    return (includeExpired || !isExpired) && license.statusName === 'Approved';
                })
                .map((license: any) => {
                    const expiryDate = new Date(license.expiryDate);
                    const isExpired = expiryDate < currentDate;
                    const displayName = isExpired
                        ? `${license.name} - ${
                              license.stateAcronym == null ? 'Compact' : license.stateAcronym
                          } (Expired)`
                        : `${license.name} - ${license.stateAcronym == null ? 'Compact' : license.stateAcronym}`;

                    return {
                        id: license.id,
                        type: 'License',
                        name: displayName,
                        expiryDate: license.expiryDate,
                        pdfName: license.fileName,
                        fileUrl: license.fileUrl,
                    };
                });

            // Ensure existing documentation is preserved and update with new licenses
            const updatedDocumentation = this.availableDocumentation
                .filter((doc: any) => doc.type !== 'License') // Keep non-License documents
                .concat(filteredLicenses); // Add updated licenses

            // Remove duplicates based on the document name
            this.availableDocumentation = updatedDocumentation;
        }
    }

    updateAvailableDocumentationWithCredentials(includeExpired: boolean): void {
        if (this.model && this.model.credentials) {
            const currentDate = new Date();

            // Filter credentials based on expiry date and checkbox status
            const filteredCredentials = this.model.credentials.flatMap((category: any) =>
                category.credentials
                    ? category.credentials
                          .filter((cred: any) => {
                              const expiryDate = new Date(cred.expiryDate);
                              const isExpired = expiryDate < currentDate;
                              return (includeExpired || !isExpired) && cred.statusName === 'Approved';
                          })
                          .map((cred: any) => {
                              const expiryDate = new Date(cred.expiryDate);
                              const isExpired = expiryDate < currentDate;
                              const displayName = isExpired
                                  ? `${cred.credentialTypeName} - ${cred.stateName} (Expired)`
                                  : `${cred.credentialTypeName} - ${cred.stateName}`;

                              return {
                                  id: cred.id,
                                  type: 'Credentials',
                                  name: displayName,
                                  expiryDate: cred.expiryDate,
                                  pdfName: cred.fileName,
                                  fileUrl: cred.fileUrl,
                              };
                          })
                    : []
            );

            // Ensure existing documentation is preserved and update with new credentials
            const updatedDocumentation = this.availableDocumentation
                .filter((doc: any) => doc.type !== 'Credentials') // Keep non-Credentials documents
                .concat(filteredCredentials); // Add updated credentials

            // Remove duplicates based on the document name
            this.availableDocumentation = updatedDocumentation;
        }
    }

    updateAvailableDocumentationWithBackgroundChecks() {
        if (this.model && this.model.backgroundChecks) {
            const backgroundChecks = this.model.backgroundChecks
                .filter((check: any) => check.statusName === 'Approved')
                .map((check: any) => ({
                    id: check.id,
                    type: 'BackgroundChecks',
                    pdfName: check.fileName,
                    name: check.name + '- ' + check.companyName,
                    fileUrl: check.fileUrl,
                }));
            const combined = [...this.availableDocumentation, ...backgroundChecks];
            this.availableDocumentation = combined;
        }
    }

    updateAvailableDocumentationWithEducation() {
        if (this.model && this.model.educations) {
            const educations = this.model.educations
                .filter((edu: any) => edu.statusName === 'Approved')
                .map((edu: any) => ({
                    id: edu.id,
                    type: 'Educations',
                    name: edu.courseName,
                    pdfName: edu.fileName,
                    fileUrl: edu.fileUrl,
                }));
            const combined = [...this.availableDocumentation, ...educations];
            this.availableDocumentation = combined;
        }
    }

    updateAvailableDocumentationWithW4I9() {
        if (this.model && this.model.clinicianEmploymentForms) {
            const clinicianForm = this.model.clinicianEmploymentForms
                .filter((emp: any) => emp.statusName === 'Approved')
                .map((emp: any) => ({
                    id: emp.id,
                    type: 'W4-I9',
                    pdfName: emp.fileName,
                    name: emp.formTypeName + '- ' + emp.companyName,
                    fileUrl: emp.fileUrl,
                }));
            const combined = [...this.availableDocumentation, ...clinicianForm];
            this.availableDocumentation = combined;
        }
    }

    async downloadProfile(): Promise<void> {
        if (this.addedDocumentation.length !== 0) {
            const zip = new JSZip();
            const folderPromises = new Map<string, Promise<void>[]>();

            this.addedDocumentation.forEach((doc: any, index: number) => {
                // Ensure the folder for the file type exists
                const fileTypeFolder = zip.folder(doc.type) || zip.folder(doc.type);

                // Initialize the array for the folder type if not already done
                if (!folderPromises.has(doc.type)) {
                    folderPromises.set(doc.type, []);
                }

                const addFileToZip = (url: string, pdfName: string) => {
                    const filePromise = fetch(url)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error(`Network response was not ok for ${pdfName}`);
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const extension = url.split('.').pop();
                            const uniqueName = `${pdfName}_${index}.${extension}`;
                            fileTypeFolder?.file(uniqueName, blob);
                        })
                        .catch((error) => {
                            console.error(`Failed to fetch file for ${pdfName}:`, error);
                        });

                    folderPromises.get(doc.type)!.push(filePromise);
                };

                // Check and add main file URL
                if (doc.fileUrl) {
                    addFileToZip(doc.fileUrl, doc.pdfName);
                } else {
                    console.warn(`File URL is undefined for ${doc.pdfName}`);
                }
            });

            // Wait for all files to be added
            await Promise.all(Array.from(folderPromises.values()).flat())
                .then(() => {
                    return zip.generateAsync({ type: 'blob' });
                })
                .then((content: any) => {
                    saveAs(content, `${this.model.firstName + ' ' + this.model.lastName}.zip`);
                    this.activeModal.close();
                });
        }
    }

    dismissModel() {
        this.activeModal.close();
    }
}
