import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TimeSheetModel } from 'src/app/model/timesheet.model';
import { AdminDecision, TimeSheetDisputeInformation } from 'src/app/model/shiftTime.model';
import { LocalDateTimePipe } from 'src/app/pipes/local-date-time.pipe';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { TimeSheetService } from 'src/app/service/timesheet.service';
import { noWhitespaceValidator, removeSpaces } from 'src/app/utill/validator';
import moment from 'moment';
import { NurseRatingComponent } from '../nurse-rating/nurse-rating.component';
import { ConfirmationModelComponent } from '../../shift/confirmation-model/confirmation-model.component';
import { BaseMasterFormComponent } from 'src/app/shared/components/base-master-form.component';

@Component({
    selector: 'app-timesheet-update-status',
    templateUrl: './timesheet-update-status.component.html',
    styleUrls: ['./timesheet-update-status.component.scss'],
    providers: [LocalDateTimePipe],
})
export class TimesheetUpdateStatusComponent extends BaseMasterFormComponent implements OnInit {
    @Input() model: any;
    timeSheetDetails: any = [];
    timeSheetForm: FormGroup;
    disputeReasonList: any[] = [];
    timeSheetHistory: any[] = [];
    startTime?: any = null;
    endTime?: any = null;
    isDisableApply = true;
    isShowResetBtn = false;
    selectedJobSpeciality: any = null;
    selectedStatus: any = null;
    selectedDispute: number | null;
    disputeInfo: any;
    adminDecisionList: any;
    adminDecision: any;
    selectedDisputeReason: any;
    timeSheetModel: any = new TimeSheetModel();
    breakIn?: any = null;
    breakOut?: any = null;
    clockIn?: any = null;
    clockOut?: any = null;
    isExtraTimePaid: boolean = false;
    hour: any;
    hours: any;

    constructor(
        public modal: NgbActiveModal,
        public timeSheetService: TimeSheetService,
        private modalService: NgbModal,
        private fb: FormBuilder,
        private localDateTimePipe: LocalDateTimePipe,
        public toastrService: ToastrService,
        private ngxSpinnerService: NgxSpinnerService,
        public dateUtilService: DateUtilService,
        confirmationModalService: NgbModal
    ) {
        super(confirmationModalService);
    }

    ngOnInit(): void {
        this.initForm();
        this.getTimeSheetDetails();
        // this.getDisputeTimesheetReason();
        this.disputeInfo = this.convertObjectToKeyValuePairs(this.enumToObj(TimeSheetDisputeInformation));
        this.adminDecisionList = this.convertObjectToKeyValuePairs(this.enumToObj(AdminDecision));        
    }

    convertObjectToKeyValuePairs(obj: any): { name: string; id: any }[] {
        return Object.entries(obj).map(([name, id]) => ({ name: this.splitCamelCase(name), id }));
    }

    splitCamelCase(str: string): string {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    enumToObj(enumType: any): { [key: string]: number } {
        const enumObject: { [key: string]: number } = {};
        for (const key in enumType) {
            if (isNaN(Number(key))) {
                enumObject[key] = enumType[key];
            }
        }
        return enumObject;
    }

    getTimeSheetDetails() {
        let id = this.model.shiftTimesheetId != null ? this.model.shiftTimesheetId : this.model.id;
        this.timeSheetService.getTimeSheetDetailsById(id).subscribe((res) => {
            this.timeSheetDetails = res.result;
            this.timeSheetHistory = res.result.timeSheetHistory;

            this.timeSheetForm.controls.breakIn.setValue(this.timeSheetDetails.breakIn);
            this.timeSheetForm.controls.clockIn.setValue(this.timeSheetDetails.clockIn);
            this.timeSheetForm.controls.breakOut.setValue(this.timeSheetDetails.breakOut);
            this.timeSheetForm.controls.clockOut.setValue(this.timeSheetDetails.clockOut);

            if (this.timeSheetDetails.breakIn) {
                let breakIn = this.localDateTimePipe.transform(this.timeSheetDetails.breakIn, 'shortTime');
                this.breakIn = breakIn == null ? null : this.dateUtilService.convertTo24HourFormat(breakIn);

                let breakOut = this.localDateTimePipe.transform(this.timeSheetDetails.breakOut, 'shortTime');

                this.breakOut = breakOut == null ? null : this.dateUtilService.convertTo24HourFormat(breakOut);
            }

            let clockIn = this.localDateTimePipe.transform(this.timeSheetDetails.clockIn, 'shortTime');

            this.clockIn = this.dateUtilService.convertTo24HourFormat(clockIn);

            let clockOut = this.localDateTimePipe.transform(this.timeSheetDetails.clockOut, 'shortTime');
            this.clockOut = this.dateUtilService.convertTo24HourFormat(clockOut);
            this.timeSheetForm.get('isExtraTimePaid')?.setValue(this.timeSheetDetails.isExtraTimePaid);
            this.isExtraTimePaid = this.timeSheetDetails.isExtraTimePaid;
            if (this.model.statusName == 'In Review') {
                this.timeSheetForm.controls.description.setValidators(
                    Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                );
                this.timeSheetForm.controls.disputeReason.setValidators(Validators.compose([Validators.required]));
                this.timeSheetForm.controls.requestType.setValidators(Validators.compose([Validators.required]));
                this.timeSheetForm.controls.disputeReason.updateValueAndValidity();
                this.timeSheetForm.controls.description.updateValueAndValidity();
                this.timeSheetForm.controls.requestType.updateValueAndValidity();
            }

            this.timeSheetForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
            this.storeInitialFormState();
        });
    }

    getDisputeTimesheetReason(type: any) {
        let obj = {
            isClickOnSentBack: type,
        };
        this.timeSheetService.getDisputeTimesheetReason(obj).subscribe((response: any) => {
            this.disputeReasonList = response.result;
        });
    }

    initForm() {
        this.timeSheetForm = this.fb.group({
            id: [this.timeSheetModel.id],
            disputeReason: ['', Validators.compose([])],
            description: ['', Validators.compose([])],
            requestType: [''],
            isExtraTimePaid: [false],
            canAskToReUpload: [false],
            verifiedById: [this.timeSheetModel.verifiedById],
            verifiedOn: [this.timeSheetModel.verifiedOn],
            clockIn: [this.timeSheetModel?.clockIn],
            clockOut: [this.timeSheetModel?.clockOut],
            breakOut: [this.timeSheetModel?.breakOut],
            breakIn: [this.timeSheetModel?.breakIn],
            adminDecision: [this.timeSheetModel?.adminDecision],
        });
    }

    handleInput(callingFrom: any) {
        if (callingFrom == 'breakIn') {
            if (this.breakIn != null) {
                let breakInDate = this.timeSheetDetails.breakIn.split('T')[0];
                let breakInTime = this.breakIn.toTimeString().split(' ')[0];
                this.breakIn = new Date(breakInDate + 'T' + breakInTime);
                this.timeSheetForm.controls.breakIn.setValue(this.breakIn);
            } else {
                this.timeSheetForm.controls.breakIn.setValue(this.breakIn);
            }
        } else if (callingFrom == 'breakOut') {
            if (this.breakOut != null) {
                let breakOutDate = this.timeSheetDetails.breakOut.split('T')[0];
                let breakOutTime = this.breakOut.toTimeString().split(' ')[0];
                this.breakOut = new Date(breakOutDate + 'T' + breakOutTime);
                this.timeSheetForm.controls.breakOut.setValue(this.breakOut);
                this.timeSheetForm.controls.breakOut.setValue(this.breakOut);
            } else {
                this.timeSheetForm.controls.breakIn.setValue(this.breakOut);
            }
        } else if (callingFrom == 'clockOut') {
            if (this.clockOut != null) {
                let clockOutDate = this.timeSheetDetails.clockOut.split('T')[0];
                let clockOutTime = this.clockOut.toTimeString().split(' ')[0];
                this.clockOut = new Date(clockOutDate + 'T' + clockOutTime);
                this.timeSheetForm.controls.clockOut.setValue(this.clockOut);
            } else {
                this.timeSheetForm.controls.breakIn.setValue(this.clockOut);
            }
        } else {
            if (this.clockIn != null) {
                let clockInDate = this.timeSheetDetails.clockIn.split('T')[0];
                let clockInTime = this.clockIn.toTimeString().split(' ')[0];
                this.clockIn = new Date(clockInDate + 'T' + clockInTime);
                this.timeSheetForm.controls.clockIn.setValue(this.clockIn);
            } else {
                this.timeSheetForm.controls.breakIn.setValue(this.clockIn);
            }
        }
    }

    onCheckboxChange(selectedId: number) {
        this.disputeReasonList = [];
        this.selectedDisputeReason = null;
        this.selectedDispute = selectedId;
        this.timeSheetForm.controls.requestType.setValue(selectedId);
        if (this.selectedDispute === 2) {
            this.timeSheetForm.controls.disputeReason.clearValidators();
            this.timeSheetForm.controls.disputeReason.updateValueAndValidity();
            this.timeSheetForm.controls.clockIn.setValidators(Validators.compose([Validators.required]));
            this.timeSheetForm.controls.clockOut.setValidators(Validators.compose([Validators.required]));
            this.timeSheetForm.controls.clockIn.updateValueAndValidity();
            this.timeSheetForm.controls.clockOut.updateValueAndValidity();
            if (this.timeSheetDetails.breakIn) {
                this.timeSheetForm.controls.breakIn.setValidators(Validators.compose([Validators.required]));
                this.timeSheetForm.controls.breakOut.setValidators(Validators.compose([Validators.required]));
                this.timeSheetForm.controls.breakIn.updateValueAndValidity();
                this.timeSheetForm.controls.breakOut.updateValueAndValidity();
            } else {
                // this.timeSheetForm.reset();
            }
        } else {
            this.timeSheetForm.controls.disputeReason.setValidators(Validators.compose([Validators.required]));
            this.timeSheetForm.controls.disputeReason.updateValueAndValidity();
        }
        if (selectedId == 1 || selectedId == 3) {
            this.getDisputeTimesheetReason(selectedId == 1 ? true : false);
        }
    }

    onChangeofAdminDecision(value: number) {
        this.adminDecision = value;
        this.timeSheetForm.controls.adminDecision.setValue(value);
    }
    onCheckboxExtraTimePaidChanges(event: any) {
        this.timeSheetForm.get('isExtraTimePaid')?.setValue(event);
    }

    onCheckboxReuploadChange(event: any): void {
        this.timeSheetForm.get('canAskToReUpload')?.setValue(event.target.checked);
    }

    onOpenFile(fileUrl: any) {
        window.open(fileUrl);
    }

    onTimechange(callingFrom: any) {
        if (callingFrom === 'start') {
            this.startTime = this.startTime ? this.startTime : '';
        } else {
            this.endTime = this.endTime ? this.endTime : '';
        }

        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (this.startTime == null && this.endTime == null) {
            this.startTime = null;
            this.endTime = null;
            if ((this.selectedJobSpeciality || this.selectedStatus) == null) {
                this.isDisableApply = true;
                this.isShowResetBtn = true;
            }
        }
    }

    onDisputeReasonChange(event: any) {
        if (event.length != 0) {
            this.selectedDisputeReason = event;
            let selectedDisputeReasonNames = this.disputeReasonList
                .filter((item) => event.includes(item.id))
                .map((item) => item.name);

            let selectedDisputeReason = selectedDisputeReasonNames.join(', ');
            this.timeSheetForm.controls.disputeReason.setValue(selectedDisputeReason);
        } else {
            this.selectedDisputeReason = [];
            this.timeSheetForm.controls.disputeReason.setValue(null);
        }
    }

    viewNurseRating(model: any) {
        const modalRef = this.modalService.open(NurseRatingComponent, {
            size: 'xl',
            backdrop: false,
            centered: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.model = model;
    }

    submit() {
        if (this.timeSheetForm.invalid) {
            return;
        }

        let timeSheetForm = this.timeSheetForm.value as TimeSheetModel;

        timeSheetForm.verifiedById = Number(sessionStorage.getItem('contactId'));
        timeSheetForm.verifiedOn = new Date().toISOString();
        timeSheetForm.id = this.model.id;
        timeSheetForm.isExtraTimePaid = this.isExtraTimePaid;
        timeSheetForm.canAskToReUpload = !!timeSheetForm.canAskToReUpload;

        if (this.selectedDispute == 2) {
            let clockInTime, clockOutTime, breakInTime, breakOutTime;
            let fromTimeParts, toTimeParts, breakInTimeParts, breakOutTimeParts;
            let clockIn: any = timeSheetForm.clockIn;
            if (!this.isString(timeSheetForm.clockIn)) {
                clockInTime = this.dateUtilService.convertTo12HourFormat(
                    this.dateUtilService.mergeDateAndTime2(this.timeSheetDetails.shiftFromTime, timeSheetForm.clockIn)
                );

                // Convert UTC time to local time
                clockIn = timeSheetForm.clockIn;
                timeSheetForm.clockIn = this.dateUtilService.convertToUtc(
                    this.dateUtilService.mergeDateAndTime2(
                        this.dateUtilService.convertUtcToLocalDate(this.timeSheetDetails.shiftFromTime),
                        timeSheetForm.clockIn
                    )
                );
            } else {
                timeSheetForm.clockIn = this.timeSheetDetails.clockIn;
                clockInTime = this.localDateTimePipe.transform(this.timeSheetDetails.clockIn, 'shortTime');
            }

            fromTimeParts = this.safeSplit(clockInTime, '-', 0, ' ');
            if (!fromTimeParts) return;

            if (!this.isString(timeSheetForm.clockOut)) {
                let clockOut = timeSheetForm.clockOut;
                clockOutTime = this.dateUtilService.convertTo12HourFormat(clockOut);
                toTimeParts = this.safeSplit(clockOutTime, '-', 0, ' ');
                if (!toTimeParts) return;

                let clockInDateTime = this.dateUtilService.mergeDateAndTime2(
                    this.dateUtilService.convertUtcToLocalDate(this.timeSheetDetails.shiftFromTime),
                    clockIn
                );

                timeSheetForm.clockOut = this.getTime(
                    timeSheetForm.clockIn,
                    fromTimeParts,
                    toTimeParts,
                    clockInDateTime,
                    false
                );
            } else {
                timeSheetForm.clockOut = this.timeSheetDetails.clockOut;
            }

            if (this.timeSheetDetails.breakIn) {
                if (!this.isString(timeSheetForm.breakIn)) {
                    let breakIn = timeSheetForm.breakIn;
                    breakInTime = this.dateUtilService.convertTo12HourFormat(breakIn);
                    breakInTimeParts = this.safeSplit(breakInTime, '-', 0, ' ');
                    if (!breakInTimeParts) return;

                    timeSheetForm.breakIn = this.dateUtilService.convertToUtc(
                        this.dateUtilService.mergeDateAndTime2(
                            this.dateUtilService.convertUtcToLocalDate(this.timeSheetDetails.shiftFromTime),
                            timeSheetForm.breakIn
                        )
                    );
                } else {
                    timeSheetForm.breakIn = this.timeSheetDetails.breakIn;
                    breakInTime = this.localDateTimePipe.transform(this.timeSheetDetails.breakIn, 'shortTime');
                    breakInTimeParts = this.safeSplit(breakInTime, '-', 0, ' ');
                    if (!breakInTimeParts) return;
                }

                if (!this.isString(timeSheetForm.breakOut)) {
                    let breakOut = timeSheetForm.breakOut;
                    breakOutTime = this.dateUtilService.convertTo12HourFormat(breakOut);
                    breakOutTimeParts = this.safeSplit(breakOutTime, '-', 0, ' ');
                    if (!breakOutTimeParts) return;

                    timeSheetForm.breakOut = this.dateUtilService.convertToUtc(
                        this.dateUtilService.mergeDateAndTime2(
                            this.dateUtilService.convertUtcToLocalDate(this.timeSheetDetails.shiftFromTime),
                            timeSheetForm.breakOut
                        )
                    );
                } else {
                    timeSheetForm.breakOut = this.timeSheetDetails.breakOut;
                    breakOutTime = this.localDateTimePipe.transform(this.timeSheetDetails.breakIn, 'shortTime');
                    breakOutTimeParts = this.safeSplit(breakOutTime, '-', 0, ' ');
                    if (!breakOutTimeParts) return;
                }
            }

            this.timeSheetService.shiftAttendanceTimesheet(this.model?.id, timeSheetForm).subscribe(
                (response: any) => {
                    if (response.result) {
                        this.toastrService.success('Timesheet updated successfully!');
                        this.modal.close();
                    } else {
                        this.toastrService.error(response.message);
                    }
                    this.ngxSpinnerService.hide();
                },
                (err) => {
                    this.ngxSpinnerService.hide();
                },
                () => console.log('complete')
            );
        } else if (this.selectedDispute == 1) {
            this.timeSheetService.updateTimeSheetStatus(this.model?.id, timeSheetForm).subscribe(
                (response: any) => {
                    if (response) {
                        this.toastrService.success('Timesheet updated successfully!');
                        this.modal.close();
                    } else {
                        this.toastrService.error(response.message);
                    }
                    this.ngxSpinnerService.hide();
                },
                (err) => {
                    this.ngxSpinnerService.hide();
                },
                () => console.log('complete')
            );
        } else {
            timeSheetForm.adminDecision = this.adminDecision;

            this.timeSheetService.shiftCancelTimesheet(this.model?.id, timeSheetForm).subscribe(
                (response: any) => {
                    if (response) {
                        this.toastrService.success('Timesheet updated successfully!');
                        this.modal.close();
                    } else {
                        this.toastrService.error(response.message);
                    }
                    this.ngxSpinnerService.hide();
                },
                (err) => {
                    this.ngxSpinnerService.hide();
                    this.toastrService.error(err.error.message);
                }
            );
        }
    }

    isString(value: any) {
        return typeof value === 'string';
    }

    // Utility function to safely split a string and access a part
    safeSplit(value: string, splitBy: string, partIndex: number, subSplitBy?: string) {
        if (!value) {
            console.error('Invalid value for splitting:', value);
            return null;
        }

        const parts = value.split(splitBy);
        if (parts.length <= partIndex) {
            console.error('Insufficient parts after splitting:', value);
            return null;
        }

        if (subSplitBy) {
            return parts[partIndex].trim().split(subSplitBy);
        }

        return parts[partIndex];
    }

    getTime(shiftDate: any, fromTimeParts: any, toTimeParts: any, clockIn: any, isCallingFromBreakIn: boolean): any {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let toTime, toTimeUtc;

        const date = shiftDate.split('T')[0];
        if (toTimeParts[1] === 'AM' && fromTimeParts[1] === 'PM') {
            // toTime = this.dateUtilService.convertTo24HourFormat(toTimeParts[0] + ' ' + toTimeParts[1]) + ':00';
            // const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
            // toTimeUtc = this.dateUtilService.convertToUtcPipe(nextDay, toTime, timeZone);

            let date = !isCallingFromBreakIn
                ? this.timeSheetForm.controls.clockOut.value
                : this.timeSheetForm.controls.breakOut.value;

            return this.dateUtilService.onTimeChangeGetLocalToUtcTime(clockIn, date).toTime;
        } else if (toTimeParts[1] === 'AM' && fromTimeParts[1] === 'AM') {
            let date = !isCallingFromBreakIn
                ? this.timeSheetForm.controls.clockOut.value
                : this.timeSheetForm.controls.breakOut.value;

            return this.dateUtilService.onTimeChangeGetLocalToUtcTime(clockIn, date).toTime;
        } else {
            toTime = this.dateUtilService.convertTo24HourFormat(toTimeParts[0] + ' ' + toTimeParts[1]) + ':00';
            toTimeUtc = this.dateUtilService.convertToUtcPipe(date, toTime, timeZone);
            const utcTime = toTimeUtc.toTimeString().split(' ')[0];
            const toDateString = toTimeUtc.toDateString();
            return moment(toDateString).format('YYYY-MM-DD') + 'T' + utcTime;
        }
    }

    // getTime(shiftDate: any, fromTimeParts: any, toTimeParts: any): any {
    //     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     let toTime, toTimeUtc;

    //     const date = shiftDate.split('T')[0];
    //     if (toTimeParts[1] === 'AM' && fromTimeParts[1] === 'PM') {
    //         debugger;
    //         toTime = this.dateUtilService.convertTo24HourFormat(toTimeParts[0] + ' ' + toTimeParts[1]) + ':00';
    //         const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
    //         toTimeUtc = this.dateUtilService.convertToUtcPipe(nextDay, toTime, timeZone);
    //     } else {
    //         toTime = this.dateUtilService.convertTo24HourFormat(toTimeParts[0] + ' ' + toTimeParts[1]) + ':00';
    //         toTimeUtc = this.dateUtilService.convertToUtcPipe(date, toTime, timeZone);
    //     }

    //     const utcTime = toTimeUtc.toTimeString().split(' ')[0];
    //     const toDateString = toTimeUtc.toDateString();
    //     return moment(toDateString).format('YYYY-MM-DD') + 'T' + utcTime;
    // }

    calculateTotalTime(): void {
        const startTimeValue = this.timeSheetForm.get('clockIn')?.value;
        const endTimeValue = this.timeSheetForm.get('clockOut')?.value;
        const isoStartTime = moment(startTimeValue, 'hh:mm:00 A')
            .set({ year: 2024, month: 0, date: 1, second: 0 })
            .startOf('minute');

        const isoEndTime = moment(endTimeValue, 'hh:mm:00 A')
            .set({ year: 2024, month: 0, date: 1, second: 0 })
            .startOf('minute');

        // Adjust end time if it occurs on the next day
        if (isoEndTime.isBefore(isoStartTime)) {
            isoEndTime.add(1, 'day');
        }
        const differenceInMinutes = moment.duration(isoEndTime.diff(isoStartTime)).asMinutes();
        const hours = Math.floor(differenceInMinutes / 60);
        this.hours = hours;
        const minutes = Math.floor(differenceInMinutes % 60);
        const totalHours = `${hours}.${minutes}`;
        this.hour = `${hours ? hours : 0}h ${minutes ? minutes : 0}min`;
    }

    get form() {
        return { timeSheetForm :  this.timeSheetForm};
    }

    dismissModel() {
        this.dismissModal(this.modal); 
    }
}
