<div class="profile-modal-content modal-content fixwidth">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <h4>Nurse Profile</h4>
        <div *ngIf="displayFor == 'restricted'">
            <label class="badge fw-bolder text-white" style="background: #ff5045" *ngIf="model?.status == 'DNR'">{{
                model?.status
            }}</label>
            <label
                class="badge fw-bolder text-white"
                style="background: #7291ff"
                *ngIf="model?.status == 'Suspended'"
                >{{ model?.status }}</label
            >
        </div>
    </div>
    <div class="modal-body p-3 pt-0">
        <div [ngClass]="displayFor == 'restricted' ? 'profile-model-box mb-3' : 'profile-model-box'">
            <div class="profile-model-img">
                <figure>
                    <img
                        *ngIf="clinicianDetails?.clinicianProfileUrl != null"
                        src="{{ clinicianDetails.clinicianProfileUrl }}"
                        alt=""
                    />
                    <img *ngIf="clinicianDetails?.clinicianProfileUrl == null" src="./assets/images/others/blank.png" />
                </figure>
            </div>
            <div class="profile-model-info">
                <h4>
                    {{
                        ((clinicianDetails?.firstName | titlecase) ?? '') +
                            ' ' +
                            ((clinicianDetails?.lastName | titlecase) ?? '')
                    }}
                </h4>
                <p class="text-primary">
                    {{ (clinicianDetails?.cityName ?? '') + ', ' + (clinicianDetails?.stateAcronym ?? '') }}
                </p>
                <p class="text-primary">
                    <a
                        *ngIf="clinicianDetails?.email"
                        href="mailto:{{ clinicianDetails?.email }}"
                        class="nurse-email"
                        >{{ clinicianDetails?.email }}</a
                    >
                    {{ clinicianDetails?.email ? ' | ' : ' ' }}
                    {{ formatPhoneNumber(clinicianDetails?.phoneNumber ?? '') }}
                </p>
                <p class="text-primary" *ngIf="clinicianDetails?.ssn">SSN: {{ clinicianDetails?.ssn }}</p>
                <div class="label-list">
                    <label
                        class="badge fw-bolder"
                        [ngStyle]="{
                            'background-color': type?.chipBackgroundColor,
                            color: type?.chipFontColor
                        }"
                        *ngFor="let type of license"
                        >{{ type?.acronym ?? '' }}</label
                    >
                </div>
            </div>

            <div class="ms-auto mt-auto">
                <button class="w-100 btn btn-orange" (click)="downloadProfile()">Download Profile</button>
            </div>
        </div>
        <p *ngIf="displayFor == 'restricted'" class="text-secondary mb-3">
            {{ model?.status == 'DNR' ? 'DNR' : 'Suspended' }}
            {{ model?.verifiedOn ? 'on ' + (model?.verifiedOn | localDateTime) : '' }}
            {{ model?.verifyByName ? 'by ' + model?.verifyByName : '' }}
            {{
                model?.status == 'Suspended' && model?.suspendedTill
                    ? 'until ' + (model?.suspendedTill | localDateTime)
                    : ''
            }}
        </p>
        <div class="profile-model-nav">
            <ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs">
                <li [ngbNavItem]="5" class="" (click)="onNavChange(5)" *ngIf="displayFor == 'restricted'">
                    <a ngbNavLink class="">Restriction Details</a>
                    <ng-template ngbNavContent>
                        <div class="d-flex">
                            <div class="d-flex align-items-center justify-content-center">
                                <figure>
                                    <img
                                        *ngIf="model?.verifyByProfileUrl != null"
                                        src="{{ model.verifyByProfileUrl }}"
                                        alt=""
                                        height="52px"
                                        width="52px"
                                        class="rounded-circle"
                                    />
                                    <img
                                        height="52px"
                                        width="52px"
                                        class="rounded-circle"
                                        *ngIf="model?.verifyByProfileUrl == null"
                                        src="./assets/images/others/blank.png"
                                    />
                                </figure>
                            </div>
                            <div class="profile-model-info ms-2">
                                <p>{{ model?.status == 'DNR' ? 'DNR by' : 'Suspended by' }}</p>
                                <h4 class="text-secondary">
                                    {{ model?.verifyByName ?? '' }}
                                </h4>
                            </div>
                        </div>
                        <div class="text-secondary">
                            <div class="row mt-3">
                                <h5>Facility</h5>
                                <p class="text-break description mt-2">{{ model?.hospitalName }}</p>
                            </div>
                            <div class="row mt-3" *ngIf="model?.reasonName">
                                <h5>Reason</h5>
                                <p class="text-break description mt-2">{{ model?.reasonName }}</p>
                            </div>
                            <div class="row mt-3" *ngIf="model?.description">
                                <h5>Additional Information</h5>
                                <p class="text-break description mt-2">{{ model?.description }}</p>
                            </div>
                            <div class="row mt-3">
                                <div class="col-5" *ngIf="model?.suspendedFrom">
                                    <h5>Suspended From</h5>
                                    <p class="text-break description mt-2">
                                        {{ model?.suspendedFrom | localDateTime : 'ordinal' }}
                                    </p>
                                </div>

                                <div class="col-5" *ngIf="model?.suspendedTill">
                                    <h5>Suspended Until</h5>
                                    <p class="text-break description mt-2">
                                        {{ model?.suspendedTill | localDateTime : 'ordinal' }}
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="model?.status == 'Suspended'">
                                <div class="col-sm-12 mt-4">
                                    <div class="form-group toggle-margin">
                                        <app-inputbox
                                            id="formSwitch1"
                                            fieldName="isUpdateRestrictedNurse"
                                            label="Do you want to do modification in Suspend Period ?"
                                            type="checkbox"
                                            [isInputCheckbox]="true"
                                            [checkbBoxValue]="isUpdateRestrictedNurse"
                                            (onChangeSelect)="onCheckboxChange($event)"
                                        ></app-inputbox>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isUpdateRestrictedNurse">
                                <form [formGroup]="nurseRestrictionForm">
                                    <div class="row mb-2">
                                        <div class="col-md-6 mb-2">
                                            <div class="form-group date-picker-control">
                                                <app-datepicker
                                                    controlName="suspendedFrom"
                                                    fieldName="suspendedFrom"
                                                    fieldId="suspendedFrom"
                                                    [selectedFieldDate]="selectedSuspendedFromDate"
                                                    (onDateSelect)="onDateSelectChange($event, 'from')"
                                                    requiredLableName="Suspended from"
                                                    lableName="Suspended from"
                                                    [isRequired]="true"
                                                    [formName]="nurseRestrictionForm"
                                                >
                                                </app-datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <div class="form-group date-picker-control">
                                                <app-datepicker
                                                    controlName="suspendedTill"
                                                    fieldName="suspendedTill"
                                                    fieldId="suspendedTill"
                                                    [selectedFieldDate]="selectedSuspendedtillDate"
                                                    (onDateSelect)="onDateSelectChange($event, 'till')"
                                                    requiredLableName="Suspended until"
                                                    lableName="Suspended until"
                                                    [isRequired]="true"
                                                    [formName]="nurseRestrictionForm"
                                                >
                                                </app-datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <app-textarea
                                            label="Comments"
                                            [fieldName]="'Comments'"
                                            [placeholder]="'Please leave your comments here'"
                                            [isRequired]="false"
                                            [rows]="5"
                                            [cols]="250"
                                            [min]="1"
                                            [max]="1000"
                                            [isShowCharacterCount]="true"
                                            [parentForm]="nurseRestrictionForm"
                                            formControlName="description"
                                            (keyup)="onDescriptionChange($event)"
                                        >
                                        </app-textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="" (click)="onNavChange(2)">
                    <a ngbNavLink class="">{{ (clinicianDetails?.firstName | titlecase) ?? '' }}’s Profile</a>
                    <ng-template ngbNavContent>
                        <div class="profile-personal-information">
                            <h4 class="mb-2">Personal Information</h4>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <h6>
                                        {{
                                            ((clinicianDetails?.firstName | titlecase) ?? '') +
                                                ' ' +
                                                ((clinicianDetails?.lastName | titlecase) ?? '')
                                        }}
                                    </h6>
                                    <address>
                                        {{ clinicianDetails?.address }} <br />{{
                                            (clinicianDetails?.cityName ? clinicianDetails?.cityName + ', ' : '') +
                                                (clinicianDetails?.stateAcronym ?? '') +
                                                ' ' +
                                                (clinicianDetails?.zipCode ?? '')
                                        }}
                                        <br />

                                        <a
                                            *ngIf="clinicianDetails?.email"
                                            href="mailto:{{ clinicianDetails?.email }}"
                                            class="nurse-email text-dark"
                                            >{{ clinicianDetails?.email }}</a
                                        >
                                        {{ clinicianDetails?.email ? ' | ' : ' ' }}
                                        {{ formatPhoneNumber(clinicianDetails?.phoneNumber ?? '') }}
                                    </address>
                                </div>
                                <div class="col-md-6" *ngIf="clinicianDetails?.driverLicenseFileName">
                                    <div
                                        class="person-file-box cursor-pointer"
                                        (click)="onDocumentShow(clinicianDetails.driverLicenseFileUrl ?? '')"
                                    >
                                        <figure>
                                            <svg
                                                width="50"
                                                height="50"
                                                viewBox="0 0 50 50"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.83398 3.90734V44.4733L10.55 46.9863L39.341 46.8183L40.925 43.7363L41.363 14.0073L30.675 13.6323L29.925 2.31934H9.67398"
                                                    fill="#E4F6FF"
                                                />
                                                <rect
                                                    x="8.48584"
                                                    y="33.1865"
                                                    width="32.5"
                                                    height="13.193"
                                                    fill="#AFE5FF"
                                                />
                                                <path
                                                    d="M29.9248 3.25684L30.8608 13.2568H41.1108L29.9248 3.25684Z"
                                                    fill="#AFE5FF"
                                                />
                                                <rect
                                                    x="13.1499"
                                                    y="19.6865"
                                                    width="22.896"
                                                    height="2"
                                                    fill="#2D9CD3"
                                                />
                                                <rect
                                                    x="13.1499"
                                                    y="25.3115"
                                                    width="12.377"
                                                    height="2"
                                                    fill="#2D9CD3"
                                                />
                                                <rect
                                                    x="7.83398"
                                                    y="32.1865"
                                                    width="33.529"
                                                    height="2"
                                                    fill="#2D9CD3"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M41.024 11.315L40.529 10.825V10.845L32.616 3H32.636L31.74 2.112C30.828 1.194 29.68 1.024 29.111 1H11C8.794 1 7 2.794 7 5V44C7 46.206 8.794 48 11 48H38.529C40.735 48 42.529 46.206 42.529 44V14.354C42.427 12.998 41.514 11.837 41.024 11.315ZM31.067 4.984L38.596 12.449H31.067V4.984ZM38.529 46C39.632 46 40.529 45.104 40.529 44L40.527 14.653C40.5076 14.6542 40.4883 14.6566 40.4689 14.6589C40.4403 14.6625 40.4114 14.666 40.381 14.666H31.067C29.844 14.666 28.848 13.672 28.848 12.449V3.233C28.848 3.17676 28.8549 3.12249 28.8618 3.06847C28.8647 3.04564 28.8676 3.02287 28.87 3H11C9.897 3 9 3.897 9 5V44C9 45.104 9.897 46 11 46H38.529Z"
                                                    fill="#2D9CD3"
                                                />
                                                <path
                                                    opacity="0.02"
                                                    d="M40.3408 14.0491C40.3408 14.0491 38.0088 27.9921 27.6748 32.1881C17.3408 36.3841 9.48584 46.3821 9.48584 46.3821L14.6108 46.9861L38.3608 46.9021L41.1738 46.2141L42.7988 43.0751L42.1108 13.9961L39.922 38.4511"
                                                    fill="black"
                                                />
                                                <path
                                                    d="M14.3021 43.5247C14.1261 43.3257 14.0381 43.0867 14.0381 42.8097V37.6777C14.0381 37.3587 14.1441 37.1187 14.3581 36.9597C14.5721 36.8007 14.8441 36.7207 15.1761 36.7207H18.2131C18.3891 36.7207 18.5381 36.7847 18.6601 36.9127C18.7821 37.0417 18.8431 37.1987 18.8431 37.3847C18.8431 37.5707 18.7821 37.7257 18.6601 37.8507C18.5381 37.9767 18.3891 38.0387 18.2131 38.0387H15.8451V39.5377H18.0621C18.2381 39.5377 18.3871 39.6007 18.5091 39.7277C18.6311 39.8547 18.6921 40.0127 18.6921 40.2017C18.6921 40.3877 18.6311 40.5427 18.5091 40.6687C18.3871 40.7937 18.2381 40.8567 18.0621 40.8567H15.8451L15.8401 42.8097C15.8401 43.0857 15.7521 43.3257 15.5761 43.5247C15.4001 43.7257 15.1871 43.8257 14.9361 43.8257C14.6901 43.8257 14.4781 43.7267 14.3021 43.5247Z"
                                                    fill="#2787B8"
                                                />
                                                <path
                                                    d="M20.6321 43.5278C20.4581 43.3298 20.3711 43.0898 20.3711 42.8098V37.7368C20.3711 37.4568 20.4581 37.2158 20.6321 37.0168C20.8061 36.8158 21.0191 36.7158 21.2691 36.7158C21.5171 36.7158 21.7281 36.8158 21.9041 37.0168C22.0801 37.2158 22.1681 37.4558 22.1681 37.7368V42.8098C22.1681 43.0898 22.0801 43.3298 21.9041 43.5278C21.7281 43.7258 21.5151 43.8258 21.2641 43.8258C21.0171 43.8258 20.8061 43.7268 20.6321 43.5278Z"
                                                    fill="#2787B8"
                                                />
                                                <path
                                                    d="M24.633 43.6018C24.493 43.4528 24.398 43.2728 24.347 43.0648C24.296 42.8568 24.271 42.6208 24.271 42.3568V37.7328C24.271 37.4528 24.359 37.2128 24.535 37.0148C24.711 36.8168 24.922 36.7168 25.17 36.7168C25.42 36.7168 25.633 36.8168 25.809 37.0148C25.985 37.2128 26.073 37.4528 26.073 37.7328L26.079 42.4738H28.524C28.704 42.4738 28.856 42.5388 28.981 42.6688C29.106 42.7988 29.169 42.9588 29.169 43.1478C29.169 43.3368 29.106 43.4968 28.981 43.6288C28.856 43.7598 28.704 43.8258 28.524 43.8258H25.175C24.954 43.8258 24.773 43.7508 24.633 43.6018Z"
                                                    fill="#2787B8"
                                                />
                                                <path
                                                    d="M31.132 43.7167C31.015 43.6427 30.923 43.5437 30.859 43.4177C30.794 43.2927 30.746 43.1597 30.714 43.0207C30.682 42.8817 30.667 42.7327 30.667 42.5767V38.1427C30.667 37.9567 30.674 37.8007 30.687 37.6737C30.701 37.5467 30.73 37.4187 30.775 37.2907C30.821 37.1617 30.884 37.0587 30.963 36.9797C31.042 36.9007 31.151 36.8387 31.29 36.7917C31.429 36.7437 31.595 36.7207 31.79 36.7207H34.935C35.124 36.7207 35.285 36.7847 35.416 36.9127C35.548 37.0417 35.614 37.1987 35.614 37.3847C35.614 37.5707 35.548 37.7257 35.416 37.8507C35.284 37.9767 35.124 38.0387 34.935 38.0387H32.415V39.5577H34.716C34.904 39.5577 35.065 39.6217 35.196 39.7507C35.328 39.8787 35.393 40.0357 35.393 40.2217C35.393 40.4077 35.328 40.5627 35.196 40.6887C35.064 40.8137 34.904 40.8767 34.716 40.8767H32.415V42.5127H35.058C35.234 42.5127 35.382 42.5757 35.504 42.7027C35.626 42.8297 35.687 42.9857 35.687 43.1717C35.687 43.3577 35.626 43.5127 35.504 43.6387C35.382 43.7637 35.233 43.8267 35.058 43.8267H31.542C31.386 43.8257 31.249 43.7887 31.132 43.7167Z"
                                                    fill="#2787B8"
                                                />
                                            </svg>
                                        </figure>
                                        <div class="person-file-info" *ngIf="clinicianDetails?.driverLicenseFileName">
                                            <h6>Driver’s License File</h6>
                                            <p>
                                                {{ clinicianDetails?.driverLicenseFileName ?? '' | ellipsis : 22 }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 class="fw-normal text-primary mb-2" *ngIf="clinicianDetails?.professionalSummary">
                                Professional summary
                            </h4>
                            <p>
                                {{ clinicianDetails?.professionalSummary ?? '' }}
                            </p>
                            <hr *ngIf="educations.length > 0" />
                            <h4 *ngIf="educations.length > 0">Education</h4>
                            <ng-container *ngFor="let education of educations">
                                <h4
                                    class="fw-normal text-primary mt-1 mb-2 cursor-pointer"
                                    (click)="
                                        viewProfileDetails(
                                            education.id,
                                            'education',
                                            education,
                                            'Nurse Profile - Educational Information',
                                            apiUrl.UPDATE_CLINICIAN_EDUCATION_STATUS
                                        )
                                    "
                                >
                                    {{ education?.university ?? '' }}
                                </h4>
                                <p>
                                    {{ education?.courseName ?? '' }} <br />
                                    {{
                                        (education?.startDate ?? '' | localDateTime : 'MMM y') +
                                            (education?.endDate
                                                ? ' - ' + (education?.endDate | localDateTime : 'MMM y')
                                                : '')
                                    }}
                                </p>
                            </ng-container>
                            <hr *ngIf="licenses.length > 0" />
                            <h4 class="mb-2" *ngIf="licenses.length > 0">Active Licenses</h4>
                            <ng-container *ngFor="let license of licenses">
                                <h5
                                    class="fw-normal text-primary mt-3 mb-1 cursor-pointer"
                                    (click)="
                                        viewProfileDetails(
                                            license.id,
                                            'license',
                                            license,
                                            'License Verification',
                                            apiUrl.UPDATE_CLINICIAN_LICENSE_STATUS
                                        )
                                    "
                                >
                                    {{
                                        (license?.acronym ?? '') +
                                            (license?.isItCompatStateLicense
                                                ? ' - Compact'
                                                : ' - ' + license?.stateAcronym) +
                                            ' | ' +
                                            (license?.name ?? '')
                                    }}
                                </h5>
                                <p class="d-flex">
                                    <span
                                        [ngClass]="
                                            license?.statusName == 'Approved' && checkIsExpired(license?.expiryDate)
                                                ? 'verified-round danger'
                                                : license?.statusName == 'Approved'
                                                ? 'verified-round'
                                                : license?.statusName == 'In Progress'
                                                ? 'verified-round inprogress'
                                                : license?.statusName == 'Unverified'
                                                ? 'verified-round unverify'
                                                : 'verified-round danger'
                                        "
                                    ></span
                                    >{{
                                        license?.statusName == 'Approved' && checkIsExpired(license?.expiryDate)
                                            ? 'Expired' + ' | Expired on ' + (license?.expiryDate ?? '' | localDateTime)
                                            : (license?.statusName ?? '') +
                                              ' | Expires on ' +
                                              (license?.expiryDate ?? '' | localDateTime)
                                    }}
                                </p>
                            </ng-container>
                            <hr *ngIf="credentials.length > 0" />
                            <h4 class="mb-2" *ngIf="credentials.length > 0">Credentials</h4>
                            <ng-container *ngFor="let credential of credentials">
                                <h5 class="fw-normal mt-3 mb-1">
                                    {{ credential?.credentialCategoryName ?? '' }}
                                </h5>
                                <p class="text-primary">
                                    <ng-container *ngFor="let type of credential.credentials; let last = last">
                                        <span
                                            class="cursor-pointer"
                                            (click)="
                                                viewProfileDetails(
                                                    type.id,
                                                    'credential',
                                                    type,
                                                    'Nurse Profile - Credential',
                                                    apiUrl.UPDATE_CLINICIAN_CREDENTIAL_STATUS
                                                )
                                            "
                                        >
                                            {{ type?.credentialTypeName ?? '' }}{{ !last ? ', ' : '' }}
                                        </span>
                                    </ng-container>
                                </p>
                            </ng-container>
                            <hr *ngIf="resumes.length > 0 || experiences.length > 0" />
                            <h4 class="mb-2" *ngIf="resumes.length > 0 || experiences.length > 0">
                                Professional Experience
                            </h4>
                            <h5 class="fw-normal text-primary mb-1" *ngIf="resumes.length > 0">Resume</h5>
                            <div class="person-resume-box mb-3" *ngFor="let resume of resumes">
                                <div
                                    class="person-file-box cursor-pointer"
                                    (click)="onDocumentShow(clinicianDetails?.driverLicenseFileUrl)"
                                >
                                    <figure>
                                        <svg
                                            width="50"
                                            height="50"
                                            viewBox="0 0 50 50"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.83398 3.90734V44.4733L10.55 46.9863L39.341 46.8183L40.925 43.7363L41.363 14.0073L30.675 13.6323L29.925 2.31934H9.67398"
                                                fill="#E4F6FF"
                                            />
                                            <rect x="8.48584" y="33.1865" width="32.5" height="13.193" fill="#AFE5FF" />
                                            <path
                                                d="M29.9248 3.25684L30.8608 13.2568H41.1108L29.9248 3.25684Z"
                                                fill="#AFE5FF"
                                            />
                                            <rect x="13.1499" y="19.6865" width="22.896" height="2" fill="#2D9CD3" />
                                            <rect x="13.1499" y="25.3115" width="12.377" height="2" fill="#2D9CD3" />
                                            <rect x="7.83398" y="32.1865" width="33.529" height="2" fill="#2D9CD3" />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M41.024 11.315L40.529 10.825V10.845L32.616 3H32.636L31.74 2.112C30.828 1.194 29.68 1.024 29.111 1H11C8.794 1 7 2.794 7 5V44C7 46.206 8.794 48 11 48H38.529C40.735 48 42.529 46.206 42.529 44V14.354C42.427 12.998 41.514 11.837 41.024 11.315ZM31.067 4.984L38.596 12.449H31.067V4.984ZM38.529 46C39.632 46 40.529 45.104 40.529 44L40.527 14.653C40.5076 14.6542 40.4883 14.6566 40.4689 14.6589C40.4403 14.6625 40.4114 14.666 40.381 14.666H31.067C29.844 14.666 28.848 13.672 28.848 12.449V3.233C28.848 3.17676 28.8549 3.12249 28.8618 3.06847C28.8647 3.04564 28.8676 3.02287 28.87 3H11C9.897 3 9 3.897 9 5V44C9 45.104 9.897 46 11 46H38.529Z"
                                                fill="#2D9CD3"
                                            />
                                            <path
                                                opacity="0.02"
                                                d="M40.3408 14.0491C40.3408 14.0491 38.0088 27.9921 27.6748 32.1881C17.3408 36.3841 9.48584 46.3821 9.48584 46.3821L14.6108 46.9861L38.3608 46.9021L41.1738 46.2141L42.7988 43.0751L42.1108 13.9961L39.922 38.4511"
                                                fill="black"
                                            />
                                            <path
                                                d="M14.3021 43.5247C14.1261 43.3257 14.0381 43.0867 14.0381 42.8097V37.6777C14.0381 37.3587 14.1441 37.1187 14.3581 36.9597C14.5721 36.8007 14.8441 36.7207 15.1761 36.7207H18.2131C18.3891 36.7207 18.5381 36.7847 18.6601 36.9127C18.7821 37.0417 18.8431 37.1987 18.8431 37.3847C18.8431 37.5707 18.7821 37.7257 18.6601 37.8507C18.5381 37.9767 18.3891 38.0387 18.2131 38.0387H15.8451V39.5377H18.0621C18.2381 39.5377 18.3871 39.6007 18.5091 39.7277C18.6311 39.8547 18.6921 40.0127 18.6921 40.2017C18.6921 40.3877 18.6311 40.5427 18.5091 40.6687C18.3871 40.7937 18.2381 40.8567 18.0621 40.8567H15.8451L15.8401 42.8097C15.8401 43.0857 15.7521 43.3257 15.5761 43.5247C15.4001 43.7257 15.1871 43.8257 14.9361 43.8257C14.6901 43.8257 14.4781 43.7267 14.3021 43.5247Z"
                                                fill="#2787B8"
                                            />
                                            <path
                                                d="M20.6321 43.5278C20.4581 43.3298 20.3711 43.0898 20.3711 42.8098V37.7368C20.3711 37.4568 20.4581 37.2158 20.6321 37.0168C20.8061 36.8158 21.0191 36.7158 21.2691 36.7158C21.5171 36.7158 21.7281 36.8158 21.9041 37.0168C22.0801 37.2158 22.1681 37.4558 22.1681 37.7368V42.8098C22.1681 43.0898 22.0801 43.3298 21.9041 43.5278C21.7281 43.7258 21.5151 43.8258 21.2641 43.8258C21.0171 43.8258 20.8061 43.7268 20.6321 43.5278Z"
                                                fill="#2787B8"
                                            />
                                            <path
                                                d="M24.633 43.6018C24.493 43.4528 24.398 43.2728 24.347 43.0648C24.296 42.8568 24.271 42.6208 24.271 42.3568V37.7328C24.271 37.4528 24.359 37.2128 24.535 37.0148C24.711 36.8168 24.922 36.7168 25.17 36.7168C25.42 36.7168 25.633 36.8168 25.809 37.0148C25.985 37.2128 26.073 37.4528 26.073 37.7328L26.079 42.4738H28.524C28.704 42.4738 28.856 42.5388 28.981 42.6688C29.106 42.7988 29.169 42.9588 29.169 43.1478C29.169 43.3368 29.106 43.4968 28.981 43.6288C28.856 43.7598 28.704 43.8258 28.524 43.8258H25.175C24.954 43.8258 24.773 43.7508 24.633 43.6018Z"
                                                fill="#2787B8"
                                            />
                                            <path
                                                d="M31.132 43.7167C31.015 43.6427 30.923 43.5437 30.859 43.4177C30.794 43.2927 30.746 43.1597 30.714 43.0207C30.682 42.8817 30.667 42.7327 30.667 42.5767V38.1427C30.667 37.9567 30.674 37.8007 30.687 37.6737C30.701 37.5467 30.73 37.4187 30.775 37.2907C30.821 37.1617 30.884 37.0587 30.963 36.9797C31.042 36.9007 31.151 36.8387 31.29 36.7917C31.429 36.7437 31.595 36.7207 31.79 36.7207H34.935C35.124 36.7207 35.285 36.7847 35.416 36.9127C35.548 37.0417 35.614 37.1987 35.614 37.3847C35.614 37.5707 35.548 37.7257 35.416 37.8507C35.284 37.9767 35.124 38.0387 34.935 38.0387H32.415V39.5577H34.716C34.904 39.5577 35.065 39.6217 35.196 39.7507C35.328 39.8787 35.393 40.0357 35.393 40.2217C35.393 40.4077 35.328 40.5627 35.196 40.6887C35.064 40.8137 34.904 40.8767 34.716 40.8767H32.415V42.5127H35.058C35.234 42.5127 35.382 42.5757 35.504 42.7027C35.626 42.8297 35.687 42.9857 35.687 43.1717C35.687 43.3577 35.626 43.5127 35.504 43.6387C35.382 43.7637 35.233 43.8267 35.058 43.8267H31.542C31.386 43.8257 31.249 43.7887 31.132 43.7167Z"
                                                fill="#2787B8"
                                            />
                                        </svg>
                                    </figure>
                                    <div class="person-file-info">
                                        <h6>{{ resume?.title ?? '' | ellipsis : 22 }}</h6>
                                        <p>{{ resume?.fileName ?? '' | ellipsis : 22 }}</p>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngFor="let experience of experiences">
                                <h5
                                    class="fw-normal text-primary mb-1 cursor-pointer"
                                    (click)="
                                        viewProfileDetails(
                                            experience.id,
                                            'experience',
                                            experience,
                                            'Nurse Profile - Experience',
                                            apiUrl.UPDATE_CLINICIAN_EXPERIENCE_STATUS
                                        )
                                    "
                                >
                                    {{ experience?.companyName ?? '' }}
                                </h5>
                                <div
                                    class="d-flex"
                                    *ngIf="
                                        experience?.reference1FirstName ||
                                        experience?.reference1LastName ||
                                        experience?.reference1PhoneNumber ||
                                        experience?.reference1ContactBestTime
                                    "
                                >
                                    <p>
                                        Reference1 - {{ experience?.reference1FirstName | titlecase }}
                                        {{
                                            (experience?.reference1LastName | titlecase) +
                                                ' | ' +
                                                formatPhoneNumber(experience?.reference1PhoneNumber ?? ' ')
                                        }}
                                    </p>
                                    <p class="ms-1" *ngIf="experience?.reference1ContactBestTime">
                                        ({{ experience?.reference1ContactBestTime }})
                                    </p>
                                </div>
                                <div
                                    class="d-flex"
                                    *ngIf="
                                        experience?.reference2FirstName ||
                                        experience?.reference2LastName ||
                                        experience?.reference2PhoneNumber ||
                                        experience?.reference2ContactBestTime
                                    "
                                >
                                    <p>
                                        Reference2 - {{ experience?.reference2FirstName | titlecase }}
                                        {{
                                            (experience?.reference2LastName | titlecase) +
                                                ' | ' +
                                                formatPhoneNumber(experience?.reference2PhoneNumber ?? ' ')
                                        }}
                                    </p>

                                    <p class="ms-1" *ngIf="experience?.reference2ContactBestTime">
                                        ({{ experience?.reference2ContactBestTime }})
                                    </p>
                                </div>
                                <p class="mb-3">
                                    Skills:
                                    {{
                                        experience?.skills.split(',').length > 4
                                            ? experience?.skills.split(',').slice(0, 4).join(', ') +
                                              ' + ' +
                                              (experience?.skills.split(',').length - 4)
                                            : experience?.skills.split(',').join(', ') ?? ''
                                    }}
                                </p>
                            </ng-container>
                            <hr *ngIf="backgrounds.length > 0" />
                            <h4 class="mb-2" *ngIf="backgrounds.length > 0">Background Checks</h4>
                            <ng-container *ngFor="let background of backgrounds">
                                <h5
                                    class="fw-normal text-primary mb-1 cursor-pointer"
                                    (click)="
                                        viewProfileDetails(
                                            background.id,
                                            'backgroundCheck',
                                            background,
                                            'Nurse Profile - Background Check',
                                            apiUrl.UPDATE_CLINICIAN_BACKGROUND_CHECK_STATUS
                                        )
                                    "
                                >
                                    {{ background?.name }} - {{ background?.companyName }}
                                </h5>
                                <p
                                    class="d-flex mb-3"
                                    *ngIf="
                                        background?.statusName == 'Unverified' ||
                                            background?.statusName == 'In Progress';
                                        else verify
                                    "
                                >
                                    <span
                                        [ngClass]="
                                            background?.statusName == 'Unverified'
                                                ? 'verified-round unverify'
                                                : 'verified-round inprogress'
                                        "
                                    ></span>
                                    {{ background?.statusName ?? '' }}
                                </p>
                                <ng-template #verify>
                                    <p class="d-flex mb-3">
                                        <span
                                            [ngClass]="
                                                background?.statusName == 'Declined'
                                                    ? 'verified-round danger'
                                                    : 'verified-round'
                                            "
                                        ></span>
                                        {{
                                            background?.statusName == 'Declined'
                                                ? 'Declined on ' + (background?.updatedOn | localDateTime)
                                                : 'Verified on ' + (background?.updatedOn | localDateTime)
                                        }}
                                        {{
                                            background?.isExpire
                                                ? ' | Expired on ' + (background?.expiredOn | localDateTime)
                                                : ''
                                        }}
                                    </p>
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-template>
                </li>

                <li [ngbNavItem]="12" (click)="onNavChange(12)">
                    <a ngbNavLink class="">Documents</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="row g-3 mb-3 align-items-end">
                                <div class="col-xl-4 col-lg-4 col-4">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="documentStatus"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Status"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedDocumentStatus"
                                                (onChangeSelect)="onDocumentStatusChange($event)"
                                                requiredLableName="Author"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="true"
                                                lableName="Status"
                                                [closeOnSelect]="false"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-3">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="documentTypeList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Document Type"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedDocumentType"
                                                (onChangeSelect)="onDocumentTypeChange($event)"
                                                requiredLableName="Document Type"
                                                [isRequired]="false"
                                                controlName="type"
                                                [isMultiple]="false"
                                                lableName="Document Type"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-1 col-lg-2 col-md-2 col-4">
                                    <button
                                        class="w-100 btn btn-orange apply-btn"
                                        *ngIf="!isShowResetBtn"
                                        (click)="filterDocument()"
                                        [disabled]="isDisableApply"
                                    >
                                        Apply
                                    </button>
                                    <button
                                        class="w-100 btn btn-orange"
                                        *ngIf="isShowResetBtn"
                                        (click)="resetDocument()"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <div class="position-responsive" *ngIf="documentDataTableModel?.displayBlock">
                                <app-datatable
                                    [configDataTable]="documentDataTableModel"
                                    [isLoadData]="documentDataTableModel.isLoadData"
                                    (rowClickHandler)="documentRowCallback($event)"
                                    [isActive]="documentDataTableModel.isActive"
                                    [requestObject]="documentDataTableModel.displayBlock.requestObject"
                                    [requestType]="documentDataTableModel.displayBlock.requestType"
                                    [searchText]="documentDataTableModel.displayBlock.searchText"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li [ngbNavItem]="1" class="" (click)="onNavChange(1)" *ngIf="isShowAllTabs">
                    <a ngbNavLink class="">{{ clinicianDetails?.firstName | titlecase }}’s Summary</a>
                    <ng-template ngbNavContent>
                        <div class="col-md-12">
                            <h4 class="mb-3">Shift History (last {{ shiftHistoryDetail?.historyMonths }} months)</h4>
                            <table class="table table-bordered shift-history-table">
                                <tbody class="table-content">
                                    <tr
                                        *ngFor="let shiftHistory of shiftHistoryDetail?.shiftHistoryDetail"
                                        class="text-secondary"
                                    >
                                        <td class="p-2">{{ shiftHistory?.title }}</td>
                                        <td class="p-2">
                                            {{ shiftHistory?.count }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" (click)="onNavChange(4)">
                    <a ngbNavLink class="">{{ clinicianDetails?.firstName | titlecase }}’s Jobs</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="position-responsive" *ngIf="dataTableModel.displayBlock">
                                <app-datatable
                                    [configDataTable]="dataTableModel"
                                    [isLoadData]="dataTableModel.isLoadData"
                                    [isActive]="dataTableModel.isActive"
                                    [queryparam]="dataTableModel.queryparam"
                                    [isQueryParam]="dataTableModel.isQueryParam"
                                    (rowClickHandler)="rowCallback($event)"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="7" (click)="onNavChange(7)" *ngIf="isShowAllTabs">
                    <a ngbNavLink class="">Shifts</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="row g-3 mb-3 align-items-end">
                                <div class="col-xl-3 col-lg-5 col-7">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="statusSelectList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Status"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedStatusId"
                                                (onChangeSelect)="onStatusChange($event)"
                                                requiredLableName="Shift Status"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="Status"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-4 col-6">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="licenseType"
                                                fieldName="acronym"
                                                fieldId="id"
                                                placeholder="License"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedlicenseTypeId"
                                                requiredLableName="License"
                                                (onChangeSelect)="onJobTypeChange($event)"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="License"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-3">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="jobSpeciality"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Speciality"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedJobSpeciality"
                                                requiredLableName="Speciality"
                                                (onChangeSelect)="onSpecialityChange($event)"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="Speciality"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-3">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="facilityList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Select Facility Name"
                                                [isSearchable]="true"
                                                [selectedFieldId]="facilityId"
                                                requiredLableName="Facility"
                                                (onChangeSelect)="onFacilityTypeChange($event)"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="Facility"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-1 col-lg-2 col-md-2 col-4">
                                    <button
                                        class="btn btn-orange apply-btn"
                                        *ngIf="!isShowResetBtn"
                                        [disabled]="isDisableApply"
                                        (click)="filterShiftList()"
                                    >
                                        Apply
                                    </button>
                                    <button class="btn btn-orange" (click)="resetFilter()" *ngIf="isShowResetBtn">
                                        Reset
                                    </button>
                                </div>
                            </div>

                            <div class="position-responsive" *ngIf="shiftDataTableModel?.displayBlock">
                                <app-datatable
                                    [configDataTable]="shiftDataTableModel"
                                    [isLoadData]="shiftDataTableModel.isLoadData"
                                    [isActive]="shiftDataTableModel.isActive"
                                    [queryparam]="shiftDataTableModel.displayBlock.queryparam"
                                    [isQueryParam]="shiftDataTableModel.isQueryParam"
                                    (rowClickHandler)="shiftRowCallback($event)"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="8" (click)="onNavChange(8)" *ngIf="isShowAllTabs">
                    <a ngbNavLink class="">Timesheets</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="row g-3 mb-3 align-items-end">
                                <div class="col-xl-6 col-lg-6 col-6">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="facilityList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Facility"
                                                [isSearchable]="true"
                                                [selectedFieldId]="timesheetFacilityId"
                                                (onChangeSelect)="onTimesheetFacilityTypeChange($event)"
                                                requiredLableName="Facility"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="Facility"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="position-responsive" *ngIf="timeSheetDataTableModel?.displayBlock">
                                <app-datatable
                                    [configDataTable]="timeSheetDataTableModel"
                                    [isLoadData]="timeSheetDataTableModel.isLoadData"
                                    [isActive]="timeSheetDataTableModel.isActive"
                                    [queryparam]="timeSheetDataTableModel.displayBlock.queryparam"
                                    [isQueryParam]="timeSheetDataTableModel.isQueryParam"
                                    (rowClickHandler)="timesheetRowCallback($event)"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li [ngbNavItem]="11" (click)="onNavChange(11)" *ngIf="isShowAllTabs">
                    <a ngbNavLink class="">Restrictions</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="row g-3 mb-3 align-items-end">
                                <div class="col-xl-4 col-lg-4 col-4">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="restrictedFacilityList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Facility"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedfacilityId"
                                                (onChangeSelect)="onFacilityChange($event)"
                                                requiredLableName="Author"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="true"
                                                lableName="Facility"
                                                [closeOnSelect]="false"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-3">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="restrictionTypeList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Type"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedType"
                                                (onChangeSelect)="onRestrictionTypeChange($event)"
                                                requiredLableName="Type"
                                                [isRequired]="false"
                                                controlName="type"
                                                [isMultiple]="true"
                                                lableName="Type"
                                                [closeOnSelect]="false"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-1 col-lg-2 col-md-2 col-4">
                                    <button
                                        class="w-100 btn btn-orange apply-btn"
                                        *ngIf="!isShowResetBtn"
                                        (click)="filterRestriction()"
                                        [disabled]="isDisableApply"
                                    >
                                        Apply
                                    </button>
                                    <button
                                        class="w-100 btn btn-orange"
                                        *ngIf="isShowResetBtn"
                                        (click)="resetRestriction()"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <div class="position-responsive" *ngIf="restrictionDataTableModel?.displayBlock">
                                <app-datatable
                                    [configDataTable]="restrictionDataTableModel"
                                    [isLoadData]="restrictionDataTableModel.isLoadData"
                                    (rowClickHandler)="restrictionRowCallback($event)"
                                    [isActive]="restrictionDataTableModel.isActive"
                                    [requestObject]="restrictionDataTableModel.displayBlock.requestObject"
                                    [requestType]="restrictionDataTableModel.displayBlock.requestType"
                                    [searchText]="restrictionDataTableModel.displayBlock.searchText"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li [ngbNavItem]="10" (click)="onNavChange(10)" *ngIf="isShowAllTabs">
                    <a ngbNavLink class="">Call outs/NCNS</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="row g-3 mb-3 align-items-end">
                                <div class="col-xl-7 col-lg-5 col-7">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="facilityList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Select Facility Name"
                                                [isSearchable]="true"
                                                [selectedFieldId]="facilityId"
                                                (onChangeSelect)="onFacilityTypeChange($event)"
                                                requiredLableName="Facility"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="Facility"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-5 col-7">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="typesList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Type"
                                                [isSearchable]="true"
                                                [selectedFieldId]="selectedStatusId"
                                                (onChangeSelect)="onStatusChange($event)"
                                                requiredLableName="Shift Status"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="false"
                                                lableName="Type"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-1 col-lg-2 col-md-2 col-4">
                                    <button
                                        class="btn btn-orange apply-btn"
                                        *ngIf="!isShowResetBtn"
                                        [disabled]="isDisableApply"
                                        (click)="filterShiftList('callout')"
                                    >
                                        Apply
                                    </button>
                                    <button
                                        class="btn btn-orange"
                                        (click)="resetFilter('callout')"
                                        *ngIf="isShowResetBtn"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>

                            <div class="position-responsive" *ngIf="calloutstDataTableModel?.displayBlock">
                                <app-datatable
                                    [configDataTable]="calloutstDataTableModel"
                                    [isLoadData]="calloutstDataTableModel.isLoadData"
                                    [isActive]="calloutstDataTableModel.isActive"
                                    [queryparam]="calloutstDataTableModel.displayBlock.queryparam"
                                    [isQueryParam]="calloutstDataTableModel.isQueryParam"
                                    (rowClickHandler)="calloutRowCallback($event)"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>

                <li [ngbNavItem]="9" (click)="onNavChange(9)" *ngIf="isShowAllTabs">
                    <a ngbNavLink class="">Notes</a>
                    <ng-template ngbNavContent>
                        <div class="tab-pane">
                            <div class="row g-3 mb-3 align-items-end">
                                <div class="col-xl-5 col-lg-5 col-5">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="authorList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Author"
                                                [isSearchable]="true"
                                                [selectedFieldId]="authorId"
                                                (onChangeSelect)="onAuthorChange($event)"
                                                requiredLableName="Author"
                                                [isRequired]="false"
                                                controlName="template"
                                                [isMultiple]="true"
                                                lableName="Author"
                                                [closeOnSelect]="false"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-3 col-lg-3 col-3">
                                    <div class="form-group">
                                        <div class="custom-dropdown-box dropdown-box">
                                            <app-selectbox
                                                [selectItems]="typeList"
                                                fieldName="name"
                                                fieldId="id"
                                                placeholder="Type"
                                                [isSearchable]="true"
                                                [selectedFieldId]="typeId"
                                                (onChangeSelect)="onTypeChange($event)"
                                                requiredLableName="Type"
                                                [isRequired]="false"
                                                controlName="type"
                                                [isMultiple]="true"
                                                lableName="Type"
                                                [closeOnSelect]="false"
                                            >
                                            </app-selectbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-2"></div>
                                <div class="col-xl-2 col-lg-2 col-2" *ngIf="companyId">
                                    <button class="w-100 btn btn-orange" (click)="create()">Add Note</button>
                                </div>
                            </div>
                            <div class="position-responsive" *ngIf="notesDataTableModel?.displayBlock">
                                <app-datatable
                                    [configDataTable]="notesDataTableModel"
                                    [isLoadData]="notesDataTableModel.isLoadData"
                                    (rowClickHandler)="noteRowCallback($event)"
                                    [isActive]="notesDataTableModel.isActive"
                                    [requestObject]="notesDataTableModel.displayBlock.requestObject"
                                    [requestType]="notesDataTableModel.displayBlock.requestType"
                                    [searchText]="notesDataTableModel.displayBlock.searchText"
                                >
                                </app-datatable>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li
                    [ngbNavItem]="3"
                    class=""
                    (click)="onNavChange(3)"
                    *ngIf="displayFor != 'restricted' && isHospitalLevel && displayFor != 'notification'"
                >
                    <a ngbNavLink class="">Chat with {{ clinicianDetails?.firstName | titlecase }}</a>
                    <ng-template ngbNavContent>
                        <div #scrollable class="chat-log job-profile-card-list" (scroll)="onScroll()">
                            <div *ngFor="let item of chatModel; let i = index">
                                <div class="chat-log-top m-1">
                                    <div class="msger-chat">
                                        <span class="msger-timeline" *ngIf="item.isNewDay">{{ item.date }}</span>
                                        <div class="msger-chat-box msger-chat-left" *ngIf="item._userId != userId">
                                            <div class="msger-img">
                                                <figure *ngIf="item.avatar">
                                                    <img [src]="item.avatar" alt="" />
                                                </figure>
                                                <div *ngIf="!item.avatar" class="rounded-circle">
                                                    <span
                                                        class="d-flex justify-content-center align-items-center bg-primary fw-bolder msger-badge"
                                                    >
                                                        <p>
                                                            {{ item?.name?.split(' ')?.[0]?.charAt(0)}}
                                                            {{ item?.name?.split(' ')?.[1]?.charAt(0) }}
                                                        </p>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="msger-msg-box">
                                                <div class="msger-msg secondary">
                                                    <p style="word-wrap: break-word">{{ item.text }}</p>
                                                    <div class="d-flex justify-content-end">
                                                        <p class="text-muted">~ {{ item?.name }}</p>
                                                    </div>
                                                </div>
                                                <span class="msg-time">{{
                                                    item.time | localDateTime : 'shortTime'
                                                }}</span>
                                            </div>
                                        </div>
                                        <div class="msger-chat-box msger-chat-right" *ngIf="item._userId == userId">
                                            <div class="msger-msg-box">
                                                <div class="msger-msg">
                                                    <p style="word-wrap: break-word">{{ item.text }}</p>
                                                </div>
                                                <span class="msg-time">{{
                                                    item.time | localDateTime : 'shortTime'
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="slide-chat-log-bottom">
                            <div action="#" class="d-flex w-100">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Type your message"
                                    [(ngModel)]="message"
                                    (keyup.enter)="sendMessage()"
                                />
                                <button
                                    type="button"
                                    class="btn btn-primary send-btn"
                                    [disabled]="!message"
                                    (click)="sendMessage()"
                                >
                                    Send
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="defaultNav" class="p-0"></div>
        </div>
    </div>
    <div class="modal-footer p-3">
        <button
            type="button"
            class="btn btn-primary fw-semibold px-4 px-md-3 ms-2"
            *ngIf="displayFor != 'restricted'"
            (click)="openCertificateUploadScreen('credential', 'Upload Nurse Credential')"
        >
            Upload Credential
        </button>
        <button
            type="button"
            class="btn btn-primary fw-semibold px-4 px-md-3 ms-2"
            *ngIf="(displayFor != 'restricted' && isHospitalLevel) || (callingFrom == 'lead-nurse' && model?.isHired)"
            (click)="onRestriction()"
        >
            Restrict this nurse
        </button>
        <button
            type="button"
            class="btn btn-primary fw-semibold px-4 px-md-3 ms-2"
            (click)="onUpdateRestrictionNurse()"
            *ngIf="displayFor == 'restricted' && isUpdateRestrictedNurse"
            [disabled]="nurseRestrictionForm.invalid || isDisabled"
        >
            Update
        </button>
        <button type="button" [ngClass]="'btn btn-outline-primary fw-semibold px-4 px-md-5'" (click)="onClose()">
            Close
        </button>
    </div>
</div>
