import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
    providedIn: 'root',
})
export class DateUtilService {
    constructor() {}

    convertDateToUtcString(date: any, time: any): string {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let fromDate = new Date(date);

        let month = (fromDate.getMonth() + 1).toString().padStart(2, '0');
        let day = fromDate.getDate().toString().padStart(2, '0');
        let formattedDate = `${fromDate.getFullYear()}-${month}-${day}`;

        const utcDate = this.convertToUtcPipe(formattedDate, time, timeZone);
        const utcTime = utcDate.toTimeString().split(' ')[0];

        return moment(utcDate.toDateString()).format('YYYY-MM-DD') + 'T' + utcTime;
    }

    convertCurrentDateToUTC(): any {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentDate = new Date();

        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        let day = currentDate.getDate().toString().padStart(2, '0');
        let formattedDate = `${currentDate.getFullYear()}-${month}-${day}`;

        let hours = currentDate.getHours().toString().padStart(2, '0');
        let minutes = currentDate.getMinutes().toString().padStart(2, '0');
        let seconds = currentDate.getSeconds().toString().padStart(2, '0');
        let currentTime = `${hours}:${minutes}:${seconds}`;

        const utcDate = this.convertToUtcPipe(formattedDate, currentTime, timeZone);
        const utcTime = utcDate.toTimeString().split(' ')[0];

        return moment(utcDate.toDateString()).format('YYYY-MM-DD') + 'T' + utcTime;
    }

    getLocalToUtcTime(shiftDate: any, shiftTimeList: any[], selectedShiftTime: number) {
        if (shiftDate && selectedShiftTime) {
            let shiftTime: any;

            // Check if shiftDate is an object with year, month, and day properties
            if (typeof shiftDate === 'object' && shiftDate.year && shiftDate.month && shiftDate.day) {
                shiftDate = `${shiftDate.year}-${String(shiftDate.month).padStart(2, '0')}-${String(
                    shiftDate.day
                ).padStart(2, '0')}`;
            } else {
                // Split the date to access year, month, and day separately
                let [year, month, day] = shiftDate.split('-');

                // Add leading zeros to month and day if needed
                month = month.padStart(2, '0');
                day = day.padStart(2, '0');

                // Recombine to get the formatted date
                shiftDate = `${year}-${month}-${day}`;
            }

            if (selectedShiftTime == 0) {
                shiftTime = shiftTimeList;
            } else {
                shiftTime = shiftTimeList.find((x) => x.id == Number(selectedShiftTime));
            }

            let date = shiftDate.split('T')[0];

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            let toTimeParts = shiftTime.shiftTime.split('-')[1].trim().split(' ');
            let fromTimeParts = shiftTime.shiftTime.split('-')[0].trim().split(' ');

            let toTime, toTimeUtc, fromTimeUtc;

            if (toTimeParts[1] == 'AM' && fromTimeParts[1] == 'PM') {
                toTime = this.convertTo24HourFormat(shiftTime.toTime) + ':00';
                const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
                toTimeUtc = this.convertToUtcPipe(nextDay, toTime, timeZone);
                const utcTime = toTimeUtc.toTimeString().split(' ')[0];
                const toDateString = toTimeUtc.toDateString();
                toTime = moment(toDateString).format('YYYY-MM-DD') + 'T' + utcTime;
            } else {
                toTimeUtc = this.convertToUtcPipe(date, this.convertTo24HourFormat(shiftTime.toTime) + ':00', timeZone);
                toTime =
                    moment(toTimeUtc.toDateString()).format('YYYY-MM-DD') +
                    'T' +
                    toTimeUtc.toTimeString().split(' ')[0];
            }

            let fromTime = this.convertTo24HourFormat(shiftTime.fromTime) + ':00';

            fromTimeUtc = this.convertToUtcPipe(date, fromTime, timeZone);
            const formattedFromTime =
                moment(fromTimeUtc.toDateString()).format('YYYY-MM-DD') +
                'T' +
                fromTimeUtc.toTimeString().split(' ')[0];

            return {
                toTime: toTime,
                fromTime: formattedFromTime,
                shiftTimeId: selectedShiftTime,
            };
        }

        // Ensure that a default value is returned if shiftDate is not provided
        return {
            toTime: '',
            fromTime: '',
            shiftTimeId: null,
        };
    }

    convertDateCurrentTimeToUTC(date: any, time?: any): any {
        const selectedDate = new Date(date);

        let month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        let day = selectedDate.getDate().toString().padStart(2, '0');
        let formattedDate = `${selectedDate.getFullYear()}-${month}-${day}`;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentDate = time ? time : new Date();
        const currentTime = currentDate.toTimeString().split(' ')[0];

        const utcDate = this.convertToUtcPipe(formattedDate, currentTime, timeZone);
        const utcTime = utcDate.toTimeString().split(' ')[0];
        return moment(utcDate.toDateString()).format('YYYY-MM-DD') + 'T' + utcTime;
    }

    convertToUtc(dateString: any): string {
        // Get the local time zone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Combine the date with the current time and convert to UTC
        const localDateTime = moment.tz(dateString, 'YYYY-MM-DD', timeZone);
        const utcDateTime = localDateTime.utc();

        // Format the result as an ISO 8601 string
        return utcDateTime.format('YYYY-MM-DDTHH:mm:ss');
    }

    //TODO: This method is duplicate of convertToUtcPipe in convert-to-utc.pipe.ts and needs to be removed
    convertToUtcPipe(localDate: any, localTime: any, timezone: any): any {
        // Combine date and time strings into a single datetime string
        const localDateTimeString = localDate + 'T' + localTime;

        // Create a date object in the local timezone
        const localDateTime = new Date(localDateTimeString);

        // Get the offset in minutes for the specified timezone
        const timeZoneOffsetInMinutes = new Date(
            localDateTime.toLocaleString('en-US', { timeZone: timezone })
        ).getTimezoneOffset();

        // Calculate the UTC time
        const utcTime = new Date(localDateTime.getTime() + timeZoneOffsetInMinutes * 60000);

        return utcTime; // Returns the UTC time in ISO format
    }

    //TODO: Need to remove this usage. This is beig used in get Time Difference.
    convertUtcToLocalDate(val: Date): Date {
        var d = new Date(val); // val is in UTC
        var localOffset = d.getTimezoneOffset() * 60000;
        var localTime = d.getTime() - localOffset;

        d.setTime(localTime);
        return d;
    }

    //TODO: Need to remove this usage. This is beig used in get Time Difference.
    convertUtcToLocalTime(value: any | null, is24HrsFormat?: boolean | false): string | null {
        if (!value) return null;

        let d: Date;

        const currentDate = new Date();
        let [hours, minutes, seconds] = value.split(':').map(Number);
        d = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes, seconds);

        if (isNaN(d.getTime())) {
            // Handle invalid date
            return null;
        }

        // Convert the date to local time
        const localOffset = d.getTimezoneOffset() * 60000;
        const localTime = d.getTime() - localOffset;
        d.setTime(localTime);

        if (is24HrsFormat == true) {
            // Format the time string in 24-hour format
            const hours = ('0' + d.getHours()).slice(-2);
            const minutes = ('0' + d.getMinutes()).slice(-2);

            // Format the time string in 24-hour format
            const time = hours + ':' + minutes;
            return time;
        } else {
            // Format the time string in 12-hour format
            let hours = d.getHours();
            let minutes = d.getMinutes();
            const period = hours >= 12 ? 'PM' : 'AM';

            // Convert hours to 12-hour format
            hours = hours % 12;
            hours = hours ? hours : 12; // Handle midnight (0 hours)

            // Format the time string
            const time = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + period;

            return time;
        }
    }

    // Convert 12 Hrs Time format to 24 Hrs Time format
    convertTo24HourFormat(time: string): string {
        // Regular expression to match the 12-hour time format with AM/PM
        const [match, hours, minutes, period] = time.match(/(\d{1,2}):(\d{2})\s*([APMapm]{2})/) || [];
        if (!match) throw new Error('Invalid time format');

        let hrs = parseInt(hours, 10);
        if (period.toUpperCase() === 'PM' && hrs < 12) hrs += 12;
        if (period.toUpperCase() === 'AM' && hrs === 12) hrs = 0;

        return `${hrs.toString().padStart(2, '0')}:${minutes}`;
    }

    // Convert 24 Hrs Time format to 12 Hrs Time format
    convertTo12HourFormat(dateString: any): string {
        const date = new Date(dateString);

        let hours = date.getHours(); // Local time hours
        let minutes = date.getMinutes(); // Local time minutes
        const period = hours >= 12 ? 'PM' : 'AM';

        // Adjust hours to 12-hour format
        hours = hours % 12 || 12; // Convert '0' hours to '12'
        // Return formatted string with leading zero for minutes if necessary
        return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
    }

    localTimeToUtcPipe(time: any): any {
        if (/^\d{2}:\d{2}(:\d{2})?$/.test(time)) {
            // Handle HH:mm and HH:mm:ss formats
            if (/^\d{2}:\d{2}$/.test(time)) {
                return moment(time, 'HH:mm').utc().format('HH:mm:00');
            } else if (/^\d{2}:\d{2}:\d{2}$/.test(time)) {
                return moment(time, 'HH:mm:ss').utc().format('HH:mm:00');
            }
        } else {
            // Assuming the input is a full date with time
            return moment.utc(time).format('HH:mm:00');
        }
    }

    checkShiftHoursCalculation(startTimeValue: any, endTimeValue: any) {
        const isoStartTime = moment(startTimeValue, 'hh:mm:00 A')
            .set({ year: 2024, month: 0, date: 1, second: 0 })
            .startOf('minute');

        const isoEndTime = moment(endTimeValue, 'hh:mm:00 A')
            .set({ year: 2024, month: 0, date: 1, second: 0 })
            .startOf('minute');

        // Adjust end time if it occurs on the next day
        if (isoEndTime.isBefore(isoStartTime)) {
            isoEndTime.add(1, 'day');
        }
        const differenceInMinutes = moment.duration(isoEndTime.diff(isoStartTime)).asMinutes();
        const hours = Math.floor(differenceInMinutes / 60);
        return hours;
    }

    onTimeChangeGetLocalToUtcTime(fromDate: any, toDate: any) {
        if (fromDate && toDate) {
            let shiftFromDate: any;
            let shiftToDate: any;

            let shiftFromTime: any;
            let shiftToTime: any;

            // Check if shiftDate is an object with year, month, and day properties
            if (typeof fromDate === 'object' && fromDate.getFullYear() && fromDate.getMonth() && fromDate.getDate()) {
                shiftFromDate = `${fromDate.getFullYear()}-${String(fromDate.getMonth() + 1).padStart(2, '0')}-${String(
                    fromDate.getDate()
                ).padStart(2, '0')}`;

                shiftFromTime = this.convertTo12HourFormat(fromDate);
            } else {
                // Assume shiftDate is a string in a recognizable format
                shiftFromDate = moment(new Date(fromDate)).format('YYYY-MM-DD');
                shiftFromTime = this.convertTo12HourFormat(fromDate);
            }

            if (typeof toDate === 'object' && toDate.getFullYear() && toDate.getMonth() && toDate.getDate()) {
                shiftToDate = `${toDate.getFullYear()}-${String(toDate.getMonth() + 1).padStart(2, '0')}-${String(
                    toDate.getDate()
                ).padStart(2, '0')}`;

                shiftToTime = this.convertTo12HourFormat(toDate);
            } else {
                // Assume shiftDate is a string in a recognizable format
                shiftToDate = moment(new Date(toDate)).format('YYYY-MM-DD');
                shiftToTime = this.convertTo12HourFormat(toDate);
            }

            let date = shiftFromDate;

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            let fromTimeParts = shiftFromTime.split(' ');
            let toTimeParts = shiftToTime.split(' ');

            let toTime, toTimeUtc;

            if (toTimeParts[1] == 'AM' && fromTimeParts[1] == 'PM') {
                toTime = this.convertTo24HourFormat(shiftToTime) + ':00';
                const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
                toTimeUtc = this.convertToUtcPipe(nextDay, toTime, timeZone);
                const utcTime = toTimeUtc.toTimeString().split(' ')[0];
                const toDateString = toTimeUtc.toDateString();
                toTime = moment(toDateString).format('YYYY-MM-DD') + 'T' + utcTime;
            } else {
                toTimeUtc = this.convertToUtcPipe(date, this.convertTo24HourFormat(shiftToTime) + ':00', timeZone);
                toTime =
                    moment(toTimeUtc.toDateString()).format('YYYY-MM-DD') +
                    'T' +
                    toTimeUtc.toTimeString().split(' ')[0];
            }

            return {
                toTime: toTime,
                fromTime: fromDate,
            };
        }

        // Ensure that a default value is returned if shiftDate is not provided
        return {
            toTime: '',
            fromTime: '',
            shiftTimeId: null,
        };
    }

    // Helper function to merge date and time
    mergeDateAndTime = (date: any, time: any): Date => {
        date = this.parseDateString(date);

        const newDate = new Date(date.year, date.month - 1, date.day);
        if (typeof time === 'string') {
            const [hours, minutes] = time.split(':').map(Number);
            newDate.setHours(hours, minutes, 0, 0);
        } else if (time instanceof Date) {
            newDate.setHours(time.getHours(), time.getMinutes(), 0, 0);
        } else {
            throw new Error('Invalid time format');
        }

        return newDate;
    };

    //TODO: Need To delete. Helper function to merge date and time
    mergeDateAndTime2 = (date: any, time: any): Date => {
        const newDate = new Date(date);
        if (typeof time === 'string') {
            const [hours, minutes] = time.split(':').map(Number);
            newDate.setHours(hours, minutes, 0, 0);
        } else if (time instanceof Date) {
            newDate.setHours(time.getHours(), time.getMinutes(), 0, 0);
        } else {
            throw new Error('Invalid time format');
        }

        return newDate;
    };

    parseDateString(dateString: string): { year: number; month: number; day: number } {
        let [year, month, day] = dateString.split('-');

        // Ensure month and day are two digits
        month = month.padStart(2, '0');
        day = day.padStart(2, '0');

        return {
            year: parseInt(year, 10),
            month: parseInt(month, 10),
            day: parseInt(day, 10),
        };
    }
}
