import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DocumentationVerficationModel, JobApplicantDocumentationStatus } from 'src/app/model/clinicianProfile.model';
import { ClinicianProfileService } from 'src/app/service/clinicianProfile.service';
import { HealthcareEmployeeService } from 'src/app/service/healthcareEmployee.service';
import { StatusChangeService } from 'src/app/service/statusChange.service';

@Component({
    selector: 'app-update-documentation-status-model',
    templateUrl: './update-documentation-status-model.component.html',
    styleUrl: './update-documentation-status-model.component.scss',
})
export class UpdateDocumentationStatusModelComponent implements OnInit {
    @Input() data: any;
    @Input() id: any;
    @Input() moduleURL: any;
    @Input() label: any;
    @Input() status: any;
    @Input() jobApplicantId: any;
    @Input() type: any;

    restrictionList: any;
    selectedRestriction: any;
    isRestrictionShow: boolean = false;
    documentationVerficationForm: FormGroup;
    documentationVerificationModel: any = new DocumentationVerficationModel();
    isDisable: boolean = false;
    selectedReApplyDate: any;
    moment: any = moment;

    constructor(
        public clinicianProfileService: ClinicianProfileService,
        public modal: NgbActiveModal,
        public fb: FormBuilder,
        private statusChangeService: StatusChangeService,
        private toastrService: ToastrService,
        public healthcareEmployeeService: HealthcareEmployeeService
    ) {}

    ngOnInit(): void {
        this.restrictionList = [
            { id: 1, name: 'Nurse can not apply again for this job' },
            { id: 2, name: 'Nurse can apply again for this job at any time' },
            { id: 3, name: 'Nurse can apply again only after this date:' },
        ];
        this.getDocumentDetails();
        this.initForm();
        this.onStatusChange();
    }

    initForm() {
        this.documentationVerficationForm = this.fb.group({
            id: [this.id],
            jobId: [this.data.jobId],
            jobApplicantId: [this.jobApplicantId],
            clinicianId: [this.data.clinicianId],
            status: [this.status],
            canApply: [this.documentationVerificationModel.canApply],
            reApplyDate: [this.documentationVerificationModel.reApplyDate],
            feedback: [this.documentationVerificationModel.feedback],
            sentBackNotes: [this.documentationVerificationModel.sentBackNotes],
            internalComment: [this.documentationVerificationModel.internalComment],
        });
    }
    getDocumentDetails() {
        if (this.type) {
            this.clinicianProfileService.getClinicianEmpolyeementFormDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    // this.applicantDetails = res.result;
                    this.documentationVerificationModel = res.result;
                }
            });
        } else {
            this.clinicianProfileService.getClinicianDocumentationDetailById(this.id).subscribe((res: any) => {
                if (res.result) {
                    this.documentationVerificationModel = res.result;
                    this.initForm();
                    this.onStatusChange();
                }
            });
        }
    }

    onRestrictionChange(value: any) {
        this.selectedRestriction = value;
        if (this.selectedRestriction === 1) {
            this.documentationVerficationForm.controls.canApply.setValue(false);
            this.documentationVerficationForm.controls.reApplyDate.setValidators(Validators.compose([]));
            this.documentationVerficationForm.controls.reApplyDate.updateValueAndValidity();
        } else if (this.selectedRestriction === 2) {
            this.documentationVerficationForm.controls.canApply.setValue(true);
            this.documentationVerficationForm.controls.reApplyDate.setValidators(Validators.compose([]));
            this.documentationVerficationForm.controls.reApplyDate.updateValueAndValidity();
        } else {
            this.documentationVerficationForm.controls.canApply.setValue(true);
            this.documentationVerficationForm.controls.reApplyDate.setValidators(
                Validators.compose([Validators.required])
            );
            this.documentationVerficationForm.controls.reApplyDate.updateValueAndValidity();
            this.documentationVerficationForm.controls.reApplyDate.setValue(null);
        }
    }

    submit() {
        let verficationModel = this.documentationVerficationForm.value as DocumentationVerficationModel;
        if (verficationModel.reApplyDate) {
            let month =
                (verficationModel.reApplyDate.getMonth() + 1).toString().length == 1
                    ? '0' + (verficationModel.reApplyDate.getMonth() + 1)
                    : verficationModel.reApplyDate.getMonth() + 1;
            let day =
                verficationModel.reApplyDate.getDate().toString().length == 1
                    ? '0' + verficationModel.reApplyDate.getDate()
                    : verficationModel.reApplyDate.getDate();
            verficationModel.reApplyDate =
                verficationModel.reApplyDate.getFullYear() + '-' + month + '-' + day + 'T18:30:00';
        }
        this.isDisable = true;
        verficationModel.status = this.status == JobApplicantDocumentationStatus.SentBack ? 'SentBack' : 'Declined';
        verficationModel.isVerified = true;
        verficationModel.verifiedDate = new Date().toISOString();
        if (this.selectedRestriction == 2) {
            verficationModel.reApplyDate = new Date().toISOString();
        }

        verficationModel.jobApplicantId = this.jobApplicantId;
        this.updateVerificationStatus(verficationModel);
    }

    updateVerificationStatus(verficationModel: any) {
        this.statusChangeService.updateStatus(this.moduleURL, verficationModel).subscribe(
            (response: any) => {
                if (response.result == true) {
                    this.modal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            },
            (err: any) => {
                this.isDisable = false;
                this.toastrService.error(err.error.message);
            }
        );
    }

    close() {
        this.modal.close();
    }

    onReApplyDateSelectChange(event: any) {
        this.documentationVerficationForm.controls.reApplyDate.setValue(event);
    }
    onDocumentShow(data: any) {
        window.open(data);
    }

    onStatusChange() {
        if (this.status == JobApplicantDocumentationStatus.Declined) {
            this.isRestrictionShow = true;
            this.documentationVerficationForm.controls.canApply.setValidators(
                Validators.compose([Validators.required])
            );
            this.documentationVerficationForm.controls.canApply.updateValueAndValidity();
            this.documentationVerficationForm.controls.canApply.setValue(null);
            this.documentationVerficationForm.controls.sentBackNotes.setValidators(Validators.compose([]));
            this.documentationVerficationForm.controls.feedback.setValidators(
                Validators.compose([Validators.required])
            );
            this.documentationVerficationForm.controls.internalComment.setValidators(
                Validators.compose([Validators.required])
            );
            this.documentationVerficationForm.controls.sentBackNotes.updateValueAndValidity();
            this.documentationVerficationForm.controls.feedback.updateValueAndValidity();
            this.documentationVerficationForm.controls.internalComment.updateValueAndValidity();
        } else if (this.status == JobApplicantDocumentationStatus.SentBack) {
            this.documentationVerficationForm.controls.sentBackNotes.setValidators(
                Validators.compose([Validators.required])
            );
            this.documentationVerficationForm.controls.feedback.setValidators([]);
            this.documentationVerficationForm.controls.internalComment.setValidators([]);
            this.documentationVerficationForm.controls.sentBackNotes.updateValueAndValidity();
            this.documentationVerficationForm.controls.feedback.updateValueAndValidity();
            this.documentationVerficationForm.controls.internalComment.updateValueAndValidity();
            this.isRestrictionShow = false;
            this.documentationVerficationForm.controls.canApply.setValidators(Validators.compose([]));
            this.documentationVerficationForm.controls.canApply.updateValueAndValidity();
        }
    }
    formatNumber(number: any) {
        return number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }
}
