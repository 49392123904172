import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CropperComponent } from 'angular-cropperjs';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit {
    @Input() event: any;
    @Input() aspectRatio = 2 / 1;
    imageUrl = '';
    containWithinAspectRatio = true;
    transform: ImageTransform = {};
    backgroundColor = 'rgb(0, 0, 0)';
    croppedImage: any = '';
    imageFile: any = '';
    showCropper = false;

    @ViewChild('angularCropper') public angularCropper: CropperComponent;

    constructor(private modal: NgbActiveModal) {}

    ngOnInit(): void {
        this.cropImage();
    }

    cropImage() {
        this.imageUrl = this.event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    dismissModel() {
        this.modal.close();
    }

    cropButton() {
        this.modal.close(this.croppedImage);
    }
}
