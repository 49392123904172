<div class="horizontal-menu d-flex bottom-navbar p-3 align-items-center">
    <div class="mainlogo text-center">
        <img src="assets/logo/logo1-new.png" class="logo" />
    </div>
    <div class="mainMenu">
        <div class="d-flex">
            <div #mainheaderContent class="slideouter">
                <ul class="slideinner srcl p-0">
                    <li class="nav-item" *ngFor="let item of level1MenuItems">
                        <a class="nav-link active" [class.active]="item.setDefault" (click)="hasItems(item)">
                            <span class="menu-title">{{ item.displayText }}</span></a
                        >
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex">
            <div #submenuContent class="slideouter sub-menu">
                <ul class="nav page-navigation mb-0">
                    <li class="nav-item" *ngFor="let item of level2MenuItems">
                        <a class="nav-link" [class.active]="item.setDefault" (click)="parentMenu(item)">{{
                            item.displayText
                        }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="author-info">
        <div class="d-flex justify-content-end align-items-center">
            <div class="junral-settings">
                <!-- <span _ngcontent-gkw-c154="" class="material-icons ng-tns-c154-1" [style.pointer-events]="'none'"
                        >settings</span
                    > -->
                <span
                    _ngcontent-gkw-c154=""
                    class="material-icons ng-tns-c154-1 cursor-pointer setting-icons fs-4"
                    (click)="openChatWindow()"
                    >message</span
                >
                <span class="ms-2 me-1 cursor-pointer position-relative">
                    <i
                        class="link-icon feather icon-bell setting-icons fs-4 position-relative"
                        (click)="openNotificationWindow()"
                    ></i>
                    <p *ngIf="unReadNotificationCount > 0" class="notification-badge">
                        {{ unReadNotificationCount }}
                    </p>
                </span>
            </div>
            <div class="admin-image ms-2">
                <div ngbDropdown>
                    <a class="nav-link d-flex align-items-center" ngbDropdownToggle id="profileDropdown">
                        <div *ngIf="other_content1 == true" class="w-50 ht-50 rounded-circle">
                            <img
                                class="wd-50 ht-50 rounded-circle m-0"
                                [src]="avtarLogoPic"
                                (error)="onImgLogoError($event)"
                                alt=""
                            />
                        </div>
                        <img
                            *ngIf="profileUrl != 'null' && other_content1 == false"
                            class="wd-50 ht-50 rounded-circle m-0"
                            [src]="profileUrl"
                            (error)="onImgLogoError($event)"
                            alt=""
                        />
                        <img
                            *ngIf="profileUrl == 'null' && other_content1 == false"
                            class="wd-50 ht-50 rounded-circle m-0"
                            [src]="avtarLogoPic"
                            (error)="onImgLogoError($event)"
                            alt=""
                        />
                    </a>
                    <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
                        <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
                            <div class="mb-3">
                                <img
                                    class="wd-80 ht-80 rounded-circle m-0"
                                    *ngIf="profileUrl != 'null' && other_content == false"
                                    (error)="onImgError($event)"
                                    [src]="profileUrl"
                                    alt=""
                                />
                                <img
                                    class="wd-80 ht-80 rounded-circle m-0"
                                    *ngIf="profileUrl == 'null' && other_content == false"
                                    (error)="onImgError($event)"
                                    [src]="avtarLogoPic"
                                    alt=""
                                />
                                <div class="mr-5" *ngIf="other_content == true">
                                    <img
                                        *ngIf="profileUrl == 'null'"
                                        class="wd-50 ht-50 rounded-circle m-0"
                                        [src]="avtarLogoPic"
                                        (error)="onImgLogoError($event)"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div class="text-center">
                                <p class="tx-16 fw-bolder">
                                    {{ firstName }}{{ ' ' }}{{ lastName == 'null' ? ' ' : lastName }}
                                </p>
                                <p class="tx-12 text-muted">{{ email }}</p>
                            </div>
                        </div>
                        <ul class="list-unstyled p-1">
                            <li class="dropdown-item py-2">
                                <a routerLink="/thrive-user-profile" class="d-flex text-body ms-0">
                                    <i class="feather icon-user me-2 icon-md"></i>
                                    <span> Personal Profile</span>
                                </a>
                            </li>
                            <li class="dropdown-item py-2">
                                <a href="" [routerLink]="['/change-password']" class="d-flex text-body ms-0">
                                    <i class="feather icon-lock me-2 icon-md"></i>
                                    <span>Change Password</span>
                                </a>
                            </li>
                            <li class="dropdown-item py-2">
                                <a href="" (click)="onLogout()" class="d-flex text-body ms-0">
                                    <i class="feather icon-log-out me-2 icon-md"></i>
                                    <span>Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
