import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
    @Input() mapLongitude: any;
    @Input() mapLatitude: any;
    @Input() markers: any;
    showMarker: any[] = [];
    center: google.maps.LatLngLiteral;

    constructor() {}

    ngOnInit(): void {
        this.mapLongitude = parseFloat(this.mapLongitude);
        this.mapLatitude = parseFloat(this.mapLatitude);

        this.center = {
            lat: this.mapLatitude,
            lng: this.mapLongitude,
        };
        this.markers.forEach((element: any) => {
            if (element) {
                this.showMarker.push({
                    position: {
                        lat: element.latitude,
                        lng: element.longitude,
                    },
                    label: {
                        color: 'red',
                    },
                    options: { animation: google.maps.Animation.BOUNCE },
                });
            }
        });
    }
}
