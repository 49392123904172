<div class="" *ngIf="mapLatitude">
    <google-map [zoom]="10" [center]="center" width="100%">
        <map-marker
            *ngFor="let marker of showMarker"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
        >
        </map-marker>
    </google-map>
</div>
