import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ClinicianDetailService {
    constructor() {}

    setFields(details: any) {
        const fieldMapping: any = {
            firstName: 'clinicianFirstName',
            lastName: 'clinicianLastName',
            email: 'clinicianEmail',
            phoneNumber: 'clinicianPhoneNumber',
            stateAcronym: 'clinicianStateName',
            cityName: 'clinicianCityName',
            licenses: 'clinicianLicences',
        };

        Object.keys(fieldMapping).forEach((clinicianKey: any) => {
            const targetKey = fieldMapping[clinicianKey];
            if (details[clinicianKey]) {
                details[targetKey] = details[clinicianKey];
            }
        });
        return details;
    }
}
