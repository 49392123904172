<div [formGroup]="parentForm">
    <label *ngIf="label">{{ label }}<span *ngIf="isRequired" class="form-label required"></span></label>
    <input
        [type]="hideField ? 'password' : 'text'"
        class="form-control"
        [formControlName]="fieldName"
        placeholder="{{ label }}"
        [value]="value"
        [required]="isRequired"
        [attr.aria-label]="hideField ? 'Show password' : 'Hide password'"
        [attr.aria-pressed]="!hideField"
    />
    <div class="password-eye-icon" (click)="toggleFieldVisibility()">
        <i class="feather icon-eye" aria-hidden="true" *ngIf="!hideField"></i>
        <i class="feather icon-eye-off" *ngIf="hideField"></i>
    </div>
</div>

<ng-container *ngIf="parentForm.get(fieldName)?.invalid && parentForm.get(fieldName)?.touched">
    <ng-container *ngIf="parentForm.get(fieldName)?.errors">
        <ng-container *ngIf="parentForm.get(fieldName)?.hasError('required')" class="error-message">
            <div class="input-error">{{ label }} is required.</div>
        </ng-container>
        <ng-container *ngIf="parentForm.get(fieldName)?.hasError('pattern')" class="error-message">
            <div class="input-error">{{ label }} must meet the pattern criteria.</div>
        </ng-container>
    </ng-container>
</ng-container>
