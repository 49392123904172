import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-filter-status-selectbox',
    templateUrl: './filter-status-selectbox.component.html',
    styleUrls: ['./filter-status-selectbox.component.scss'],
})
export class FilterStatusSelectbox implements OnInit {
    selectedOption: string = '';
    @Input() activeLabel: string = 'Active';
    @Input() inactiveLabel: string = 'Inactive';
    @Output() optionSelected = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}
    onSelectionChange(value: any) {
        this.optionSelected.emit(value);
    }
}
