export class TimeSheetFormModel {
    constructor() {
        this.id = 0;
        this.name = null;
        this.isActive = false;
    }

    id: number;
    name: any;
    isActive: boolean;

    setSatetData(data: any) {
        this.id = data.id;
        this.name = data.name.trim();
        this.isActive = data.isActive;
    }
}

export class TimeSheetModel {
    id: number;
    requestType: number;
    disputeReason: string;
    additionalInformationByFacilityAdmin: string;
    isExtraTimePaid: boolean;
    canAskToReUpload: boolean;
    verifiedById: number;
    verifiedOn: any;
    clockIn: any;
    clockOut: Date;
    breakIn: any;
    breakOut: any;
    additionalInformationByThriveAdmin: string;
    adminDecision: boolean;
}
