<div class="profile-modal-content modal-content w-100">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <h4>{{ label }}</h4>
    </div>
    <form
        class="form form-label-right"
        novalidate="novalidate"
        id="kt_category_form"
        [formGroup]="verficationForm"
        (ngSubmit)="submit()"
        #verificationFormDirective="ngForm"
    >
        <div class="modal-body p-4 pt-0">
            <div class="profile-model-box">
                <div class="profile-model-img">
                    <figure>
                        <img
                            *ngIf="data?.clinicianProfileUrl || data?.profileUrl"
                            [src]="data?.clinicianProfileUrl || data?.profileUrl"
                            alt=""
                        />
                        <img
                            *ngIf="!data?.clinicianProfileUrl && !data?.profileUrl"
                            src="./assets/images/others/blank.png"
                        />
                    </figure>
                </div>
                <div class="profile-model-info">
                    <h4>{{ data?.clinicianFirstName }} {{ data?.clinicianLastName }}</h4>
                    <p class="text-primary">{{ data?.clinicianCityName }}, {{ data.clinicianStateName }}</p>
                    <p class="text-primary">
                        <a *ngIf="data?.clinicianEmail" href="mailto:{{data?.clinicianEmail}}" class="nurse-email">{{data?.clinicianEmail}}</a>
                        {{
                            data?.clinicianEmail ? ' | ' : ' '
                        }}
                        {{ formatNumber(data?.clinicianPhoneNumber ?? '') }}
                        </p>
                    <div class="label-list">
                        <label
                            class="badge fw-bolder"
                            [ngStyle]="{
                                'background-color': type?.chipBackgroundColor,
                                color: type?.chipFontColor
                            }"
                            *ngFor="let type of data?.clinicianLicences"
                            >{{ type?.acronym }}</label
                        >
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="type == 'license'">
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">License Type</label>
                        <p>{{ verificationModel?.licenseType }}</p>
                    </div>
                </div>
                <div class="col-3" *ngIf="!verificationModel?.isItCompatStateLicense">
                    <div class="form-group mb-3">
                        <label class="form-label">State</label>
                        <p>{{ verificationModel?.stateAcronym }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">License Number</label>
                        <p>{{ verificationModel?.licenseNumber }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">Is Compact?</label>
                        <p>{{ verificationModel?.isItCompatStateLicense ? 'Yes' : 'No' }}</p>
                    </div>
                </div>
                <div class="col-3" *ngIf="verificationModel?.issuedDate">
                    <div class="form-group mb-3">
                        <label class="form-label">Issue Date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.issuedDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="verificationModel?.expiryDate">
                    <div class="form-group mb-3">
                        <label class="form-label">Expiration date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.expiryDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card col-6 mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-3">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-9">
                                <p class="fw-bolder">License File</p>
                                <p
                                    class="cursor-pointer text-secondary text-break description"
                                    (click)="onOpenFile(verificationModel?.fileUrl)"
                                >
                                    {{ verificationModel?.fileName }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="type == 'education'">
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label">Graduation Type</label>
                        <p>{{ verificationModel?.graduationTypeName }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label">Course</label>
                        <p>{{ verificationModel?.courseName }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label">In Progress?</label>
                        <p>{{ verificationModel?.onGoing ? 'Yes' : 'No' }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label">Institution</label>
                        <p>{{ verificationModel?.university }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">State</label>
                        <p>{{ verificationModel?.stateAcronym }}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group mb-3">
                        <label class="form-label">Start Date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.startDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-4" *ngIf="verificationModel?.endDate">
                    <div class="form-group mb-3">
                        <label class="form-label">Finish date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.endDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card col-6 mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-3">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-9">
                                <p class="fw-bolder">Certification File</p>
                                <p
                                    class="cursor-pointer text-secondary text-break description"
                                    (click)="onOpenFile(verificationModel?.fileUrl)"
                                >
                                    {{ verificationModel?.fileName }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="type == 'experience'">
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">Empl. Type</label>
                        <p>{{ verificationModel?.employmentTypeName }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">Job Title</label>
                        <p>{{ verificationModel?.title }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">License Type</label>
                        <p>{{ verificationModel?.licenseTypeName }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">Currently working here?</label>
                        <p>{{ verificationModel?.endDate ? 'No' : 'Yes' }}</p>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group mb-3">
                        <label class="form-label">Company Name</label>
                        <p>
                            {{ verificationModel?.companyName }},{{ ' ' + verificationModel?.cityName }},{{
                                ' ' + verificationModel?.stateName
                            }}
                        </p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">Start Date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.startDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="verificationModel?.endDate">
                    <div class="form-group mb-3">
                        <label class="form-label">Finish date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.endDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <label class="form-label">Specialties</label>
                        <p>
                            {{ specialities }}
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <label class="form-label">Contact Information</label>
                        <p>
                            {{ specialities }}
                        </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-3">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-9">
                                <p class="fw-bolder">Experience File</p>
                                <p
                                    class="cursor-pointer text-secondary text-break description"
                                    (click)="onOpenFile(verificationModel?.proofOfEmployeeFileUrl)"
                                >
                                    {{ verificationModel?.proofOfEmployeeFileName }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="type == 'backgroundCheck'">
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">Range</label>
                        <p>{{ verificationModel?.backgroundCheckRangeName }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">State</label>
                        <p>{{ verificationModel?.states }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label">Background CheckType</label>
                        <p>{{ verificationModel?.backgroundCheckName }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-2">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-7">
                                <p class="fw-bolder">Background Check File</p>
                                <p class="text-secondary" *ngIf="!fileName">
                                    <small>{{ 'Click to select' }}</small>
                                </p>
                                <p
                                    class="text-secondary cursor-pointer hover-effect"
                                    *ngIf="fileName"
                                    (click)="viewPdf()"
                                >
                                    {{ fileName }}
                                </p>
                            </div>
                            <div class="col-2">
                                <label
                                    _ngcontent-ful-c200=""
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Change avatar"
                                    class="edit-profile-image rounded-5 cursor-pointer"
                                >
                                    <img src="assets/images/add-circle.png" height="30px" />

                                    <input
                                        type="file"
                                        style="display: none"
                                        #fileInput
                                        accept=".pdf, .doc"
                                        (change)="onSelectFile($event)"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="verificationFormDirective.submitted">
                        <span class="input-error" *ngIf="fileSize > 15000000"> File size exceeds 15MB limit. </span>
                        <span *ngIf="!fileName && selectedStatus != 1" class="input-error"
                            >Please Upload Document.</span
                        >
                        <span
                            class="input-error"
                            *ngIf="fileType && fileType != 'application/pdf' && fileType != 'application/msword'"
                        >
                            Please select only pdf or document file.
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="type == 'credential'">
                <div class="col-5">
                    <div class="form-group mb-3">
                        <label class="form-label">Credential Name</label>
                        <p>{{ verificationModel?.credentialTypeName }}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group mb-3">
                        <label class="form-label">Category</label>
                        <p>{{ verificationModel?.credentialCategoryTypeName }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group mb-3">
                        <label class="form-label">State</label>
                        <p>{{ verificationModel?.stateAcronym }}</p>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group mb-3">
                        <label class="form-label">Credential Number</label>
                        <p>{{ verificationModel?.credentialNumber ?? '-' }}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group mb-3">
                        <label class="form-label">Issue date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">{{ verificationModel?.issuedDate | localDateTime : 'ordinal' }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-3" *ngIf="verificationModel?.expiryDate">
                    <div class="form-group mb-3">
                        <label class="form-label">Expiration date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">
                                {{
                                    verificationModel?.isCredentialDoesNotExpire
                                        ? 'N/A'
                                        : (verificationModel?.expiryDate | localDateTime : 'ordinal')
                                }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card col-6 mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-3">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-9">
                                <p class="fw-bolder">Credential File</p>
                                <p
                                    class="cursor-pointer text-secondary text-break description"
                                    (click)="onOpenFile(verificationModel?.fileUrl)"
                                >
                                    {{ verificationModel?.fileName }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="type == 'EmployementForm'">
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label"> Employment Form Type</label>
                        <p>{{ verificationModel?.formTypeName }}</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="form-label">Uploaded Date</label>
                        <div class="d-flex align-items-center">
                            <span>
                                <i class="feather icon-calendar" aria-hidden="true"></i>
                            </span>
                            <p class="ms-2">
                                {{ verificationModel?.uploadedOn | localDateTime : 'ordinal' }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card col-6 mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-3">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-9" (click)="onOpenFile(verificationModel?.fileUrl)">
                                <p class="fw-bolder">{{ verificationModel?.fileName }}</p>
                                <p class="cursor-pointer text-secondary text-break description">
                                    Uploaded in {{ verificationModel?.uploadedOn | localDateTime : 'ordinal' }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <label class="form-label col-2 mt-1 required">Status: </label>
                <div class="col-10 d-flex">
                    <div *ngFor="let status of status">
                        <div class="form-check form-check-inline">
                            <input
                                type="radio"
                                name="status"
                                class="form-check-input cursor-pointer"
                                formControlName="status"
                                [checked]="status.id === selectedStatus"
                                [value]="status.id"
                                (change)="onStatusChange(status.id)"
                            />
                            <label
                                class="form-check-label fw-normal ms-1 d-flex justify-content-center align-items-center"
                            >
                                {{ status.name }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="type == 'license' && (selectedStatus == 2 || selectedStatus == 3)">
                <div class="col-6">
                    <div class="card mt-3 rounded-3">
                        <div class="row py-3">
                            <div class="col-2 ms-2">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-7">
                                <p class="fw-bolder">
                                    {{ type + ' Verification file' | titlecase }}
                                </p>
                                <p class="text-secondary" *ngIf="!fileName">
                                    <small>{{ 'Click to select' }}</small>
                                </p>
                                <p
                                    class="text-secondary cursor-pointer hover-effect"
                                    *ngIf="fileName"
                                    (click)="viewPdf()"
                                >
                                    {{ fileName }}
                                </p>
                            </div>
                            <div class="col-2">
                                <label
                                    _ngcontent-ful-c200=""
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Change avatar"
                                    class="edit-profile-image rounded-5 cursor-pointer"
                                >
                                    <img src="assets/images/add-circle.png" height="30px" />

                                    <input
                                        type="file"
                                        style="display: none"
                                        #fileInput
                                        accept=".pdf, .doc"
                                        (change)="onSelectFile($event)"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="verificationFormDirective.submitted">
                        <span class="input-error" *ngIf="fileSize > 15000000"> File size exceeds 15MB limit. </span>
                        <span *ngIf="!fileName && selectedStatus != 1" class="input-error"
                            >Please Upload Document.</span
                        >
                        <span
                            class="input-error"
                            *ngIf="fileType && fileType != 'application/pdf' && fileType != 'application/msword'"
                        >
                            Please select only pdf or document file.
                        </span>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="displayFor === 'renew'">
                <app-textarea
                    [label]="type == 'EmployementForm' ? 'Interal Comments (optional)' : 'Comments (optional)'"
                    [fieldName]="type == 'EmployementForm' ? 'Interal Comments (optional)' : 'Comments (optional)'"
                    [isRequired]="false"
                    [rows]="6"
                    [cols]="250"
                    [min]="1"
                    [max]="1000"
                    [isShowCharacterCount]="true"
                    [parentForm]="verficationForm"
                    formControlName="comment"
                    [placeholder]="
                        type == 'EmployementForm'
                            ? 'Please leave your internal comments here'
                            : 'Please leave your comments here'
                    "
                >
                </app-textarea>
            </div>
            <div class="row mt-3" *ngIf="isRestrictionShow && displayFor != 'renew'">
                <label class="form-label col-2 mt-2 required">Restriction: </label>
                <div class="col-10">
                    <div *ngFor="let restrictions of restrictionList; let last = last">
                        <div class="form-check form-check-inline mt-2">
                            <input
                                type="radio"
                                name="canApply"
                                formControlName="canApply"
                                class="form-check-input cursor-pointer"
                                [checked]="restrictions.id === selectedRestriction"
                                [value]="restrictions.id"
                                (change)="onRestrictionChange(restrictions.id)"
                            />
                            <label
                                class="form-check-label fw-normal ms-1 d-flex justify-content-center align-items-center"
                            >
                                {{ restrictions.name }}
                            </label>
                            <div class="form-group" *ngIf="last && selectedRestriction === 3">
                                <app-datepicker
                                    controlName="reApplyDate"
                                    fieldName="date"
                                    fieldId="date"
                                    [selectedFieldDate]="selectedReApplyDate"
                                    [formName]="verficationForm"
                                    (onDateSelect)="onReApplyDateSelectChange($event)"
                                    requiredLableName="Date"
                                    [isRequired]="true"
                                    [startFromNextDay]="true"
                                >
                                </app-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="selectedStatus !== 1 && selectedStatus !== null && displayFor != 'renew'">
                <div class="form-group mt-4">
                    <app-textarea
                        label="Feedback for Nurse (required)"
                        [fieldName]="'Feedback for Nurse (required)'"
                        [placeholder]="'Please leave your feedback here'"
                        [isRequired]="true"
                        [rows]="5"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="verficationForm"
                        formControlName="feedback"
                    >
                    </app-textarea>
                </div>
                <div class="form-group mt-3">
                    <app-textarea
                        label="Internal Comments (required)"
                        [fieldName]="'Internal Comments (required)'"
                        [isRequired]="true"
                        [rows]="6"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="verficationForm"
                        formControlName="comment"
                        [placeholder]="'Please leave your internal comments here'"
                    >
                    </app-textarea>
                </div>
            </div>
        </div>

        <div class="modal-footer p-3">
            <button
                class="btn btn-outline-primary fw-semibold px-4 px-md-5 mr-3 mr-md-4"
                (click)="close()"
                type="button"
            >
                Close
            </button>
            <button
                class="btn btn-primary fw-semibold px-4 px-md-5"
                type="submit"
                [disabled]="isDisable || verficationForm.invalid"
            >
                Save
            </button>
        </div>
    </form>
</div>
