import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { API_HEALTHCARE_EMPLOYMENT_FORM } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class HealthcareEmployeeService {
    constructor(private apiService: ApiService) {}

    addHealthcareEmployee(res: any): Observable<any> {
        return this.apiService.post(API_HEALTHCARE_EMPLOYMENT_FORM.PUT_API_HEALTHCARE_EMPLOYMENT_FORM, res);
    }

    updateHealthcareEmployee(id: any, res: any): Observable<any> {
        return this.apiService.put(API_HEALTHCARE_EMPLOYMENT_FORM.PUT_API_HEALTHCARE_EMPLOYMENT_FORM + '/' + id, res);
    }

    getHealthcareEmployeeDetailsById(id: any): Observable<any> {
        return this.apiService.get(API_HEALTHCARE_EMPLOYMENT_FORM.PUT_API_HEALTHCARE_EMPLOYMENT_FORM + '/' + id);
    }
}
