<div class="profile-modal-content modal-content w-100 fixwidth">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <h4>{{ title }}</h4>
    </div>
    <form
        class="form form-label-right"
        [formGroup]="nurseCredentialForm"
        (ngSubmit)="submit()"
        #nurseCredentialFormDirective="ngForm"
    >
        <div class="modal-body p-4 pt-0">
            <div class="profile-model-box">
                <div class="profile-model-img">
                    <figure>
                        <img *ngIf="data?.clinicianProfileUrl != null" src="{{ data?.clinicianProfileUrl }}" alt="" />
                        <img *ngIf="data?.clinicianProfileUrl == null" src="./assets/images/others/blank.png" />
                    </figure>
                </div>
                <div class="profile-model-info">
                    <h4>
                        {{
                            ((data?.clinicianFirstName | titlecase) ?? '') +
                                ' ' +
                                ((data?.clinicianLastName | titlecase) ?? '')
                        }}
                    </h4>
                    <p class="text-primary">
                        {{
                            (data?.clinicianCityName ? data?.clinicianCityName + ', ' : '') +
                                (data?.clinicianStateName ?? '')
                        }}
                    </p>
                    <p class="text-primary">
                        <a *ngIf="data?.clinicianEmail" href="mailto:{{data?.clinicianEmail}}" class="nurse-email">{{data?.clinicianEmail}}</a>
                        {{ data?.clinicianEmail }} | {{ formatNumber(data?.clinicianPhoneNumber) }}
                    </p>
                    <div class="label-list">
                        <label
                            class="badge fw-bolder"
                            [ngStyle]="{
                                'background-color': type?.chipBackgroundColor,
                                color: type?.chipFontColor
                            }"
                            *ngFor="let type of data?.clinicianLicences"
                            >{{ type?.acronym }}</label
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-3 g-2" *ngIf="type == 'credential'">
                <div class="form-group dropdown-box" style="z-index: 8">
                    <app-selectbox
                        formControlName="credentialCategoryId"
                        [selectItems]="credentialCategoryList"
                        fieldName="name"
                        fieldId="id"
                        placeholder="Select Category"
                        [isSearchable]="true"
                        [selectedFieldId]="selectedCredentialCategory"
                        [formName]="nurseCredentialForm"
                        (onChangeSelect)="onCredentialCategoryChange($event)"
                        requiredLableName="Credential Category"
                        [isRequired]="true"
                        controlName="credentialCategoryId"
                        [isMultiple]="false"
                        lableName="Credential Category"
                    >
                    </app-selectbox>
                </div>
                <div class="form-group dropdown-box" style="z-index: 7">
                    <app-selectbox
                        formControlName="credentialTypeId"
                        [selectItems]="credentialTypeList"
                        fieldName="name"
                        fieldId="id"
                        placeholder="Select Credential Name"
                        [isSearchable]="true"
                        [selectedFieldId]="selectedCredentialType"
                        [formName]="nurseCredentialForm"
                        (onChangeSelect)="onCredentialTypeChange($event)"
                        requiredLableName="Credential Name"
                        [isRequired]="true"
                        controlName="credentialTypeId"
                        [isMultiple]="false"
                        lableName="Credential Name"
                    >
                    </app-selectbox>
                </div>

                <div class="col-6">
                    <div class="form-group dropdown-box">
                        <app-selectbox
                            formControlName="stateId"
                            [selectItems]="stateList"
                            fieldName="name"
                            fieldId="id"
                            placeholder="Select State"
                            [isSearchable]="true"
                            [selectedFieldId]="selectedState"
                            [formName]="nurseCredentialForm"
                            (onChangeSelect)="onStateChange($event)"
                            requiredLableName="State"
                            [isRequired]="true"
                            controlName="stateId"
                            [isMultiple]="false"
                            lableName="State of issue"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div class="col-6">
                    <app-inputbox
                        type="text"
                        formControlName="credentialNumber"
                        [parentForm]="nurseCredentialForm"
                        label="Credential number"
                        [isWhiteSpace]="true"
                        [min]="1"
                        fieldName="credentialNumber"
                        [isRequired]="false"
                    ></app-inputbox>
                </div>
                <div [ngClass]="!isCredentialDoesNotExpire ? 'col-6' : 'col-12'">
                    <div class="form-group date-picker-control">
                        <app-datepicker
                            controlName="issuedDate"
                            fieldName="issuedDate"
                            fieldId="issuedDate"
                            [selectedFieldDate]="selectedIssueDate"
                            (onDateSelect)="onDateSelectChange($event, 'issue')"
                            requiredLableName="Issue date"
                            lableName="Issue date"
                            [isRequired]="false"
                            [formName]="nurseCredentialForm"
                            [displayPastDate]="true"
                        >
                        </app-datepicker>
                    </div>
                </div>
                <div class="col-6" *ngIf="!isCredentialDoesNotExpire">
                    <div class="form-group date-picker-control">
                        <app-datepicker
                            controlName="expiryDate"
                            fieldName="expiryDate"
                            fieldId="expiryDate"
                            [selectedFieldDate]="selectedExpiryDate"
                            (onDateSelect)="onDateSelectChange($event, 'expiry')"
                            requiredLableName="Expiration date"
                            lableName="Expiration date"
                            [isRequired]="true"
                            [formName]="nurseCredentialForm"
                        >
                        </app-datepicker>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-9">
                        <div class="card mt-3 rounded-3">
                            <div class="row ps-2 py-2">
                                <div class="col-1 mt-1">
                                    <img src="assets/images/file-logo.jpg" height="45px" />
                                </div>
                                <div class="col-8 ms-3 d-flex flex-column justify-content-center">
                                    <label class="fw-normal"> {{ type | titlecase }} File </label>
                                    <label
                                        [ngClass]="
                                            fileName
                                                ? 'text-secondary cursor-pointer hover-effect fw-normal'
                                                : 'text-secondary fw-normal'
                                        "
                                        (click)="openSelectedFile()"
                                    >
                                        {{ fileName ? fileName : 'PDF, DOCX, JPG or PNG Formats' }}
                                    </label>
                                </div>
                                <div class="col-2 d-flex justify-content-center align-items-center">
                                    <label
                                        _ngcontent-ful-c200=""
                                        data-action="change"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Change avatar"
                                        class="rounded-5 cursor-pointer"
                                    >
                                        <img src="assets/images/add-circle.png" height="20px" />

                                        <input
                                            type="file"
                                            style="display: none"
                                            #fileInput
                                            accept=".pdf, .jpg, .jpeg, .png"
                                            (change)="onSelectFile($event)"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="nurseCredentialFormDirective.submitted">
                            <span class="input-error" *ngIf="fileSize > 15000000"> File size exceeds 15MB limit. </span>
                            <span *ngIf="!fileName" class="input-error">Please Upload file.</span>
                            <span class="input-error" *ngIf="fileType && !validFileFormat.includes(fileType)">
                                Please select only valid file.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer p-3">
            <button
                class="btn btn-outline-primary fw-semibold px-4 px-md-5 mr-3 mr-md-4"
                type="button"
                (click)="close()"
            >
                Close
            </button>
            <button
                class="btn btn-primary fw-semibold px-4 px-md-5"
                type="submit"
                [disabled]="nurseCredentialForm.invalid || isDisable"
            >
                Save
            </button>
        </div>
    </form>
</div>
