import { NgModule } from '@angular/core';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { ConvertToUtcPipe } from './convert-to-utc.pipe';
import { MinutesToHoursPipe } from './minute-to-hour.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { CustomDatePipe, LocalDateTimePipe, RoleBasedTimezonePipe } from './local-date-time.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { TimezoneDifferencePipe } from './timezone-difference.pipe';

@NgModule({
    declarations: [
        DateAgoPipe,
        ConvertToUtcPipe,
        MinutesToHoursPipe,
        EllipsisPipe,
        LocalDateTimePipe,
        StripHtmlPipe,
        CustomDatePipe,
        RoleBasedTimezonePipe,
        TimezoneDifferencePipe,
    ],
    imports: [],
    exports: [
        DateAgoPipe,
        MinutesToHoursPipe,
        EllipsisPipe,
        LocalDateTimePipe,
        StripHtmlPipe,
        CustomDatePipe,
        RoleBasedTimezonePipe,
        TimezoneDifferencePipe,
    ],
})
export class PipesModule {}
