import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableRequestModelService } from './dataTableRequestModel.service';
import { ApiService } from './api.service';
import { API_HOSPITAL, API_JOB, API_SHIFT } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class ShiftService {
    constructor(private apiService: ApiService, private dataTableRequestModelService: DataTableRequestModelService) {}

    addSingleShift(res: any): Observable<any> {
        return this.apiService.post(API_SHIFT.POST_SINGLE_SHIFT, res);
    }
    updateShift(id: any, res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.PUT_SHIFT + id, res);
    }
    getJob(hospitalId: any, unitId?: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_SELECTED_LIST + '?hospitalId=' + hospitalId + '&unitId=' + unitId);
    }
    getJobWithUnitName(hospitalId: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_BY_UNIT_SELECTED_LIST + '?hospitalId=' + hospitalId);
    }
    getJobById(id: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_BY_ID + id);
    }
    getShiftList(shiftFilters: any, take: any, viewFor: any, hospitalId: any, unitId: any): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_SHIFTS_LIST +
                '?shiftFilters=' +
                shiftFilters +
                '&take=' +
                take +
                '&viewFor=' +
                viewFor +
                '&hospitalId=' +
                hospitalId +
                '&unitId=' +
                unitId
        );
    }
    getShifCliniciantList(shiftFilters: any, days: any, hospitalId: any, unitId: any): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_CLINICIAN_BY_SHIFT_LIST +
                '?shiftFilters=' +
                shiftFilters +
                '&days=' +
                days +
                '&hospitalId=' +
                hospitalId +
                '&unitId=' +
                unitId
        );
    }
    getShiftListForCalander(
        shiftFilters: any,
        days: any,
        hospitalId: any,
        unitId: any,
        jobIds: any,
        jobTypeIds: any,
        shiftManagerIds: any,
        shiftType: any,
        status: any
    ): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_SHIFTS_LIST +
                '?shiftFilters=' +
                shiftFilters +
                '&days=' +
                days +
                '&hospitalId=' +
                hospitalId +
                '&unitId=' +
                unitId +
                '&jobIds=' +
                jobIds +
                '&licenseTypeIds=' +
                jobTypeIds +
                '&shiftManagerIds=' +
                shiftManagerIds +
                '&shiftType=' +
                shiftType +
                '&status=' +
                status
        );
    }

    getActivityData(id: any, unitId: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_SHIFT_BY_HOSPITAL + id + '/' + unitId);
    }

    getDetailsByShiftId(id: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_SHIFT_BY_ID + id);
    }

    getSingleShiftDetailsById(id: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_SINGLE_SHIFT_DETAIL_BY_ID + id);
    }

    inviteStaff(res: any): Observable<any> {
        return this.apiService.post(API_SHIFT.POST_INVITE_STAFF_FOR_SHIFT, res);
    }

    changeStatus(res: any): Observable<any> {
        return this.apiService.post(API_SHIFT.POST_SHIFT_CLINICIAN_STATUS, res);
    }

    cancelShift(res: any): Observable<any> {
        return this.apiService.post(API_SHIFT.CANCEL_SHIFT, res);
    }

    deleteShift(id: any): Observable<any> {
        return this.apiService.delete(API_SHIFT.DELETE_SHIFT, id);
    }

    deleteSceduleEmployee(id: any, res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.DELETE_SHIFT_CLINCIAN + id, res);
    }

    getTopJobList(id: any, unitId: any): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_TOP_FIVE_JOB_SELECTED_LIST + '?hospitalId=' + id + '&unitId=' + unitId
        );
    }

    addOverTime(res: any): Observable<any> {
        return this.apiService.post(API_SHIFT.POST_SHIFT_CLINICIAN_OVERTIME, res);
    }

    getListOfShift(id: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_SHIFT_CLINICIAN_FEEDBACK_LIST + '?shiftId=' + id);
    }

    updatePayrateReq(res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.PUT_SHIFT_CLINICIAN_PAY_RATE_STATUS, res);
    }

    shiftGridList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForShiftList(
            `${API_SHIFT.GET_SHIFTS_GRID_LIST}`,
            req
        );
    }
    getListOfJobType(id: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_JOB_TYPE_BY_JOB_SELECTED_LIST + '?hospitalId=' + id);
    }

    addShiftTime(res: any): Observable<any> {
        return this.apiService.post(API_HOSPITAL.POST_HOSPITAL_SHIFT_TYPE, res);
    }

    getShiftTime(id: any): Observable<any> {
        return this.apiService.get(API_HOSPITAL.GET_HOSPITAL_SHIFT_TYPE + id);
    }

    updateShiftTime(id: any, res: any): Observable<any> {
        return this.apiService.put(API_HOSPITAL.PUT_HOSPITAL_SHIFT_TYPE + id, res);
    }

    getShiftTimeList(hospitalId: any, unitId: any, shiftType: any): Observable<any> {
        return this.apiService.get(
            API_HOSPITAL.GET_HOSPITAL_SHIFT_TYPE_SELECTED_LIST +
                hospitalId +
                '?unitId=' +
                unitId +
                '&shiftType=' +
                shiftType
        );
    }

    deleteShiftTime(id: any): Observable<any> {
        return this.apiService.delete(API_HOSPITAL.DELETE_HOSPITAL_SHIFT_TYPE, id);
    }

    addBulkShift(res: any): Observable<any> {
        return this.apiService.post(API_SHIFT.POST_BULK_SHIFT, res);
    }

    getShiftInspectorList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForShiftInspector(
            `${API_SHIFT.GET_SHIFT_INSPECTORS_lIST}`,
            req
        );
    }

    getCalendarList(obj: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_CALENDAR_LIST, obj);
    }

    getShiftInspectorCallOutDetails(reqObj: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_INSPECTION_OPEN_DETAILS + 'InspectionCalledoutDetail', reqObj);
    }

    getListOfShiftTimesSlots(
        marketId: any,
        companyId: any,
        hospitalId: any,
        licenseTypeIds: any,
        specialityIds: any
    ): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_SHIFT_TTIMES_SLOTS +
                '?marketId=' +
                marketId +
                '&companyId=' +
                companyId +
                '&hospitalId=' +
                hospitalId +
                '&licenseTypeIds=' +
                licenseTypeIds +
                '&specialityIds=' +
                specialityIds
        );
    }

    //InspectionOpenPositionDetail

    getShiftInspectionOpenPositionDetail(clinicianId: any, id: any): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_SHIFT_INSPECTION_OPEN_DETAILS + clinicianId + '/InspectionOpenPositionDetail?id=' + id
        );
    }

    // Inspection Missing Clock-In, Missing Clock-out, Completed, Scheduled, In Progress

    getShiftInspectionDetail(reqObj: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_INSPECTION_OPEN_DETAILS + 'InspectionDetail', reqObj);
    }
    // Inspection NCNS, Released

    getShiftInspectionNCNSDetail(reqObj: any): Observable<any> {
        return this.apiService.post(
            API_SHIFT.GET_SHIFT_INSPECTION_OPEN_DETAILS + 'InspectionNCNSNReleasedDetail',
            reqObj
        );
    }

    removePosition(): Observable<any> {
        return this.apiService.put(API_SHIFT.GET_SHIFT_INSPECTION_OPEN_DETAILS + '/CancelShift', {});
    }

    getReleaseReasonSelectList(): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_RELEASE_REASON_SELECT_LIST);
    }

    getUnscheduleReasonSelectList(): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_UNSCHEDULE_REASON_SELECT_LIST);
    }

    //opne RescheduleOpenShift

    getRescheduleOpenShift(id: any): Observable<any> {
        return this.apiService.get(API_SHIFT.GET_SHIFT_INSPECTION_OPEN_DETAILS + id + '/RescheduleOpenShift');
    }

    onDeclineExcuse(res: any): Observable<any> {
        return this.apiService.put(API_SHIFT.GET_SHIFT_BY_ID + API_SHIFT.DECLINE_EXCUSE, res);
    }

    getShiftPostionByShiftId(req: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_BY_ID + API_SHIFT.GET_SHIFT_POSITION_BY_ID, req);
    }

    getShiftPostionHistoryByShiftId(id: any, req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForStaff(
            API_SHIFT.GET_SHIFT_BY_ID + id + API_SHIFT.GET_SHIFT_POSITION_HISTORY_BY_ID,
            req
        );
    }

    getShiftAvailableNurses(req: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_BY_ID + API_SHIFT.GET_AVAILABLE_NURSE, req);
    }

    getOvertimeInformation(clinicianId: any, shiftId?: any): Observable<any> {
        return this.apiService.get(
            API_SHIFT.GET_SHIFT_OVERTIMEINFORMATION + '?ClinicianId=' + clinicianId + '&ShiftId=' + shiftId
        );
    }

    updateShiftNote(req: any): Observable<any> {
        return this.apiService.put(API_SHIFT.UPDATE_SHIFT_NOTES, req);
    }

    getNursesList(req: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_AVAILABLE_NURSE, req);
    }

    getShiftStatusCount(req: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_STATUS_COUNT, req);
    }

    getGroupCalendarList(obj: any): Observable<any> {
        return this.apiService.post(API_SHIFT.GET_SHIFT_COUNT, obj);
    }
}
