import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableRequestModelService } from './dataTableRequestModel.service';
import { ApiService } from './api.service';
import { API_CLINICIAN, API_JOB, API_RATECARD } from '../constants/api';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    constructor(
        private apiService: ApiService,
        private dataTableRequestModelService: DataTableRequestModelService,
        private httpService: HttpClient
    ) {}

    addJob(res: any): Observable<any> {
        return this.apiService.post(API_JOB.POST_JOB, res);
    }
    getJobTemplate(companyId: any, hospitalId: any): Observable<any> {
        if (companyId != null && hospitalId == null) {
            return this.apiService.get(API_JOB.GET_JOB_TEMPLATE_SELECTED_LIST + '?companyId=' + companyId);
        } else {
            return this.apiService.get(
                API_JOB.GET_JOB_TEMPLATE_SELECTED_LIST + '?companyId=' + companyId + '&hospitalId=' + hospitalId
            );
        }
    }
    getJobById(id: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_DETAIL_BY_ID + id + '/detail');
    }

    getJobEditDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_DETAIL_BY_ID + id);
    }
    getRateCard(hospitalId: any): Observable<any> {
        return this.apiService.get(API_RATECARD.GET_RATECARD_TEM_SELECTED_LIST + '?hospitalId=' + hospitalId);
    }
    getBGCheck(): Observable<any> {
        return this.apiService.get(API_RATECARD.GET_BACKGROUNGCHECK_TYPE_SELECTED_LIST);
    }
    getJobList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForJob(API_JOB.GET_JOB_LIST, req);
    }
    jobFilter(
        SearchText: any,
        skip: number,
        take: number,
        hospitalId: number | undefined,
        licenseTypeId: number | null,
        specialityIds: number[],
        unitId: number | null,
        jobListStatus: number | null
    ): Observable<any> {
        let baseUrl = `${API_JOB.GET_JOB_LIST}?Skip=${skip}&Take=${take}`;
        if (SearchText !== undefined) {
            baseUrl += `&SearchText=${SearchText}`;
        }
        if (hospitalId !== undefined) {
            baseUrl += `&hospitalId=${hospitalId}`;
        }

        if (licenseTypeId !== null && licenseTypeId !== undefined) {
            baseUrl += `&licenseTypeId=${licenseTypeId}`;
        }

        if (unitId !== null && unitId !== undefined) {
            baseUrl += `&unitId=${unitId}`;
        }

        if (jobListStatus !== null && jobListStatus !== undefined) {
            baseUrl += `&jobListStatus=${jobListStatus}`;
        }

        if (specialityIds && specialityIds.length > 0) {
            const specialityIdsString = specialityIds.join(',');
            baseUrl += `&specialityIds=${specialityIdsString}`;
        }

        return this.apiService.get(baseUrl);
    }

    searchJobs(
        search: any,
        skip: number,
        take: number,
        hospitalId: any,
        licenseTypeId: number | null,
        specialityIds: number[],
        unitId: number | null,
        jobListStatus: number | null
    ): Observable<any> {
        let baseUrl = `${API_JOB.GET_JOB_LIST}?Skip=${skip}&Take=${take}&SearchText=${search}&hospitalId=${hospitalId}`;
        if (licenseTypeId !== null && licenseTypeId !== undefined) {
            baseUrl += `&licenseTypeId=${licenseTypeId}`;
        }

        if (unitId !== null && unitId !== undefined) {
            baseUrl += `&unitId=${unitId}`;
        }

        if (jobListStatus !== null && jobListStatus !== undefined) {
            baseUrl += `&jobListStatus=${jobListStatus}`;
        }

        if (specialityIds && specialityIds.length > 0) {
            const specialityIdsString = specialityIds.join(',');
            baseUrl += `&specialityIds=${specialityIdsString}`;
        }

        return this.apiService.get(baseUrl);
    }
    searchList(search: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_LIST + '?SearchText=' + search);
    }
    getJobListById(id: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_JOB_BY_ID + id);
    }

    bookmarkUnitJobs(res: any): Observable<any> {
        return this.apiService.put(API_JOB.BOOKMARK_JOB, res);
    }

    updateJob(id: any, res: any): Observable<any> {
        return this.apiService.put(API_JOB.PUT_JOB + id, res);
    }
    getPipelineList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForPipeline(API_JOB.GET_JOB_PIPELINE, req);
    }

    getDocumentationList(req: any): Observable<any> {
        return this.apiService.get(
            `${API_JOB.GET_JOB_DOCUMENTATION}?Skip=${req.Skip}&Take=${req.Take}&clinicianId=${req.clinicianId}&jobId=${req.jobId}&jobApplicantId=${req.jobApplicantId}`
        );
    }
    getClinicianDetailById(id: any, jobid: any, shiftId: any, hospitalId: any): Observable<any> {
        return this.apiService.get(
            API_JOB.GET_CLINICIAN_BY_ID +
                'profile' +
                '?clinicianId=' +
                id +
                '&jobId=' +
                jobid +
                '&shiftId=' +
                shiftId +
                '&hospitalId=' +
                hospitalId
        );
    }

    getUnfulfilledList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForPipeline(API_JOB.GET_JOB_UNFULFILLED, req);
    }
    getHiredList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForPipeline(API_JOB.GET_JOB_HIRED, req);
    }
    getJobInterviewsListByDate(marketId: any, companyId: any, hospitalId: any, date: any): Observable<any> {
        return this.apiService.get(
            API_JOB.GET_INTERVIEW_BY_DATE +
                '?marketId=' +
                marketId +
                '&companyId=' +
                companyId +
                '&hospitalId=' +
                hospitalId +
                '&date=' +
                date
        );
    }

    getCalendarList(
        marketId: any,
        companyId: any,
        hospitalId: any,
        isShowWeekData: any,
        startDate: any,
        endDate: any
    ): Observable<any> {
        return this.apiService.get(
            API_JOB.GET_CALENDAR_LIST +
                '?marketId=' +
                marketId +
                '&companyId=' +
                companyId +
                '&hospitalId=' +
                hospitalId +
                '&isShowWeekData=' +
                isShowWeekData +
                '&startDate=' +
                startDate +
                '&endDate=' +
                endDate
        );
    }

    getJobTitleSelectList(marketId: any, companyId: any, hospitalId: any, stage?: any) {
        let url = API_JOB.GET_JOB_TITLE_SELECT_LIST + '?marketId=' + marketId;

        if (companyId) {
            url += '&companyId=' + companyId;
        }

        if (hospitalId) {
            url += '&hospitalId=' + hospitalId;
        }

        if (stage) {
            url += '&stage=' + stage;
        }

        return this.apiService.get(url);
    }

    getJobNurseSelectList(marketId: any, companyId: any, hospitalId: any, stage?: any, callingFrom?: any) {
        let url = API_JOB.GET_JOB_NURSE_SELECT_LIST + '?marketId=' + marketId;

        if (companyId) {
            url += '&companyId=' + companyId;
        }

        if (hospitalId) {
            url += '&hospitalId=' + hospitalId;
        }

        if (stage) {
            url += '&stage=' + stage;
        }

        if (callingFrom) {
            url += '&callingFrom=' + callingFrom;
        }

        return this.apiService.get(url);
    }

    getJobScreeningItemSelectList(
        marketId: any,
        companyId: any,
        hospitalId: any,
        stage: any,
        callingFrom: any,
        documentType?: any
    ) {
        let url = API_JOB.GET_JOB_SCREENING_ITEM_SELECT_LIST + '?marketId=' + marketId;

        if (companyId) {
            url += '&companyId=' + companyId;
        }

        if (hospitalId) {
            url += '&hospitalId=' + hospitalId;
        }

        if (stage) {
            url += '&stage=' + stage;
        }

        if (callingFrom) {
            url += '&callingFrom=' + callingFrom;
        }

        if (documentType) {
            url += '&documentType=' + documentType;
        }
        return this.apiService.get(url);
    }

    getJobTitleDropdownList(req: any): Observable<any> {
        return this.apiService.get(
            `${API_JOB.GET_JOB_TITLE_DROPDOWN_LIST}?marketId=${req.marketId}&companyId=${req.companyId}&hospitalId=${req.hospitalId}`,
            req
        );
    }
    getClinicianDropdownList(req: any): Observable<any> {
        return this.apiService.get(
            `${API_JOB.GET_CLINICIAN_DROPDOWN_LIST}?marketId=${req.marketId}&companyId=${req.companyId}&hospitalId=${req.hospitalId}&callingFrom=${req.callingFrom}`,
            req
        );
    }
    getDocumentDropdownList(req: any): Observable<any> {
        return this.apiService.get(
            `${API_JOB.GET_DOCUMENT_DROPDOWN_LIST}?marketId=${req.marketId}&companyId=${req.companyId}&hospitalId=${req.hospitalId}`,
            req
        );
    }

    getShiftTemplate(companyId: any, hospitalId: any): Observable<any> {
        if (companyId != null && hospitalId == null) {
            return this.apiService.get(API_JOB.GET_SHIFT_TEMPLATE_SELECTED_LIST + '?companyId=' + companyId);
        } else {
            return this.apiService.get(
                API_JOB.GET_SHIFT_TEMPLATE_SELECTED_LIST + '?companyId=' + companyId + '&hospitalId=' + hospitalId
            );
        }
    }

    getPreboardingList(req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForPipeline(API_JOB.GET_JOB_PREBOARDING, req);
    }

    getImmediateHireList(req: any) {
        return this.apiService.post(API_JOB.GET_JOB_IMMEDIATE, req);
    }

    getInterviewCount(marketId: any, companyId: any, hospitalId: any): Observable<any> {
        return this.apiService.get(
            API_JOB.GET_INTERVIEW_COUNT +
                '?marketId=' +
                marketId +
                '&companyId=' +
                companyId +
                '&hospitalId=' +
                hospitalId
        );
    }

    getScreeningCount(marketId: any, companyId: any, hospitalId: any): Observable<any> {
        let url = API_JOB.GET_SCREENING_COUNT + '?marketId=' + marketId;

        if (companyId) {
            url += '&companyId=' + companyId;
        }

        if (hospitalId) {
            url += '&hospitalId=' + hospitalId;
        }

        return this.apiService.get(url);
    }

    getCandidatesStageCount(marketId: any, companyId: any, hospitalId: any) {
        return this.apiService.get(
            API_JOB.GET_CANDIDATE_COUNT +
                '?MarketId=' +
                marketId +
                '&CompanyId=' +
                companyId +
                '&HospitalId=' +
                hospitalId
        );
    }

    getNurseSelectList(reqObj: any) {
        return this.apiService.post(API_CLINICIAN.GET_NURSE_SELECT_LIST, reqObj);
    }

    getDocumentTitleSelectList(reqObj: any) {
        return this.apiService.post(API_CLINICIAN.GET_DOCUMENT_TITLE_SELECT_LIST, reqObj);
    }

    getClinicianDocumentDownloadExcel(model: any, isSuccess?: boolean): Observable<any> {
        return this.httpService.post(`${environment.API}clinician/ExportToExcel`, model, {
            responseType: 'blob' as 'json', // Important for file downloads
        });
    }

    rejectJobApplicant(reqObj: any): Observable<any> {
        return this.apiService.post(API_JOB.REJECT_JOB_APPLICANT, reqObj);
    }
}
