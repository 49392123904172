import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_JOB_PROFILE_SUMMARY } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class ClinicianProfileService {
    constructor(private apiService: ApiService) {}

    getClinicianLicenseDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_LICENSE_DETAILS_BY_ID + id);
    }

    getClinicianEducationDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_EDUCATION_DETAILS_BY_ID + id);
    }

    getClinicianBackgroundCheckDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_BACKGROUND_CHECK_DETAILS_BY_ID + id);
    }

    getClinicianExperienceDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_EXPERIENCE_DETAILS_BY_ID + id);
    }

    getClinicianCredentialDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_CREDENTIAL_DETAILS_BY_ID + id);
    }

    updateInterviewOfClinician(id: any, res: any): Observable<any> {
        return this.apiService.put(API_JOB_PROFILE_SUMMARY.PUT_INTERVIEW + id, res);
    }

    getInterviewofClinician(jobId: any, clinicianId: any, jobApplicantId: any): Observable<any> {
        return this.apiService.get(
            API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_INTERVIEW_DETAIL +
                '?jobId=' +
                jobId +
                '&clinicianId=' +
                clinicianId +
                '&jobApplicantId=' +
                jobApplicantId
        );
    }
    getClinicianDocumentationDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_DOCUMENTATION_DETAILS_BY_ID + id);
    }

    getClinicianEmpolyeementFormDetailById(id: any): Observable<any> {
        return this.apiService.get(API_JOB_PROFILE_SUMMARY.GET_CLINICIAN_EMPLOYMENT_FORM_DETAILS_BY_ID + id);
    }
}
