import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableRequestModelService } from './dataTableRequestModel.service';
import { ApiService } from './api.service';
import { API_CONTACTS, API_HOSPITAL } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class HospitalService {
    constructor(private apiService: ApiService, private dataTableRequestModelService: DataTableRequestModelService) {}

    getHospitalList(): Observable<any> {
        return this.apiService.get(API_HOSPITAL.GET_LIST_OF_HOSPITAL);
    }

    addHospital(res: any): Observable<any> {
        return this.apiService.post(API_HOSPITAL.POST_HOSPITAL, res);
    }

    updateHospital(id: any, res: any): Observable<any> {
        return this.apiService.put(API_HOSPITAL.PUT_HOSPITAL + id, res);
    }

    deleteHospital(id: any): Observable<any> {
        return this.apiService.delete(API_HOSPITAL.DELETE_HOSPITAL, id);
    }

    addUpdateHospitalContact(res: any): Observable<any> {
        return this.apiService.post(API_HOSPITAL.POST_HOSPITAL_CONTACT, res);
    }

    addUpdateHospitalAdmin(res: any): Observable<any> {
        return this.apiService.post(API_HOSPITAL.POST_HOSPITAL_ADMIN_CONTACT, res);
    }

    getHospital(id: any): Observable<any> {
        return this.apiService.get(API_HOSPITAL.GET_HOSPITAL_BY_ID + id);
    }

    getHospitalDetailsById(id: any, companyId: any): Observable<any> {
        return this.apiService.get(API_HOSPITAL.GET_HOSPITAL_BY_ID + id + '?companyId=' + companyId);
    }

    getContactList(hospitalId: any, req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestService(
            API_CONTACTS.GET_CONTACTS_LIST + '?hospitalId=' + hospitalId,
            req
        );
    }
    getHospitalSelectList(id: any, marketId: any, callingFrom: any): Observable<any> {
        let callingFromParam = callingFrom ? '&callingFrom=' + callingFrom : '';
        return this.apiService.get(
            API_HOSPITAL.GET_HOSPITAL_LIST + '?companyId=' + id + '&marketId=' + marketId + callingFromParam
        );
    }

    getHospitalSelectListByJob(id: any, marketId: any): Observable<any> {
        return this.apiService.get(
            API_HOSPITAL.GET_HOSPITAL_LIST_FOR_JOB + '?companyId=' + id + '&marketId=' + marketId
        );
    }

    getHealthcareList(res: any): Observable<any> {
        return this.apiService.post(API_HOSPITAL.POST_HEALTHCARE_LIST, res);
    }

    getHealthcareListByJob(res: any): Observable<any> {
        return this.apiService.post(API_HOSPITAL.POST_HEALTHCARE_LIST_FOR_JOB, res);
    }
}
