import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormGroup, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const CUSTOM_TEXTAREA_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextareaComponent),
    multi: true,
};

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.css'],
    providers: [CUSTOM_TEXTAREA_VALUE_ACCESSOR],
})
export class TextareaComponent implements ControlValueAccessor, OnInit {
    @Input() label: string;
    @Input() isRequired: boolean = false;
    @Input() class: string = 'mt-1 form-control';
    @Input() fieldName: string;
    @Input() isDisabled: boolean = false;
    @Input() isShowCharacterCount: boolean = false;
    @Input() rows: number = 3;
    @Input() cols: number = 250;
    @Input() max: number = 100000;
    @Input() min: number = 1;
    @Input() parentForm: any; // You can use specific FormGroup type if available

    value: any = '';

    @Output() blur: EventEmitter<void> = new EventEmitter<void>();
    @Output() characterCount: EventEmitter<number> = new EventEmitter<number>();
    itemLength: number;
    @Input() isMinimum: boolean = false;
    @Input() isNumber: boolean = false;
    @Input() placeholder: any;
    constructor() {}

    ngOnInit(): void {}

    onChange(event: Event) {
        const value = (event.target as HTMLTextAreaElement).value;
        this.writeValue(value);
        this.propagateChange(value);

        this.itemLength = value.length;
        if (this.isShowCharacterCount) {
            this.characterCount.emit(value.length);
        }
    }

    writeValue(value: any): void {
        this.value = value;
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
