import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { DataTableModel, DisplayElement } from 'src/app/providers/classes/helper.classes';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { CurrencyPipe, DatePipe, LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { CustomRendererService } from './renderer/custom-renderer.service';
import { EllipsisPipe } from 'src/app/pipes/ellipsis.pipe';
import { TreeRefreshService } from 'src/app/service/treeRefresh.service';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { CustomDatePipe, LocalDateTimePipe, RoleBasedTimezonePipe } from 'src/app/pipes/local-date-time.pipe';
import { PaginationService } from 'src/app/service/pagination.service';
import { TimezoneDifferencePipe } from 'src/app/pipes/timezone-difference.pipe';
import { getUtctoFacilityDate } from 'src/app/providers/utils';

export class ReponseModel {
    result: any;
    pageDetail: {
        skip: number;
        take: number;
        count: number;
        searchText: null;
        isActive: null;
    };
}

@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss'],
    providers: [LocalDateTimePipe, CustomDatePipe, RoleBasedTimezonePipe, TimezoneDifferencePipe],
})
export class DatatableComponent implements OnInit, OnChanges {
    @Input() showToggle: boolean = false;

    @Input() configDataTable: DataTableModel = new DataTableModel();

    getUrl: string;

    isServerSidePagination: boolean = false;

    isAllowPaging: boolean = true;

    sortOrder: any = [];

    @Input() isLoadData: boolean = false;

    isShowPaginationInfo: boolean = true;

    isSearching: boolean = false;

    @Input() isActive: boolean = true;

    @Input() filter: boolean = false;

    @Input() queryparam: any;
    @Input() requestObject: any;

    @Input() searchText: any = '';

    @Input() isQueryParam: boolean = false;
    @Input() isRequestObject: boolean = false;
    @Input() screenStatus: any;

    @Output() rowClickHandler = new EventEmitter();

    @Output() length = new EventEmitter();
    @Input() filterValue: any;
    dtOptions: any = {};
    rowIndex: number = 0;
    dtInstance: any;
    @ViewChild(DataTableDirective) datatableElement: DataTableDirective;
    dtTrigger: Subject<any> = new Subject();
    recordsCount: number = 0;
    isShiftData: boolean = false;
    filteredData: any[] = [];
    data: any[] = [];
    isLoading: boolean = false;

    @Input() isInherit: any = '';
    @Input() isInheritedShow: any = '';

    @Input() isMarketLevel: boolean = false;

    @Output() getInheritType: EventEmitter<any> = new EventEmitter();
    size: any;
    unsubscribe$ = new Subject<void>();

    @Input() isActionBtnShow: boolean = false;
    @Input() requestType: any = 'GET';
    labels: any[] = ['# In Progress', '# Unfulfilled', '# Hired'];
    @Output() getShiftData: EventEmitter<any> = new EventEmitter();

    @Input() isShowUnit: boolean;
    @Input() isJobPostingPermission: boolean = false;

    constructor(
        private apiService: ApiService,
        private ngxSpinnerService: NgxSpinnerService,
        private customRendererService: CustomRendererService,
        private titleCase: TitleCasePipe,
        private upperCase: UpperCasePipe,
        private lowerCase: LowerCasePipe,
        private pipeCurrencyInstance: CurrencyPipe,
        private ellipsis: EllipsisPipe,
        private treeRefreshService: TreeRefreshService,
        private localDateTime: LocalDateTimePipe,
        private dateUtilService: DateUtilService,
        private datePipe: DatePipe,
        private customDatePipe: CustomDatePipe,
        private roleBasedTimezone: RoleBasedTimezonePipe,
        private timezoneDiff: TimezoneDifferencePipe,
        private PaginationService: PaginationService
    ) {}

    ngOnInit(): void {
        this.getUrl = this.configDataTable?.displayBlock?.getUrl ?? '';
        this.isServerSidePagination = this.configDataTable?.displayBlock?.isServerSidePagination ?? false;
        this.isAllowPaging = this.configDataTable?.displayBlock?.isAllowPaging ?? false;
        this.sortOrder = this.configDataTable?.displayBlock?.sortOrder ?? [];
        this.isLoadData = this.configDataTable?.displayBlock?.isLoadData ?? false;
        this.isShowPaginationInfo = this.configDataTable?.displayBlock?.isShowPaginationInfo ?? false;
        this.isSearching = this.configDataTable?.displayBlock?.isSearching ?? false;
        this.isShiftData = this.configDataTable?.displayBlock?.isShiftData ?? false;
        this.filterValue = this.configDataTable?.displayBlock?.filterValue;
        this.isActive = this.configDataTable?.displayBlock?.isActive ?? false;
        this.queryparam = this.configDataTable?.displayBlock?.queryparam ?? '';
        this.isQueryParam = this.configDataTable?.displayBlock?.isQueryParam ?? false;
        this.screenStatus = this.configDataTable?.displayBlock?.screenStatus ?? '';
        this.treeRefreshService
            .getSelectedCompanyObservable()
            .pipe(
                distinctUntilChanged(), // This will ensure that only distinct values are processed
                takeUntil(this.unsubscribe$)
            )
            .subscribe((ids: any) => {
                this.size = ids?.size;
            });

        if (this.isShiftData) {
            this.dataTableBindedShift();
        } else {
            this.dataTableBinded();
        }
    }

    rerender_datatable(): void {
        if (this.datatableElement && this.datatableElement.dtInstance) {
            this.datatableElement.dtInstance.then((dtInstance: any) => {
                dtInstance.ajax.reload();
            });
        }
    }
    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnChanges() {
        if (this.isLoadData) {
            this.queryparam = this.configDataTable?.displayBlock?.queryparam ?? '';
            if (this.isActionBtnShow?.toString() != '') {
                if (this.datatableElement && this.datatableElement.dtInstance) {
                    this.datatableElement.dtInstance.then((dtInstance: any) => {
                        const column = dtInstance.column(':contains("Actions")'); // Assuming you want to toggle the third column
                        column.visible(!this.isActionBtnShow);
                    });
                }
            }
            if (this.isShowUnit?.toString() != '') {
                if (this.datatableElement && this.datatableElement.dtInstance) {
                    this.datatableElement.dtInstance.then((dtInstance: any) => {
                        const column = dtInstance.column(':contains("Entity")'); // Assuming you want to toggle the third column
                        column.visible(this.isShowUnit);
                    });
                }
            }
            setTimeout(() => {
                if (this.datatableElement && this.datatableElement.dtInstance) {
                    this.datatableElement.dtInstance.then((dtInstance: any) => {
                        const column = dtInstance.column(':contains("# In Progress")');
                        column.visible(this.isJobPostingPermission);
                    });
                }
            });

            this.rerender_datatable();
        }

        if ((this.filterValue != null || this.isActive != null) && !this.isServerSidePagination) {
            this.updateFilteredData();
        }

        if (this.isInherit?.toString() != '') {
            this.queryparam = this.configDataTable?.displayBlock?.queryparam ?? '';
            this.dataTableBinded();
            this.rerender_datatable();
            if (this.isInheritedShow) {
                const isInherit = this.isInheritedShow;

                // Update column visibility based on isInherit
                this.updateColumnVisibility(isInherit);
                // Proceed to refresh or reinitialize the DataTable

                // Make sure this accounts for the updated column visibility
            }
        }
    }

    prepareDataTableOptions(): void {
        this.dataTableBinded();
    }

    refreshDataTable(): void {
        // Trigger the DataTable to refresh. This method should be called after dtOptions is updated.
        if (this.datatableElement && this.datatableElement.dtInstance) {
            this.datatableElement.dtInstance.then((dtInstance: any) => {
                dtInstance.destroy(); // Destroy the current instance
                this.dtTrigger.next(true); // Trigger reinitialization with updated options
            });
        }
    }

    updateColumnVisibility(isInherit: boolean): void {
        if (this.datatableElement && this.datatableElement.dtInstance) {
            this.datatableElement.dtInstance.then((dtInstance: any) => {
                // Directly show/hide the "Inherited?" column using the DataTable API
                // Array of column visibility updates
                const columnsToUpdate = [
                    { columnName: 'Inherited ?', isVisible: isInherit },
                    { columnName: 'Entity', isVisible: this.isShowUnit },
                ];

                columnsToUpdate.forEach((columnUpdate) => {
                    const column = dtInstance.column(`:contains("${columnUpdate.columnName}")`); // Selector for the column header
                    if (column) {
                        column.visible(columnUpdate.isVisible); // Update visibility dynamically
                    }
                });

                // const column = dtInstance.column(':contains("Inherited ?")'); // Adjust selector as needed
                // if (column) {
                //     column.visible(isInherit);
                // }
            });
        }
    }

    dataTableBinded() {
        const self = this;
        this.dtOptions = {
            serverSide: this.isServerSidePagination,
            processing: false,
            paging: this.isAllowPaging,
            searching: this.isSearching,
            language: {
                emptyTable: 'No data available',
                loadingRecords: '&nbsp;',
                search: '',
                searchPlaceholder: 'Search',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">',
                },
                info: 'Showing page _PAGE_ of _PAGES_',
            },
            displayStart: self.rowIndex,
            pagingType: 'full_numbers',
            pageLength: this.PaginationService.getRecordsPerPage(),
            lengthMenu: environment.paging.lengthMenu,
            order: this.sortOrder,
            info: this.isShowPaginationInfo,

            ajax: (dataTablesParameters: any, callback: any) => {
                self.dataTableAjaxCall(dataTablesParameters, callback, this.requestType, self);
            },
            drawCallback: function () {
                self.dataTablePagging();

                // Add page size change event listener
                if (self.datatableElement && self.datatableElement.dtInstance) {
                    self.datatableElement.dtInstance.then((dtInstance: any) => {
                        dtInstance.on('length.dt', (e: any, settings: any, len: number) => {
                            self.PaginationService.setRecordsPerPage(len);
                        });
                    });
                }
            },
            columns: this.createColumns(),
            rowCallback(row: any, data: any) {
                let obj = {
                    row: row,
                    data: data,
                };
                self.customRowCallback(obj);
            },
        };
    }

    private createColumns(): any[] {
        // Dynamically create column definitions using a loop
        const columns: any = [];

        if ((this.configDataTable?.displayBlock?.rows?.length ?? 0) > 0) {
            this.configDataTable?.displayBlock?.rows?.forEach((col: DisplayElement) => {
                if (col?.hidden == true) {
                } else if (col.type === 'date') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getDateFormatRenderer(col?.name, col?.dateFormat, col?.label),
                    });
                } else if (col.type === 'number') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        ngPipeInstance: this.pipeCurrencyInstance,
                        ngPipeArgs: ['USD', 'symbol'],
                        className: 'dataTable-column-align',
                    });
                } else if (col.type === 'icon') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.customRendererService.getIconRenderer(),
                    });
                } else if (col.type === 'status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getStatusRenderer(),
                    });
                } else if (col.type === 'bgColor') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getBGColorRenderer(),
                    });
                } else if (col.type === 'level') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getLevelRenderer(col.label),
                    });
                } else if (col.type === 'modifiedBy') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getLastModifiedByRenderer(),
                    });
                } else if (col.type === 'action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getActionRenderer(
                            col?.actions,
                            this.configDataTable?.displayBlock?.screenStatus,
                            col?.label
                        ),
                    });
                } else if (col.type === 'website') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                    });
                } else if (col.type === 'email') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                    });
                } else if (col.type === 'name') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getUsernameRenderer(),
                    });
                } else if (col.type === 'full-name') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getFullNameRenderer(),
                    });
                } else if (col.type === 'base-location') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getBaseLocationRenderer(),
                    });
                } else if (col.type === 'updatedBy') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        visible: col.visible ? true : false,
                    });
                } else if (col.type === 'inherited') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        visible: col.visible == true ? true : false,
                        render: this.getInheriteRenderer(),
                    });
                } else if (col.type === 'facility') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        visible: col.visible == true ? true : false,
                        render: this.getCombineFacilityRenderer(col.label),
                    });
                } else if (col.type === 'time') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getTimeRenderer(),
                    });
                } else if (col.type === 'city-state') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getCityStateRenderer(),
                    });
                } else if (col.type === 'totalHour') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getTotalHourRenderer(),
                    });
                } else if (col.type === 'pipeline-status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getPipelineStatusRenderer(),
                    });
                } else if (col.type === 'credential-status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getCredentialStatusRenderer(),
                    });
                } else if (col.type === 'candidate-status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getCandidiateStatusRenderer(),
                    });
                } else if (col.type === 'document-status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getDocumentStatusRenderer(),
                    });
                } else if (col.type === 'interview-status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getInterviewStatusRenderer(),
                    });
                } else if (col.type === 'dateUTCToLocal') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: (data: any, type: any, row: any) => {
                            if (col?.visibleFor != 'license') {
                                const renderedData = this.getExpiryDateRenderer(col.label, col?.visibleFor)(
                                    data,
                                    type,
                                    row
                                );
                                if (renderedData === 'n/a' || renderedData == '-') {
                                    return renderedData;
                                }
                            }
                            const today = new Date().toISOString().split('T')[0];
                            const expiryDate = row?.expiryDate?.split('T')[0];
                            if (col.label == 'Exp. Date' && expiryDate <= today) {
                                return `<span style="color: red;">${this.returnPipeInstance(col.pipe).transform(
                                    data,
                                    ...['ordinal']
                                )}</span>`;
                            }
                            if (col?.label == 'Date | Time') {
                                return this.datePipe.transform(data, ...['MMM d, y | h:mm a']);
                            }
                            const weekDay = row?.weekDay;

                            return weekDay
                                ? `<div> ${this.returnPipeInstance(col.pipe).transform(
                                      data,
                                      ...['ordinal'],
                                      row?.timezoneStandardName
                                  )}</div><div> ${weekDay}</div>`
                                : this.returnPipeInstance(col.pipe).transform(
                                      data,
                                      ...['ordinal'],
                                      row?.timezoneStandardName
                                  );
                        },
                    });
                } else if (col.type === 'rate') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getPricerateRenderer(col.label),
                    });
                } else if (col.type === 'shift-rate') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getCombinedRenderer(col.label),
                    });
                } else if (col.type === 'timeFormat') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getFormatTimeRender(col?.label),
                    });
                } else if (col.type === 'timeFormatForShiftTime') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getFormatShiftTimeRender(),
                    });
                } else if (col.type === 'timeFormatForPunched') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getFormatPunchedTimeRender(),
                    });
                } else if (col.type === 'position') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getPositionRenderer(col.label),
                    });
                } else if (col.type === 'shift-position') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getShiftStatusRenderer(),
                    });
                } else if (col.type === 'multi-licenses') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getMultipleLicenseRenderer(),
                    });
                } else if (col.type === 'phone') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getPhoneRenderer(),
                    });
                } else if (col.type === 'restricted-until') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getRestrictedUntilRenderer(),
                    });
                } else if (col.type === 'timesheet-status') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getTimesheetStatusRenderer(),
                    });
                } else if (col.type === 'job-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getJobActionRenderer(),
                    });
                } else if (col.type === 'shift-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getShiftActionRenderer(),
                    });
                } else if (col.type === 'candidate-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        render: this.getCandidateActionRenderer(),
                    });
                } else if (col.type === 'multi-credential') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getMultipleCredentialRenderer(),
                    });
                } else if (col.type === 'break-time') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getBreakTimeRenderer(),
                    });
                } else if (col.type === 'bulk-rate') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getBulkListRateRenderer(),
                    });
                } else if (col.type === 'year-month') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getYearMonthRenderer(),
                    });
                } else if (col.type === 'nurse-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: false,
                        width: col.colSize,
                        className: 'dataTable-column-align',
                        visible: col.visible ? true : false,
                        render: this.getActionRenderer(
                            col?.actions,
                            this.configDataTable?.displayBlock?.screenStatus,
                            col?.label
                        ),
                    });
                } else if (col.type === 'notes') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getNotesRenderer(col.maxLength),
                    });
                } else if (col.type === 'checkbox-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getCheckBoxActionRenderer(),
                    });
                } else if (col.type === 'audit-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getAuditActionRenderer(),
                    });
                } else if (col.type === 'document') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getCombineDocumentRenderer(col.label),
                    });
                } else if (col.type === 'nurse-email') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getNurseEmailRenderer(),
                    });
                } else if (col.type === 'license-speciality') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getLicenseSpecialityRenderer(),
                    });
                } else if (col.type === 'timesheet-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getTimesheetRenderer(),
                    });
                } else if (col.type === 'lead-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        visible: col.visible ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getLeadNurseAction(),
                    });
                } else if (col.type === 'renewal-action') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getRenewalAction(),
                    });
                } else if (col.type === 'local-date') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getLocalDateRenderer(),
                    });
                } else if (col.type === 'hospital-name') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getCombinedRenderer(col.label),
                    });
                } else if (col.type === 'hiring-info') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getNurseLevelRenderer(),
                    });
                } else if (col.type === 'shift-info') {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        width: col.colSize,
                        orderable: col.sorting ? true : false,
                        className: 'dataTable-column-align',
                        render: this.getShiftInfoRenderer(),
                    });
                } else {
                    columns.push({
                        title: col.label,
                        data: col.name,
                        orderable: col.sorting ? true : false,
                        width: col.colSize,
                        ngPipeInstance: this.returnPipeInstance(col.pipe),
                        className: col.wordWrap
                            ? 'dataTable-column-align  wraptext'
                            : 'dataTable-column-align ellipsis',
                        render: this.getTextRenderer(col.maxLength),
                    });
                }
            });
        }
        // Add orderSequence to all columns
        for (const column of columns) {
            column.orderSequence = ['asc', 'desc'];
        }

        return columns;
    }

    getCandidateActionRenderer() {
        return (data: any, type: any, row: any) => {
            let viewBtn = `
                <button title="View" class="btn btn-icon btn-sm ms-1" action-type="view">
                    <em class="feather icon-eye text-primary" aria-hidden="true"></em>
                </button>
            `;

            let chatBtn = `
                <button title="Chat" class="btn btn-icon btn-sm ms-1" action-type="chat">
                    <em class="feather icon-message-square text-primary" aria-hidden="true"></em>
                </button>
            `;

            let editBtn = `
                <button title="Edit" class="btn btn-icon btn-sm ms-1" action-type="edit">
                    <em class="feather icon-edit text-primary" aria-hidden="true"></em>
                </button>
            `;

            let screeningBtn = row.hasScreeningStageCompeleted
                ? ''
                : `
                <button title="Screening" class="dropdown-item d-flex align-items-center btn p-2" action-type="screening">
                    <span class="d-block ms-1">Screening</span>
                </button>
            `;

            let documentBtn = row.hasDocumentationStageCompeleted
                ? ''
                : row.isAnyDocument
                ? `
                <button title="Document" class="dropdown-item d-flex align-items-center btn p-2" action-type="document">
                    <span class="d-block ms-1">Document</span>
                </button>
            `
                : '';

            let moreOptionsBtn = `
                <div class="dropdown">
                    <a class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-end text-center top-0 wd-20 cursor-pointer" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                    <div class="dropdown-menu end-100" style="left: auto !important" aria-labelledby="dropdownMenuLink">
                        ${screeningBtn}
                        ${documentBtn}
                        <button title="Chat" class="dropdown-item d-flex align-items-center btn p-2" action-type="chat">
                            <span class="d-block ms-1">Chat</span>
                        </button>
                    </div>
                </div>
            `;

            if (row?.stage === 'Hired') {
                return viewBtn + chatBtn;
            } else if (row?.stage === 'Screening and Preboarding') {
                return moreOptionsBtn;
            } else {
                return editBtn + chatBtn;
            }
        };
    }

    getDateFormatRenderer(datetype: any, dateFormat: any, label: any) {
        return (data: any, type: any, row: any) => {
            let finalDate = data;

            if (datetype === 'publishDate') {
                const publishDate = row.publishDate;
                const expiryDate = row.expiryDate ? row.expiryDate : ' ';
                finalDate = row.expiryDate ? `${publishDate} - ${expiryDate}` : publishDate;
            }
            if (label == 'Suspension range') {
                if (row?.suspendedFrom && row?.suspendedTill) {
                    const suspendedFrom = row?.suspendedFrom
                        ? this.roleBasedTimezone.transform(row?.suspendedFrom, 'ordinal', row?.timezoneStandardName)
                        : '';
                    const suspendedTill = row?.suspendedTill
                        ? this.roleBasedTimezone.transform(row?.suspendedTill, 'ordinal', row?.timezoneStandardName)
                        : '';
                    finalDate = `${suspendedFrom} - ${suspendedTill}`;
                    return finalDate;
                } else {
                    return '-';
                }
            }
            if (label == 'Expiration Date') {
                if (!row?.isCredentialDoesNotExpire && row?.expiryDate) {
                    return this.localDateTime.transform(row?.expiryDate, 'ordinal');
                } else if (row?.isCredentialDoesNotExpire && row?.expiryDate) {
                    return 'N/A';
                } else {
                    return '-';
                }
            }

            if (finalDate != null) {
                return this.localDateTime?.transform(new Date(finalDate), dateFormat);
            } else {
                return '';
            }
        };
    }

    getTextRenderer(maxLength: any) {
        return (data: any, type: any, row: any) => {
            if (data && data !== 'null') {
                return data.length > maxLength ? `<span title="${data}"> ${data} </span>` : data;

                //     return data.length > maxLength ? data : data;
            } else {
                return '';
            }
        };
    }

    getFormatTimeRender(label: any) {
        return (data: any, type: any, row: any) => {
            if (label === 'Date | Time') {
                const date = this.roleBasedTimezone.transform(row?.date, 'ordinal', row?.timezoneStandardName);
                const time =
                    this.roleBasedTimezone.transform(
                        row.fromTime ? row.fromTime : row.shiftFromTime ? row.shiftFromTime : row.startTime,
                        'shortTime',
                        row?.timezoneStandardName
                    ) +
                    ' - ' +
                    this.roleBasedTimezone.transform(
                        row.toTime
                            ? row?.isExtended
                                ? row?.extendedTime
                                : row.toTime
                            : row.shiftToTime
                            ? row?.isExtended
                                ? row?.extendedTime
                                : row.shiftToTime
                            : row.endTime,
                        'shortTime',
                        row?.timezoneStandardName
                    );
                const weekDay = row?.weekDay;
                return `<div> ${date}</div><div> ${time}</div><div> ${weekDay}</div>`;
            } else {
                if (data) {
                    const time =
                        this.roleBasedTimezone.transform(
                            row.fromTime ? row.fromTime : row.shiftFromTime ? row.shiftFromTime : row.startTime,
                            'shortTime',
                            row?.timezoneStandardName
                        ) +
                        ' - ' +
                        this.roleBasedTimezone.transform(
                            row.toTime
                                ? row?.isExtended
                                    ? row?.extendedTime
                                    : row.toTime
                                : row.shiftToTime
                                ? row?.isExtended
                                    ? row?.extendedTime
                                    : row.shiftToTime
                                : row.endTime,
                            'shortTime',
                            row?.timezoneStandardName
                        );

                    return time;
                } else {
                    return '';
                }
            }
        };
    }

    getYearMonthRenderer() {
        return (data: any, type: any, row: any) => {
            const formatYears = (years: number) => {
                if (years === 0) return '0 year';
                if (years === 1) return '1 year';
                return `${years} years`;
            };

            const formatMonths = (months: number) => {
                if (months === 0) return '';
                if (months === 1) return '1 month';
                return `${months} months`;
            };

            // Ensure row.year and row.month are numbers
            const years = Number(row.year);
            const months = Number(row.month);

            // If both years and months are 0, return 'N/A'
            if (years === 0 && months === 0) {
                return 'n/a';
            }

            let result = '';

            if (!isNaN(years) && years >= 0) {
                result = formatYears(years);
            }

            if (!isNaN(months) && months >= 0) {
                const monthsText = formatMonths(months);
                if (monthsText) {
                    result = result ? `${result} - ${monthsText}` : monthsText;
                }
            }

            return result;
        };
    }

    getFormatShiftTimeRender() {
        return (data: any, type: any, row: any) => {
            if (data) {
                const time = this.dateUtilService.convertUtcTimeToFacilityTime(data, false, row?.timezoneType);

                return time;
            } else {
                return '';
            }
        };
    }

    getFormatPunchedTimeRender() {
        return (data: any, type: any, row: any) => {
            if (data) {
                const time =
                    this.roleBasedTimezone.transform(row.clockIn, 'shortTime', row?.timezoneStandardName) +
                    ' - ' +
                    this.roleBasedTimezone.transform(row.clockOut, 'shortTime', row?.timezoneStandardName);

                return time;
            } else {
                return '';
            }
        };
    }

    getPositionRenderer(label: any) {
        return (data: any, type: any, row: any) => {
            if (label == '# Filled') {
                if (row.filledPosition < row.numberOfPosition) {
                    return (
                        '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">' +
                        row.filledPosition +
                        '</span>'
                    );
                } else if (row.filledPosition == row.numberOfPosition) {
                    return (
                        '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">' +
                        row.filledPosition +
                        '</span>'
                    );
                } else {
                    return row.filledPosition;
                }
            } else if (label == '# Open') {
                if (row.openPosition == 0) {
                    return (
                        '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">' +
                        row.openPosition +
                        '</span>'
                    );
                } else if (row.openPosition > 0) {
                    return (
                        '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">' +
                        row.openPosition +
                        '</span>'
                    );
                } else {
                    return row.openPosition;
                }
            } else if (label == '# Total') {
                if (row.filledPosition != row.numberOfPosition) {
                    return (
                        '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">' +
                        row.numberOfPosition +
                        '</span>'
                    );
                } else if (row.filledPosition == row.numberOfPosition) {
                    return (
                        '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">' +
                        row.numberOfPosition +
                        '</span>'
                    );
                } else {
                    return row.numberOfPosition;
                }
            } else if (label == '# Unfilled') {
                if (row.unfilledPosition == 0) {
                    return (
                        '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">' +
                        row.unfilledPosition +
                        '</span>'
                    );
                } else {
                    return (
                        '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">' +
                        row.unfilledPosition +
                        '</span>'
                    );
                }
            } else if (this.labels.includes(label)) {
                if (data != 0) {
                    return data;
                } else {
                    return '-';
                }
            }
        };
    }

    getInheriteRenderer() {
        return (data: any, type: any, row: any) => {
            return data === true
                ? `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">Yes</span>`
                : `<i class="fa fa-circle text-info" aria-hidden="true"></i><span class="ms-2">No</span>`;
        };
    }
    getTotalHourRenderer() {
        return (data: any, type: any, row: any) => {
            if (data) {
                const totalHour = data?.toString()?.includes('.')
                    ? data.toString().replace('.', 'h ') + 'min'
                    : data + 'h';
                return totalHour;
            } else {
                return '';
            }
        };
    }

    getPipelineStatusRenderer() {
        return (data: any, type: any, row: any) => {
            if (row.status === 'Approved') {
                return '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Approved</span>';
            } else if (
                row.status === 'Declined' ||
                row?.status == 'DNR' ||
                row?.status == 'Released' ||
                row?.status == 'Terminated'
            ) {
                return `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            } else if (row.status === 'Reproved') {
                return '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">Reproved</span>';
            } else if (row.status === 'In Progress' || row?.status === 'Suspended' || row?.status === 'Inactive') {
                return `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            } else if (row?.jobStatus === 'In Progress') {
                return `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">${row?.jobStatus}</span>`;
            } else if (row?.jobStatus === 'Hired') {
                return `<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">${row?.jobStatus}</span>`;
            } else if (row?.jobStatus == 'Preboarding') {
                return `<i class="fa fa-circle" aria-hidden="true" style="color:#f6851f"></i><span class="ms-2">${row?.jobStatus}</span>`;
            } else if (row?.jobStatus == 'Released' || row?.jobStatus == 'Withdraw' || row?.jobStatus == 'Declined') {
                return `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">${row?.jobStatus}</span>`;
            } else if (row?.status == 'Deleted (Lead)') {
                return `<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">Deleted (Lead)</span>`;
            } else if (row.status === 'Signed') {
                return '<i class="fa fa-circle" aria-hidden="true" style="color:#AFE5FF"></i><span class="ms-2">Signed</span>';
            } else if (row.status === 'Sent Back') {
                return '<i class="fa fa-circle" aria-hidden="true" style="color:#FCF8D5"></i><span class="ms-2">Sent Back</span>';
            } else {
                return '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">Unverified</span>';
            }
        };
    }

    getCredentialStatusRenderer() {
        return (data: any, type: any, row: any) => {
            const today = new Date().toISOString().split('T')[0];
            const expiryDate = row.expiryDate.split('T')[0];

            if (expiryDate <= today) {
                return '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">Expired</span>';
            } else if (row.status === 'Approved') {
                return '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Approved</span>';
            } else if (row.status === 'Declined' || row?.status == 'DNR') {
                return `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            } else if (row.status === 'In Progress' || row?.status === 'Suspended') {
                return `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            } else if (row.status === 'Reproved') {
                return '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">Reproved</span>';
            } else {
                return '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">Unverified</span>';
            }
        };
    }

    getCandidiateStatusRenderer() {
        return (data: any, type: any, row: any) => {
            if (row.status === 'Approved') {
                return '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Approved</span>';
            } else if (row.status === 'Declined' || row?.status == 'DNR' || row?.status == 'Terminated') {
                return `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            } else if (row.status === 'Scheduled') {
                return '<i class="fa fa-circle text-purple" aria-hidden="true"></i><span class="ms-2">Scheduled</span>';
            } else if (row.status === 'In Progress' || row?.status === 'Suspended') {
                return `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            } else if (row.status === 'Hired' || row?.isHired) {
                return '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Hired</span>';
            } else if (row.status === 'Pending') {
                return '<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">Pending</span>';
            } else if (row.isHired == false) {
                return `<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">Lead</span>`;
            } else {
                return `<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">${row.status}</span>`;
            }
        };
    }

    getDocumentStatusRenderer() {
        return (data: any, type: any, row: any) => {
            if (row.status === 'Pending') {
                return '<i class="fa fa-circle" aria-hidden="true" style="color:#f6851f"></i><span class="ms-2">Pending</span>';
            } else if (row.status === 'Signed') {
                return '<i class="fa fa-circle" aria-hidden="true" style="color:#AFE5FF"></i><span class="ms-2">Signed</span>';
            } else if (row.status === 'Approved') {
                return '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Approved</span>';
            } else if (row.status === 'Sent Back') {
                return '<i class="fa fa-circle" aria-hidden="true" style="color:#FCF8D5"></i><span class="ms-2">Sent Back</span>';
            } else if (row.status === 'Declined') {
                return '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">Declined</span>';
            } else if (row.status === 'In Progress') {
                return `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">In Progress</span>`;
            } else {
                return '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">Unverified</span>';
            }
        };
    }

    getInterviewStatusRenderer() {
        return (data: any, type: any, row: any) => {
            if (row.status === 'Approved') {
                return '<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Approved</span>';
            } else if (row.status === 'Declined') {
                return '<i class="fa fa-circle" style="color: #ff5045" aria-hidden="true"></i><span class="ms-2">Declined</span>';
            } else if (row.status === 'In Progress') {
                return '<i class="fa fa-circle" style="color: #e586ed" aria-hidden="true"></i><span class="ms-2">In Progress</span>';
            } else if (row.status === 'Scheduled') {
                return '<i class="fa fa-circle" style="color: #3adcff" aria-hidden="true"></i><span class="ms-2">Scheduled</span>';
            } else if (row.status === 'Pending') {
                return '<i class="fa fa-circle" style="color: #f6851f" aria-hidden="true"></i><span class="ms-2">Pending</span>';
            } else {
                return '<i class="fa fa-circle" style="color: #c9c9c9" aria-hidden="true"></i><span class="ms-2">Not Scheduled</span>';
            }
        };
    }

    getRestrictedUntilRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                if (row?.status === 'DNR') {
                    return row?.restrictedUntil;
                } else {
                    return this.roleBasedTimezone.transform(row?.suspendedTill, 'ordinal', row?.timezoneStandardName);
                }
            } else {
                return '';
            }
        };
    }

    getInterviewTimeRender() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                const formattedTime = row.startTime.substring(0, 5) + ' - ' + row.endTime.substring(0, 5);
                return formattedTime;
            } else {
                return '';
            }
        };
    }

    getMultipleLicenseRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                const nameArray = data.map((item: any) => item.acronym);
                if (data.length > 2) {
                    return `${nameArray.slice(0, 2).join(', ')} +${nameArray.length - 2}`;
                } else {
                    return nameArray.join(', ');
                }
            } else {
                return '';
            }
        };
    }

    getMultipleCredentialRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                const nameArray = data.map((item: any) => item);
                if (data.length > 1) {
                    return `${nameArray.slice(0, 1).join(', ')} +${nameArray.length - 1}`;
                } else {
                    return nameArray.join(', ');
                }
            } else {
                return '';
            }
        };
    }

    getBreakTimeRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null && row.breakTimeMinute !== undefined && row.breakTimeMinute !== null) {
                return `${row.breakTimeMinute} (${row.breakTimePaid == 'paid' ? 'Paid' : 'Unpaid'})`;
            } else if (data != null && row.breakTime !== undefined && row.breakTime !== null) {
                return `${row.breakTime} (${row.breakTimePaid == 'paid' ? 'Paid' : 'Unpaid'})`;
            } else {
                return 'n/a'; // Return null when neither value is available
            }
        };
    }

    getPhoneRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                return data?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
            } else {
                return '';
            }
        };
    }
    getPricerateRenderer(label: any) {
        return (data: any, type: any, row: any) => {
            let format;
            if (data != null) {
                if (label == 'Regular') {
                    if (row.billRateRegularPrice) {
                        format = `<div> Pay Rate: $ ${row.regularPrice} </div>  <div> Bill Rate: $ ${row.billRateRegularPrice} </div>  
 `;
                    } else {
                        format = `<div> Pay Rate: $ ${row.regularPrice} </div>`;
                    }
                }

                if (label == 'Weekend') {
                    if (row.billRateWeekendPrice) {
                        format = `<div> Pay Rate: $ ${row.weekendPrice} </div>  <div> Bill Rate: $ ${row.billRateWeekendPrice} </div>
 `;
                    } else {
                        format = `<div> Pay Rate: $ ${row.weekendPrice} </div>`;
                    }
                }

                if (label == 'Holiday') {
                    if (row.billRateHolidayPrice) {
                        format = `<div> Pay Rate: $ ${row.holidayPrice} </div>  <div> Bill Rate: $ ${row.billRateHolidayPrice} </div>
 `;
                    } else {
                        format = `<div> Pay Rate: $ ${row.holidayPrice} </div>`;
                    }
                }

                if (label == 'HolidayWeekend') {
                    if (row.billRateWeekendHolidayPrice) {
                        format = `<div>Holiday Rate: $ ${row.weekendHolidayPrice} </div>  <div> Bill Rate: $ ${row.billRateWeekendHolidayPrice} </div>
 `;
                    } else {
                        format = `<div>Holiday Rate: $ ${row.weekendHolidayPrice} </div>`;
                    }
                }

                return format;
            } else {
                return 'n/a';
            }
        };
    }

    getCombinedRenderer(label: any) {
        return (data: any, type: any, row: any) => {
            let format = '';

            if (label === 'Rate') {
                // Hourly Rate and Total Price
                if (row.price || row.totalPrice) {
                    format += `
                        <div> Pay Rate : ${
                            row.totalPrice !== undefined && row.totalPrice !== null
                                ? '$ ' + row.totalPrice + ` ($ ${row.price}  /h)`
                                : '-'
                        } </div>`;
                }
                // Conditionally display the Bill Rate label only if totalBillRate has a value
                if (row.totalBillRate !== undefined && row.totalBillRate !== null) {
                    format += `
                        <div> Bill Rate : $ ${row.totalBillRate + ` ($ ${row.billRate}  /h)`} </div>`;
                }
                if (row?.isShiftPriceBoosted) {
                    format += `<label class="text-primary fw-bolder">Boosted</label>`;
                }
                if (row?.isExtended) {
                    format += row?.isShiftPriceBoosted
                        ? ' | <label class="text-primary fw-bolder">Extended</label>'
                        : ` <label class="text-primary fw-bolder">Extended</label>`;
                }
            }
            if (label === 'Status') {
                format += row.status;
                if (row?.isShiftPriceBoosted) {
                    format += ` > <label class="text-primary fw-bolder">Boosted</label>`;
                }
                if (row?.isExtended) {
                    format += row?.isShiftPriceBoosted
                        ? ' | <label class="text-primary fw-bolder">Extended</label>'
                        : ` > <label class="text-primary fw-bolder">Extended</label>`;
                }
            }
            if (label === 'Bill Rate') {
                // Hourly Bill Rate and Total Bill Price
                if (row.billRate || row.totalBillRate) {
                    format += `<div> Hour Bill Rate: ${
                        row.billRate !== undefined && row.billRate !== null ? '$ ' + row.billRate : '-'
                    } </div>
                               <div> Total Bill Price: ${
                                   row.totalBillRate !== undefined && row.totalBillRate !== null
                                       ? '$ ' + row.totalBillRate
                                       : '-'
                               } </div>`;
                }
            }

            if (label === 'Facility') {
                let timezone: any = '';
                if (row.fromTime || row.shiftFromTime || row.startTime) {
                    // Convert UTC time to the target timezone
                    let convertedTime = getUtctoFacilityDate(
                        [
                            row.fromTime
                                ? row.fromTime
                                : row.shiftFromTime
                                ? row.shiftFromTime
                                : row.startTime
                                ? row.startTime
                                : new Date().toISOString(),
                            row.toTime
                                ? row.toTime
                                : row.shiftToTime
                                ? row.shiftToTime
                                : row.endTime
                                ? row.endTime
                                : new Date().toISOString(),
                        ],
                        row?.timezoneStandardName
                    );
                    timezone = this.timezoneDiff.transform(row?.timezoneName, convertedTime);
                }

                if (row.unitName != null) {
                    format += `<div> ${row.hospitalName} ${timezone}</div>`;
                    format += row.unitName != null ? `<div class="text-primary">${row.unitName}</div>` : '';
                } else if (row.hospitalName) {
                    format += `<div> ${row.hospitalName} ${timezone} </div>`;
                }
            }

            if (label === 'Healthcare System Name') {
                let isFacilityLevelLogin: any = sessionStorage.getItem('isFacilityLevelLogin');
                isFacilityLevelLogin = JSON.parse(isFacilityLevelLogin);
                let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
                isAgencyLevelLogin = JSON.parse(isAgencyLevelLogin);

                let hospitalName: any = sessionStorage.getItem('hospitalName');
                if (isFacilityLevelLogin && !isAgencyLevelLogin) {
                    format +=
                        row.healthcareSystemSize == 1 ? `<div> ${row?.name} > ${hospitalName} </div> ` : row?.name;
                } else if (isAgencyLevelLogin && hospitalName != 'null') {
                    format += row.isThriveHealthLevel ? `<div> ${row?.name} > ${hospitalName} </div> ` : row?.name;
                } else {
                    format += row?.name;
                }
            }

            if (label === 'User Id | Role') {
                let getUrl = window.location.pathname.split('/')[1];
                let isFacilityShow = getUrl == 'audit-trail' ? true : false;

                // Display role and first/last name combined
                format += `<div> ${row.firstName || ''} ${row.lastName || ''} ${
                    row.role ? '| ' + row.role : ''
                } </div>`;
                if (row.companyName || row.hospitalName) {
                    isFacilityShow
                        ? (format += `<div> ${row.companyName || ''} ${
                              row.hospitalName
                                  ? row.companyName && row.hospitalName
                                      ? ' > ' + row.hospitalName
                                      : '' + row.hospitalName
                                  : ''
                          } </div>`)
                        : '';
                }
            }
            return format || '-';
        };
    }

    getBulkListRateRenderer() {
        return (data: any, type: any, row: any) => {
            let format;
            if (data != null) {
                format = `<div> $ ${data} </div>  `;
            } else {
                format = '-';
            }
            return format;
        };
    }

    returnPipeInstance(pipeType: any): any {
        switch (pipeType) {
            case 'upper':
                return this.upperCase;
            case 'title':
                return this.titleCase;
            case 'lower':
                return this.lowerCase;
            case 'ellipsis':
                return this.ellipsis;
            case 'localDateTime':
                return this.localDateTime;
            case 'roleBasedTimezone':
                return this.roleBasedTimezone;
            case 'customDate':
                return this.customDatePipe;
            default:
                return null;
        }
    }

    getTimeRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                const formattedTime = data.substring(0, 5);
                return formattedTime;
            } else {
                return '';
            }
        };
    }

    getCityStateRenderer() {
        return (data: any, type: any, row: any) => {
            // Combine city and state names from the row data
            const city = row.clinicianCityName || '';
            const state = row.clinicianStateName || '';
            return `${city}, ${state}`;
        };
    }

    //timesheet - in dispute to pending status

    getTimesheetStatusRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                if (row?.statusName === 'In Dispute') {
                    return row?.isExtended
                        ? `Pending > <label class="text-primary fw-bolder">Extended</label>`
                        : 'Pending';
                } else if (row?.statusName === 'Canceled') {
                    return row?.isExtended
                        ? `Rejected > <label class="text-primary fw-bolder">Extended</label>`
                        : 'Rejected';
                } else {
                    return row?.isExtended
                        ? `${row?.statusName} > <label class="text-primary fw-bolder">Extended</label>`
                        : row?.statusName;
                }
            } else {
                return '';
            }
        };
    }

    getExpiryDateRenderer(label: any, visibleFor: any) {
        return (data: any, type: any, row: any) => {
            const today = new Date().toISOString().split('T')[0];
            const expiryDate = row?.expiryDate?.split('T')[0];
            if (row.documentType === 'License') return data;
            if (label === 'Expiry At' || label == 'Exp. Date') {
                return row.isExpire
                    ? row.status === 'Unverified'
                        ? visibleFor === 'credential'
                            ? data
                            : '-'
                        : data
                    : expiryDate <= today
                    ? row.expiryDate
                    : 'n/a';
            }
        };
    }

    dataTableBindedShift() {
        const self = this;
        this.dtOptions = {
            serverSide: this.isServerSidePagination,
            processing: false,
            paging: this.isAllowPaging,
            searching: this.isSearching,
            language: {
                emptyTable: 'No data available',
                loadingRecords: '&nbsp;',
                search: '',
                searchPlaceholder: 'Search',
                paginate: {
                    first: '<em class="fa fa-angle-double-left">',
                    last: '<em class="fa fa-angle-double-right">',
                    previous: '<em class="fa fa-angle-left">',
                    next: '<em class="fa fa-angle-right">',
                },
                info: 'Showing page _PAGE_ of _PAGES_ ',
            },
            displayStart: self.rowIndex,
            pagingType: 'full_numbers',
            pageLength: environment.shiftPaging.defaultPageSize,
            lengthMenu: environment.shiftPaging.lengthMenu,
            order: this.sortOrder,
            info: this.isShowPaginationInfo,

            ajax: (dataTablesParameters: any, callback: any) => {
                self.dataTableAjaxCall(dataTablesParameters, callback);
            },
            drawCallback: function () {
                self.dataTablePagging();
            },
            columns: this.createColumns(),
            rowCallback(row: any, data: any) {
                let obj = {
                    row: row,
                    data: data,
                };
                self.customRowCallback(obj);
            },
        };
    }

    customRowCallback(obj: any) {
        let that = this;
        that.rowClickHandler.emit(obj);
    }

    dataTableAjaxCall(dataTablesParameters: any, callback: any, requestType?: any, requestObject?: any) {
        const startTime = performance.now(); // Get the current time before making the API call

        // Token
        let authToken = sessionStorage.getItem('token');

        // Prepare headers
        const requestOptions = {
            Authorization: 'Bearer ' + authToken,
        };

        let serviceCall;

        if (requestType == 'POST') {
            // Using POST
            let Skip = dataTablesParameters.start;
            let Take = dataTablesParameters.length;
            let SearchText = this.searchText?.length > 0 ? this.searchText : dataTablesParameters.search.value;
            let IsActive = dataTablesParameters.search.value;
            let column = dataTablesParameters.order[0].column;
            let dir = dataTablesParameters.order[0].dir;

            let OrderBy = dataTablesParameters.columns[column].data + ' ' + dir;

            let requestModel = {
                pagedListCriteria: {
                    skip: Skip,
                    take: Take,
                    orderBy: [OrderBy],
                    searchText: SearchText ? SearchText : null,
                },
            };

            let request = { ...requestModel, ...requestObject.requestObject };
            serviceCall = this.apiService.post(requestObject.getUrl, request);
        } else {
            let apiUrl = this.getUrlWithPagination(dataTablesParameters);
            // Using GET
            serviceCall = this.apiService.get(apiUrl, requestOptions);
        }

        serviceCall.subscribe((resp: any) => {
            const endTime = performance.now();
            const elapsedTime = endTime - startTime;
            if (elapsedTime >= 500) {
                this.ngxSpinnerService.show();
                this.isLoading = true;
            }

            this.processDataTableResponse(resp, callback);

            this.isLoading = false;
            this.ngxSpinnerService.hide();
        });
    }

    processDataTableResponse(resp: any, callback: any) {
        this.data = resp.result;
        this.recordsCount = this.isServerSidePagination ? resp.pageDetail?.count : resp.result?.length;

        // Select all .dt-length elements and toggle display based on the data availability
        const dtLengthElements = document.querySelectorAll('.dt-length') as NodeListOf<HTMLElement>;

        // Loop over each .dt-length element
        dtLengthElements.forEach((dtLengthElement, index) => {
            // Check if there's data available for the current index
            if (dtLengthElement && this.data) {
                dtLengthElement.classList.add('data');
                dtLengthElement.style.display = 'block';
            } else {
                dtLengthElement.classList.remove('data');
                dtLengthElement.style.display = 'none'; // Hide if there's no data
            }
        });

        // Emit event if needed
        this.getInheritType.emit({
            count: this.recordsCount,
            isDataInherited: this.data[0]?.isDataInherited,
        });
        // Emit event if needed
        this.getShiftData.emit({
            result: resp.result,
        });
        this.length.emit(this.recordsCount);

        if (this.data[0]?.isDataInherited == true && this.size == 1) {
            this.updateColumnVisibility(true);
        } else {
            this.updateColumnVisibility(false);
        }

        callback({
            recordsTotal: this.recordsCount,
            recordsFiltered: this.recordsCount,
            data: this.data,
        });
    }

    dataTablePagging() {
        $('.paginate_button')
            .addClass('btn btn-icon btn-light btn-hover-primary btn-sm button-margin')
            .removeClass('paginate_button');
    }

    getUrlWithPagination(dataTablesParameters: any) {
        if (this.isServerSidePagination) {
            let Skip = dataTablesParameters.start;
            let Take = dataTablesParameters.length;
            let SearchText = this.searchText?.length > 0 ? this.searchText : dataTablesParameters.search.value;
            let IsActive = dataTablesParameters.search.value;
            // let column = dataTablesParameters.order[0].column;
            // let dir = dataTablesParameters.order[0].dir;

            // let OrderBy = dataTablesParameters.columns[column].data + ' ' + dir;
            let orderByArray: string[] = [];

            // Iterate over the order array in dataTablesParameters
            dataTablesParameters.order.forEach((orderItem: any) => {
                let columnIndex = orderItem.column;
                let direction = orderItem.dir;

                // Get the column name using the column index
                let columnName = dataTablesParameters.columns[columnIndex].data;

                // Add the column and direction to the orderByArray
                orderByArray.push(`${columnName} ${direction}`);
            });

            // Join the array to create a single string for multiple ordering
            let OrderBy = orderByArray.join(',');
            if (this.isQueryParam) {
                // Temporary solution: in interview list search ui.
                if (this.isSearching) {
                    return (
                        this.getUrl +
                        '?Skip=' +
                        Skip +
                        '&Take=' +
                        Take +
                        '&SearchText=' +
                        SearchText +
                        '&OrderBy=' +
                        OrderBy +
                        this.queryparam
                    );
                } else {
                    return this.getUrl + '?Skip=' + Skip + '&Take=' + Take + '&OrderBy=' + OrderBy + this.queryparam;
                }
            } else {
                return (
                    this.getUrl +
                    '?Skip=' +
                    Skip +
                    '&Take=' +
                    Take +
                    '&SearchText=' +
                    SearchText +
                    '&IsActive=' +
                    this.isActive +
                    '&OrderBy=' +
                    OrderBy
                );
            }
        } else {
            if (this.isQueryParam) {
                return (
                    this.getUrl +
                    '?Skip=' +
                    0 +
                    '&Take=' +
                    environment.paging.take +
                    '&SearchText=' +
                    '' +
                    '&IsActive=' +
                    this.isActive +
                    this.queryparam
                );
            } else {
                return (
                    this.getUrl +
                    '?Skip=' +
                    0 +
                    '&Take=' +
                    environment.paging.take +
                    '&SearchText=' +
                    '' +
                    '&IsActive=' +
                    this.isActive
                );
            }
        }
    }

    updateFilteredData(): void {
        // Your custom filter logic

        if (this.isActive != null) {
            let isActive: any;
            isActive = this.isActive;
            this.filteredData =
                isActive.toString() == '' ? this.data : this.data.filter((item) => item.isActive == this.isActive);
        }
        if (this.filterValue != null) {
            let filterValue = this.filterValue == '' ? '' : JSON.parse(this.filterValue);
            this.filteredData =
                this.filterValue == ''
                    ? this.data
                    : this.data.filter((item) => item.includeCompactState === filterValue);
        }
        // Now that you have your filtered data, reset the DataTable's data and call rerender
        if (this.datatableElement && this.datatableElement.dtInstance) {
            this.datatableElement.dtInstance.then((dtInstance: any) => {
                // Clear the DataTable's data
                dtInstance.clear();
                // Add new data
                dtInstance.rows.add(this.filteredData);
                // Draw the DataTable with the new data
                dtInstance.draw();
            });
        }
    }

    getStatusRenderer() {
        return (data: any, type: any, row: any) => {
            if (row.clinicianStatus === 'Active') {
                return `<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">${row.clinicianStatus}</span>`;
            } else if (row.clinicianStatus === 'DNR') {
                return `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">${row.clinicianStatus}</span>`;
            } else if (row.clinicianStatus === 'Suspended') {
                return `<i class="fa fa-circle text-warning" aria-hidden="true"></i><span class="ms-2">${row.clinicianStatus}</span>`;
            }
            return data === true
                ? `<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Active</span>`
                : `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">Inactive</span>`;
        };
    }

    getUsernameRenderer() {
        return (data: any, type: any, row: any) => {
            if (row?.profileUrl) {
                return `
                    <div class="mr-5">
                        <span class="">
                            <img alt="" src="${
                                row?.profileUrl
                            }" class="h-100 align-self-center" action-type="preview" style="cursor: pointer; border-radius: 50px;width:30px; height: 30px !important;" />
                            ${row?.firstName ?? ''} ${row?.lastName ?? ''}
                        </span>
                    </div>`;
            } else {
                return `${row?.firstName ?? ''} ${row?.lastName ?? ''}`;
            }
        };
    }

    getFullNameRenderer() {
        return (data: any, type: any, row: any) => {
            const firstName = row?.firstName ?? row?.clinicianFirstName ?? '';
            const middleName = row?.middleName ?? row?.clinicianMiddleName ?? '';
            const lastName = row?.lastName ?? row?.clinicianLastName ?? '';
            return firstName + ' ' + middleName + ' ' + lastName;
        };
    }

    getBaseLocationRenderer() {
        return (data: any, type: any, row: any) => {
            return (row?.cityName ?? '') + (row?.cityName && row.stateAcronym ? ', ' : '') + (row?.stateAcronym ?? '');
        };
    }

    getLevelRenderer(label: any) {
        return (data: any, type: any, row: any) => {
            if (label == 'Priority ?') {
                return row.priority == 1
                    ? '<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">Yes</span>'
                    : '<i class="fa fa-circle text-light" aria-hidden="true"></i><span class="ms-2">No</span>';
            } else {
                return data === true
                    ? `<i class="fa fa-circle text-success" aria-hidden="true"></i><span class="ms-2">Yes</span>`
                    : `<i class="fa fa-circle text-danger" aria-hidden="true"></i><span class="ms-2">No</span>`;
            }
        };
    }

    getBGColorRenderer() {
        return (data: any, type: any, row: any) => {
            let isShowDeleteButton =
                `<span><i class="fa fa-circle" aria-hidden="true" style="color:${data}"></i></span> ` + data;
            return data !== null ? isShowDeleteButton : ``;
        };
    }

    getLastModifiedByRenderer() {
        return (data: any, type: any, row: any) => {
            const lastModifiedBy = row.createdBy ? this.titleCase.transform(row.createdBy) + ' - ' : '';
            const createdTime = this.localDateTime.transform(row.createdOn, 'MMM d, y | h:mm a');
            return `${lastModifiedBy} ${createdTime}`;
        };
    }

    getActionRenderer(colAction: any, screenStatus: any, label?: any) {
        return (data: any, type: any, row: any) => {
            let activeBtn =
                row.isActive == true
                    ? `<span title='Inactive' class='btn btn-icon btn-sm' action-type='active'><i class="fa fa-ban text-danger" aria-hidden="true"></i><span class="d-block"></span></span>`
                    : `<span title='Active' class='btn btn-icon btn-sm' action-type='active' disabled><i class="fa-regular fa-circle-check text-success" aria-hidden="true"></i><span class="d-block"></span></span>`;

            activeBtn =
                row.isEditable == false
                    ? `<button title='Active' class='btn btn-icon btn-sm ms-1 btn-border' action-type='active' disabled><em class="feather icon-eye icon-color" aria-hidden="true"></em><span class="d-block"></span></button>`
                    : activeBtn;

            let deleteBtn =
                row.isDeleted == false
                    ? row.isEditable == false
                        ? `<button title='Delete' class='btn btn-icon btn-sm ms-1 btn-view btn-border' disabled><em class="feather icon-trash icon-color" aria-hidden="true"></em></button>`
                        : `<span title='Delete' class='btn btn-icon btn-sm ms-1 btn-view' action-type="delete"><em class="feather icon-trash text-primary" aria-hidden="true"></em></span>`
                    : `<button title='Delete' class='btn btn-icon btn-sm ms-1 btn-view btn-border' disabled><em class="feather icon-trash icon-color" aria-hidden="true"></em></button>`;

            if (row.isInherited != undefined) {
                deleteBtn =
                    !row.isInherited && !row.isDeleted
                        ? `<span title='Delete' class='btn btn-icon btn-sm ms-1 btn-view' action-type="delete"><em class="feather icon-trash text-primary" aria-hidden="true"></em></span>`
                        : `<button title='Delete' class='btn btn-icon btn-sm ms-1 btn-view btn-border' disabled><em class="feather icon-trash icon-color" aria-hidden="true"></em></button>`
                        ? `<button title='Delete' class='btn btn-icon btn-sm ms-1 btn-view btn-border' disabled><em class="feather icon-trash icon-color" aria-hidden="true"></em></button>`
                        : `<button title='Delete' class='btn btn-icon btn-sm ms-1 btn-view btn-border' disabled><em class="feather icon-trash icon-color" aria-hidden="true"></em></button>`;
            }

            let editBtn =
                row.isEditable == true || row.isEditable == undefined
                    ? `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`
                    : `<button title='Edit' class='btn btn-icon btn-sm btn-view ms-1 btn-border' action-type="edit" disabled><em class="feather icon-edit icon-color" aria-hidden="true"></em></button>`;

            if (row.isInherited != undefined) {
                editBtn = !row.isInherited
                    ? `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`
                    : `<button title='Edit' class='btn btn-icon btn-sm btn-view ms-1 btn-border' action-type="edit" disabled><em class="feather icon-edit icon-color" aria-hidden="true"></em></button>`;
            }

            if ((row.isInherited == null || row.isInherited == false) && row.isDeleted) {
                editBtn = `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`;
                deleteBtn = `<span title='Delete' class='btn btn-icon btn-sm ms-1 btn-view' action-type="delete"><em class="feather icon-trash text-primary" aria-hidden="true"></em></span>`;
            }

            if (row.isStaticRole != undefined || row?.isLoginUser != undefined) {
                let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');
                isThriveLevelLogin = JSON.parse(isThriveLevelLogin);
                editBtn =
                    isThriveLevelLogin && row.isStaticRole
                        ? `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`
                        : !row.isStaticRole
                        ? `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`
                        : '';

                if (row?.isLoginUser) {
                    editBtn = '';
                }
            }

            if (row.canModify) {
                editBtn = `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`;
                deleteBtn = `<span title='Delete' class='btn btn-icon btn-sm ms-1 btn-view' action-type="delete"><em class="feather icon-trash text-primary" aria-hidden="true"></em></span>`;
            }

            if (row.canDeactive !== undefined || row.canDelete !== undefined) {
                // Deactivate/Activate button logic

                if (row.canDeactive) {
                    activeBtn = activeBtn;
                } else {
                    activeBtn = ``;
                }

                // Delete button logic
                deleteBtn = row.canDelete
                    ? `<span title='Delete' class='btn btn-icon btn-sm ms-1 btn-view' action-type="delete">
                                <em class="feather icon-trash text-primary" aria-hidden="true"></em>
                           </span>`
                    : ``;
                if (row?.isLoginUser) {
                    activeBtn = '';
                    deleteBtn = '';
                }
            }
            if (colAction?.isPublicholiday) {
                const inputDate = (row?.date).split('T')[0]; // Given date in YYYY-MM-DD format
                const currentDate = new Date();

                // Convert current date to YYYY-MM-DD format
                const currentDateStr = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
                    2,
                    '0'
                )}-${String(currentDate.getDate()).padStart(2, '0')}`;

                if (colAction?.isPublicholiday && (inputDate === currentDateStr || inputDate < currentDateStr)) {
                    deleteBtn = '';
                    activeBtn = '';
                    editBtn = '';
                }
            }
            const today = new Date().toISOString().split('T')[0];
            const expiryDate = row?.expiryDate?.split('T')[0];

            if (row.status === 'Unverified' && expiryDate <= today) {
                editBtn = '';
            }

            let viewBtn = `<button title='View' class='btn btn-icon btn-sm ms-1' action-type='view'><em class="feather icon-eye text-primary" aria-hidden="true"></em><span class="d-block"></span></button>`;

            let viewDocument = row?.isAnyDocument
                ? `<button title='View Document' class='btn btn-icon btn-sm ms-1 text-primary' action-type='view'>View Document<span class="d-block"></span></button>`
                : `<button class='btn btn-icon btn-sm ms-1 text-primary d-none' >View Document</button>`;

            let viewMoreBtn = `<button title='View Applicants' class='dropdown-item d-flex align-items-center btn p-2' action-type='viewApplicants'><em class="feather icon-eye" aria-hidden="true"></em><span class="d-block ms-1">View Applicants</span></button>`;

            let downloadUnitTemplateBtn = `<button title='Download Template' class='dropdown-item d-flex align-items-center btn p-2' action-type='downloadTemplate'><em class="feather icon-download" aria-hidden="true"></em><span class="d-block ms-1">Download Unit Template</span></button>`;
            let unitUploadBtn = `<button title='Bulk Upload' class='dropdown-item d-flex align-items-center btn p-2' action-type='bulkupload'><em class="feather icon-upload" aria-hidden="true"></em><span class="d-block ms-1">Unit Upload</span></button>`;
            let copyAndNewBtn = `<button title='Copy & New' class='dropdown-item d-flex align-items-center btn p-2' action-type='copy&new'><em class="feather icon-edit" aria-hidden="true"></em><span class="d-block ms-1">Copy & New</span></button>`;

            let chatBtn = `<button title='Chat' class='btn btn-icon btn-sm btn-view ms-1' action-type='chat'><em class="feather icon-message-square text-primary" aria-hidden="true"></em></button>`;
            let openExternalFileLink = `<button title='Open File ' class='btn btn-icon-sm btn-sm btn-view' action-type='openExternalFileLink'><i class="fa fa-external-link  text-primary" aria-hidden="true"></i></button>`;
            let meetingBtn = `<button title='Meeting' class='btn btn-icon btn-sm btn-view' action-type='meeting'><em class="feather mdi mdi-calendar-clock text-info fs-4" aria-hidden="true"></em></button>`;
            let invitedBtn = `<button class='btn ht-40 wd-40 fs-4 d-flex align-items-center justify-content-center ms-1'><i class="mdi mdi-message-text-outline text-warning"></i></button>`;
            let inviteBtn = `<button  title='Invite' class='btn ht-40 wd-40 fs-4 d-flex align-items-center justify-content-center ms-1' action-type='invite'><i class="feather icon-user-plus text-success"></i></button>`;

            let disputeBtn = `<button title='Dispute' class='dropdown-item d-flex align-items-center btn p-2' action-type='dispute'><em class="feather icon-x-circle" aria-hidden="true"></em><span class="d-block ms-1">Dispute</span></button>`;

            return row.clinicianStatus == 'Released' && !colAction?.isProfileShiftView && !colAction?.isRestrictionView
                ? ''
                : ((colAction && colAction?.isEdit) ||
                  (row.status == 'In Progress' && !colAction?.isProfileShiftView) ||
                  row.status == 'Unverified' ||
                  row.status == 'Not Scheduled' ||
                  (row.status == 'Scheduled' && !colAction?.isProfileShiftView) ||
                  row.status == 'Pending' ||
                  row.status == 'Signed' ||
                  (row?.status == 'Suspended' && !colAction?.isRestrictionView)
                      ? this.screenStatus == 'Declined' || screenStatus == 'Withdraw' || screenStatus == 'Rejected'
                          ? viewBtn
                          : editBtn
                      : '') +
                      (colAction && colAction?.isDelete ? deleteBtn : '') +
                      (colAction && colAction?.isFeedBackSend ? this.getFeedBackSendHtml(row) : '') +
                      ((colAction && colAction?.isView) ||
                      ((row.status == 'Approved' ||
                          row.status == 'Declined' ||
                          row.status == 'Reproved' ||
                          row.status == 'Sent Back' ||
                          row?.status == 'DNR' ||
                          row?.status == 'Terminated' ||
                          row.status == 'Inactive') &&
                          !colAction?.isRestrictionView)
                          ? viewBtn
                          : '') +
                      (colAction?.isProfileShiftView &&
                      (row.status == 'NCNS' ||
                          row.status == 'Completed' ||
                          row.status == 'Released' ||
                          row.status == 'Unschedule' ||
                          row.status == 'Cancel' ||
                          row.excusedStatus != 'Pending' ||
                          ((row.status == 'Called Out' ||
                              row.status == 'Missing Clock-in' ||
                              row.status == 'Scheduled') &&
                              row.clinicianStatus == 'Released')) &&
                      row.status != 'Scheduled' &&
                      !(
                          row.clinicianStatus == 'Released' &&
                          (row.status == 'Called Out' || row.status == 'Missing Clock-in' || row.status == 'Scheduled')
                      )
                          ? viewBtn
                          : colAction?.isProfileShiftView &&
                            !(
                                row.clinicianStatus == 'Released' &&
                                (row.status == 'Called Out' ||
                                    row.status == 'Missing Clock-in' ||
                                    row.status == 'Scheduled')
                            )
                          ? editBtn
                          : '') +
                      (colAction?.isCalloutView
                          ? row.excusedStatus == 'Not Excused' || row.excusedStatus == 'Excused' || row.status == 'NCNS'
                              ? viewBtn
                              : editBtn
                          : '') +
                      (colAction?.isRestrictionView
                          ? row?.status == 'DNR' || row?.status == 'Inactive'
                              ? viewBtn
                              : row?.isShowEditIcon == true && row?.status == 'Suspended'
                              ? editBtn
                              : viewBtn
                          : '') +
                      (colAction && colAction?.isViewMore ? viewMoreBtn : '') +
                      (colAction && colAction?.isApproveReject ? this.getApproveRejectHtml(data) : '') +
                      (colAction && colAction?.isExpiryDate ? this.getExpiryDateOptions(row) : '') +
                      (colAction && colAction?.isDownloadUnitTemplate ? downloadUnitTemplateBtn : '') +
                      (colAction && colAction?.isUnitUpload ? unitUploadBtn : '') +
                      (colAction && colAction?.isCopyNew ? copyAndNewBtn : '') +
                      (colAction && colAction?.isChat ? chatBtn : '') +
                      (colAction && colAction?.isExternalLink
                          ? row.documentType != 'Background Check' || row.status != 'Unverified'
                              ? openExternalFileLink
                              : ''
                          : '') +
                      (colAction && colAction?.isMeeting ? meetingBtn : '') +
                      (colAction && colAction?.isShiftCancel ? chatBtn : this.getShiftCancelHtml(data)) +
                      (colAction && colAction?.isViewDocument ? viewDocument : '') +
                      (colAction && colAction?.isActive ? activeBtn : '');
        };
    }

    getShiftCancelHtml(data: any): string {
        let buttons = '';

        return buttons;
    }

    getFeedBackSendHtml(row: any): string {
        if (!row.isFeedBackSend) {
            return `<button title='Make Request For Feedback' class='btn btn-inverse-success' action-type='send'>Send</button>`;
        } else {
            return `<button title='Make Request For Feedback' class='btn btn-inverse-success' action-type='send'>Re-Send</button>
                        <button title='Reject' class='btn btn-inverse-secondary' action-type='view'>View Feedback</button>`;
        }
    }

    getApproveRejectHtml(data: any): string {
        if (data.statusName === 'Approved') {
            return '<span class="badge bg-success">Approved</span>';
        } else if (data.statusName === 'Rejected') {
            return '<span class="badge" style="background-color:red">Rejected</span>';
        } else {
            return '';
        }
    }

    getExpiryDateOptions(row: any): string {
        if (row.expiryDate != null) {
            return '';
        } else {
            return `
                <div class="table-dropdown dropdown">
                    <a class="text-black dropdown-toggle fs-5" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="feather icon-more-vertical"></i>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <button title='Copy & New' class='dropdown-item d-flex align-items-center btn p-2' action-type='copy&new'>
                            <em class="feather icon-edit" aria-hidden="true"></em>
                            <span class="d-block ms-1">Copy & New</span>
                        </button>
                        <button title='Add New Version' class='dropdown-item d-flex align-items-center btn p-2' action-type='copy&update'>
                            <em class="feather icon-edit" aria-hidden="true"></em>
                            <span class="d-block ms-1">Add New Version</span>
                        </button>
                    </div>
                </div>`;
        }
    }

    getJobActionRenderer() {
        return (data: any, type: any, row: any) => {
            if (row?.statusType == 1 && this.isJobPostingPermission) {
                return `
                <a
                    class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-center text-center top-0 wd-20 cursor-pointer"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-star fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu end-100" style="left: auto !important" aria-labelledby="dropdownMenuLink">
                    <button
                        title="Edit"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="edit"                    
                        >
                        <span class="d-block ms-1">Edit</span>
                    </button>
                    <button
                        title="Delete"
                        action-type="delete"
                        class="dropdown-item d-flex align-items-center btn p-2"
                    >
                        <span class="d-block ms-1">Delete</span>
                    </button>
                    <button
                        title="Post"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="post"
                    >
                        <span class="d-block ms-1">Post</span>
                    </button>
                </div>`;
            } else if (row.statusType == 2 && this.isJobPostingPermission) {
                return `
                <a
                    class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-center text-center top-0 wd-20 cursor-pointer"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-star fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu end-100" style="left: auto !important" aria-labelledby="dropdownMenuLink">
                    <button
                        title="View"
                        class="dropdown-item d-flex align-items-center btn p-2"-+
                        action-type="view"
                    >
                        <span class="d-block ms-1">View</span>
                    </button>
                    <button
                        title="Close"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="close"
                    >
                        <span class="d-block ms-1">Close</span>
                    </button>
                     <button
                        title="Cancel"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="cancel"
                    >
                        <span class="d-block ms-1">Cancel</span>
                    </button>
                </div>`;
            } else if (row?.statusType == 3 && this.isJobPostingPermission) {
                return `
                <a
                    class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-center text-center top-0 wd-20 cursor-pointer"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-star fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu end-100" style="left: auto !important" aria-labelledby="dropdownMenuLink">
                       <button
                        title="View"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="view"
                    >
                        <span class="d-block ms-1">View</span>
                    </button>       
                             <button
                        title="Reopen"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="reopen"
                    >
                        <span class="d-block ms-1">Reopen</span>
                    </button>
                    <button
                        title="Cancel"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="cancel"
                    >
                        <span class="d-block ms-1">Cancel</span>
                    </button>
                </div>`;
            } else {
                return `
                <a
                    class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-center text-center top-0 wd-20 cursor-pointer"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-star fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu end-100" style="left: auto !important" aria-labelledby="dropdownMenuLink">
                    <button
                        title="View"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="view"
                    >
                        <span class="d-block ms-1">View</span>
                    </button>
                </div>`;
            }
        };
    }

    getCombineFacilityRenderer(label: any) {
        return (data: any, type: any, row: any) => {
            let format = '';

            if (label === 'Facility') {
                // When both companyName and hospitalName are present
                if (row.companyName && row.hospitalName) {
                    format += `<div>${row.companyName} > ${row.hospitalName}</div>`;
                }
                // When only companyName is present
                else if (row.companyName && !row.hospitalName) {
                    format += `<div>${row.companyName}</div>`;
                }
            }
            if (label === 'Healthcare') {
                if (row.companyName) {
                    format += `<div>${row.companyName}</div>`;
                }
            }

            return format || '-';
        };
    }

    getCombineDocumentRenderer(label: any) {
        let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');
        isThriveLevelLogin = JSON.parse(isThriveLevelLogin);

        return (data: any, type: any, row: any) => {
            let format = '';

            if (label === 'Document') {
                // Display role and first/last name combined
                format += `<div> ${row?.documentTitle || ''} </div>`;
                isThriveLevelLogin ? (format += `<div> ${row?.companyName || ''} </div>`) : '';
            }

            return format || '-';
        };
    }
    getShiftActionRenderer() {
        const baseButton = `
            <a
                class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-center text-center top-0 wd-20 cursor-pointer"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <i class="fa fa-star fa-ellipsis-v" aria-hidden="true"></i>
            </a>
            <div class="dropdown-menu" style="left: auto !important;  max-height: 170px !important; overflow: auto !important;" aria-labelledby="dropdownMenuLink">
        `;

        const generateActionButtons = (actions: { key: string; label: string }[]) =>
            actions
                .map((action) => {
                    return `
                    <button
                        title="${action.label}"
                        class="dropdown-item d-flex align-items-center btn p-2"
                        action-type="${action.key}"
                    >
                        <span class="d-block ms-1">${action.label}</span>
                    </button>`;
                })
                .join('');

        return (data: any, type: any, row: any) => {
            const commonActions = [
                { key: 'rescheduleShift', label: 'Reschedule Shift' },
                // { key: 'rescheduleNBoostShift', label: 'Reschedule & Boost Shift' },
                { key: 'boostShift', label: 'Boost Shift' },
                { key: 'cancel', label: 'Cancel' },
                { key: 'repush', label: 'Re-push Shift' },
            ];
            const inProgressActions = [
                { key: 'release', label: 'Release' },
                { key: 'extend', label: 'Extend shift' },
            ];
            const scheduledActions = [{ key: 'unscheduled', label: 'Unschedule' }];
            const completedActions = [{ key: 'viewTimesheet', label: 'View Timesheet' }];
            const inviteSentActions = [{ key: 'withdrawInvite', label: 'Withdraw Invite' }];
            const missingClockOutActions = [{ key: 'manualClockOut', label: 'Clock-out Manually' }];
            const missingClockInActions = [
                { key: 'manualClockIn', label: 'Clock-in Manually' },
                { key: 'ncns', label: 'NCNS' },
                { key: 'ncnsNReschedule', label: 'NCNS & Reschedule' },
            ];
            const defaultActions = [{ key: 'openShift', label: 'View Details' }];
            const cancelActions = [{ key: 'viewShift', label: 'View Shift' }];

            // Define common actions for notes with dynamic labels
            const commonActionsForNote = [
                { key: 'internalNote', label: row.internalNote ? 'Edit Internal Note' : 'Add Internal Note' },
                { key: 'nurseNote', label: row.nurseNote ? 'Edit Note for Nurse' : 'Add Note for Nurse' },
            ];

            // Include removal actions if 'Edit' actions are present
            if (row.internalNote) {
                commonActionsForNote.push({ key: 'removeInternalNote', label: 'Remove Internal Note' });
            }
            if (row.nurseNote) {
                commonActionsForNote.push({ key: 'removeNurseNote', label: 'Remove Note for Nurse' });
            }

            const actionsMap: { [key: string]: { key: string; label: string }[] } = {
                'Open Shift': [...defaultActions, ...commonActions, ...commonActionsForNote],
                'Open Shift, Late Call': [...defaultActions, ...commonActions, ...commonActionsForNote],
                'Open Shift, Called Out': [...defaultActions, ...commonActions, ...commonActionsForNote],
                'Open Shift, Late Call, Called Out': [...defaultActions, ...commonActions, ...commonActionsForNote],
                'Open Shift, Late Call, NCNS': [...defaultActions, ...commonActions, ...commonActionsForNote],
                'Open Shift, NCNS': [...defaultActions, ...commonActions, ...commonActionsForNote],
                'In Progress': [...defaultActions, ...inProgressActions, ...commonActionsForNote],
                'Invite Sent': [...defaultActions, ...inviteSentActions, ...commonActionsForNote],
                'Missing Clock-in': [
                    ...defaultActions,
                    ...missingClockInActions,
                    ...scheduledActions,
                    ...commonActionsForNote,
                ],
                'Missing Clock-out': [...defaultActions, ...missingClockOutActions, ...commonActionsForNote],
                Scheduled: [...defaultActions, ...scheduledActions, ...commonActionsForNote],
                Unfilled: [...defaultActions],
                Completed: [...defaultActions, ...completedActions, ...commonActionsForNote],
                Cancel: cancelActions,
                default: [...defaultActions, ...commonActionsForNote],
            };

            const actions = actionsMap[row?.status] || actionsMap['default'];
            const actionButtons = generateActionButtons(actions);
            return `${baseButton}${actionButtons}</div>`;
        };
    }

    getShiftStatusRenderer() {
        return (data: any, type: any, row: any) => {
            let statusIconsHtml = ''; // Initialize an empty string for all status icons

            // Split the row.status string by commas and trim any extra spaces
            const statuses = row.status.split(',').map((status: any) => status.trim());

            statuses.forEach((status: any, index: number) => {
                let statusIcon = ''; // Default to no icon after the first status

                // Show the icon only for the first status
                if (index === 0) {
                    if (status.includes('Canceled')) {
                        statusIcon = '<i class="fa fa-circle text-light" aria-hidden="true"></i>';
                    } else if (status === 'Scheduled' || status === 'In Progress' || status === 'Invite Sent') {
                        statusIcon = '<i class="fa fa-circle" style="color:#3ADCFF" aria-hidden="true"></i>';
                    } else if (status === 'Completed') {
                        statusIcon = '<i class="fa fa-circle text-success" aria-hidden="true"></i>';
                    } else {
                        statusIcon = '<i class="fa fa-circle text-danger" aria-hidden="true"></i>'; // Default icon for other statuses
                    }

                    // Append the icon and the first status with a comma if there are more statuses
                    statusIconsHtml += `${statusIcon}<span class="ms-2">${status}</span>`;
                } else {
                    // For subsequent statuses, add them with a comma and no icon
                    statusIconsHtml += `, <br><span class="ms-4">${status}</span>`;
                }
            });

            return statusIconsHtml; // Return the combined HTML for all statuses
        };
    }

    getNotesRenderer(maxLength: any) {
        return (data: any, type: any, row: any) => {
            let truncatedInternalNote = '';
            let truncatedNurseNote = '';
            if (row.internalNote != null) {
                // Truncate and display both internalNote and nurseNote on different lines
                truncatedInternalNote =
                    row.internalNote.length > maxLength
                        ? `<b>Internal Note: </b> <span title="${row.internalNote}">${row.internalNote.substring(
                              0,
                              maxLength
                          )}</span>...`
                        : `<b>Internal Note: </b>` + row.internalNote;
            }

            if (row.nurseNote != null) {
                truncatedNurseNote =
                    row.nurseNote.length > maxLength
                        ? `<b>Nurse Note: </b> <span title="${row.nurseNote}">${row.nurseNote.substring(
                              0,
                              maxLength
                          )}</span>...`
                        : ` <b>Nurse Note: </b>` + row.nurseNote;
            }
            return `
            <div>
                <div>${truncatedInternalNote}</div>
                <div>${truncatedNurseNote}</div>
            </div>`;
        };
    }

    getCheckBoxActionRenderer() {
        return (data: any, type: any, row: any) => {
            if (row.id && !['Completed', 'Released', 'Cancel', 'Unschedule', 'Unfilled'].includes(row?.status)) {
                return (
                    '<div class="cursor-pointer"><input type="checkbox" class="form-check-input cursor-pointer" id="subscribe_' +
                    row.id +
                    '" name="subscribe"></div>'
                );
            } else {
                return '';
            }
        };
    }

    getAuditActionRenderer() {
        return (data: any, type: any, row: any) => {
            let viewBtn = `
                <button title="View" class="btn btn-icon btn-sm ms-1" action-type="view">
                    <em class="feather icon-eye text-primary" aria-hidden="true"></em>
                </button>
            `;
            if (!row.useModifiedJsonValue) {
                return '';
            } else {
                return viewBtn;
            }
        };
    }

    getNurseEmailRenderer() {
        return (data: any, type: any, row: any) => {
            let email = `<a href="mailto:${data}" class="nurse-email">${data}</a>`;
            return email;
        };
    }

    getLicenseSpecialityRenderer() {
        return (data: any, type: any, row: any) => {
            let formattedData = `<div> ${row?.licenseTypeAcronym} > ${row?.specialityName} </div>`;
            return formattedData;
        };
    }

    getTimesheetRenderer() {
        return (data: any, type: any, row: any) => {
            if (
                row.clinicianStatus == 'Released' &&
                row.statusName != 'Validated' &&
                row.statusName != 'Rejected' &&
                row.statusName != 'Nurse Review'
            ) {
                return '';
            } else if (
                row.statusName != 'Validated' &&
                row.statusName != 'Rejected' &&
                row.statusName != 'Nurse Review'
            ) {
                return `<span title='Edit' class='btn btn-icon btn-sm btn-view ms-1' action-type="edit"><em class="feather icon-edit text-primary" aria-hidden="true"></em></span>`;
            } else {
                return `<button title='View' class='btn btn-icon btn-sm ms-1' action-type='view'><em class="feather icon-eye text-primary" aria-hidden="true"></em><span class="d-block"></span></button>`;
            }
        };
    }

    getLeadNurseAction() {
        return (data: any, type: any, row: any) => {
            const pendingStages = [];

            if (!row?.hasLicenceStageCompelted) {
                pendingStages.push('License');
            }
            if (!row?.hasInterviewStageCompelted) {
                pendingStages.push('Interview');
            }
            if (!row?.hasScreeningStageCompelted) {
                pendingStages.push('Screening Stage');
            }
            if (!row?.hasDocumentationStageCompelted) {
                pendingStages.push('Job Contract');
            }
            if (!row?.isClinicianEmploymentFormsStageComplete) {
                pendingStages.push('W4/I9');
            }

            let displayText = '';
            const visibleStages = pendingStages.slice(0, 2); // Get the first two stages

            if (pendingStages.length > 2) {
                displayText = `${visibleStages.join(', ')} +${pendingStages.length - 2}`;
            } else if (pendingStages.length > 0) {
                displayText = visibleStages.join(', ');
            }

            // Return the display text with a tooltip for full list of pending stages
            return `<span title="${pendingStages.join(', ')}">${displayText}</span>`;
        };
    }

    getRenewalAction() {
        return (data: any, type: any, row: any) => {
            const baseButton = `
                <a
                    class="rounded-1 align-items-center bg-gray-0 text-black btn-icon d-flex end-0 ht-20 justify-content-center text-center top-0 wd-20 cursor-pointer"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-star fa-ellipsis-v" aria-hidden="true"></i>
                </a>
                <div class="dropdown-menu" style="left: auto !important; max-height: 170px !important; overflow: auto !important;" aria-labelledby="dropdownMenuLink">
            `;

            const viewFileButton = `
                <button title="View File" class="dropdown-item d-flex align-items-center btn p-2" action-type="view">
                    <span class="d-block ms-1">View File</span>
                </button>
            `;

            const chatButton = `
                <button title="Chat" class="dropdown-item d-flex align-items-center btn p-2" action-type="chat">
                    <span class="d-block ms-1">Chat</span>
                </button>
            `;

            let actionButtons = '';

            if (row.statusName === 'Signed') {
                actionButtons = `
                    <button title="Send Back" class="dropdown-item d-flex align-items-center btn p-2" action-type="sendBack">
                        <span class="d-block ms-1">Send Back</span>
                    </button>
                    <button title="Valid" class="dropdown-item d-flex align-items-center btn p-2" action-type="valid">
                        <span class="d-block ms-1">Valid</span>
                    </button>
                    ${viewFileButton}
                    ${chatButton}
                `;
            } else if (row.statusName === 'Sent Back') {
                actionButtons = `
                    ${viewFileButton}
                    ${chatButton}
                `;
            } else {
                actionButtons = `
                    <button title="edit" class="dropdown-item d-flex align-items-center btn p-2" action-type="edit">
                        <span class="d-block ms-1">Edit</span>
                    </button>
                    ${viewFileButton}
                    ${chatButton}
                `;
            }

            return `${baseButton}${actionButtons}</div>`;
        };
    }
    getLocalDateRenderer() {
        return (data: any, type: any, row: any) => {
            return this.customDatePipe.transform(data);
        };
    }

    getNurseLevelRenderer() {
        return (data: any, type: any, row: any) => {
            if (data != null) {
                const items = data.map(
                    (item: any) =>
                        `<div> <label class="fw-bolder">${item.licenseTypeName}</label> - ${item.specialityName} - ${item.level}</div>`
                );
                return `<div>${items.join('')}</div>`;
            } else {
                return '';
            }
        };
    }

    getShiftInfoRenderer() {
        return (data: any, type: any, row: any) => {
            if (data) {
                let parts = data.split('|').map((part: string) => {
                    part = part.trim();

                    // List of labels that should stay intact
                    const labelsToPreserve = ['Invitation Sent'];

                    // Check if the part starts with any of these labels
                    if (labelsToPreserve.some((label) => part.startsWith(label + ':'))) {
                        return part; // Keep this line together
                    }

                    // Otherwise, replace commas with line breaks
                    return part.replace(/,\s*/g, ',<br>');
                });

                return parts.join('<br>'); // Join with line breaks for display
            }
            return '';
        };
    }
}
