import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataTableRequestModelService } from './dataTableRequestModel.service';
import { ApiService } from './api.service';
import { API_CLINICIAN, API_JOB } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class StaffService {
    constructor(private apiService: ApiService, private dataTableRequestModelService: DataTableRequestModelService) {}

    getStaffById(id: any): Observable<any> {
        return this.apiService.get(API_CLINICIAN.GET_CLINICIAN_BY_ID + id);
    }

    getStaffList(id: any, req: any): Observable<any> {
        return this.dataTableRequestModelService.dataTableRequestServiceForStaff(
            API_CLINICIAN.GET_CLINICIAN_BY_HOSPITAL + id,
            req
        );
    }
    searchStaff(
        search: any,
        skip: number,
        take: number,
        hospitalId: any,
        jobTypeId: number,
        jobSpecialityIds: number[]
    ): Observable<any> {
        let baseUrl = `${API_CLINICIAN.GET_CLINICIAN_BY_HOSPITAL}${hospitalId}?Skip=${skip}&Take=${take}&SearchText=${search}`;
        if (jobTypeId !== null && jobTypeId !== undefined) {
            baseUrl += `&jobTypeId=${jobTypeId}`;
        }

        if (jobSpecialityIds && jobSpecialityIds.length > 0) {
            const specialityIdsString = jobSpecialityIds.join(',');
            baseUrl += `&jobSpecialityIds=${specialityIdsString}`;
        }

        return this.apiService.get(baseUrl);
    }
    staffFilter(
        SearchText: any,
        skip: number,
        take: number,
        hospitalId: number,
        jobTypeId: number,
        jobSpecialityIds: number[]
    ): Observable<any> {
        // Construct the base URL with common parameters
        let baseUrl = `${API_CLINICIAN.GET_CLINICIAN_BY_HOSPITAL}${hospitalId}?Skip=${skip}&Take=${take}&jobTypeId=${jobTypeId}`;
        if (SearchText !== undefined) {
            baseUrl += `&SearchText=${SearchText}`;
        }

        // Check if jobSpecialityIds are available
        if (jobSpecialityIds && jobSpecialityIds.length > 0) {
            // Join the jobSpecialityIds array into a comma-separated string
            const specialityIdsString = jobSpecialityIds.join(',');
            baseUrl += `&jobSpecialityIds=${specialityIdsString}`;
        }

        return this.apiService.get(baseUrl);
    }

    getOrientationbyClinician(clinicianId: any, hospitalId: any): Observable<any> {
        return this.apiService.get(API_JOB.GET_ORIENTATION_CLINICIAN_BY_HOSPITAL + clinicianId + '/' + hospitalId);
    }
    updateOrientationClinicianInfo(res: any): Observable<any> {
        return this.apiService.put(API_JOB.PUT_ORIENTATION_CLINICION_INFO, res);
    }

    getNurseStateSelectList(marketId: any, companyId: any, hospitalId: any): Observable<any> {
        return this.apiService.get(
            `${API_CLINICIAN.NURSE_STATE_SELECT_LIST}?marketId=${marketId}&companyId=${companyId}&hospitalId=${hospitalId}`
        );
    }

    getNurseCitySelectList(marketId: any, companyId: any, hospitalId: any, stateIds: any): Observable<any> {
        return this.apiService.get(
            `${API_CLINICIAN.NURSE_CITY_SELECT_LIST}?marketId=${marketId}&companyId=${companyId}&hospitalId=${hospitalId}&stateIds=${stateIds}`
        );
    }

    getRestrictionReasonSelectList(): Observable<any> {
        return this.apiService.get(API_CLINICIAN.GET_RESTRICT_REASON_SELECT_LIST);
    }

    getNurseFacilitySelectList(obj: any): Observable<any> {
        return this.apiService.post(
            `${API_CLINICIAN.BASE_URL_CLINICIAN + API_CLINICIAN.GET_NURSE_FACILITY_SELECT_LIST} `,
            obj
        );
    }

    restrictNurse(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.RESTRICT_NURSE, res);
    }

    getShiftHistory(reqObject: any): Observable<any> {
        return this.apiService.post(`${API_CLINICIAN.SHIFT_HISTORY}`, reqObject);
    }

    getNurseHealthcareSelectList(obj: any): Observable<any> {
        return this.apiService.post(
            `${API_CLINICIAN.BASE_URL_CLINICIAN + API_CLINICIAN.GET_NURSE_HEALTHCARE_SELECT_LIST} `,
            obj
        );
    }

    getHirecClinicianHospitalList(clinicianId: any, marketId: any, companyId: any, hospitalId: any): Observable<any> {
        return this.apiService.get(
            `${API_CLINICIAN.GET_HIRED_CLINICIAN_HOSPITAL}?clinicianId=${clinicianId}&marketId=${marketId}&companyId=${companyId}&hospitalId=${hospitalId}`
        );
    }

    updateRestrictedNurse(res: any): Observable<any> {
        return this.apiService.put(API_CLINICIAN.UPDATE_RESTRICTED_NURSE, res);
    }

    getInactiveReasonList(): Observable<any> {
        return this.apiService.get(`${API_CLINICIAN.GET_INACTIVE_REASON_LIST}`);
    }

    inactiveNurse(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.POST_INACTIVE_NURSE, res);
    }

    activeNurse(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.POST_ACTIVE_NURSE, res);
    }

    getInactiveHospitalList(clinicianId: any): Observable<any> {
        return this.apiService.get(`${API_CLINICIAN.GET_INACTIVE_HOSPITAL}?clinicianId=${clinicianId}`);
    }

    getAttentionRecords(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.GET_ATTENTION_RECORDS_LIST, res);
    }

    getTerminateReasonList(): Observable<any> {
        return this.apiService.get(`${API_CLINICIAN.GET_TERMINATE_REASON_LIST}`);
    }

    terminateNurse(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.POST_TERMINATE_NURSE, res);
    }

    checkNurseStatus(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.GET_NURSE_STATUS, res);
    }

    onboardNurse(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.POST_ONBOARD_NURSE, res);
    }

    onboardNurseDetails(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.GET_ONBOARD_NURSE_DETAIL, res);
    }

    geJobList(res: any): Observable<any> {
        return this.apiService.post(API_CLINICIAN.GET_HOSPITAL_LIST, res);
    }

    updateOnbaordNurseLevel(res: any): Observable<any> {
        return this.apiService.put(API_CLINICIAN.ONBOARD_NURSE_LEVEL, res);
    }
}
