import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-titlebar',
    templateUrl: './titlebar.component.html',
    styleUrls: ['./titlebar.component.scss'],
})
export class TitlebarComponent implements OnInit {
    @Input()
    title: string;

    @Output() create: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    createRecord() {
        this.create.emit();
    }
}
