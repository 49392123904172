import { Component, ElementRef, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from 'src/app/service/job.service';
import { ChatService } from 'src/app/service/chat.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { DataTableModel, DisplayBlock } from 'src/app/providers/classes/helper.classes';
import { API_CLINICIAN, API_JOB_PROFILE_SUMMARY } from 'src/app/constants/api';
import { EllipsisPipe } from 'src/app/pipes/ellipsis.pipe';
import { StaffRestrictionModelComponent } from '../../staff/staff-list/staff-restriction-model/staff-restriction-model.component';
import { StaffService } from 'src/app/service/staff.service';
import { LocalDateTimePipe } from 'src/app/pipes/local-date-time.pipe';
import { DocumentationVerificationModelComponent } from '../tabs/documentation/documentation-verification-model.component';
import { JobTemplateService } from 'src/app/service/jobTemplate.service';
import { TimesheetUpdateStatusComponent } from '../../timesheet/timesheet-update-status/timesheet-update-status.component';
import { Shift } from 'src/app/model/shift.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShiftInspectorDetailsComponent } from '../../shift/shift-inspector-details/shift-inspector-details.component';
import { AddViewNoteComponent } from '../add-view-note/add-view-note.component';
import { Category } from 'src/app/model/clinicianProfile.model';
import { AddViewRestrictionComponent } from '../add-view-restriction/add-view-restriction.component';
import { removeSpaces } from 'src/app/utill/validator';
import { DownloadProfileComponent } from '../download-profile/download-profile.component';
import { UploadNurseCredentialModelComponent } from '../upload-nurse-credential-model/upload-nurse-credential-model.component';
import { UpdateVerficationModelComponent } from '../tabs/update-verfication-model/update-verfication-model.component';
import { ViewVerficationModelComponent } from '../tabs/view-verfication-model/view-verfication-model.component';

export class ChatModel {
    _id: number;
    text: string;
    createdAt: Date;
    date: any;
    time: any;
    image: null;
    _userId: string;
    name: string;
    avatar: any;
    isNewDay: any;
}

@Component({
    selector: 'app-view-profile-model',
    templateUrl: './view-profile-model.component.html',
    styleUrl: './view-profile-model.component.scss',
    providers: [EllipsisPipe, LocalDateTimePipe],
    encapsulation: ViewEncapsulation.None,
})
export class ViewProfileModelComponent implements OnInit {
    @Input() model: any = {};
    @Input() id = null;
    @Input() date: any;
    @Input() jobId = null;
    @Input() shiftId = null;
    @Input() type = null;
    @Input() displayFor = null;
    @Input() hospitalId: any = '';
    @Input() companyId: any;
    @Input() marketId: any;
    @Input() isShowAllTabs: any = true;
    @Input() callingFrom: any;
    @Input() chatForHospital: any;

    defaultNavActiveId = 2;
    clinicianDetails: any;
    educations: any[] = [];
    licenses: any[] = [];
    credentials: any[] = [];
    resumes: any[] = [];
    experiences: any[] = [];
    backgrounds: any[] = [];
    license: any[] = [];
    phoneNumber: any;
    statusId: any;
    facilityId: any;
    isAddNoteMode: boolean = true;
    authorList: any[] = [];
    typeList: any[] = [];
    typeId: any;
    authorId: any;
    selectedfacilityId: any;
    selectedType: any;

    //chat variables
    skip: number = 0;
    take: number = 20;
    chatDetails: any;
    message: any;
    userId: any;
    chatModel: ChatModel[] = [];
    @ViewChild('scrollable') private scrollContainer: ElementRef<HTMLDivElement>;
    isLoading: boolean = false;
    wasAtBottom: boolean = true;
    noMoreMessages: boolean = false;
    private messageReceivedSubscription: Subscription;
    public dataTableModel: DataTableModel = new DataTableModel();
    public shiftDataTableModel: DataTableModel = new DataTableModel();
    public timeSheetDataTableModel: DataTableModel = new DataTableModel();
    public calloutstDataTableModel: DataTableModel = new DataTableModel();
    public notesDataTableModel: DataTableModel = new DataTableModel();
    public restrictionDataTableModel: DataTableModel = new DataTableModel();
    public documentDataTableModel: DataTableModel = new DataTableModel();

    displayBlock!: DisplayBlock;
    shiftHistoryDetail: any;
    jobsType: any[] = [];
    jobSpeciality: any[] = [];
    selectedlicenseTypeId: any = null;
    licenseType: any[] = [];
    selectedJobSpeciality: any = null;
    isDisableApply = true;
    isShowResetBtn = false;
    selectedTypeId: any;
    facilityList = [];
    timesheetFacilityId: any;
    selectedStatusId: any;
    licenseTypeId: any;
    fromDate: any = null;
    toDate: any = null;
    selectedStatus: any = null;
    stateIds: any = null;
    selectedDocumentType: any;
    selectedDocumentStatus: any;
    selectedDocumentStatusId: any;
    restrictedFacilityList: any = [];
    loginCompanyId: any;
    isCompanyLevelLogin: boolean = false;
    isFacilityLevelLogin: boolean = false;
    isAgencyLevelLogin: boolean = false;

    statusSelectList: any = [
        { id: Shift.OpenShift, name: 'Open Shift' },
        { id: Shift.LateCall, name: 'Open Shift, Late Call' },
        { id: Shift.MissingClockIn, name: 'Missing Clock-In' },
        { id: Shift.NCNS, name: 'NCNS' },
        { id: Shift.CalledOut, name: 'Called Out' },
        { id: Shift.MissingClockOut, name: 'Missing Clock-Out' },
        { id: Shift.Scheduled, name: 'Scheduled' },
        { id: Shift.InProgress, name: 'In Progress' },
        { id: Shift.Released, name: 'Released' },
        { id: Shift.Completed, name: 'Completed' },
        { id: Shift.Overtime, name: 'Overtime, Scheduled' },
        { id: Shift.Unschedule, name: 'Unschedule' },
    ];

    documentTypeList: any = [
        { id: 'License', name: 'License' },
        { id: 'BackgroundCheck', name: 'Background Check' },
        { id: 'Credential', name: 'Credential' },
        { id: 'Education', name: 'Education' },
        { id: 'Document', name: 'Document' },
        { id: 'EmployementForm', name: 'Employement Form' },
    ];

    documentStatus: any = [
        { id: 'InProgress', name: 'In Progress' },
        { id: 'Approved', name: 'Approved' },
        { id: 'Declined', name: 'Declined' },
        { id: 'Unverified', name: 'Unverified' },
        { id: 'Pending', name: 'Pending' },
        { id: 'Signed', name: 'Signed' },
        { id: 'SentBack', name: 'Sent Back' },
        { id: 'Expired', name: 'Expired' },
    ];

    typesList: any = [
        { id: Shift.NCNS, name: 'NCNS' },
        { id: Shift.CalledOut, name: 'Called Out' },
    ];

    restrictionTypeList = [
        { id: 'DNR', name: 'DNR' },
        { id: 'Suspended', name: 'Suspened' },
    ];
    isUpdateRestrictedNurse: boolean = false;
    nurseRestrictionForm: FormGroup;
    selectedSuspendedFromDate: any;
    selectedSuspendedtillDate: any;
    isDisabled: any;
    restrictionType: any;
    isHospitalLevel: boolean = false;

    apiUrl: any;
    isActionBtnShow: boolean = false;
    restrictionDetails: any;

    constructor(
        public modal: NgbActiveModal,
        private jobService: JobService,
        private modalService: NgbModal,
        private chatService: ChatService,
        private localDateTime: LocalDateTimePipe,
        private staffService: StaffService,
        private jobTemplateService: JobTemplateService,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        public dateUtilService: DateUtilService,
        public activeModal: NgbActiveModal,
        private ngxSpinnerService: NgxSpinnerService
    ) {
        this.userId = sessionStorage.getItem('userId');
        this.chatService.messageReceived.subscribe((message) => {
            this.handleReceivedMessage(message);
        });

        this.loginCompanyId = sessionStorage.getItem('companyId');
        this.loginCompanyId = JSON.parse(this.loginCompanyId);
        this.loginCompanyId = this.loginCompanyId == null ? null : this.loginCompanyId;

        let isCompanyLevelLogin: any = sessionStorage.getItem('isCompanyLevelLogin');
        this.isCompanyLevelLogin = JSON.parse(isCompanyLevelLogin);
        let isFacilityLevelLogin: any = sessionStorage.getItem('isFacilityLevelLogin');
        this.isFacilityLevelLogin = JSON.parse(isFacilityLevelLogin);
        let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
        this.isAgencyLevelLogin = JSON.parse(isAgencyLevelLogin);
    }
    ngOnInit(): void {
        this.apiUrl = API_JOB_PROFILE_SUMMARY;

        this.getProfileDetail(this.id);
        this.getJobSpeciality();
        this.getJobTypeList();
        this.getFacilitylist();

        this.typeList = this.getEnumList(Category);

        setTimeout(() => {
            if (this.type == 'chat') {
                this.defaultNavActiveId = 3;
                this.onNavChange(3);
            }
        }, 600);

        if (this.displayFor == 'restricted') {
            this.defaultNavActiveId = 5;
            this.getRestrictionDetails();
            this.initForm();
        }

        this.messageReceivedSubscription = this.chatService.messageReceived.subscribe((message) => {
            this.handleReceivedMessage(message);
            this.scrollToBottom();
        });

        if (this.callingFrom == 'lead-nurse' && !this.hospitalId) {
            this.isHospitalLevel = false;
        } else if (this.callingFrom == 'lead-nurse' && this.hospitalId) {
            this.isHospitalLevel = this.model?.isHired ? true : false;
        } else {
            this.isHospitalLevel = true;
        }
    }

    getProfileDetail(id: any) {
        this.educations = [];
        this.credentials = [];
        this.licenses = [];
        this.experiences = [];
        this.backgrounds = [];
        this.resumes = [];
        this.license = [];
        let jobId = this.jobId || '';
        let shiftId = this.shiftId || '';
        let hospitalId = this.chatForHospital || '';
        this.jobService.getClinicianDetailById(id, jobId, shiftId, hospitalId).subscribe(async (response) => {
            if (response.result) {
                this.clinicianDetails = response.result;

                this.clinicianDetails.educations?.forEach((element: any) => {
                    this.educations.push(element);
                });
                this.clinicianDetails.credentials?.forEach((element: any) => {
                    this.credentials.push(element);
                });
                this.clinicianDetails.licenses?.forEach((element: any) => {
                    this.licenses.push(element);
                });
                this.clinicianDetails.resumes?.forEach((element: any) => {
                    this.resumes.push(element);
                });
                this.clinicianDetails.experiences?.forEach((element: any) => {
                    this.experiences.push(element);
                });
                this.clinicianDetails.backgroundChecks?.forEach((element: any) => {
                    this.backgrounds.push(element);
                });

                const acronyms = new Set<string>();
                this.clinicianDetails.licenses?.forEach((element: any) => {
                    if (!acronyms.has(element.acronym)) {
                        acronyms.add(element.acronym);
                        this.license.push(element);
                    }
                });

                this.model.chatGroupsId = this.clinicianDetails?.chatGroupsId;
                // if (this.type == 'chat') {
                //     this.defaultNavActiveId = 3;
                //     await this.onNavChange(3);
                // }
            }
        });
    }

    downloadProfile() {
        this.notesDataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(DownloadProfileComponent, {
            size: 'xl',
            backdrop: false,
            centered: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.model = this.clinicianDetails;
        modalRef.result.then(
            () => (this.notesDataTableModel.isLoadData = true),
            () => {}
        );
    }

    onDocumentShow(data: any) {
        window.open(data);
    }

    viewProfileDetails(id: any, type: string, data: any, label?: any, url?: any) {
        this.model.clinicianProfileUrl = this.clinicianDetails.clinicianProfileUrl;
        this.model.clinicianLicences = this.license;
        this.model.firstName = this.clinicianDetails?.firstName;
        this.model.lastName = this.clinicianDetails?.lastName;
        this.model.clinicianEmail = this.clinicianDetails?.email;
        this.model.clinicianPhoneNumber = this.clinicianDetails?.phoneNumber;
        this.model.clinicianStateName = this.clinicianDetails?.stateAcronym;
        this.model.clinicianCityName = this.clinicianDetails?.cityName;
        this.model.clinicianId = this.model?.clinicianId ?? this.id;
        if (['Unverified', 'In Progress'].includes(data.statusName) && type != 'experience') {
            const modalRef = this.modalService.open(UpdateVerficationModelComponent, {
                size: 'lg',
                backdrop: false,
                windowClass: 'my-custom-modal',
            });
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.data = this.model;
            modalRef.componentInstance.label = label;
            modalRef.componentInstance.type = type;
            modalRef.componentInstance.moduleURL = url;

            modalRef.result.then((res: any) => {
                if (res.isSuccess) {
                    this.getProfileDetail(this.model.clinicianId);
                }
            });
        } else {
            const modalRef = this.modalService.open(ViewVerficationModelComponent, {
                size: 'xl',
                backdrop: false,
                windowClass: 'my-custom-modal',
            });
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.data = this.model;
            modalRef.componentInstance.type = type;
            modalRef.componentInstance.label = label;
        }
    }

    formatPhoneNumber(phoneNumber: string): string {
        return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }

    onClose() {
        this.modal.dismiss();
        if (this.model?.chatGroupsId != null) {
            this.chatService.upateChatStatus(this.model?.chatGroupsId).subscribe((response: any) => {});
        }
    }

    // chat
    handleReceivedMessage(message: any) {
        if (!this.chatModel.find((msg) => msg._id === message._id)) {
            const lastMessage = this.chatModel[this.chatModel.length - 1];
            const isNewDay = lastMessage
                ? new Date(message.createdAt).toDateString() !== new Date(lastMessage.createdAt).toDateString()
                : true;

            let chatMessage: ChatModel = {
                _id: message._id,
                text: message.text,
                createdAt: message.createdAt,
                image: message.image,
                _userId: message.user._id,
                name: message.user.name,
                avatar: message.user.avatar,
                date: this.localDateTime.transform(message.createdAt.split('Z')[0]),
                time: message.createdAt.split('Z')[0],
                isNewDay: isNewDay,
            };
            this.chatModel.push(chatMessage);
            this.scrollToBottom();
        }
    }

    ngAfterViewChecked() {
        // Adding a timeout ensures the DOM is updated before scrolling
        setTimeout(() => {
            if (this.wasAtBottom) {
                this.scrollToBottom();
            }
        }, 0);
    }

    scrollToBottom() {
        if (this.scrollContainer) {
            // Scroll to the very bottom
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
    }

    loadMessages(loadMore: boolean = false) {
        let chatGroupsId = this.model?.chatGroupsId ?? this.clinicianDetails.chatGroupsId ?? '';
        if (this.isLoading || this.noMoreMessages) return;

        this.isLoading = true;

        const params = {
            Skip: this.skip,
            Take: this.take,
            groupId: chatGroupsId,
            callingFrom: 'web',
        };

        this.chatService.getChatDetails(params).subscribe((response) => {
            if (response.result && response.result.length > 0) {
                const messages = response.result.map((element: any) => ({
                    _id: element._id,
                    text: element.text,
                    createdAt: element.createdAt,
                    image: element.image,
                    _userId: element.user._id,
                    name: element.user.name,
                    avatar: element.user.avatar,
                    date: this.localDateTime.transform(element.createdAt),
                    time: element.createdAt,
                    isNewDay: false, // Initially false, set properly below
                }));

                // Ensure messages are in correct chronological order
                messages.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                this.updateIsNewDayFlags(messages);

                // Add new messages to the chat model
                if (loadMore) {
                    this.chatModel = [...this.chatModel, ...messages];
                } else {
                    this.chatModel = messages;
                    this.scrollToBottom();
                }
                this.skip += this.take;
            } else {
                this.noMoreMessages = true;
            }
            this.isLoading = false;
        });
    }

    sendMessage() {
        let contactId = sessionStorage.getItem('contactId');
        let currentDate = new Date().toISOString();
        let clinicianId = this.model?.clinicianId ?? this.id;
        let newMessage = {
            jobId: this.jobId == '' ? null : this.jobId,
            shiftId: this.shiftId,
            clinicianId: clinicianId,
            contactId: contactId,
            message: this.message,
            hospitalId: this.jobId == null || this.jobId == '' ? this.clinicianDetails?.hospitalId : null,
            groupsType: this.shiftId != null ? 1 : this.jobId != '' ? 2 : 3,
            date: currentDate.split('Z')[0],
        };

        if (this.message.trim() !== '') {
            this.chatService.postMessage(newMessage).subscribe((response: any) => {
                if (response.result) {
                    this.message = '';
                    this.scrollToBottom();
                }
            });
        }
    }
    onScroll(): void {
        const target = this.scrollContainer?.nativeElement;

        // A larger threshold ensures that the last message is shown completely
        this.wasAtBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - 2;

        if (target.scrollTop <= 100 && !this.isLoading && !this.noMoreMessages) {
            this.loadMessages(true);
        }
    }

    back() {
        this.modal.close();
    }

    joinChat(jobId: any, clinicianId: any) {
        this.chatService
            .joinChat(jobId + '_' + clinicianId)
            .then(() => {})
            .catch((err) => {
                console.log(err);
            });
    }

    onJobTypeChange(event: any) {
        this.selectedlicenseTypeId = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event == null) {
            this.selectedlicenseTypeId = null;

            if ((this.facilityId || this.selectedStatusId || this.selectedJobSpeciality) == null) {
                this.resetFilter();
            }
        }
    }
    onFacilityTypeChange(event: any) {
        this.facilityId = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event == null) {
            this.facilityId = null;

            if ((this.selectedlicenseTypeId || this.selectedStatusId || this.selectedJobSpeciality) == null) {
                this.resetFilter();
            }
        }
    }

    onTimesheetFacilityTypeChange(event: any) {
        this.timesheetFacilityId = event;
        this.timesheetdataTableBinding('');
    }

    onStatusChange(event: any) {
        this.selectedStatusId = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event == null) {
            this.selectedStatusId = null;

            if ((this.selectedlicenseTypeId || this.facilityId || this.selectedJobSpeciality) == null) {
                this.resetFilter();
            }
        }
    }

    getJobSpeciality() {
        this.jobTemplateService.getJobSpecialityList().subscribe((response: any) => {
            this.jobSpeciality = response.result || [];
        });
    }

    getFacilitylist() {
        let clinicianId = this.model?.clinicianId ?? this.id;
        let hospitalId = this.hospitalId ?? null;
        let marketId = this.marketId ?? null;

        let companyId = this.isAgencyLevelLogin
            ? ''
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? ''
            : this.loginCompanyId;

        companyId = companyId ? companyId : '';

        this.staffService.getHirecClinicianHospitalList(clinicianId, '', companyId, '').subscribe((response: any) => {
            this.facilityList = response.result;
        });
    }

    onSpecialityChange(event: any) {
        this.selectedJobSpeciality = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event == null) {
            this.selectedJobSpeciality = null;

            if ((this.facilityId || this.selectedStatusId || this.selectedlicenseTypeId) == null) {
                this.resetFilter();
            }
        }
    }

    resetFilter(callingFrom?: any) {
        this.selectedJobSpeciality = null;
        this.selectedlicenseTypeId = null;
        this.selectedStatusId = null;
        this.facilityId = null;
        this.isDisableApply = true;
        this.isShowResetBtn = false;

        if (callingFrom) {
            this.calloutstDataTableModel.isLoadData = false;
            this.calloutdataTableBinding('');
            this.calloutstDataTableModel.isLoadData = true;
        } else {
            this.shiftDataTableModel.isLoadData = false;
            this.shiftdataTableBinding('');
            this.shiftDataTableModel.isLoadData = true;
        }
    }

    onNavChange(data: any) {
        this.isUpdateRestrictedNurse = false;
        this.selectedStatusId = null;
        this.isShowResetBtn = false;
        this.isDisableApply = true;
        this.facilityId = null;
        this.selectedlicenseTypeId = null;
        this.selectedJobSpeciality = null;
        this.timesheetFacilityId = null;
        this.selectedfacilityId = null;
        this.selectedType = null;
        this.authorId = null;
        this.typeId = null;
        switch (data) {
            case 1:
                this.getShiftHistory();
                break;
            case 4:
                this.dataTableBinding();
                break;
            case 7:
                this.shiftdataTableBinding(true);
                break;
            case 8:
                this.timesheetdataTableBinding(true);
                break;
            case 9:
                this.notestdataTableBinding(true);
                this.getAuthorList();
                break;
            case 10:
                this.calloutdataTableBinding(true);
                break;
            case 11:
                this.restrictiondataTableBinding(true);
                this.getRestrictedFacility();
                break;
            case 12:
                this.documentdataTableBinding(true);
                break;
            case 3:
                let clinicianId = this.model?.clinicianId ?? this.id;
                this.joinChat(this.jobId, clinicianId);
                this.chatService.initializeConnection();
                this.loadMessages();
                break;
            default:
                break;
        }
    }

    updateIsNewDayFlags(messages: ChatModel[]) {
        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to 00:00:00

        // Assuming messages are already sorted in ascending order by createdAt
        if (messages.length > 0) {
            // Set the first message's isNewDay to true
            messages[0].isNewDay = true;
            // Loop through the rest of the messages
            for (let i = 1; i < messages.length; i++) {
                // Compare each message with the previous one
                messages[i].isNewDay =
                    new Date(messages[i].createdAt).toDateString() !==
                    new Date(messages[i - 1].createdAt).toDateString();
                // Check if the message was sent today
                if (new Date(messages[i].createdAt).setHours(0, 0, 0, 0) === today.getTime()) {
                    messages[i].date = 'Today';
                }
            }
        }
    }

    checkIsExpired(expiryDate: any) {
        // Parse the date string using Moment.js
        const date = moment(expiryDate);
        // Get the current date
        const currentDate = moment();
        // Compare the current date with the provided date
        // If the provided date is in the past, it's expired
        return currentDate.isAfter(date);
    }

    getEnumList(enumType: any): { id: number; name: string }[] {
        return Object.keys(enumType)
            .filter((key) => isNaN(Number(key)))
            .map((key) => ({ id: enumType[key], name: key }));
    }

    dataTableBinding() {
        if (this.callingFrom == 'lead-nurse') {
            this.isActionBtnShow = this.model?.isHired ? true : false;
        } else {
            this.isActionBtnShow = true;
        }
        let that = this;
        let clinicianId = this.model?.clinicianId ?? this.id;

        let companyId = this.isAgencyLevelLogin
            ? this.loginCompanyId
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? ''
            : this.loginCompanyId;

        companyId = companyId ? companyId : '';

        this.displayBlock = {
            getUrl: API_CLINICIAN.GET_NURSE_JOB_LIST,
            isServerSidePagination: true,
            isAllowPaging: true,
            sortOrder: [[1, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            isQueryParam: true,
            queryparam: '&clinicianId=' + clinicianId + '&marketId=' + '&hospitalId=' + '&companyId=' + companyId,
            rows: [
                {
                    name: 'hospitalName',
                    label: 'Facility',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },
                {
                    name: 'hiredDate',
                    label: 'Hired Date',
                    type: 'dateUTCToLocal',
                    colSize: '1%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'licenseTypeAcronym',
                    label: 'License',
                    type: 'text',
                    colSize: '10%',
                    sorting: true,
                },
                {
                    name: 'specialityName',
                    label: 'Specialty',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                    maxLength: '30',
                },
                {
                    name: 'nurseLevelName',
                    label: 'Level',
                    type: 'text',
                    colSize: '10%',
                    sorting: true,
                    pipe: 'title',
                },
                {
                    name: 'status',
                    label: 'Job Status',
                    type: 'pipeline-status',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'clinicianStatus',
                    label: 'Nurse Status',
                    type: 'status',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'nurse-action',
                    colSize: '5%',
                    visible: this.isActionBtnShow,
                    actions: {
                        isEdit: false,
                        isDeactivate: false,
                        isDelete: false,
                        isView: true,
                        isChat: false,
                    },
                },
            ],
            options: {
                add: true,
                clear: false,
                delete: true,
                search: true,
                export: false,
                active: true,
            },
        };

        this.dataTableModel.displayBlock = this.displayBlock;
    }

    shiftdataTableBinding(isActive: any) {
        let that = this;
        let licenseId = this.selectedlicenseTypeId == null ? '' : this.selectedlicenseTypeId;
        let specialityIds = this.selectedJobSpeciality == null ? '' : this.selectedJobSpeciality;
        let status = this.selectedStatusId ?? '';
        let facilityId = this.facilityId ?? '';
        let hospitalId = this.hospitalId ?? null;
        let marketId = this.marketId ?? null;

        let companyId = this.isAgencyLevelLogin
            ? ''
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? ''
            : this.loginCompanyId;

        companyId = companyId ? companyId : '';

        let clinicianId = this.model?.clinicianId ?? this.id;

        this.displayBlock = {
            getUrl: API_CLINICIAN.GET_NURSE_SHIFT,
            isServerSidePagination: true,
            isActive: isActive ?? null,
            isAllowPaging: true,
            sortOrder: [[1, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            isQueryParam: true,
            isLoadData: true,
            queryparam:
                '&clinicianId=' +
                clinicianId +
                '&facilityIds=' +
                facilityId +
                '&hospitalId=' +
                '' +
                '&companyId=' +
                companyId +
                '&marketId=' +
                '' +
                '&status=' +
                status +
                '&LicenseId=' +
                licenseId +
                '&specialityId=' +
                specialityIds,
            rows: [
                {
                    name: 'hospitalName',
                    label: 'Facility',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },
                {
                    name: 'licenseTypeAcronym',
                    label: 'License',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'specialityAcronym',
                    label: 'Specialty',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '20',
                },
                {
                    name: 'date',
                    label: 'Date',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'fromTime',
                    label: 'Time',
                    type: 'timeFormat',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'status',
                    label: 'Status',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '5%',
                    actions: {
                        isEdit: false,
                        isDeactivate: false,
                        isDelete: false,
                        isProfileShiftView: true,
                    },
                },
            ],
            options: {
                add: true,
                clear: false,
                delete: true,
                search: true,
                export: false,
                active: true,
            },
        };

        this.shiftDataTableModel.displayBlock = this.displayBlock;
    }

    timesheetdataTableBinding(isActive: any) {
        let that = this;
        let facilityId = this.timesheetFacilityId == null ? '' : this.timesheetFacilityId;
        let clinicianId = this.model?.clinicianId ?? this.id;
        let hospitalId = this.hospitalId ?? null;
        let marketId = this.marketId ?? null;

        let companyId = this.isAgencyLevelLogin
            ? ''
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? ''
            : this.loginCompanyId;

        companyId = companyId ? companyId : '';

        this.displayBlock = {
            getUrl: API_CLINICIAN.GET_NURSE_TIMESHEETS,
            isServerSidePagination: true,
            isActive: isActive ?? null,
            isAllowPaging: true,
            sortOrder: [[1, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            isQueryParam: true,
            isLoadData: true,
            queryparam:
                '&clinicianId=' +
                clinicianId +
                '&facilityId=' +
                facilityId +
                '&hospitalId=' +
                '' +
                '&companyId=' +
                companyId +
                '&marketId=' +
                '',
            rows: [
                {
                    name: 'hospitalName',
                    label: 'Facility',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },
                {
                    name: 'date',
                    label: 'Date',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'fromTime',
                    label: 'Schedule Time',
                    type: 'timeFormat',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'clockIn',
                    label: 'Punched Time',
                    type: 'timeFormatForPunched',
                    colSize: '20%',
                    sorting: true,
                },
                {
                    name: 'statusName',
                    label: 'Status',
                    type: 'timesheet-status',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '10%',
                    actions: {
                        isEdit: false,
                        isView: false,
                        isDeactivate: false,
                        isDelete: false,
                        isProfileTimesheetView: true,
                    },
                },
            ],
            options: {
                add: true,
                clear: false,
                delete: true,
                search: true,
                export: false,
                active: true,
            },
        };

        this.timeSheetDataTableModel.displayBlock = this.displayBlock;
    }

    notestdataTableBinding(isActive: any) {
        let marketId = this.marketId == '' ? null : this.marketId;

        let companyId = this.isAgencyLevelLogin
            ? this.loginCompanyId
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? this.companyId || null
            : this.loginCompanyId;

        companyId = companyId ? companyId : null;

        let hospitalId = this.hospitalId == '' ? null : this.hospitalId;

        let loginFrom = this.isAgencyLevelLogin
            ? 'AgencyLevel'
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? 'ThriveLevel' || null
            : 'HealthcareLevel';

        let authorNames = null;
        if (this.authorId != null) {
            console.log(this.authorId);
        }

        this.displayBlock = {
            getUrl: API_CLINICIAN.GET_NOTELIST,
            isServerSidePagination: true,
            isRequestObject: true,
            isAllowPaging: true,
            sortOrder: [[2, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            isLoadData: true,
            requestType: 'POST',
            requestObject: {
                clinicianId: this.id,
                authorIds: this.authorId != null ? String(this.authorId) : null,
                types: this.typeId != null ? String(this.typeId) : null,
                marketId: null,
                companyId: this.loginCompanyId ? this.loginCompanyId : null,
                hospitalId: null,
                loginFrom: loginFrom,
            },

            rows: [
                {
                    name: 'author',
                    label: 'Author',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                },
                {
                    name: 'categoryName',
                    label: 'Category',
                    type: 'text',
                    colSize: '10%',
                    sorting: true,
                },
                {
                    name: 'date',
                    label: 'Date',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'note',
                    label: 'Note',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '10%',
                    actions: {
                        isView: true,
                    },
                },
            ],
            options: {
                add: false,
                clear: false,
                delete: false,
                search: true,
                export: false,
            },
        };
        this.notesDataTableModel.displayBlock = this.displayBlock;
        this.notesDataTableModel.requestType = 'POST';
    }

    restrictiondataTableBinding(isActive: any) {
        let marketId = this.marketId === '' ? null : parseInt(this.marketId, 10);

        let companyId = this.isAgencyLevelLogin
            ? this.loginCompanyId
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? null
            : this.loginCompanyId;

        companyId = companyId ? companyId : null;

        let hospitalId = this.hospitalId === '' ? null : parseInt(this.hospitalId, 10);

        this.displayBlock = {
            getUrl: API_CLINICIAN.POST_RESTICTIONHISTORIES,
            isServerSidePagination: true,
            isRequestObject: true,
            isAllowPaging: true,
            sortOrder: [[3, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            requestType: 'POST',
            requestObject: {
                clinicianId: this.id,
                type: this.selectedType != null ? String(this.selectedType) : '',
                marketId: null,
                companyId: companyId,
                hospitalId: null,
                facilityIds: this.selectedfacilityId != null ? String(this.selectedfacilityId) : '',
            },

            rows: [
                {
                    name: 'hospitalName',
                    label: 'Facility',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                },
                {
                    name: 'status',
                    label: 'Type',
                    type: 'text',
                    colSize: '10%',
                    sorting: true,
                },
                {
                    name: 'suspendedFrom',
                    label: 'Suspension range',
                    type: 'date',
                    colSize: '20%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'verifiedOn',
                    label: 'When',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'verifyByName',
                    label: 'By Who',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '10%',
                    actions: {
                        isView: false,
                        isEdit: false,
                        isRestrictionView: true,
                    },
                },
            ],
            options: {
                add: false,
                clear: false,
                delete: false,
                search: true,
                export: false,
            },
        };
        this.restrictionDataTableModel.displayBlock = this.displayBlock;
        this.restrictionDataTableModel.requestType = 'POST';
    }

    documentdataTableBinding(isActive: any) {
        let marketId = this.marketId === '' ? null : parseInt(this.marketId, 10);
        let companyId = this.companyId === '' ? null : parseInt(this.companyId, 10);
        let hospitalId = this.hospitalId === '' ? null : parseInt(this.hospitalId, 10);

        this.displayBlock = {
            getUrl: API_CLINICIAN.POST_CLINICIAN_DOCUMENTS,
            isServerSidePagination: true,
            isRequestObject: true,
            isAllowPaging: true,
            sortOrder: [[4, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            requestType: 'POST',
            requestObject: {
                clinicianId: this.id,
                companyId: companyId,
                hospitalId: hospitalId,
                marketId: marketId,
                status: this.selectedDocumentStatusId || '',
                type: this.selectedDocumentType || '',
            },

            rows: [
                {
                    name: 'documentTitle',
                    label: 'Document',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                },
                {
                    name: 'documentType',
                    label: 'Document Type',
                    type: 'text',
                    colSize: '10%',
                    sorting: true,
                },
                {
                    name: 'status',
                    label: 'Status',
                    type: 'document-status',
                    colSize: '12%',
                    sorting: true,
                },
                {
                    name: 'expiryDate',
                    label: 'Exp. Date',
                    type: 'dateUTCToLocal',
                    colSize: '20%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'uploadedOn',
                    label: 'Upload Date',
                    type: 'dateUTCToLocal',
                    colSize: '20%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'verifiedon',
                    label: 'Verified On',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'verifiedByName',
                    label: 'Verified By',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '12%',
                    actions: {
                        isEdit: false,
                        isDeactivate: false,
                        isDelete: false,
                        isView: false,
                        isChat: false,
                        isExternalLink: true,
                    },
                },
            ],
            options: {
                add: false,
                clear: false,
                delete: false,
                search: true,
                export: false,
            },
        };
        this.documentDataTableModel.displayBlock = this.displayBlock;
        this.documentDataTableModel.requestType = 'POST';
    }

    documentRowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        let type = '';
        let label = '';
        let url = '';
        if (event.data.documentType == 'License') {
            label = 'License Verification';
            type = 'license';
            url = this.apiUrl.UPDATE_CLINICIAN_LICENSE_STATUS;
        } else if (event.data.documentType == 'Background Check') {
            label = 'Background Check Verification';
            type = 'backgroundCheck';
            url = this.apiUrl.UPDATE_CLINICIAN_BACKGROUND_CHECK_STATUS;
        } else if (event.data.documentType == 'Credential') {
            label = 'Credential Verification';
            type = 'credential';
            url = this.apiUrl.UPDATE_CLINICIAN_CREDENTIAL_STATUS;
        } else if (event.data.documentType == 'Education') {
            label = 'Education Verification';
            type = 'education';
            url = this.apiUrl.UPDATE_CLINICIAN_EDUCATION_STATUS;
        }

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                if (event.data.documentType != 'Document' && event.data.documentType != 'Employement Form') {
                    that.viewProfileDetails(event.data.id, type, event.data, label, url);
                } else {
                    that.viewDocument(event.data);
                }
            });

        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                if (event.data.documentType != 'Document' && event.data.documentType != 'Employement Form') {
                    that.updateStatus(event.data, event.data.id, label, url, type);
                } else {
                    that.updateStatusDocument(event.data);
                }
            });

        _row.find('[action-type="openExternalFileLink"]')
            .off('click')
            .on('click', function () {
                that.onOpenFile(event?.data.fileUrl);
            });
    }

    viewDocument(data: any) {
        this.model.clinicianProfileUrl = this.clinicianDetails.clinicianProfileUrl;
        this.model.clinicianLicences = this.license;
        this.model.firstName = this.clinicianDetails?.firstName;
        this.model.lastName = this.clinicianDetails?.lastName;
        this.model.clinicianEmail = this.clinicianDetails?.email;
        this.model.clinicianPhoneNumber = this.clinicianDetails?.phoneNumber;
        this.model.clinicianStateName = this.clinicianDetails?.stateAcronym;
        this.model.clinicianCityName = this.clinicianDetails?.cityName;
        this.model.clinicianId = this.model?.clinicianId ?? this.id;
        this.model.jobId = data.jobId;
        const modalRef = this.modalService.open(DocumentationVerificationModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.data = this.model;
        modalRef.componentInstance.jobApplicantId = data.jobApplicantId;
    }

    updateStatusDocument(data: any) {
        this.model.clinicianProfileUrl = this.clinicianDetails.clinicianProfileUrl;
        this.model.clinicianLicences = this.license;
        this.model.firstName = this.clinicianDetails?.firstName;
        this.model.lastName = this.clinicianDetails?.lastName;
        this.model.clinicianEmail = this.clinicianDetails?.email;
        this.model.clinicianPhoneNumber = this.clinicianDetails?.phoneNumber;
        this.model.clinicianStateName = this.clinicianDetails?.stateAcronym;
        this.model.clinicianCityName = this.clinicianDetails?.cityName;
        this.model.clinicianId = this.model?.clinicianId ?? this.id;
        this.model.jobId = data.jobId;
        this.documentDataTableModel.isLoadData = false;
        const modalRef = this.modalService.open(DocumentationVerificationModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.data = this.model;
        modalRef.componentInstance.jobApplicantId = data.jobApplicantId;

        modalRef.result.then(
            () => {
                this.documentDataTableModel.isLoadData = true;
            },
            () => {}
        );
    }

    onOpenFile(fileUrl: any) {
        window.open(fileUrl);
    }

    updateStatus(data: any, id: any, label: any, url: any, type: any) {
        this.model.clinicianLicences = this.license;
        this.model.firstName = this.clinicianDetails?.firstName;
        this.model.lastName = this.clinicianDetails?.lastName;
        this.model.clinicianEmail = this.clinicianDetails?.email;
        this.model.clinicianPhoneNumber = this.clinicianDetails?.phoneNumber;
        this.model.clinicianStateName = this.clinicianDetails?.stateAcronym;
        this.model.clinicianCityName = this.clinicianDetails?.cityName;
        this.model.clinicianId = this.model?.clinicianId ?? this.id;
        this.model.clinicianProfileUrl = this.clinicianDetails.clinicianProfileUrl;
        this.documentDataTableModel.isLoadData = false;
        const modalRef = this.modalService.open(UpdateVerficationModelComponent, {
            size: 'lg',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.data = this.model;
        modalRef.componentInstance.label = label;
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.moduleURL = url;
        modalRef.componentInstance.jobApplicantId = data.jobApplicantId;
        modalRef.result.then(() => {
            this.documentDataTableModel.isLoadData = true;
        });
    }

    onFacilityChange(event: any) {
        this.selectedfacilityId = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event.length == 0) {
            this.selectedfacilityId = null;

            if (this.selectedType == null) {
                this.resetFilter();
            }
        }
    }

    onRestrictionTypeChange(event: any) {
        this.selectedType = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event.length == 0) {
            this.selectedType = null;

            if (this.selectedfacilityId == null) {
                this.resetFilter();
            }
        }
    }

    onDocumentTypeChange(event: any) {
        this.selectedDocumentType = event;
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event == null) {
            this.selectedDocumentType = null;
            if (this.selectedDocumentStatusId == null) {
                this.resetDocument();
            }
        }
    }

    onDocumentStatusChange(event: any) {
        this.selectedDocumentStatus = event;
        this.selectedDocumentStatusId = event.join(',');
        this.isDisableApply = false;
        this.isShowResetBtn = false;

        if (event.length == 0) {
            this.selectedDocumentStatus = null;
            if (this.selectedDocumentType == null) {
                this.resetDocument();
            }
        }
    }

    filterDocument() {
        this.isShowResetBtn = true;
        this.documentDataTableModel.isLoadData = false;
        this.documentdataTableBinding(true);
        this.documentDataTableModel.isLoadData = true;
    }

    getRestrictedFacility() {
        let companyId = this.isAgencyLevelLogin
            ? this.loginCompanyId
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? null
            : this.loginCompanyId;

        const payload = {
            clinicianId: this.model.clinicianId ?? this.id,
            marketId: null,
            companyId: companyId ? Number(companyId) : null,
            hospitalId: null,
        };

        this.jobTemplateService.getRestrictedFacility(payload).subscribe((response: any) => {
            this.restrictedFacilityList = response.result;
        });
    }

    calloutdataTableBinding(isActive: any) {
        let that = this;
        let facilityId = this.facilityId ?? '';
        let hospitalId = this.hospitalId ?? '';
        let marketId = this.marketId ?? '';

        let companyId = this.isAgencyLevelLogin
            ? ''
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? ''
            : this.loginCompanyId;

        companyId = companyId ? companyId : '';

        let clinicianId = this.model?.clinicianId ?? this.id;
        let status = this.selectedStatusId == null ? Shift.CalledOut + ',' + Shift.NCNS : this.selectedStatusId;

        this.displayBlock = {
            getUrl: API_CLINICIAN.GET_NURSE_SHIFT,
            isServerSidePagination: true,
            isActive: isActive ?? null,
            isAllowPaging: true,
            sortOrder: [[3, 'desc']],
            isShowPaginationInfo: true,
            isSearching: false,
            isQueryParam: true,
            isLoadData: true,
            queryparam:
                '&clinicianId=' +
                clinicianId +
                '&facilityIds=' +
                facilityId +
                '&hospitalId=' +
                '' +
                '&companyId=' +
                companyId +
                '&marketId=' +
                '' +
                '&status=' +
                status,
            rows: [
                {
                    name: 'status',
                    label: 'Type',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'hospitalName',
                    label: 'Facility',
                    type: 'text',
                    colSize: '20%',
                    sorting: true,
                    maxLength: '35',
                },

                {
                    name: 'date',
                    label: 'Shift Date',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'statusUpdatedDate',
                    label: 'Callout/NCNS Date',
                    type: 'dateUTCToLocal',
                    colSize: '10%',
                    pipe: 'localDateTime',
                    sorting: true,
                },
                {
                    name: 'reason',
                    label: 'Reason',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'excusedStatus',
                    label: 'Status',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'verifiedByName',
                    label: 'Admin',
                    type: 'text',
                    colSize: '15%',
                    sorting: true,
                },
                {
                    name: 'action',
                    label: 'Actions',
                    type: 'action',
                    colSize: '5%',
                    actions: {
                        isEdit: false,
                        isDeactivate: false,
                        isDelete: false,
                        isCalloutView: true,
                    },
                },
            ],
            options: {
                add: true,
                clear: false,
                delete: true,
                search: true,
                export: false,
                active: true,
            },
        };
        this.calloutstDataTableModel.displayBlock = this.displayBlock;
    }
    onAuthorChange(event: any) {
        this.authorId = event;
        this.notestdataTableBinding(true);
    }

    onTypeChange(event: any) {
        this.typeId = event;
        this.notestdataTableBinding(true);
    }

    getAuthorList() {
        let loginFrom = this.isAgencyLevelLogin
            ? 'AgencyLevel'
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? 'ThriveLevel' || null
            : 'HealthcareLevel';

        const payload = {
            clinicianId: this.model.clinicianId ?? this.id,
            marketId: null,
            companyId: this.loginCompanyId ? this.loginCompanyId : null,
            hospitalId: null,
            loginFrom: loginFrom,
        };
        this.jobTemplateService.getAuthorList(payload).subscribe((response: any) => {
            this.authorList = response.result;
        });
    }

    getJobTypeList() {
        this.jobTemplateService.getJobTypeList().subscribe((response: any) => {
            this.licenseType = response.result;
        });
    }

    filterRestriction() {
        this.isShowResetBtn = true;
        this.restrictionDataTableModel.isLoadData = false;
        this.restrictiondataTableBinding(true);
        this.restrictionDataTableModel.isLoadData = true;
    }

    resetDocument() {
        this.documentDataTableModel.isLoadData = false;
        this.isShowResetBtn = false;
        this.selectedDocumentType = null;
        this.selectedDocumentStatusId = null;
        this.selectedDocumentStatus = null;
        this.isDisableApply = true;
        this.documentdataTableBinding(true);
        this.documentDataTableModel.isLoadData = true;
    }

    resetRestriction() {
        this.selectedfacilityId = null;
        this.selectedType = null;
        this.selectedStatus = null;
        this.isDisableApply = true;
        this.isShowResetBtn = false;
        this.restrictionDataTableModel.isLoadData = false;
        this.restrictiondataTableBinding(true);
        this.restrictionDataTableModel.isLoadData = true;
    }

    filterShiftList(callingFrom?: any) {
        this.isShowResetBtn = true;
        if (callingFrom) {
            this.calloutstDataTableModel.isLoadData = false;
            this.calloutdataTableBinding('');
            this.calloutstDataTableModel.isLoadData = true;
        } else {
            this.shiftDataTableModel.isLoadData = false;
            this.shiftdataTableBinding('');
            this.shiftDataTableModel.isLoadData = true;
        }
    }

    onRestriction() {
        let ids: any = {
            hospitalId: this.hospitalId ? this.hospitalId : null,
            companyId: this.companyId ? this.companyId : null,
        };
        const modalRef = this.modalService.open(StaffRestrictionModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });

        this.model.clinicianId = this.model?.clinicianId ?? this.id;
        this.model.firstName = this.clinicianDetails?.firstName;
        this.model.lastName = this.clinicianDetails?.lastName;
        this.model.clinicianProfileUrl = this.clinicianDetails?.clinicianProfileUrl;
        this.model.clinicianStateName = this.clinicianDetails?.stateAcronym;
        this.model.clinicianCityName = this.clinicianDetails?.cityName;
        this.model.clinicianLicences = this.license;
        modalRef.componentInstance.model = this.model;
        modalRef.componentInstance.ids = ids;
        modalRef.result.then(
            () => {
                this.modal.close();
            },
            () => {}
        );
    }

    getShiftHistory() {
        let companyId = this.isAgencyLevelLogin
            ? ''
            : !this.isCompanyLevelLogin && !this.isFacilityLevelLogin
            ? ''
            : this.loginCompanyId;

        companyId = companyId ? companyId : '';

        let reqObject = {
            id: null,
            marketId: null,
            companyId: !companyId ? null : companyId,
            hospitalId: null,
            clinicianId: this.model?.clinicianId ?? this.id,
        };
        this.staffService.getShiftHistory(reqObject).subscribe((response: any) => {
            if (response.result) {
                this.shiftHistoryDetail = response.result;
            }
        });
    }

    rowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                that.onDocumentationSummary(event.data);
            });
    }

    timesheetRowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                that.onTimesheetDetails(event.data);
            });

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                that.onTimesheetDetails(event.data);
            });
    }

    restrictionRowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                that.onRestrictionData(event.data);
            });

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                that.onRestrictionData(event.data);
            });
    }

    onRestrictionData(model: any) {
        const modalRef = this.modalService.open(AddViewRestrictionComponent, {
            size: 'xl',
            backdrop: 'static',
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.id = model.clinicianId;
        modalRef.componentInstance.hospitalId = this.hospitalId || '';
        modalRef.componentInstance.companyId = this.companyId || '';
        modalRef.componentInstance.marketId = this.marketId || '';
        this.restrictionDataTableModel.isLoadData = false;
        modalRef.result.then((res: any) => {
            if (res == true) {
                this.restrictionDataTableModel.isLoadData = true;
            }
        });
    }

    shiftRowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                that.onShiftDetails(event.data);
            });

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                that.onShiftDetails(event.data);
            });
    }

    calloutRowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        _row.find('[action-type="edit"]');
        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                that.onShiftDetails(event.data, 'callout');
            });

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                that.onShiftDetails(event.data, 'callout');
            });
    }

    onShiftDetails(event: any, callingFrom?: any) {
        const modalRef = this.modalService.open(ShiftInspectorDetailsComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.data = event;
        modalRef.componentInstance.date = this.date;
        modalRef.componentInstance.companyId = this.companyId ?? '';
        modalRef.componentInstance.hospitalId = this.hospitalId ?? '';
        modalRef.componentInstance.marketId = this.marketId ?? '';

        modalRef.result.then(() => {
            this.skip = 0;
            this.take = 12;
            if (callingFrom) {
                this.calloutstDataTableModel.isLoadData = false;
                this.calloutdataTableBinding('');
                this.calloutstDataTableModel.isLoadData = true;
            } else {
                this.shiftDataTableModel.isLoadData = false;
                this.shiftdataTableBinding(true);
                this.shiftDataTableModel.isLoadData = true;
            }
        });
    }
    noteRowCallback(event: any) {
        let that = this;
        const _row = $(event.row);

        _row.find('[action-type="view"]')
            .off('click')
            .on('click', function () {
                that.isAddNoteMode = false;
                that.onNotesDetails(event.data);
            });
    }

    create() {
        this.isAddNoteMode = true;
        this.onNotesDetails(null);
    }

    createRestriction() {
        this.onRestrictionDetails();
    }

    onRestrictionDetails() {
        let ids: any = {
            hospitalId: this.hospitalId ? this.hospitalId : null,
            companyId: this.companyId ? this.companyId : null,
        };
        const modalRef = this.modalService.open(StaffRestrictionModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        this.model.clinicianId = this.model?.clinicianId ?? this.id;
        this.model.firstName = this.clinicianDetails?.firstName;
        this.model.lastName = this.clinicianDetails?.lastName;
        this.model.lastName = this.clinicianDetails?.lastName;
        modalRef.componentInstance.model = this.model;
        modalRef.componentInstance.ids = ids;
        modalRef.result.then(() => {
            if (this.displayFor == 'nurse' || this.displayFor == 'restricted') {
                this.modal.close();
            } else {
                this.restrictionDataTableModel.isLoadData = false;
                this.restrictiondataTableBinding('');
                this.restrictionDataTableModel.isLoadData = true;
            }
        });
    }

    onNotesDetails(model: any) {
        this.notesDataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(AddViewNoteComponent, {
            size: 'xl',
            backdrop: false,
            centered: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.hospitalId = this.hospitalId;
        modalRef.componentInstance.marketId = this.marketId;
        modalRef.componentInstance.companyId = this.companyId;
        modalRef.componentInstance.clinicianId = this.id;
        modalRef.componentInstance.isAddNoteMode = this.isAddNoteMode;
        modalRef.result.then(
            () => (this.notesDataTableModel.isLoadData = true),
            () => {}
        );
    }

    onTimesheetDetails(data: any) {
        this.dataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(TimesheetUpdateStatusComponent, {
            size: 'xl',
            backdrop: false,
            centered: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.model = data;
        modalRef.result.then(
            () => (
                (this.timeSheetDataTableModel.isLoadData = false),
                this.timesheetdataTableBinding(''),
                (this.timeSheetDataTableModel.isLoadData = true)
            )
        );
    }

    onDocumentationSummary(data: any) {
        const modalRef = this.modalService.open(DocumentationVerificationModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        let obj = {
            clinicianProfileUrl: this.clinicianDetails.clinicianProfileUrl,
            clinicianFirstName: this.clinicianDetails.firstName,
            clinicianLastName: this.clinicianDetails.lastName,
            clinicianCityName: this.clinicianDetails.cityName,
            clinicianStateName: this.clinicianDetails.stateAcronym,
            clinicianEmail: this.clinicianDetails.email,
            clinicianPhoneNumber: this.clinicianDetails.phoneNumber,
            clinicianLicences: this.license,
            clinicianId: this.model?.clinicianId ?? this.id,
            jobId: data.jobId,
        };
        modalRef.componentInstance.data = obj;
        modalRef.componentInstance.id = data.jobApplicantId;
        modalRef.componentInstance.jobApplicantId = data.jobApplicantId;
    }

    initForm() {
        this.nurseRestrictionForm = this.fb.group({
            id: [this.model.id],
            suspendedFrom: ['', Validators.compose([Validators.required])],
            suspendedTill: ['', Validators.compose([Validators.required])],
            description: [null, Validators.compose([removeSpaces])],
            verifiedById: [''],
            verifiedOn: [''],
        });
    }

    onCheckboxChange(event: any) {
        this.isUpdateRestrictedNurse = event;
        const currentTime = new Date();
        const selectedSuspendedFromDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedFrom);
        this.nurseRestrictionForm.controls.suspendedFrom.setValue(selectedSuspendedFromDate);
        selectedSuspendedFromDate.setHours(currentTime.getHours());
        selectedSuspendedFromDate.setMinutes(currentTime.getMinutes());
        selectedSuspendedFromDate.setSeconds(currentTime.getSeconds());
        this.selectedSuspendedFromDate = {
            year: selectedSuspendedFromDate.getFullYear(),
            month: selectedSuspendedFromDate.getMonth() + 1,
            day: selectedSuspendedFromDate.getDate(),
        };

        const selectedSuspendedtillDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedTill);
        this.nurseRestrictionForm.controls.suspendedTill.setValue(selectedSuspendedtillDate);
        selectedSuspendedtillDate.setHours(currentTime.getHours());
        selectedSuspendedtillDate.setMinutes(currentTime.getMinutes());
        selectedSuspendedtillDate.setSeconds(currentTime.getSeconds());
        this.selectedSuspendedtillDate = {
            year: selectedSuspendedtillDate.getFullYear(),
            month: selectedSuspendedtillDate.getMonth() + 1,
            day: selectedSuspendedtillDate.getDate(),
        };
        this.isDisabled = true;
    }

    onDateSelectChange(event: any, type: any) {
        if (type == 'till') {
            this.nurseRestrictionForm.controls.suspendedTill.setValue(event);
        } else {
            this.nurseRestrictionForm.controls.suspendedFrom.setValue(event);
        }
        this.updateValue();
    }

    onDescriptionChange(event: any) {
        if (event.target.value?.length > 0) {
            this.nurseRestrictionForm.controls.description.setValue(event.target.value);
        } else {
            this.nurseRestrictionForm.controls.description.setValue(null);
        }
        this.updateValue();
    }

    updateValue() {
        const selectedSuspendedFromDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedFrom);
        const selectedSuspendedtillDate = this.dateUtilService.convertUtcToLocalDate(this.model?.suspendedTill);

        if (
            selectedSuspendedFromDate.toDateString() !=
                this.nurseRestrictionForm.controls.suspendedFrom.value.toDateString() ||
            selectedSuspendedtillDate.toDateString() !=
                this.nurseRestrictionForm.controls.suspendedTill.value.toDateString() ||
            this.nurseRestrictionForm.controls.description.value?.length
        ) {
            this.isDisabled = false;
        } else {
            this.isDisabled = true;
        }
    }

    onUpdateRestrictionNurse() {
        let nurseReastrictionModel = this.nurseRestrictionForm.value;

        const suspendedTillDate = moment(nurseReastrictionModel.suspendedTill, 'YYYY-MM-DD'); // adjust format as necessary
        const suspendedFromDate = moment(nurseReastrictionModel.suspendedFrom, 'YYYY-MM-DD'); // adjust format as necessary

        if (suspendedTillDate.isBefore(suspendedFromDate)) {
            this.toastrService.error('Suspended until Date should be greater than Suspended from Date');
            return;
        }

        nurseReastrictionModel.description =
            nurseReastrictionModel.description?.length > 0 ? nurseReastrictionModel.description : null;
        nurseReastrictionModel.verifiedOn = new Date().toISOString();
        nurseReastrictionModel.verifiedById = Number(sessionStorage.getItem('contactId'));

        if (nurseReastrictionModel.suspendedTill) {
            nurseReastrictionModel.suspendedTill = this.dateUtilService.convertDateCurrentTimeToUTC(
                nurseReastrictionModel.suspendedTill
            );
        }
        if (nurseReastrictionModel.suspendedFrom) {
            nurseReastrictionModel.suspendedFrom = this.dateUtilService.convertDateCurrentTimeToUTC(
                nurseReastrictionModel.suspendedFrom
            );
        }
        this.updateRestrictionNurse(nurseReastrictionModel);
    }

    updateRestrictionNurse(nurseReastrictionModel: any) {
        this.staffService.updateRestrictedNurse(nurseReastrictionModel).subscribe(
            (response: any) => {
                if (response.result) {
                    this.activeModal.close(true);
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.message);
            }
        );
    }

    openCertificateUploadScreen(type: any, title: string) {
        let modalRef = this.modalService.open(UploadNurseCredentialModelComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });

        this.clinicianDetails.licenses = this.license;
        this.clinicianDetails.clinicianId = this.model?.clinicianId ?? this.id;
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.data = this.clinicianDetails;

        modalRef.result.then(() => {
            this.getProfileDetail(this.clinicianDetails.clinicianId);
        });
    }

    getRestrictionDetails() {
        let id = this.model?.id;
        this.jobTemplateService.getNurseRestrictionDetails(id).subscribe((response) => {
            this.model = response.result;
        });
    }
}
