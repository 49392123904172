import { NgModule } from '@angular/core';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { ConvertToUtcPipe } from './convert-to-utc.pipe';
import { MinutesToHoursPipe } from './minute-to-hour.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { CustomDatePipe, LocalDateTimePipe } from './local-date-time.pipe';
import { StripHtmlPipe } from './strip-html.pipe';

@NgModule({
    declarations: [
        DateAgoPipe,
        ConvertToUtcPipe,
        MinutesToHoursPipe,
        EllipsisPipe,
        LocalDateTimePipe,
        StripHtmlPipe,
        CustomDatePipe,
    ],
    imports: [],
    exports: [DateAgoPipe, MinutesToHoursPipe, EllipsisPipe, LocalDateTimePipe, StripHtmlPipe, CustomDatePipe],
})
export class PipesModule {}
