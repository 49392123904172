import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatDetailsComponent } from './chat-details/chat-details.component';
import { ChatService } from 'src/app/service/chat.service';
import { LocalDateTimePipe } from 'src/app/pipes/local-date-time.pipe';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css'],
    providers: [LocalDateTimePipe],
})
export class ChatComponent implements OnInit {
    defaultNavActiveId = 1;
    take: number = 6;
    allDataLoaded: boolean = false;
    type: any = '';
    // Sample data to match the UI design from the image
    chatList: any[] = [];
    @ViewChild('scrollable') private scrollContainer: ElementRef<HTMLDivElement>;

    constructor(public modal: NgbActiveModal, private modalService: NgbModal, private chatService: ChatService) {}
    isLoading: boolean = false;
    groupId: any;
    ngOnInit() {
        this.getChatList(null);
    }

    dismissModel() {
        this.modal.close();
    }

    onChatDetails(data: any) {
        const modalRef = this.modalService.open(ChatDetailsComponent, {
            size: 'xl',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.data = data;
        modalRef.result.then((res) => {
            // Find the index of the chat in the chatList array
            // const index = this.chatList.findIndex((chat) => chat.id === updatedChat.id);
            // Update the specific chat in the chatList array
            // if (index !== -1) {
            //     this.chatList[index] = updatedChat;
            // }
            if (res) {
                this.groupId = res;
                this.chatList = [];
                this.allDataLoaded = false;
                this.getChatList(res);
            }
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        const target = this.scrollContainer.nativeElement;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
            this.loadMore();
        }
    }

    loadMore(): void {
        if (!this.isLoading && !this.allDataLoaded) {
            this.getChatList(null);
        }
    }

    getChatList(groupId: any) {
        this.isLoading = true;
        let obj = {
            Skip: this.chatList.length,
            Take: this.take,
            type: this.type,
            chatGroupId: groupId == null ? ' ' : groupId,
        };

        this.chatService.getChatList(obj).subscribe(
            (response: any) => {
                if (response.result) {
                    if (response.pageDetail) {
                        this.take = response.pageDetail.take; // Update take value
                    }
                    if (response.result.length < this.take) {
                        this.allDataLoaded = true;
                    }
                    this.chatList = [...this.chatList, ...response.result];
                }
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
                // Handle error
            }
        );
    }

    onNavChange(data: any) {
        this.isLoading = false;
        this.allDataLoaded = false;

        if (data == 1) {
            this.type = '';
        } else if (data == 2) {
            this.type = data;
        } else if (data == 3) {
            this.type = 1;
        } else {
            this.type = 3;
        }
        this.take = 6;
        this.chatList = [];
        this.getChatList(null);
    }
}
