import { Component, DebugElement, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';

@Component({
    selector: 'app-input-file-picker',
    templateUrl: './input-file-picker.component.html',
    styleUrls: ['./input-file-picker.component.scss'],
})
export class InputFilePickerComponent implements OnInit {
    public changed: (value: any) => void;
    @Input() parentForm: FormGroup;
    @Input() fieldName: string;
    @Input() label: string;
    @Input() aspectRatio = 1 / 1;
    public isDisabled: boolean;

    image: any = './assets/images/others/blank.png';
    profileLogoBase64: any;
    logoFile: any = '';
    isShowFileLogo = false;
    @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {}
    get formField(): FormControl {
        return this.parentForm?.get(this.fieldName) as FormControl;
    }

    onFileChange(event: any) {
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
        this.image = '';

        const modalRef = this.modalService.open(ImageCropperComponent);
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.aspectRatio = this.aspectRatio;
        modalRef.result.then(
            (res) => {
                if (res) {
                    this.fileInput.nativeElement.value = null;
                    this.moreInfoLogoImagePreview(res);
                }
            },
            () => {}
        );
    }

    moreInfoLogoImagePreview(event: any) {
        this.profileLogoBase64 = event.base64;
        var blob = this.dataURItoBlob(event.base64);

        let fileExt = blob.type.split('/')[1];
        this.logoFile = new File([blob], 'image.' + fileExt, {
            type: blob.type,
        });

        if (this.logoFile) {
            this.isShowFileLogo = true;
            this.image = '';
            this.fileSelected.emit(this.profileLogoBase64);
        }
    }

    dataURItoBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
        else byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }
}
