import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { TnResponse } from '../model/tnResponse.model';

@Injectable({
    providedIn: 'root',
})
class ApiService {
    constructor(private http: HttpClient) {}

    headAuth = {
        'Content-Type': 'application/json',
    };

    get<T>(apiUrl: string, headers?: object): Observable<any> {
        // TODO: Observable<T>
        let serviceObject;

        serviceObject = this.http.get<TnResponse<T>>(`${environment.API}${apiUrl}`, headers).pipe(
            map((response: TnResponse<T>) => {
                if (response.message !== '') {
                    return response; //TODO: response.result; along with pageDetail
                } else {
                    throw new Error(response.message);
                }
            })
        );

        return serviceObject;
    }

    public post<T>(apiUrl: string, itemName: T): Observable<any> {
        const to_add = JSON.stringify(itemName);

        let serviceObject;

        serviceObject = this.http
            .post<TnResponse<T>>(`${environment.API}${apiUrl}`, to_add, { headers: this.headAuth })
            .pipe(
                map((response: TnResponse<T>) => {
                    if (response.message !== '') {
                        return response;
                    } else {
                        throw new Error(response.message);
                    }
                })
            );

        return serviceObject;
    }

    public put<T>(apiUrl: string, itemName: T): Observable<any> {
        const to_add = JSON.stringify(itemName);

        let serviceObject;

        serviceObject = this.http
            .put<TnResponse<T>>(`${environment.API}${apiUrl}`, to_add, { headers: this.headAuth })
            .pipe(
                map((response: TnResponse<T>) => {
                    if (response.message !== '') {
                        return response;
                    } else {
                        throw new Error(response.message);
                    }
                })
            );

        return serviceObject;
    }

    public delete<T>(apiUrl: string, id: number): Observable<any> {
        let serviceObject;

        serviceObject = this.http.delete<TnResponse<T>>(`${environment.API}${apiUrl}/${id}`).pipe(
            map((response: TnResponse<T>) => {
                if (response.message !== '') {
                    return response;
                } else {
                    throw new Error(response.message);
                }
            })
        );

        return serviceObject;
    }

    public patch<T>(apiUrl: string, itemName: T): Observable<T> {
        const to_add = JSON.stringify(itemName);

        let serviceObject;

        serviceObject = this.http.patch<TnResponse<T>>(`${environment.API}${apiUrl}`, to_add).pipe(
            map((response: TnResponse<T>) => {
                if (response.message !== '') {
                    return response.result;
                } else {
                    throw new Error(response.message);
                }
            })
        );

        return serviceObject;
    }
}

export { ApiService };
