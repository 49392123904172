import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { BaseComponent } from './base/base.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import { ContentAnimateDirective } from '../../core/content-animate/content-animate.directive';

import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { FeatherIconModule } from '../../core/feather-icon/feather-icon.module';

import { TitlebarComponent } from './titlebar/titlebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PipesModule } from '../../pipes/pipe.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderComponent } from './header/header.component';

@NgModule({
    declarations: [
        BaseComponent,
        NavbarComponent,
        FooterComponent,
        ContentAnimateDirective,
        TitlebarComponent,
        BreadcrumbComponent,
        HeaderComponent,
    ],
    exports: [TitlebarComponent, BreadcrumbComponent, HeaderComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbDropdownModule,
        NgbCollapseModule,
        FeatherIconModule,
        PipesModule,
        SharedModule,
    ],
})
export class LayoutModule {}
