<!-- Shift Details Modal -->
<div class="profile-modal-content modal-content fixwidth overflow-hidden shift-details-modal" *ngIf="inspectorDetails">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <div class="modal-title">
            <h4 *ngIf="type != 'ExtendShift'">Shift Details</h4>
            <h4 *ngIf="type == 'ExtendShift'">Extend Shift Time</h4>
        </div>
        <label
            class="badge status-badge fw-bolder"
            *ngIf="!inspectorDetails?.status.includes(',')"
            [ngStyle]="{
                'background-color': getStatusColor(inspectorDetails?.status, 'background'),
                color: getStatusColor(inspectorDetails?.status, 'text'),
                'border-color': getStatusColor(inspectorDetails?.status, 'border'),
                'border-style': 'solid',
                'border-width': '1px'
            }"
            >{{ inspectorDetails?.status }}</label
        >
        <div *ngIf="inspectorDetails?.status.includes(',')">
            <label
                *ngFor="let status of inspectorDetails?.status.split(',')"
                class="badge status-badge fw-bolder flex-column ms-2"
                [ngStyle]="{
                    'background-color': getStatusColor(status, 'background'),
                    color: getStatusColor(status, 'text'),
                    'border-color': getStatusColor(status, 'border'),
                    'border-style': 'solid',
                    'border-width': '1px'
                }"
                >{{ status }}</label
            >
        </div>
    </div>
    <hr class="mt-0" />
    <form
        [formGroup]="shiftDetailsForm"
        class="form form-label-right overflow-auto"
        novalidate="novalidate"
        id="kt_category_form"
        [appUnsavedChanges]="shiftDetailsForm"
        [hasFormChanged]="hasFormChanged.bind(this)"
    >
        <div class="modal-body p-4 pt-0">
            <!-- Facility, Date, Shift Period -->
            <div class="row">
                <div class="my-2 col-md-5">
                    <h5>Facility</h5>
                    <p class="text-secondary">
                        {{ inspectorDetails?.hospitalName }}
                    </p>
                </div>
                <div class="my-2 col-md-3">
                    <h5>Date</h5>
                    <p class="text-secondary">{{ inspectorDetails?.date | date : 'mediumDate' }}</p>
                </div>
                <div class="my-2 col-md-4">
                    <h5>Shift Period</h5>
                    <p class="text-secondary">
                        {{ inspectorDetails?.fromTime | localDateTime : 'shortTime' }} -
                        {{ inspectorDetails?.toTime | localDateTime : 'shortTime' }}
                    </p>
                </div>
            </div>
            <!-- Extend Shift, Date, Shift Period, Bonus-->
            <div class="row" *ngIf="inspectorDetails?.isExtended">
                <div class="my-2 col-md">
                    <h5>Extended Time</h5>
                    <p class="text-secondary">
                        {{ inspectorDetails?.extendedTime + ' minutes' }}
                    </p>
                </div>
                <div class="my-2 col-md-4" *ngIf="inspectorDetails?.extendedBonus">
                    <h5>Extended Bonus</h5>
                    <p class="text-secondary">
                        {{
                            inspectorDetails?.extendedBonus
                                ? '$ ' + inspectorDetails?.extendedBonus
                                : inspectorDetails?.extendedBonus
                        }}
                    </p>
                </div>
                <div class="my-2 col-md-4">
                    <h5>New Shift Period</h5>
                    <p class="text-secondary">
                        {{ inspectorDetails?.fromTime | localDateTime : 'shortTime' }} -
                        {{ inspectorDetails?.newShiftTime | localDateTime : 'shortTime' }}
                    </p>
                </div>
            </div>
            <!-- License, Specialty -->
            <div class="row mt-2">
                <div class="col-md-4">
                    <h5>License</h5>
                    <p class="text-secondary">{{ inspectorDetails?.licenseTypeAcronym }}</p>
                </div>
                <div class="col-md-4">
                    <h5>Specialty</h5>
                    <p class="text-secondary">{{ inspectorDetails?.speciality }}</p>
                </div>
                <div class="my-2 col-md-4" *ngIf="inspectorDetails?.unitName != null">
                    <h5>Unit</h5>
                    <p class="text-secondary">{{ inspectorDetails?.unitName }}</p>
                </div>
            </div>
            <!-- Nurse Information -->
            <hr class="model-body-hr" />
            <div
                class="row my-2"
                *ngIf="
                    this.inspectorDetails?.status == 'Missing Clock-in' ||
                    this.inspectorDetails?.status == 'Missing Clock-out' ||
                    this.inspectorDetails?.status == 'Scheduled' ||
                    this.inspectorDetails?.status == 'In Progress'
                "
            >
                <div class="col-md-12">
                    <h4>Nurse Information</h4>
                    <div class="row mt-3">
                        <div class="col-md-2">
                            <img
                                *ngIf="inspectorDetails?.nurseInformation?.profileUrl"
                                [src]="inspectorDetails?.nurseInformation?.profileUrl"
                                height="70px"
                                width="70px"
                                class="rounded-circle"
                            />
                            <img
                                *ngIf="!inspectorDetails?.nurseInformation?.profileUrl"
                                src="./assets/images/others/blank.png"
                                height="70px"
                                width="70px"
                                class="rounded-circle"
                            />
                        </div>
                        <div class="col-md-5 d-flex align-items-center">
                            <div>
                                <h6 class="nurse-name">
                                    {{ inspectorDetails?.nurseInformation?.firstName }}
                                    {{ inspectorDetails?.nurseInformation?.lastName }}
                                </h6>
                                <div class="d-flex label-list">
                                    <label
                                        class="badge fw-bolder"
                                        [ngStyle]="{
                                            'background-color': type?.chipBackgroundColor,
                                            color: type?.chipFontColor
                                        }"
                                        *ngFor="let type of inspectorDetails?.nurseInformation?.clinicianLicences"
                                        >{{ type?.acronym }}</label
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 d-flex align-items-end flex-column">
                            <label
                                *ngIf="inspectorDetails.status == 'Missing Clock-in'"
                                class="badge d-flex align-items-end justify-content-center"
                                [ngStyle]="{
                            'background-color': '#FCE9E9',
                            color: '#103558',
                        }"
                                ><i class="feather icon-clock text-danger me-2"></i
                                ><span>{{ getTimeDifference(inspectorDetails?.fromTime) + ' late' }}</span></label
                            >
                            <label
                                *ngIf="inspectorDetails.status == 'In Progress'"
                                class="badge d-flex align-items-end justify-content-center"
                                [ngStyle]="{
                        'background-color': '#E9FCF4',
                        color: '#103558',
                    }"
                                ><i class="feather icon-clock text-success me-2"></i>
                                <span
                                    >Clocked-in at {{ inspectorDetails?.clockIn | localDateTime : 'shortTime' }}</span
                                ></label
                            >
                            <div class="d-flex justify-content-end mt-1 ms-auto">
                                <button
                                    class="btn btn-primary fw-semibold px-2 px-md-3"
                                    type="button"
                                    (click)="viewProfile(inspectorDetails)"
                                >
                                    Nurse profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="model-body-hr" />
            <div class="row mt-3">
                <h3 *ngIf="type == 'NcncAndReschedule' || type == 'NcnsOnly'">No Call No Show (NCNS) confirmation</h3>
                <h3 *ngIf="type == 'ReleaseCancel' || type == 'ReleaseReschedule'">Removal confirmation</h3>
                <h3 *ngIf="type == 'Scheduled'">Unschedule confirmation</h3>

                <p class="mt-2 text-secondary" *ngIf="type == 'NcncAndReschedule' || type == 'NcnsOnly'">
                    You are about to set a NCNS for {{ inspectorDetails?.firstName }} {{ inspectorDetails?.lastName }}
                    {{ type == 'NcncAndReschedule' ? 'and close the shift position.' : '.' }}
                </p>

                <div class="form-group label-normal" *ngIf="type == 'NcncAndReschedule' || type == 'NcnsOnly'">
                    <app-textarea
                        label="Please left some public comments for {{ inspectorDetails?.firstName }} (required)"
                        [fieldName]="
                            'Please left some public comments for {{ inspectorDetails?.firstName }} (required)'
                        "
                        [placeholder]="'Please add your comments for the nurse here'"
                        [isRequired]="false"
                        [rows]="5"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="shiftDetailsForm"
                        formControlName="publicComment"
                    >
                    </app-textarea>
                </div>

                <div class="mt-3" *ngIf="type == 'Scheduled'">
                    <div class="form-check form-check-inline mt-3 col-md-12">
                        <input
                            type="radio"
                            name="canReApplyForJob"
                            class="form-check-input cursor-pointer"
                            value="1"
                            (change)="onShiftPositionChange(1)"
                        />
                        <label class="form-check-label"> I want to keep this position open </label>
                    </div>
                    <div class="form-check form-check-inline mt-3 col-md-12">
                        <input
                            type="radio"
                            name="canReApplyForJob"
                            class="form-check-input cursor-pointer"
                            value="2"
                            (change)="onShiftPositionChange(2)"
                        />
                        <label class="form-check-label"> I want to cancel this position </label>
                    </div>
                </div>

                <div
                    class="form-group mt-2 label-normal"
                    *ngIf="type == 'ReleaseCancel' || type == 'ReleaseReschedule' || type == 'Scheduled'"
                >
                    <span *ngIf="type == 'ReleaseCancel' || type == 'ReleaseReschedule'"
                        >You are about to release this nurse.</span
                    >

                    <span class="mt-2" *ngIf="type == 'Scheduled'">You are about to unschedule this nurse. </span>
                    <app-textarea
                        *ngIf="type == 'Scheduled' ? selectedValue == 1 : true"
                        label="Please left some public comments for the nurse (required):"
                        [fieldName]="'Please left some public comments for the nurse (required):'"
                        [placeholder]="'Please add your comments for the nurse here'"
                        [isRequired]="false"
                        [rows]="5"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="shiftDetailsForm"
                        formControlName="publicComment"
                    >
                    </app-textarea>
                </div>

                <div
                    class="form-group reason-dropdown dropdown-box mt-2 col-md-12 position-relative"
                    style="z-index: 1"
                    *ngIf="type == 'ReleaseCancel' || type == 'ReleaseReschedule'"
                >
                    <app-selectbox
                        formControlName="reasonsId"
                        [selectItems]="reasonsList"
                        fieldName="name"
                        fieldId="id"
                        placeholder="Select Reason(s) for releasing the nurse"
                        [selectedFieldId]="selectedReason"
                        (onChangeSelect)="onReasonsIdChange($event)"
                        requiredLableName="Reason(s) for releasing the nurse"
                        [isRequired]="true"
                        controlName="reasonsId"
                        [isMultiple]="true"
                        [closeOnSelect]="false"
                        lableName="Reason(s) for releasing the nurse"
                    >
                    </app-selectbox>
                </div>

                <div
                    class="form-group reason-dropdown dropdown-box mt-2 col-md-12 position-relative"
                    style="z-index: 1"
                    *ngIf="type == 'Scheduled'"
                >
                    <app-selectbox
                        formControlName="reasonsId"
                        [selectItems]="unschedulingReasonsList"
                        fieldName="name"
                        fieldId="id"
                        placeholder="Select Reason for unscheduling the nurse"
                        [selectedFieldId]="selectedReason"
                        (onChangeSelect)="onUnschedulingReasonsIdChange($event)"
                        requiredLableName="Reason for unscheduling the nurse"
                        [isRequired]="true"
                        controlName="reasonsId"
                        [isMultiple]="false"
                        lableName="Reason for unscheduling the nurse"
                    >
                    </app-selectbox>
                </div>

                <div class="form-group mt-4 label-normal" *ngIf="type != 'ExtendShift'">
                    <app-textarea
                        *ngIf="type == 'Scheduled' ? selectedValue == 1 : true"
                        label="Additionally, write some internal comments (required)"
                        [fieldName]="'Additionally, write some internal comments (required)'"
                        [placeholder]="'Please add your internal comments here'"
                        [isRequired]="false"
                        [rows]="5"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="shiftDetailsForm"
                        formControlName="comment"
                    >
                    </app-textarea>
                </div>
            </div>

            <div class="row mt-3" *ngIf="type == 'NcncAndReschedule' || type == 'ReleaseReschedule'">
                <div class="col-xl-6 col-lg-6 col-6 mb-1">
                    <div class="form-group date-picker-control">
                        <app-datepicker
                            controlName="date"
                            fieldName="date"
                            fieldId="date"
                            [selectedFieldDate]="selectedDate"
                            [formName]="shiftDetailsForm"
                            (onDateSelect)="onDateSelectChange($event)"
                            requiredLableName="New Shift Date"
                            lableName="New Shift Date"
                            [isRequired]="true"
                        >
                        </app-datepicker>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-6 dropdown-box mb-2" style="z-index: 9">
                    <div class="form-group">
                        <app-selectbox
                            [selectItems]="shiftTimeList"
                            fieldName="shiftTime"
                            fieldId="id"
                            placeholder="Select Shift Times"
                            [isSearchable]="false"
                            [selectedFieldId]="selectedShiftTime"
                            (onChangeSelect)="onShiftTimeChange($event)"
                            requiredLableName="New Shift Time"
                            [isRequired]="true"
                            controlName="shiftTimeId"
                            [isMultiple]="false"
                            lableName="New Shift Time"
                            [formName]="shiftDetailsForm"
                            formControlName="shiftTimeId"
                        >
                        </app-selectbox>
                    </div>
                </div>
                <div class="row" *ngIf="selectedShiftTime === 'custom'">
                    <!-- Start Time -->
                    <div class="col-lg-3 col-12 mb-1">
                        <label class="form-label fw-bolder"> Start Time</label>
                        <p-calendar
                            class="p-calendar"
                            [showTime]="true"
                            [timeOnly]="true"
                            formControlName="fromTime"
                            placeholder="Start Time"
                            hourFormat="12"
                            (onSelect)="selectedTime()"
                            (keyup)="selectedTime()"
                        ></p-calendar>
                        <div
                            *ngIf="
                                shiftDetailsForm.get('fromTime')?.invalid &&
                                (shiftDetailsForm.get('fromTime')?.dirty || shiftDetailsForm.get('fromTime')?.touched)
                            "
                        >
                            <small class="input-error">Start Time is required</small>
                        </div>
                    </div>

                    <!-- End Time -->
                    <div class="col-lg-3 col-12 mb-1">
                        <label class="form-label fw-bolder"> End Time</label>

                        <p-calendar
                            class="p-calendar"
                            [showTime]="true"
                            [timeOnly]="true"
                            formControlName="toTime"
                            placeholder="End Time"
                            hourFormat="12"
                            (onSelect)="selectedTime()"
                            (keyup)="selectedTime()"
                        ></p-calendar>
                        <div
                            *ngIf="
                                shiftDetailsForm.get('toTime')?.invalid &&
                                (shiftDetailsForm.get('toTime')?.dirty || shiftDetailsForm.get('toTime')?.touched)
                            "
                        >
                            <small class="input-error">End Time is required</small>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Extend Shift Time -->
            <div *ngIf="type == 'ExtendShift'" class="">
                <div class="row">
                    <h4>Extend Shift Time</h4>
                </div>
                <div class="row mt-3">
                    <p>You are about to extend shift time.</p>
                    <p>Please inform the extra number of minutes and the bonus for this extra (if any)</p>
                </div>
                <form [formGroup]="shiftDetailsForm">
                    <div class="row mt-3">
                        <div class="form-group col-sm-6">
                            <app-inputbox
                                type="number"
                                formControlName="extendedMinutes"
                                [parentForm]="shiftDetailsForm"
                                label="Extra minutes to be added"
                                [isRequired]="true"
                                [isWhiteSpace]="false"
                                [min]="1"
                                [max]="3"
                                fieldName="extendedMinutes"
                                [isMinimum]="true"
                                [isNumber]="true"
                                [isPattern]="false"
                                [space]="false"
                                [isAlphabet]="true"
                            ></app-inputbox>
                        </div>
                        <div class="form-group col-sm-6">
                            <app-inputbox
                                type="number"
                                fieldName="extendedBonus"
                                formControlName="extendedBonus"
                                [parentForm]="shiftDetailsForm"
                                label="Extra bonus (optional)"
                                [isRequired]="false"
                                [isWhiteSpace]="false"
                                [max]="3"
                                [isNumber]="true"
                                [isPattern]="false"
                                [isAlphabet]="true"
                                [placeholder]="'$ 000.00'"
                                [isRate]="true"
                                [rateMax]="3"
                            ></app-inputbox>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-outline-primary backBtn fw-semibold px-4 px-md-5 m-0"
                type="button"
                (click)="close()"
            >
                Back
            </button>
            <button
                class="btn btn-primary fw-semibold px-2 px-md-5 ms-2"
                type="button"
                (click)="onConfirm()"
                [disabled]="shiftDetailsForm.invalid || !hasFormChanged()"
            >
                Confirm
            </button>
        </div>
    </form>
</div>
