import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CityModel } from 'src/app/model/city.model';
import { RegionService } from 'src/app/service/region.service';
import { noWhitespaceValidator, removeSpaces } from 'src/app/utill/validator';

@Component({
    selector: 'app-add-edit-city-model',
    templateUrl: './add-edit-city-model.component.html',
    styleUrls: ['./add-edit-city-model.component.scss'],
})
export class AddEditCityModelComponent implements OnInit {
    @Input() editcityModel = null;

    cityForm: FormGroup;
    public cityModel: any = new CityModel();
    canAddCity = true;
    saveDisable = false;
    states: any[] = [];
    selectedStateId: any;
    stateId: any;

    constructor(
        private fb: FormBuilder,
        public modal: NgbActiveModal,
        private regionService: RegionService,
        public toastrService: ToastrService,
        private ngxSpinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        if (!this.editcityModel) {
            this.initForm();
        } else {
            this.canAddCity = false;
            this.cityModel = this.editcityModel;
            this.selectedStateId = this.cityModel.stateId;
            this.initForm();
        }
        this.getStates();
    }

    getStates() {
        this.regionService.stateSelectList().subscribe((response: any) => {
            this.states = response.result;
        });
    }

    initForm() {
        this.cityForm = this.fb.group({
            id: [this.cityModel?.id, Validators.compose([])],
            name: [
                this.cityModel?.name,
                Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces, Validators.minLength(1)]),
            ],
            stateId: [this.cityModel?.stateId, Validators.compose([Validators.required])],
            isActive: [this.cityModel?.isActive, Validators.compose([Validators.required])],
        });
    }
    get f() {
        return this.cityForm.controls;
    }
    submit() {
        if (this.cityForm.invalid) {
            return;
        }

        const result: any = {};
        Object.keys(this.f).forEach((key) => {
            result[key] = this.f[key].value;
            this.stateId = result.stateId;
        });
        this.saveDisable = true;

        const cityModel = new CityModel();
        cityModel.setCityData(result);
        cityModel.stateId = this.stateId;

        if (this.canAddCity) {
            this.addCity(cityModel);
        } else {
            this.updateCity(cityModel);
        }
    }

    addCity(cityModel: any) {
        this.ngxSpinnerService.show();
        this.regionService.addCity(cityModel).subscribe(
            (response: any) => {
                if (response.result != null) {
                    this.modal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.error.message);
            },
            () => console.log('complete')
        );
    }

    updateCity(cityModel: any) {
        this.ngxSpinnerService.show();
        this.regionService.updateCity(cityModel.id, cityModel).subscribe(
            (response) => {
                if (response.result == true) {
                    this.toastrService.success(response.message);
                    this.modal.close();
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.error.message);
            },
            () => console.log('complete')
        );
    }

    dismissModel() {
        this.modal.dismiss();
    }

    onStateChange(event: any) {
        this.cityForm.controls.stateId.setValue(event);
    }
}
