import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { DataTableModel, DisplayBlock } from 'src/app/providers/classes/helper.classes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditAssetsModelComponent } from './add-edit-assets-model/add-edit-assets-model.component';
import { DeleteAssetsModelComponent } from './delete-assets-model/delete-assets-model.component';
import { EllipsisPipe } from 'src/app/pipes/ellipsis.pipe';

@Component({
    selector: 'app-assets',
    templateUrl: './assets.component.html',
    styleUrls: ['./assets.component.scss'],
    providers: [TitleCasePipe, EllipsisPipe],
})
export class AssetsComponent implements OnInit {
    public dataTableModel: DataTableModel = new DataTableModel();
    displayBlock!: DisplayBlock;

    constructor(private modalService: NgbModal, private titleCasePipe: TitleCasePipe) {}

    ngOnInit(): void {
        this.dataTableBinding();
    }
    dataTableBinding() {
        let that = this;

        this.displayBlock = {
            getUrl: 'assets/site-map-list',
            isServerSidePagination: false,
            isAllowPaging: true,
            sortOrder: [[0, 'asc']],
            isShowPaginationInfo: true,
            isSearching: true,
            rows: [
                {
                    name: 'name',
                    label: 'Name',
                    type: 'text',
                },
                {
                    name: 'assetLevelName',
                    label: 'Asset Level',
                    type: 'text',
                    pipe: 'title',
                },
                {
                    name: 'displayText',
                    label: 'Display Text',
                    type: 'text',
                    pipe: 'title',
                },
                {
                    name: 'position',
                    label: 'Position',
                    type: 'text',
                },
                {
                    name: 'isItComingFromRealData',
                    label: 'Coming From Real Data',
                    type: 'status',
                },
                {
                    name: 'isActive',
                    label: 'Status',
                    type: 'status',
                },
                {
                    name: 'createdOn',
                    label: 'Last Modified By',
                    type: 'modifiedBy',
                },
                {
                    name: 'action',
                    label: 'Action',
                    type: 'action',
                    actions: {
                        isEdit: true,
                    },
                },
            ],
            options: {
                add: false,
                clear: false,
                delete: false,
                search: true,
                export: false,
            },
        };
        this.dataTableModel.displayBlock = this.displayBlock;
    }
    create() {
        this.addEdit(null);
    }

    rowCallback(event: any) {
        let that = this;
        const _row = $(event.row);
        _row.find('[action-type="edit"]')
            .off('click')
            .on('click', function () {
                that.addEdit(event.data);
            });

        _row.find('[action-type="delete"]')
            .off('click')
            .on('click', function () {
                that.delete(event.data.id);
            });
    }

    addEdit(model: any) {
        this.dataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(AddEditAssetsModelComponent, {
            size: 'xl',
            backdrop: true,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.editassetsModel = model;
        modalRef.result.then(
            () => (this.dataTableModel.isLoadData = true),
            () => {}
        );
    }

    delete(id: any) {
        this.dataTableModel.isLoadData = false;

        const modalRef = this.modalService.open(DeleteAssetsModelComponent, {
            size: 'l',
            backdrop: false,
            windowClass: 'my-custom-modal',
        });
        modalRef.componentInstance.id = id;
        modalRef.result.then(
            () => (this.dataTableModel.isLoadData = true),
            () => {}
        );
    }
}
