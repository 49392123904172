import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StatusChangeService {
    constructor(private apiService: ApiService) {}

    delete(URL: any, id: any): Observable<any> {
        return this.apiService.delete(URL, id);
    }

    updateStatus(URL: any, id: any): Observable<any> {
        return this.apiService.put(URL, id);
    }

    addStatus(URL: any, res: any): Observable<any> {
        return this.apiService.post(URL, res);
    }
}
