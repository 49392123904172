import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { API_CREDENTIAL } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class CreditialTemplateService {
    constructor(private apiService: ApiService) {}

    addCredentialTemplate(res: any): Observable<any> {
        return this.apiService.post(API_CREDENTIAL.POST_CREDENTIAL_TEM, res);
    }

    getCredentialTemplateById(id: any): Observable<any> {
        return this.apiService.get(API_CREDENTIAL.GET_CREDENTIAL_TEM_BY_ID + id);
    }

    getCradentialCategoryList(): Observable<any> {
        return this.apiService.get(API_CREDENTIAL.GET_CREDENTIAL_CATEGORY_SELECTED_LIST);
    }

    getCradentialList(id: any): Observable<any> {
        return this.apiService.get(API_CREDENTIAL.GET_CREDENTIAL_TYPE_SELECTED_LIST + '?credentialCategoryId=' + id);
    }

    getCredentialCategoryForThrive(): Observable<any> {
        return this.apiService.get(API_CREDENTIAL.GET_CREDENTIAL_CATEGORY_FOR_THRIVE);
    }

    getCredentialTypeForThrive(id: any): Observable<any> {
        return this.apiService.get(API_CREDENTIAL.GET_CREDENTIAL_TYPE_FOR_THRIVE + id);
    }

    addNurseCredential(res: any): Observable<any> {
        return this.apiService.post(API_CREDENTIAL.ADD_NURSE_CREDENTIAL, res);
    }

    getCredentialStates(apiUrl: any): Observable<any> {
        return this.apiService.get(apiUrl);
    }
}
