<div class="modal-header border-primary border-bottom">
    <h5 class="text-secondary">{{ title }}</h5>
</div>
<div class="modal-body p-5">
    <div class="text-center">
        <p class="text-secondary" [innerHTML]="message"></p>
    </div>
</div>

<div class="mb-4 mt-2 d-flex justify-content-md-evenly">
    <div>
        <button type="button" class="btn btn-light btn-elevate mr-1 btn-lg px-5 btn-border" (click)="callBack(false)">
            Cancel
        </button>
        <button
            type="button"
            class="btn btn-light btn-orange mr-1 btn-lg px-5 ms-4"
            (click)="type == 'add-shift' ? callBack(true) : confirm()"
            [disabled]="isDisable"
        >
            Confirm
        </button>
    </div>
</div>
