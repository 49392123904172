import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    // BehaviorSubject to keep track of the active menu
    private activeMenu = new BehaviorSubject<string>('defaultMenu');
    currentActiveMenu = this.activeMenu.asObservable();

    // BehaviorSubject to keep track of notification count (example)
    private notificationCount = new BehaviorSubject<boolean>(false);
    currentNotificationCount = this.notificationCount.asObservable();

    // Update the active menu
    updateActiveMenu(menu: any, ids?: any): void {
        let menusNIds: any = {
            menu: menu,
            ids: ids,
        };
        this.activeMenu.next(menusNIds);
    }

    // Update the notification count
    updateNotificationCount(isCountUpdate: boolean): void {
        this.notificationCount.next(isCountUpdate);
    }
}
