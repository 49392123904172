<div class="modal-content fixwidth">
    <div class="modal-body p-3">
        <div class="d-flex justify-content-flex-start border-bottom border-primary">
            <div class="profile-model-img mb-2">
                <figure>
                    <img
                        *ngIf="restrictionDetails?.clinicianProfileUrl != null"
                        src="{{ restrictionDetails.clinicianProfileUrl }}"
                        alt=""
                    />
                    <img
                        *ngIf="restrictionDetails?.clinicianProfileUrl == null"
                        src="./assets/images/others/blank.png"
                    />
                </figure>
            </div>
            <div class="profile-model-info">
                <h4>
                    {{
                        ((restrictionDetails?.firstName | titlecase) ?? '') +
                            ' ' +
                            ((restrictionDetails?.lastName | titlecase) ?? '')
                    }}
                </h4>
                <p class="text-primary">
                    {{
                        (restrictionDetails?.clinicianCityName ?? '') +
                            ', ' +
                            (restrictionDetails?.clinicianStateName ?? '')
                    }}
                </p>
                <div class="label-list">
                    <label
                        class="badge fw-bolder"
                        [ngStyle]="{
                            'background-color': type?.chipBackgroundColor,
                            color: type?.chipFontColor
                        }"
                        *ngFor="let type of clinicianLicences"
                        >{{ type?.acronym ?? '' }}</label
                    >
                </div>
            </div>
        </div>
        <div class="view-note">
            <h4 class="mt-2">Nurse Restriction</h4>
            <div class="row mt-3">
                <div class="row mt-3">
                    <h5>Facility</h5>
                    <p class="text-break description mt-2">{{ model?.hospitalName }}</p>
                </div>
                <div class="col-5 mt-3">
                    <h5>Restriction Type</h5>
                    <p class="text-break description mt-2">{{ restrictionDetails.status }}</p>
                </div>

                <div class="col-5 mt-3">
                    <h5>Reason</h5>
                    <p class="text-break description mt-2">
                        {{ restrictionDetails.reasonName }}
                    </p>
                </div>
                <div class="col-5 mt-3" *ngIf="restrictionDetails?.suspendedFrom">
                    <h5>Suspended From</h5>
                    <p class="text-break description mt-2">
                        {{ restrictionDetails?.suspendedFrom | localDateTime : 'ordinal' }}
                    </p>
                </div>

                <div class="col-5 mt-3" *ngIf="restrictionDetails?.suspendedTill">
                    <h5>Suspended Until</h5>
                    <p class="text-break description mt-2">
                        {{ restrictionDetails?.suspendedTill | localDateTime : 'ordinal' }}
                    </p>
                </div>
            </div>

            <div class="mt-4" *ngIf="restrictionDetails.description">
                <h5>Additional information</h5>
                <p class="text-break description mt-2">{{ restrictionDetails.description }}</p>
            </div>
        </div>

        <div class="row" *ngIf="model?.status == 'Suspended'">
            <div class="col-sm-12 mt-4" *ngIf="model.isShowEditIcon">
                <div class="form-group toggle-margin">
                    <app-inputbox
                        id="formSwitch1"
                        fieldName="isUpdateRestrictedNurse"
                        label="Do you want to do modification in Suspend Period ?"
                        type="checkbox"
                        [isInputCheckbox]="true"
                        [checkbBoxValue]="isUpdateRestrictedNurse"
                        (onChangeSelect)="onCheckboxChange($event)"
                    ></app-inputbox>
                </div>
            </div>
        </div>

        <div *ngIf="isUpdateRestrictedNurse">
            <form [formGroup]="restrictDetailsForm">
                <div class="row mb-2">
                    <div class="col-md-6 mb-2">
                        <div class="form-group date-picker-control">
                            <app-datepicker
                                controlName="suspendedFrom"
                                fieldName="suspendedFrom"
                                fieldId="suspendedFrom"
                                [selectedFieldDate]="selectedSuspendedFromDate"
                                (onDateSelect)="onDateSelectChange($event, 'from')"
                                requiredLableName="Suspended from"
                                lableName="Suspended from"
                                [isRequired]="true"
                                [formName]="restrictDetailsForm"
                            >
                            </app-datepicker>
                        </div>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="form-group date-picker-control">
                            <app-datepicker
                                controlName="suspendedTill"
                                fieldName="suspendedTill"
                                fieldId="suspendedTill"
                                [selectedFieldDate]="selectedSuspendedtillDate"
                                (onDateSelect)="onDateSelectChange($event, 'till')"
                                requiredLableName="Suspended until"
                                lableName="Suspended until"
                                [isRequired]="true"
                                [formName]="restrictDetailsForm"
                            >
                            </app-datepicker>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <app-textarea
                        label="Comments"
                        [fieldName]="'Comments'"
                        [placeholder]="'Please leave your comments here'"
                        [isRequired]="false"
                        [rows]="5"
                        [cols]="250"
                        [min]="1"
                        [max]="1000"
                        [isShowCharacterCount]="true"
                        [parentForm]="restrictDetailsForm"
                        formControlName="description"
                        (keyup)="onDescriptionChange($event)"
                    >
                    </app-textarea>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-footer p-3">
        <button class="btn btn-outline-primary fw-semibold px-4 px-md-5 mr-3 mr-md-4" type="button" (click)="close()">
            Close
        </button>
        <button
            type="button"
            class="btn btn-primary fw-semibold px-4 px-md-5 m-0"
            (click)="onUpdateRestrictionNurse()"
            *ngIf="model.status == 'Suspended' && isUpdateRestrictedNurse"
            [disabled]="isDisabled"
        >
            Update
        </button>
    </div>
</div>
