import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { API_SHIFT } from 'src/app/constants/api';
import { StatusChangeService } from 'src/app/service/statusChange.service';

@Component({
    selector: 'app-confirmation-model',
    templateUrl: './confirmation-model.component.html',
    styleUrl: './confirmation-model.component.scss',
})
export class ConfirmationModelComponent {
    @Input() title: any;
    @Input() message: any;
    @Input() type: any;
    @Input() shiftTypePosition: any;
    @Input() inspectorDetails: any;
    @Input() requestModal: any;
    @Input() endPoint: any;
    @Input() shiftId: any;
    isDisable: boolean = false;
    constructor(
        public activeModal: NgbActiveModal,
        private statusChangeService: StatusChangeService,
        public toastrService: ToastrService
    ) {}

    ngOnInit() {}

    callBack(event: any) {
        this.activeModal.close(event);
    }

    confirm() {
        this.isDisable = true;
        if (
            this.type != 'In' &&
            this.type != 'Out' &&
            this.type != 'add' &&
            this.type != 'invite' &&
            this.type != 'scheduled' &&
            this.type != 'Cancel'
        ) {
            let url: any = API_SHIFT.GET_SHIFT_BY_ID + this.inspectorDetails?.shiftPositionId + this.endPoint;

            this.statusChangeService.updateStatus(url, this.requestModal).subscribe((response: any) => {
                if (response.result.result ? response.result.result : response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(true);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            });
        } else if (this.type == 'add') {
            let url = API_SHIFT.GET_SHIFT_BY_ID + this.shiftId + API_SHIFT.ADD_SHIFT_POSITION;
            this.statusChangeService.updateStatus(url, this.requestModal).subscribe((response: any) => {
                if (response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(response.result);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            });
        } else if (this.type == 'invite') {
            let url = this.endPoint;
            this.statusChangeService.updateStatus(url, this.requestModal).subscribe((response: any) => {
                if (response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(response.result);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            });
        } else if (this.type == 'scheduled') {
            let url = API_SHIFT.GET_SHIFT_BY_ID + this.shiftId + API_SHIFT.ADD_SHIFT_POSITION;
            this.statusChangeService.updateStatus(url, this.requestModal).subscribe((response: any) => {
                if (response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(response.result);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            });
        } else if (this.type == 'Cancel') {
            let url = API_SHIFT.GET_SHIFT_BY_ID + API_SHIFT.REMOVE_SHIFT_POSITION;
            this.statusChangeService.updateStatus(url, this.requestModal).subscribe((response: any) => {
                if (response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(response.result);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            });
        } else {
            let url: any = this.endPoint;

            this.statusChangeService.addStatus(url, this.requestModal).subscribe((response: any) => {
                if (response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(true);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            });
        }
    }
}
