<div class="profile-modal-content modal-content fixwidth">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <h4>Documentation</h4>
    </div>
    <div class="modal-body p-3 pt-0">
        <div class="profile-model-box">
            <div class="profile-model-img">
                <figure>
                    <img
                        *ngIf="data?.clinicianProfileUrl || data?.profileUrl"
                        [src]="data?.clinicianProfileUrl || data?.profileUrl"
                        alt=""
                    />
                    <img
                        *ngIf="!data?.clinicianProfileUrl && !data?.profileUrl"
                        src="./assets/images/others/blank.png"
                    />
                </figure>
            </div>
            <div class="profile-model-info">
                <h4>
                    {{ data?.clinicianFirstName || data?.firstName }} {{ data?.clinicianLastName || data?.lastName }}
                </h4>
                <p class="text-primary">{{ data?.clinicianCityName }}, {{ data.clinicianStateName }}</p>
                <p class="text-primary">
                    <a *ngIf="data?.clinicianEmail" href="mailto:{{ data?.clinicianEmail }}" class="nurse-email">{{
                        data?.clinicianEmail
                    }}</a>
                    {{ data?.clinicianEmail ? ' | ' : ' ' }}
                    {{ formatNumber(data?.clinicianPhoneNumber ?? '') }}
                </p>
                <div class="label-list">
                    <label
                        class="badge fw-bolder"
                        [ngStyle]="{
                            'background-color': type?.chipBackgroundColor,
                            color: type?.chipFontColor
                        }"
                        *ngFor="let type of data?.clinicianLicences"
                        >{{ type?.acronym }}</label
                    >
                </div>
            </div>
        </div>

        <div #scrollable class="job-profile-card-list" *ngIf="documentationList.length > 0">
            <div class="document-list overflow-hidden" *ngFor="let document of documentationList">
                <div
                    class="document-list-box cursor-pointer"
                    [ngClass]="{
                        'bg-document-status-approved': document.status == 'Approved',
                        'bg-document-status-sent-back': document.status == 'SentBack',
                        'bg-document-status-pending': document.status == 'Pending',
                        'bg-document-status-signed': document.status == 'Signed',
                        'bg-document-status-declined': document.status == 'Declined'
                    }"
                >
                    <figure class="document-icon">
                        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.7373 3.35884V38.2456L9.07306 40.4068L33.8333 40.2623L35.1956 37.6118L35.5722 12.0448L26.3806 11.7223L25.7356 1.99316H8.3197"
                                fill="#FFECEC"
                            />
                            <rect x="7.29785" y="28.54" width="27.95" height="11.346" fill="#FFAFAF" />
                            <path d="M25.7354 2.80029L26.5403 11.4003H35.3553L25.7354 2.80029Z" fill="#FFAFAF" />
                            <rect x="11.3091" y="16.9297" width="19.6906" height="1.72" fill="#8D0B0B" />
                            <rect x="11.3091" y="21.7681" width="10.6442" height="1.72" fill="#8D0B0B" />
                            <rect x="6.7373" y="27.6797" width="28.8349" height="1.72" fill="#8D0B0B" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M35.2807 9.73028L34.855 9.30888V9.32608L28.0498 2.57938H28.067L27.2964 1.81569C26.5121 1.02622 25.5248 0.880015 25.0355 0.859375H9.46002C7.56286 0.859375 6.02002 2.40222 6.02002 4.29938V37.8394C6.02002 39.7365 7.56286 41.2794 9.46002 41.2794H33.135C35.0321 41.2794 36.575 39.7365 36.575 37.8394V12.3438C36.4872 11.1777 35.7021 10.1792 35.2807 9.73028ZM26.7177 4.28497L33.1927 10.7049H26.7177V4.28497ZM33.135 39.5594C34.0835 39.5594 34.855 38.7888 34.855 37.8394L34.8532 12.601C34.8365 12.602 34.82 12.604 34.8033 12.6061C34.7787 12.6091 34.7538 12.6121 34.7277 12.6121H26.7176C25.6659 12.6121 24.8093 11.7573 24.8093 10.7055V2.77975C24.8093 2.73138 24.8153 2.68471 24.8212 2.63825C24.8237 2.61863 24.8262 2.59904 24.8282 2.57937H9.46002C8.51144 2.57937 7.74002 3.35079 7.74002 4.29938V37.8394C7.74002 38.7888 8.51144 39.5594 9.46002 39.5594H33.135Z"
                                fill="#8D0B0B"
                            />
                            <path
                                opacity="0.02"
                                d="M34.6935 12.0817C34.6935 12.0817 32.688 24.0727 23.8007 27.6813C14.9135 31.2898 8.1582 39.8881 8.1582 39.8881L12.5657 40.4075L32.9907 40.3353L35.4099 39.7436L36.8074 37.0441L36.2157 12.0361L34.3333 33.0675"
                                fill="black"
                            />
                            <path
                                d="M15.468 36V31.8H17.52C17.82 31.8 18.08 31.86 18.3 31.98C18.524 32.1 18.696 32.264 18.816 32.472C18.94 32.68 19.002 32.918 19.002 33.186C19.002 33.438 18.94 33.666 18.816 33.87C18.696 34.074 18.524 34.236 18.3 34.356C18.08 34.476 17.82 34.536 17.52 34.536H16.5V36H15.468ZM16.5 33.714H17.334C17.546 33.714 17.702 33.664 17.802 33.564C17.906 33.46 17.958 33.332 17.958 33.18C17.958 33.028 17.906 32.902 17.802 32.802C17.702 32.702 17.548 32.652 17.34 32.652H16.5V33.714ZM20.7598 35.118H21.4378C21.6338 35.118 21.8098 35.064 21.9658 34.956C22.1218 34.848 22.2458 34.702 22.3378 34.518C22.4338 34.33 22.4818 34.124 22.4818 33.9C22.4818 33.672 22.4338 33.466 22.3378 33.282C22.2458 33.098 22.1218 32.952 21.9658 32.844C21.8098 32.736 21.6338 32.682 21.4378 32.682H20.7598V35.118ZM19.7278 36V31.8H21.4378C21.7338 31.8 22.0078 31.854 22.2598 31.962C22.5158 32.07 22.7398 32.22 22.9318 32.412C23.1278 32.604 23.2798 32.828 23.3878 33.084C23.4958 33.336 23.5498 33.608 23.5498 33.9C23.5498 34.192 23.4958 34.466 23.3878 34.722C23.2798 34.974 23.1278 35.196 22.9318 35.388C22.7398 35.58 22.5158 35.73 22.2598 35.838C22.0078 35.946 21.7338 36 21.4378 36H19.7278ZM24.2864 36V31.8H27.4844V32.664H25.3184V33.492H26.8304V34.332H25.3184V36H24.2864Z"
                                fill="#8A0B0B"
                            />
                        </svg>
                    </figure>
                    <div class="document-info w-100">
                        <div class="row">
                            <div class="col" (click)="onDocumentShow(document.fileUrl)">
                                <h5>
                                    {{ document?.title | titlecase }}
                                </h5>
                                <p class="mt-2">{{ document?.fileName }}</p>
                                <ul class="mt-3">
                                    <li *ngIf="document?.status == 'Pending'">Not signed yet</li>
                                    <li *ngIf="document?.status == 'Signed'">
                                        Signed by {{ data?.clinicianFirstName }} {{ data?.clinicianLastName }} in
                                        {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                    </li>
                                    <li *ngIf="document?.status == 'Approved'">
                                        Approved by {{ document?.verifyBy }} in
                                        {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                    </li>
                                    <li *ngIf="document?.status == 'SentBack'">
                                        Sent back by {{ document?.verifyBy }} in
                                        {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                    </li>
                                    <li *ngIf="document?.status == 'Declined'">
                                        Declined by {{ document?.verifyBy }} in
                                        {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                    </li>
                                </ul>
                            </div>
                            <div
                                *ngIf="document?.status == 'Signed' && isShowActionButtons"
                                class="col-auto d-grid gap-2"
                            >
                                <button
                                    class="border-0 btn btn-primary fw-semibold text-center w-100"
                                    (click)="validDocument(document.clinicianDocumentId, true)"
                                >
                                    Valid
                                </button>
                                <button
                                    class="border-0 btn btn-primary fw-semibold text-center w-100"
                                    (click)="sendBackDocument(document.clinicianDocumentId)"
                                >
                                    Send Back
                                </button>
                                <button
                                    class="border-0 btn btn-primary fw-semibold text-center w-100"
                                    (click)="declineDocument(document.clinicianDocumentId)"
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3" *ngIf="employeementFormList.length > 0">
            <h5>Employment form</h5>

            <div #scrollable class="job-profile-card-list mt-4" *ngIf="employeementFormList.length > 0">
                <div class="document-list overflow-hidden" *ngFor="let document of employeementFormList">
                    <div
                        class="document-list-box cursor-pointer"
                        [ngClass]="{
                            'bg-document-status-approved': document.status == 'Approved',
                            'bg-document-status-sent-back': document.status == 'SentBack',
                            'bg-document-status-pending': document.status == 'Pending',
                            'bg-document-status-signed': document.status == 'Signed',
                            'bg-document-status-declined': document.status == 'Declined'
                        }"
                    >
                        <figure class="document-icon">
                            <svg
                                width="43"
                                height="43"
                                viewBox="0 0 43 43"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.7373 3.35884V38.2456L9.07306 40.4068L33.8333 40.2623L35.1956 37.6118L35.5722 12.0448L26.3806 11.7223L25.7356 1.99316H8.3197"
                                    fill="#FFECEC"
                                />
                                <rect x="7.29785" y="28.54" width="27.95" height="11.346" fill="#FFAFAF" />
                                <path d="M25.7354 2.80029L26.5403 11.4003H35.3553L25.7354 2.80029Z" fill="#FFAFAF" />
                                <rect x="11.3091" y="16.9297" width="19.6906" height="1.72" fill="#8D0B0B" />
                                <rect x="11.3091" y="21.7681" width="10.6442" height="1.72" fill="#8D0B0B" />
                                <rect x="6.7373" y="27.6797" width="28.8349" height="1.72" fill="#8D0B0B" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M35.2807 9.73028L34.855 9.30888V9.32608L28.0498 2.57938H28.067L27.2964 1.81569C26.5121 1.02622 25.5248 0.880015 25.0355 0.859375H9.46002C7.56286 0.859375 6.02002 2.40222 6.02002 4.29938V37.8394C6.02002 39.7365 7.56286 41.2794 9.46002 41.2794H33.135C35.0321 41.2794 36.575 39.7365 36.575 37.8394V12.3438C36.4872 11.1777 35.7021 10.1792 35.2807 9.73028ZM26.7177 4.28497L33.1927 10.7049H26.7177V4.28497ZM33.135 39.5594C34.0835 39.5594 34.855 38.7888 34.855 37.8394L34.8532 12.601C34.8365 12.602 34.82 12.604 34.8033 12.6061C34.7787 12.6091 34.7538 12.6121 34.7277 12.6121H26.7176C25.6659 12.6121 24.8093 11.7573 24.8093 10.7055V2.77975C24.8093 2.73138 24.8153 2.68471 24.8212 2.63825C24.8237 2.61863 24.8262 2.59904 24.8282 2.57937H9.46002C8.51144 2.57937 7.74002 3.35079 7.74002 4.29938V37.8394C7.74002 38.7888 8.51144 39.5594 9.46002 39.5594H33.135Z"
                                    fill="#8D0B0B"
                                />
                                <path
                                    opacity="0.02"
                                    d="M34.6935 12.0817C34.6935 12.0817 32.688 24.0727 23.8007 27.6813C14.9135 31.2898 8.1582 39.8881 8.1582 39.8881L12.5657 40.4075L32.9907 40.3353L35.4099 39.7436L36.8074 37.0441L36.2157 12.0361L34.3333 33.0675"
                                    fill="black"
                                />
                                <path
                                    d="M15.468 36V31.8H17.52C17.82 31.8 18.08 31.86 18.3 31.98C18.524 32.1 18.696 32.264 18.816 32.472C18.94 32.68 19.002 32.918 19.002 33.186C19.002 33.438 18.94 33.666 18.816 33.87C18.696 34.074 18.524 34.236 18.3 34.356C18.08 34.476 17.82 34.536 17.52 34.536H16.5V36H15.468ZM16.5 33.714H17.334C17.546 33.714 17.702 33.664 17.802 33.564C17.906 33.46 17.958 33.332 17.958 33.18C17.958 33.028 17.906 32.902 17.802 32.802C17.702 32.702 17.548 32.652 17.34 32.652H16.5V33.714ZM20.7598 35.118H21.4378C21.6338 35.118 21.8098 35.064 21.9658 34.956C22.1218 34.848 22.2458 34.702 22.3378 34.518C22.4338 34.33 22.4818 34.124 22.4818 33.9C22.4818 33.672 22.4338 33.466 22.3378 33.282C22.2458 33.098 22.1218 32.952 21.9658 32.844C21.8098 32.736 21.6338 32.682 21.4378 32.682H20.7598V35.118ZM19.7278 36V31.8H21.4378C21.7338 31.8 22.0078 31.854 22.2598 31.962C22.5158 32.07 22.7398 32.22 22.9318 32.412C23.1278 32.604 23.2798 32.828 23.3878 33.084C23.4958 33.336 23.5498 33.608 23.5498 33.9C23.5498 34.192 23.4958 34.466 23.3878 34.722C23.2798 34.974 23.1278 35.196 22.9318 35.388C22.7398 35.58 22.5158 35.73 22.2598 35.838C22.0078 35.946 21.7338 36 21.4378 36H19.7278ZM24.2864 36V31.8H27.4844V32.664H25.3184V33.492H26.8304V34.332H25.3184V36H24.2864Z"
                                    fill="#8A0B0B"
                                />
                            </svg>
                        </figure>
                        <div class="document-info w-100">
                            <div class="row">
                                <div class="col" (click)="onDocumentShow(document.fileUrl)">
                                    <h5>
                                        {{ document?.title | titlecase }}
                                    </h5>
                                    <p class="mt-2">{{ document?.fileName }}</p>
                                    <ul class="mt-3">
                                        <li *ngIf="document?.status == 'Pending'">Not signed yet</li>
                                        <li *ngIf="document?.status == 'Signed'">
                                            Signed by {{ document?.signingBy }} in
                                            {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                        </li>
                                        <li *ngIf="document?.status == 'Approved'">
                                            Approved by {{ document?.verifyBy }} in
                                            {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                        </li>
                                        <li *ngIf="document?.status == 'SentBack'">
                                            Sent back by {{ document?.verifyBy }} in
                                            {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                        </li>
                                        <li *ngIf="document?.status == 'Declined'">
                                            Declined by {{ document?.verifyBy }} in
                                            {{ document?.signingDate | localDateTime : 'atDateTime' }}
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    *ngIf="document?.status == 'Signed' && isShowActionButtons"
                                    class="col-auto d-grid gap-2"
                                >
                                    <button
                                        class="border-0 btn btn-primary fw-semibold text-center w-100"
                                        (click)="validDocument(document.id, true, 'employeementForm')"
                                    >
                                        Valid
                                    </button>
                                    <button
                                        class="border-0 btn btn-primary fw-semibold text-center w-100"
                                        (click)="sendBackDocument(document.id, 'employeementForm')"
                                    >
                                        Send Back
                                    </button>
                                    <!-- <button
                                        class="border-0 btn btn-primary fw-semibold text-center w-100"
                                        (click)="declineDocument(document.id, 'employeementForm')"
                                    >
                                        Decline
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mt-3">
                <table class="table dataTable">
                    <thead class="thead-light">
                        <tr>
                            <td style="width: 450px; color: black">
                                <div class="fw-bolder">Employment form type</div>
                            </td>
                            <td style="width: 100px; color: black"><div class="fw-bolder">Uploaded Date</div></td>
                            <td style="width: 100px; color: black"><div class="fw-bolder">Status</div></td>
                            <td style="width: 100px; color: black">
                                <div class="fw-bolder">Actions</div>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="text-secondary">
                        <tr *ngFor="let formData of employeementFormList; let i = index">
                            <td class="dataTable-column-align">
                                {{ formData.name }}
                            </td>
                            <td class="dataTable-column-align">
                                {{ formData?.uploadedOn | localDateTime : 'ordinal' }}
                            </td>
                            <td class="dataTable-column-align">
                                <i
                                    class="fa fa-circle text-light"
                                    aria-hidden="true"
                                    *ngIf="formData.status == 'Unverified'"
                                ></i>
                                <i
                                    class="fa fa-circle text-warning"
                                    aria-hidden="true"
                                    *ngIf="formData.status == 'In Progress'"
                                ></i>
                                <i
                                    class="fa fa-circle text-danger"
                                    aria-hidden="true"
                                    *ngIf="formData.status == 'Declined'"
                                ></i>
                                <i
                                    class="fa fa-circle text-success"
                                    aria-hidden="true"
                                    *ngIf="formData.status == 'Approved'"
                                ></i
                                ><span class="ms-2">{{ formData.status }}</span>
                            </td>
                            <td class="dataTable-column-align">
                                <button
                                    type="button"
                                    title="Edit"
                                    class="btn-view mx-2 btn-border"
                                    action-type="edit"
                                    *ngIf="
                                        ['Unverified', 'In Progress'].includes(formData.status) &&
                                        data?.status != 'Hired' &&
                                        isShowActionButtons
                                    "
                                    (click)="onUpdateFormStatus(formData)"
                                >
                                    <em class="feather icon-edit text-primary" aria-hidden="true"></em>
                                </button>
                                <button
                                    type="button"
                                    title="View"
                                    class="btn-view mx-2 btn-border"
                                    action-type="view"
                                    *ngIf="
                                        ['Approved', 'Declined'].includes(formData.status) ||
                                        data?.status == 'Hired' ||
                                        !isShowActionButtons
                                    "
                                    (click)="onViewEmployementForm(formData)"
                                >
                                    <em class="feather icon-eye text-primary" aria-hidden="true"></em>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </div>

        <div *ngIf="employeementFormList.length == 0" class="d-flex align-items-center mb-2 mt-2">
            <h6 class="text-danger">Note:</h6>
            <h6 class="fw-normal ms-2 text-danger">{{ noteMessage }}</h6>
        </div>

        <div *ngIf="documentationList.length == 0 && employeementFormList.length == 0">
            <div class="no-data-available">
                <figure>
                    <img src="assets/logo/NoListFound.png" alt="" />
                </figure>
                <h5>No Data Found</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer p-3">
        <button class="btn btn-outline-primary backBtn fw-semibold px-4 px-md-5 m-0" (click)="close()">Close</button>
    </div>
</div>
