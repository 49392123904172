import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_PRICES } from '../constants/api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PriceService {
    constructor(private apiService: ApiService) {}

    addPrice(res: any): Observable<any> {
        return this.apiService.post(API_PRICES.POST_PRICES, res);
    }
    updatePrice(id: any, res: any): Observable<any> {
        return this.apiService.put(API_PRICES.PUT_PRICES + id, res);
    }
    deletePrice(id: any): Observable<any> {
        return this.apiService.delete(API_PRICES.DELETE_PRICES, id);
    }

    getPriceList(id: any): Observable<any> {
        return this.apiService.get(API_PRICES.GET_PRICES_LIST + id);
    }
}
