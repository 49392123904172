import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { settings } from 'cluster';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
    ActiveNurseModel,
    HealthcareInactiveRestrictionType,
    HealthcareRestrictionType,
    HealthcareTerminateRestrictionType,
    InactiveNurseModel,
    RestrictionType,
    RestrictNurseModel,
    TerminateModel,
    ThriveInactiveRestrictionType,
    ThriveRestrictionType,
    ThriveTerminateRestrictionType,
} from 'src/app/model/staff.model';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { StaffService } from 'src/app/service/staff.service';
import { noWhitespaceValidator, removeSpaces } from 'src/app/utill/validator';

@Component({
    selector: 'app-staff-restriction-model',
    templateUrl: './staff-restriction-model.component.html',
    styleUrl: './staff-restriction-model.component.scss',
    providers: [DateUtilService],
})
export class StaffRestrictionModelComponent implements OnInit {
    @Input() model: any;
    @Input() ids: any;
    @Input() type: any;

    restrictNurseModel: any = new RestrictNurseModel();
    nurseRestrictionForm: FormGroup;
    restrictionTypeList: any;
    selectedRestrictiontype: any;
    phoneNumber: any;
    license: any[] = [];
    restrictionReasonList: any[] = [];
    hospitalList: any[] = [];
    companyList: any[] = [];
    attentionRecordsList: any[] = [];
    selectedRestrictReason: any;
    selectedHospitalIds: any;
    selectedCompanyIds: any;
    selectedDate: any;
    isSelectDisable: boolean = false;
    level: number;
    isCompanyLevelLogin: boolean = false;
    isFacilityLevelLogin: boolean = false;
    isThriveLevelLogin: boolean = false;
    isShowWarning: boolean = false;
    isAgencyLevelLogin: any;
    loginCompanyId: any;

    ngOnInit(): void {
        let isCompanyLevelLogin: any = sessionStorage.getItem('isCompanyLevelLogin');
        this.isCompanyLevelLogin = JSON.parse(isCompanyLevelLogin);
        let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');
        this.isThriveLevelLogin = JSON.parse(isThriveLevelLogin);
        let isFacilityLevelLogin: any = sessionStorage.getItem('isFacilityLevelLogin');
        this.isFacilityLevelLogin = JSON.parse(isFacilityLevelLogin);
        let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
        this.isAgencyLevelLogin = JSON.parse(isAgencyLevelLogin);

        this.loginCompanyId = sessionStorage.getItem('companyId');
        this.loginCompanyId = JSON.parse(this.loginCompanyId);
        this.loginCompanyId = this.loginCompanyId == null ? null : this.loginCompanyId;

        if (this.isFacilityLevelLogin || this.isCompanyLevelLogin || this.isAgencyLevelLogin) {
            this.restrictionTypeList =
                this.type == 'inactive'
                    ? this.convertObjectToKeyValuePairs(this.enumToObj(HealthcareInactiveRestrictionType))
                    : this.type == 'terminate'
                    ? this.convertObjectToKeyValuePairs(this.enumToObj(HealthcareTerminateRestrictionType))
                    : this.convertObjectToKeyValuePairs(this.enumToObj(HealthcareRestrictionType));
        } else {
            this.restrictionTypeList =
                this.type == 'inactive'
                    ? this.convertObjectToKeyValuePairs(this.enumToObj(ThriveInactiveRestrictionType))
                    : this.type == 'terminate'
                    ? this.convertObjectToKeyValuePairs(this.enumToObj(ThriveTerminateRestrictionType))
                    : this.convertObjectToKeyValuePairs(this.enumToObj(ThriveRestrictionType));
        }

        this.initForm();

        if (this.type == 'inactive') {
            this.getInactiveReasonList();
        } else if (this.type == 'terminate') {
            this.getTerminateReasonList();
        } else {
            this.getRestrictionReasonSelectList();
        }

        if (!this.isThriveLevelLogin) {
            this.ids.companyId = sessionStorage.getItem('companyId');
            this.getHospitalSelectList();
        }
        this.getNurseHealthcareSelectList();

        if (this.type == 'active') {
            this.getInactiveHospitalList();
        }

        setTimeout(() => {
            if (this.type == 'active') {
                this.nurseRestrictionForm.controls.restrictionType.clearValidators();
                this.nurseRestrictionForm.controls.reasonId.clearValidators();
                this.nurseRestrictionForm.controls.restrictionType.updateValueAndValidity();
                this.nurseRestrictionForm.controls.reasonId.updateValueAndValidity();
            }
        }, 100);
    }

    constructor(
        private staffService: StaffService,
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private ngxSpinnerService: NgxSpinnerService,
        private toastrService: ToastrService,
        public dateUtilService: DateUtilService
    ) {
        this.level = Number(sessionStorage.getItem('level'));
    }

    convertObjectToKeyValuePairs(obj: any): { name: string; id: any }[] {
        return Object.entries(obj).map(([name, id]) => ({ name, id }));
    }

    enumToObj(enumType: any): { [key: string]: number } {
        const enumObject: { [key: string]: number } = {};
        for (const key in enumType) {
            if (isNaN(Number(key))) {
                enumObject[key] = enumType[key];
            }
        }
        return enumObject;
    }

    initForm() {
        this.nurseRestrictionForm = this.fb.group({
            id: [this.restrictNurseModel.id],
            reasonId: [this.restrictNurseModel.reasonId, Validators.compose([Validators.required])],
            companyIds: [this.restrictNurseModel.companyIds],
            clinicianId: [this.restrictNurseModel.clinicianId],
            hospitalIds: [this.restrictNurseModel.hospitalIds],
            suspendedFrom: [this.restrictNurseModel.suspendedFrom],
            suspendedTill: [this.restrictNurseModel.suspendedTill],
            description: [
                this.restrictNurseModel.description,
                Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces]),
            ],
            restrictionType: [this.restrictNurseModel.restrictionType, Validators.compose([Validators.required])],
            verifiedById: [this.restrictNurseModel.verifiedById],
            verifiedOn: [this.restrictNurseModel.verifiedOn],
            callingFrom: [this.restrictNurseModel.callingFrom],
        });
    }

    onRestrictionTypeChange(restrictiontype: any) {
        this.selectedRestrictiontype = restrictiontype;
        this.selectedHospitalIds = [];
        this.selectedCompanyIds = [];

        this.isShowWarning = false;
        this.nurseRestrictionForm.controls.restrictionType.setValue(this.selectedRestrictiontype);
        if (this.isThriveLevelLogin && (this.selectedRestrictiontype == 2 || this.selectedRestrictiontype == 4)) {
            this.nurseRestrictionForm.controls.companyIds.setValue(null);
            this.nurseRestrictionForm.controls.companyIds.setValidators([Validators.required]);
            if (this.companyList.length == 0) {
                this.isShowWarning = true;
            }
            if (this.companyList.length == 1) {
                this.selectedCompanyIds = [this.companyList[0].id];
            }
        } else if (
            this.isThriveLevelLogin &&
            (this.selectedRestrictiontype == 1 || this.selectedRestrictiontype == 3)
        ) {
            const companyIds = this.companyList?.map((item) => item?.id).join(',');
            this.nurseRestrictionForm.controls.companyIds.setValue(companyIds);
            this.nurseRestrictionForm.controls.companyIds.setValidators([Validators.required]);

            if (!companyIds) {
                this.isShowWarning = true;
            }
            if (this.companyList.length == 1) {
                this.selectedCompanyIds = [this.companyList[0].id];
            }
        }
        if (
            (this.isCompanyLevelLogin || this.isFacilityLevelLogin || this.isAgencyLevelLogin) &&
            (this.selectedRestrictiontype == 2 ||
                this.selectedRestrictiontype == 4 ||
                this.selectedRestrictiontype == 6)
        ) {
            this.nurseRestrictionForm.controls.hospitalIds.setValue(null);
            this.nurseRestrictionForm.controls.hospitalIds.setValidators([Validators.required]);
            if (this.hospitalList.length == 1) {
                this.selectedHospitalIds = [this.hospitalList[0].id];
            }
            if (this.hospitalList.length == 0) {
                this.isShowWarning = true;
            }
        } else if (
            (this.isCompanyLevelLogin || this.isFacilityLevelLogin || this.isAgencyLevelLogin) &&
            (this.selectedRestrictiontype == 1 ||
                this.selectedRestrictiontype == 3 ||
                this.selectedRestrictiontype == 6)
        ) {
            const hospitalIds = this.hospitalList?.map((item) => item?.id).join(',');
            this.nurseRestrictionForm.controls.hospitalIds.setValue(hospitalIds);
            this.nurseRestrictionForm.controls.hospitalIds.setValidators([Validators.required]);

            if (!hospitalIds) {
                this.isShowWarning = true;
            }
            if (this.hospitalList.length == 1) {
                this.selectedHospitalIds = [this.hospitalList[0].id];
            }
        }
        if (this.selectedRestrictiontype == 3 || (this.selectedRestrictiontype == 4 && !this.type)) {
            this.nurseRestrictionForm.controls.suspendedFrom.setValidators([Validators.required]);
            this.nurseRestrictionForm.controls.suspendedTill.setValidators([Validators.required]);
        } else {
            this.nurseRestrictionForm.controls.suspendedFrom.setValue(null);
            this.nurseRestrictionForm.controls.suspendedTill.setValue(null);

            this.nurseRestrictionForm.controls.suspendedFrom.clearValidators();
            this.nurseRestrictionForm.controls.suspendedTill.clearValidators();
        }

        if (this.selectedRestrictiontype == 10 || this.selectedRestrictiontype == 6) {
            if (this.hospitalList?.length == 1) {
                this.selectedHospitalIds = [this.hospitalList[0].id];
                this.nurseRestrictionForm.controls.hospitalIds.setValue([this.hospitalList[0].id]);
            }
            if (this.companyList?.length == 1) {
                this.selectedCompanyIds = [this.companyList[0].id];
                this.nurseRestrictionForm.controls.companyIds.setValue([this.companyList[0].id]);
            }
        }

        this.nurseRestrictionForm.controls.companyIds.updateValueAndValidity();
        this.nurseRestrictionForm.controls.hospitalIds.updateValueAndValidity();
        this.nurseRestrictionForm.controls.suspendedTill.updateValueAndValidity();
        this.nurseRestrictionForm.controls.suspendedFrom.updateValueAndValidity();
    }

    onRestriction() {
        if (this.nurseRestrictionForm.invalid) {
            return;
        }

        let nurseReastrictionModel = this.nurseRestrictionForm.value as RestrictNurseModel;
        if ([RestrictionType.SuspendAll, RestrictionType.SuspendSelected].includes(this.selectedRestrictiontype)) {
            const suspendedTillDate = moment(nurseReastrictionModel.suspendedTill, 'YYYY-MM-DD'); // adjust format as necessary
            const suspendedFromDate = moment(nurseReastrictionModel.suspendedFrom, 'YYYY-MM-DD'); // adjust format as necessary

            if (suspendedTillDate.isBefore(suspendedFromDate)) {
                this.toastrService.error('Suspended until Date should be greater than Suspended from Date');
                return;
            }
        }
        nurseReastrictionModel.id = 0;
        nurseReastrictionModel.hospitalIds =
            this.selectedHospitalIds?.length > 0
                ? this.selectedHospitalIds?.toString()
                : nurseReastrictionModel.hospitalIds;
        nurseReastrictionModel.companyIds =
            this.selectedCompanyIds?.length > 0
                ? this.selectedCompanyIds?.toString()
                : nurseReastrictionModel.companyIds
                ? nurseReastrictionModel.companyIds
                : this.ids.companyId;
        nurseReastrictionModel.clinicianId = this.model.clinicianId;
        nurseReastrictionModel.verifiedOn = new Date().toISOString();
        nurseReastrictionModel.verifiedById = Number(sessionStorage.getItem('contactId'));

        if (nurseReastrictionModel.suspendedTill) {
            nurseReastrictionModel.suspendedTill = this.dateUtilService.convertDateCurrentTimeToUTC(
                nurseReastrictionModel.suspendedTill
            );
        }
        if (nurseReastrictionModel.suspendedFrom) {
            nurseReastrictionModel.suspendedFrom = this.dateUtilService.convertDateCurrentTimeToUTC(
                nurseReastrictionModel.suspendedFrom
            );
        }
        nurseReastrictionModel.callingFrom = this.isThriveLevelLogin ? 'ThriveAdmin' : 'HealthcareAdmin';

        if (!this.type) {
            this.restrictNurse(nurseReastrictionModel);
        } else if (this.type == 'inactive') {
            this.inactiveNurseModel();
        } else if (this.type == 'terminate') {
            this.TerminateNurseModel();
        } else {
            this.ActiveNurseModel();
        }
    }

    restrictNurse(nurseReastrictionModel: any) {
        this.staffService.restrictNurse(nurseReastrictionModel).subscribe(
            (response: any) => {
                if (response.result) {
                    this.activeModal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.message);
            }
        );
    }

    getRestrictionReasonSelectList() {
        this.staffService.getRestrictionReasonSelectList().subscribe((response) => {
            if (response.result) {
                this.restrictionReasonList = response.result;
            }
        });
    }

    getInactiveReasonList() {
        this.staffService.getInactiveReasonList().subscribe((response) => {
            if (response.result) {
                this.restrictionReasonList = response.result;
            }
        });
    }

    getTerminateReasonList() {
        this.staffService.getTerminateReasonList().subscribe((response) => {
            if (response.result) {
                this.restrictionReasonList = response.result;
            }
        });
    }

    onRestrictionReasonChange(event: any) {
        if (event) {
            this.selectedRestrictReason = event;
            this.nurseRestrictionForm.controls.reasonId.setValue(event);
            if (event == 31 && this.type) {
                this.nurseRestrictionForm.controls.description.setValidators(
                    Validators.compose([Validators.required, noWhitespaceValidator, removeSpaces])
                );
                this.nurseRestrictionForm.controls.description.updateValueAndValidity();
            } else if (this.type) {
                this.nurseRestrictionForm.controls.description.clearValidators();
                this.nurseRestrictionForm.controls.description.updateValueAndValidity();
            }
        } else {
            this.selectedRestrictReason = null;
            this.nurseRestrictionForm.controls.reasonId.setValue(null);
        }
    }

    getHospitalSelectList() {
        let obj = {
            clinicianId: this.model.clinicianId,
            companyId: this.ids.companyId,
            callingFrom: this.type == 'inactive' ? 'InactiveNurse' : this.type == 'terminate' ? 'TerminateNurse' : '',
        };
        this.staffService.getNurseFacilitySelectList(obj).subscribe((response) => {
            if (response.result) {
                this.hospitalList = response.result;
            }
        });
    }

    getNurseHealthcareSelectList() {
        let obj = {
            clinicianId: this.model.clinicianId,
            callingFrom: this.type == 'inactive' ? 'InactiveNurse' : this.type == 'terminate' ? 'TerminateNurse' : '',
        };

        this.staffService.getNurseHealthcareSelectList(obj).subscribe((response) => {
            if (response.result) {
                this.companyList = response.result;
            }
        });
    }

    onHospitalSelectionChange(event: any) {
        this.attentionRecordsList = [];

        if (event) {
            this.selectedHospitalIds = event;
            this.nurseRestrictionForm.controls.hospitalIds.setValue(event);
        } else {
            this.selectedHospitalIds = null;
            this.nurseRestrictionForm.controls.hospitalIds.setValue(null);
        }
        if ((this.type == 'inactive' || this.type == 'terminate') && this.selectedHospitalIds.length > 0) {
            this.getAttentionRecordsList();
        }
    }

    onCompanySelectionChange(event: any) {
        this.attentionRecordsList = [];

        if (event) {
            this.selectedCompanyIds = event;
            this.nurseRestrictionForm.controls.companyIds.setValue(event);
        } else {
            this.selectedCompanyIds = null;
            this.nurseRestrictionForm.controls.companyIds.setValue(null);
        }
        if ((this.type == 'inactive' || this.type == 'terminate') && this.selectedCompanyIds.length > 0) {
            this.getAttentionRecordsList();
        }
    }

    onDateSelectChange(event: any, type: any) {
        if (type == 'till') {
            this.nurseRestrictionForm.controls.suspendedTill.setValue(event);
        } else {
            this.nurseRestrictionForm.controls.suspendedFrom.setValue(event);
        }
    }

    onClose() {
        this.activeModal.dismiss();
    }

    inactiveNurseModel() {
        let nurseReastrictionModel = this.nurseRestrictionForm.value as InactiveNurseModel;
        nurseReastrictionModel.hospitalIds =
            nurseReastrictionModel.hospitalIds != null ? nurseReastrictionModel.hospitalIds.toString() : '';
        nurseReastrictionModel.companyIds =
            nurseReastrictionModel.companyIds != null ? nurseReastrictionModel.companyIds.toString() : '';
        if (this.nurseRestrictionForm.invalid) {
            return;
        }

        this.staffService.inactiveNurse(nurseReastrictionModel).subscribe(
            (response: any) => {
                if (response.result.result) {
                    let obj = {
                        inactiveRecordCount: response.result.inactiveRecordCount,
                        hospitalClinicianId: response.result.hospitalClinicianId,
                    };
                    this.activeModal.close(obj);
                    this.activeModal.close(obj);
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.message);
            }
        );
    }

    ActiveNurseModel() {
        let nurseReastrictionModel = this.nurseRestrictionForm.value as ActiveNurseModel;
        if (this.nurseRestrictionForm.invalid) {
            return;
        }

        this.staffService.activeNurse(nurseReastrictionModel).subscribe(
            (response: any) => {
                if (response.result.result) {
                    let obj = {
                        inactiveRecordCount: response.result.inactiveRecordCount,
                        hospitalClinicianId: response.result.hospitalClinicianId,
                    };
                    this.activeModal.close(obj);
                    this.toastrService.success(response.message);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.message);
            }
        );
    }

    getInactiveHospitalList() {
        this.staffService.getInactiveHospitalList(this.model.clinicianId).subscribe((response: any) => {
            if (response.result) {
                if (this.isThriveLevelLogin) {
                    this.companyList = response.result;
                    this.selectedCompanyIds = response.result.map((element: any) => element.id);
                } else {
                    this.hospitalList = response.result;
                    this.selectedHospitalIds = response.result.map((element: any) => element.id);
                }
            }
        });
    }

    getAttentionRecordsList() {
        let obj = {
            clinicianId: this.model.clinicianId,
            hospitalIds: this.selectedHospitalIds ? this.selectedHospitalIds.toString() : '',
            companyIds: this.selectedCompanyIds ? this.selectedCompanyIds.toString() : '',
        };
        this.staffService.getAttentionRecords(obj).subscribe((response: any) => {
            if (response.result) {
                this.attentionRecordsList = response.result;
            }
        });
    }

    TerminateNurseModel() {
        let nurseReastrictionModel = this.nurseRestrictionForm.value as TerminateModel;
        if (this.nurseRestrictionForm.invalid) {
            return;
        }

        this.staffService.terminateNurse(nurseReastrictionModel).subscribe(
            (response: any) => {
                if (response.result) {
                    this.toastrService.success(response.message);
                    this.activeModal.close(true);
                } else {
                    this.toastrService.error(response.message);
                }
                this.ngxSpinnerService.hide();
            },
            (err) => {
                this.ngxSpinnerService.hide();
                this.toastrService.error(err.message);
            }
        );
    }
}
