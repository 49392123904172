import { ChangeDetectorRef, Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TreeRefreshService } from 'src/app/service/treeRefresh.service';
import { NavigationService } from 'src/app/service/navigation.service';

interface SidebarTab {
    title: string;
    active: boolean; // Determine if the tab is currently active
    type: 'tree' | 'other'; // You can expand this type for other components
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'out',
                style({
                    transform: 'translateX(0)',
                })
            ),
            state(
                'in',
                style({
                    transform: 'translateX(-100%)',
                })
            ),
            transition('in => out', animate('200ms ease-out')),
            transition('out => in', animate('200ms ease-in')),
        ]),
    ],
})
export class SidebarComponent implements OnInit {
    subMenu: any[];
    treeNodes: NzTreeNodeOptions[] = [];
    sidebarState = 'out';
    isShowChildMenus: any;
    selectedKey: any = null;
    isItComingFromRealData: boolean = false;
    @Output() pageWrapper = new EventEmitter<string>();
    @Output() pageContent = new EventEmitter<string>();
    secondLevelAssetUrl: any;
    tabs: SidebarTab[] = [
        { title: 'Market', active: true, type: 'tree' },
        // Add other tabs here, for example:
        { title: 'Alerts', active: false, type: 'other' },
    ];

    level1RealData: any[];
    level2RealData: any[];
    level3RealData: any[];
    isPassFromQueryParams: boolean = false;
    isNavigationServiceCalled: boolean = false;
    data: any;
    getAssetName: any;
    constructor(
        public dataService: DataService,
        private router: Router,
        private treeRefreshService: TreeRefreshService,
        private navigationService: NavigationService,
        private cdRef: ChangeDetectorRef,
        private ngZone: NgZone
    ) {}

    ngOnInit() {
        const savedTabIndex = sessionStorage.getItem('activeTabIndex');
        this.getAssetName = window.location.pathname.split('/')[1];
        if (savedTabIndex) {
            const tabIndex = parseInt(savedTabIndex, 10);
            this.switchTab(tabIndex);
        }

        //dynamic data get

        this.dataService.jobData$.subscribe((data) => {
            const isItComingFromRealData = sessionStorage.getItem('isItComingFromRealData');
            this.secondLevelAssetUrl = sessionStorage.getItem('secondLevelAssetUrl');
            this.sidebarState =
                this.secondLevelAssetUrl == '/shift/shift-list' || this.secondLevelAssetUrl == '/shift/view-shift'
                    ? 'out'
                    : 'in';
            this.toggleSidebar();
            this.getAssetName =
                this.secondLevelAssetUrl == 'nurse-profile' || this.secondLevelAssetUrl == 'add-nurse'
                    ? window.location.pathname.split('/')[1]
                    : '';
            this.isPassFromQueryParams =
                this.secondLevelAssetUrl != '/shift/bulk-shift-list' &&
                this.secondLevelAssetUrl != '/shift/shift-list' &&
                this.secondLevelAssetUrl != '/shift' &&
                this.secondLevelAssetUrl != '/active-nurse' &&
                this.secondLevelAssetUrl != '/restricted-nurse' &&
                this.secondLevelAssetUrl != '/time-sheet' &&
                this.secondLevelAssetUrl != '/all-jobs/candidate' &&
                this.secondLevelAssetUrl != '/all-jobs/interview-list' &&
                this.secondLevelAssetUrl != '/all-jobs' &&
                this.secondLevelAssetUrl != '/lead-nurse' &&
                this.secondLevelAssetUrl != '/all-jobs/screening' &&
                this.secondLevelAssetUrl != 'all-jobs/renewals';

            if (isItComingFromRealData === 'true' && data !== null) {
                this.subMenu = data;
                this.isItComingFromRealData = true;
                let selectedKey;
                this.treeNodes = this.transformRealDataToTreeNodes(data);
                if (this.treeNodes.length > 0) {
                    let matchedNode;
                    let selectedKey = sessionStorage.getItem('selectedJobKey');
                    if (selectedKey) {
                        matchedNode = this.filterTreeNodes(this.treeNodes, selectedKey);
                    } else {
                        matchedNode = this.filterTreeNodes(this.treeNodes, data[0]?.marketName);
                    }

                    if (matchedNode) {
                        // If a match is found, set selectedKey and marketName
                        this.selectedKey = matchedNode.key;
                        this.treeRefreshService.setMarketName(matchedNode.title);
                        // if (matchedNode.levelName == 'Facility') {
                        //     let facility = matchedNode.key.split('_');
                        //     selectedKey =
                        //         'market_' +
                        //         matchedNode.marketId +
                        //         '_facility_' +
                        //         facility[3] +
                        //         '_companyId_' +
                        //         matchedNode.companyId +
                        //         '_name_' +
                        //         matchedNode.title;
                        // } else {
                        //     // selectedKey = 'market_' + matchedNode.marketId + '_' + matchedNode.key;
                        // }
                        this.selectedKey = matchedNode.key;
                        sessionStorage.setItem('assetLevel', matchedNode.level);
                    } else {
                        // If realDataAssetName is not provided or no match found, set selectedKey to the key of the first node
                        this.selectedKey = this.treeNodes[0].key;
                        this.treeRefreshService.setMarketName(this.treeNodes[0].title);
                        sessionStorage.setItem('assetLevel', '0');
                    }
                    this.isNavigationServiceCalled = false;

                    this.navigationService.currentActiveMenu.subscribe((data: any) => {
                        if (['Pipelines', 'Schedule'].includes(data.menu)) {
                            this.isNavigationServiceCalled = true;
                            if (this.isNavigationServiceCalled) {
                                sessionStorage.setItem('selectedJobKey', this.treeNodes[0]?.key);
                                sessionStorage.setItem('realDataAssetName', this.treeNodes[0]?.title);
                                sessionStorage.setItem('assetLevel', '0');
                                sessionStorage.setItem('isAllMarketLevel', this.treeNodes[0]?.isAllMarketLevel);
                            }
                            this.navigationService.updateActiveMenu('');
                        }
                    });
                    // Update the selected state based on the new selected key
                    this.updateSelected(this.treeNodes, this.selectedKey);

                    // Expand nodes based on the selected key
                    this.collapseAllNodes(this.treeNodes);
                    this.expandNodeAndParents(this.treeNodes, this.selectedKey);

                    if (this.isPassFromQueryParams) {
                        this.router.navigate([this.secondLevelAssetUrl], {
                            queryParams: {
                                id: btoa(selectedKey == null ? this.selectedKey : selectedKey),
                            },
                        });
                    } else {
                        this.router.navigate([this.secondLevelAssetUrl]);
                        this.treeRefreshService.setSelectedNode(selectedKey == null ? this.selectedKey : selectedKey);
                    }
                } else {
                    if (this.isPassFromQueryParams) {
                        this.router.navigate([this.secondLevelAssetUrl], {
                            queryParams: {
                                id: btoa(selectedKey == null ? this.selectedKey : selectedKey),
                            },
                        });
                    } else {
                        this.router.navigate([this.secondLevelAssetUrl]);
                        this.treeRefreshService.setSelectedNode(selectedKey == null ? this.selectedKey : selectedKey);
                    }
                }
            }
        });

        // Restore the initial selection state from storage
        // this.restoreSelectionFromStorage();

        //master config
        this.dataService.data$.subscribe((data) => {
            this.secondLevelAssetUrl = sessionStorage.getItem('secondLevelAssetUrl');
            let secondLevelAssetName = sessionStorage.getItem('secondLevelAssetName');
            let fourthLevelAssetUrl = sessionStorage.getItem('fourthLevelAssetUrl');
            this.sidebarState =
                secondLevelAssetName == 'Facility Management' && fourthLevelAssetUrl == '/healthcare-system'
                    ? 'out'
                    : 'in';
            this.toggleSidebar();

            this.cdRef.detectChanges();
            if (data !== null && data?.length > 0) {
                let defaultMenu;
                this.isItComingFromRealData = false;
                this.subMenu = data;
                this.treeNodes = this.transformDataToTreeNodes(data);
                if (fourthLevelAssetUrl == 'null') {
                    fourthLevelAssetUrl = data?.[0]?.childAssets[0].url;
                    sessionStorage.setItem('fourthLevelAssetUrl', data?.[0].childAssets[0].url);
                }
                const isDefultAssetSet = sessionStorage.getItem('isDefultAssetSet');
                const isAssetLoaded = sessionStorage.getItem('isAssetLoaded');

                if (this.treeNodes[0]?.children) {
                    defaultMenu = this.treeNodes[0]?.children[0]?.key;
                    if (defaultMenu != fourthLevelAssetUrl && isDefultAssetSet == 'true') {
                        sessionStorage.setItem('isDefultAssetSet', 'false');
                        this.selectedKey = [fourthLevelAssetUrl];
                        this.updateSelected(this.treeNodes, this.selectedKey);
                        this.router.navigateByUrl(this.selectedKey[0]);
                        sessionStorage.setItem('isAssetLoaded', 'false');
                    } else if (
                        this.treeNodes &&
                        this.treeNodes.length > 0 &&
                        this.treeNodes[0]?.children &&
                        this.treeNodes[0]?.children[0]?.key != fourthLevelAssetUrl &&
                        fourthLevelAssetUrl != '' &&
                        isAssetLoaded == 'true'
                    ) {
                        sessionStorage.setItem('isAssetLoaded', 'false');
                        sessionStorage.setItem('isDefultAssetSet', 'false');
                        this.selectedKey = [fourthLevelAssetUrl];
                        this.updateSelected(this.treeNodes, this.selectedKey);
                        this.router.navigateByUrl(this.selectedKey[0]);
                    } else if (this.treeNodes && this.treeNodes.length > 0 && this.treeNodes[0]?.children) {
                        sessionStorage.setItem('thridLevelAssetName', data?.[0]?.name);
                        sessionStorage.setItem('thridLevelAssetUrl', data?.[0]?.url);

                        sessionStorage.setItem('fourthLevelAssetName', data?.[0]?.childAssets[0]?.name);
                        sessionStorage.setItem('fourthLevelAssetUrl', data?.[0]?.childAssets[0]?.url);

                        sessionStorage.setItem('isAssetLoaded', 'false');
                        sessionStorage.setItem('isDefultAssetSet', 'false');
                        const firstChildKey = this.treeNodes[0].children[0].key;
                        this.selectedKey = [firstChildKey];
                        this.updateSelected(this.treeNodes, this.selectedKey);
                        this.sidebarState =
                            secondLevelAssetName == 'Facility Management' &&
                            data?.[0]?.childAssets[0]?.url == '/healthcare-system'
                                ? 'out'
                                : 'in';
                        this.toggleSidebar();
                        // Navigate to the URL of the first child of level 4
                        this.router.navigateByUrl(firstChildKey);
                    }
                    this.getAssetName = fourthLevelAssetUrl != '' ? window.location.pathname.split('/')[1] : '';
                }
            }
        });
    }

    filterTreeNodes(treeNodes: any[], selectedKey: string): any {
        for (const node of treeNodes) {
            sessionStorage.removeItem('isFacilityOperatingAllLevel');
            if (node.key === selectedKey) {
                this.selectedKey = node.key;

                if (node.isFacilityOperatingAllLevel != null) {
                    sessionStorage.setItem('isFacilityOperatingAllLevel', node.isFacilityOperatingAllLevel);
                }

                this.treeRefreshService.setMarketName(node.title);
                return node;
            }
            if (node.children && node.children.length > 0) {
                const matchedChild = this.filterTreeNodes(node.children, selectedKey);
                if (matchedChild) {
                    return matchedChild;
                }
            }
        }
        return null;
    }

    private transformDataToTreeNodes(data: any[]): NzTreeNodeOptions[] {
        // Map each top-level menu item to a TreeNode with nested children
        return data?.flatMap((menuItem) => {
            let isTopLevelNodeSelected = this.selectedKey === menuItem.url;

            return {
                title: menuItem.name,
                key: menuItem.url,
                expanded: true,
                selected: isTopLevelNodeSelected,
                children:
                    menuItem.childAssets?.map((childAsset: any) => {
                        let isChildSelected = this.selectedKey === childAsset.url;

                        // Set the parent as selected if any child is selected
                        if (isChildSelected) {
                            isTopLevelNodeSelected = true;
                        } else {
                            let getLevel4Obj = data[0].childAssets[0];
                            if (childAsset.name == getLevel4Obj.name) {
                                isTopLevelNodeSelected = true;
                                isChildSelected = true;
                                this.router.navigateByUrl(getLevel4Obj.url);
                            }
                        }
                        return {
                            title: childAsset.name,
                            key: childAsset.url,
                            isLeaf: true,
                            selected: isChildSelected,
                        };
                    }) || [],
                isLeaf: false,
            };
        });
    }

    //for dynamic data
    private transformRealDataToTreeNodes(data: any): NzTreeNodeOptions[] {
        let isFirstMarket = true; // Flag to track the first market

        // Top-level node for "All Region"

        const allMarketNode: NzTreeNodeOptions = {
            title: 'All Region',
            key: 'All Region', // Unique key for "All Region",
            marketId: '',
            expanded: true, // Optionally set this based on your needs
            selected: true, // Optionally set this based on your needs
            isAllMarketLevel: true, // Optionally set this based on your needs
            level: 0,
            children:
                data?.markets?.map((marketItem: any) => {
                    // let isTopLevelNodeSelected = this.selectedKey === `market_${marketItem.marketId}`;
                    let isTopLevelNodeSelected = this.selectedKey === marketItem.marketId;
                    let isExpanded = isFirstMarket; // Expand only the first market

                    // Set the flag to false after processing the first market
                    if (isFirstMarket) {
                        isFirstMarket = false;
                    }

                    return {
                        title: marketItem.marketName,
                        key: `market_${marketItem.marketId}_thirveHealthLevel_${marketItem.isDefaultMarket}`, // Use a unique key for each market
                        expanded: isExpanded, // Set expanded to true for the first market
                        selected: isTopLevelNodeSelected,
                        marketId: marketItem.marketId,
                        isFacilityOperatingAllLevel: marketItem.isFacilityOperatingAllLevel,
                        isAllMarketLevel: false, // Optionally set this based on your needs
                        level: marketItem.level,
                        children:
                            marketItem.healthcare?.map((healthcareItem: any) => {
                                let isHealthcareSelected =
                                    healthcareItem.levelName == 'Facility'
                                        ? this.selectedKey ===
                                          `market_${healthcareItem.marketId}_healthcare_${healthcareItem.companyId}_facility_${healthcareItem.id}_thirveHealthLevel_${healthcareItem.isThriveHealthLevel}_isFacilityOperatingAllLevel_${healthcareItem.isFacilityOperatingAllLevel}`
                                        : this.selectedKey ===
                                          `market_${healthcareItem.marketId}_healthcare_${healthcareItem.companyId}_thirveHealthLevel_${healthcareItem.isThriveHealthLevel}`;

                                return {
                                    title: healthcareItem.name,
                                    key:
                                        healthcareItem.levelName == 'Facility'
                                            ? `market_${healthcareItem.marketId}_healthcare_${healthcareItem.companyId}_facility_${healthcareItem.id}_name_${healthcareItem.name}_level_${healthcareItem.levelName}_thirveHealthLevel_${healthcareItem.isThriveHealthLevel}_marketName_${marketItem.marketName}`
                                            : `market_${healthcareItem.marketId}_healthcare_${healthcareItem.companyId}_level_${healthcareItem.levelName}_thirveHealthLevel_${healthcareItem.isThriveHealthLevel}`, // Use a unique key for each healthcare item
                                    expanded: isExpanded, // Set expanded to true for the first market
                                    selected: isHealthcareSelected,
                                    marketId: healthcareItem.marketId,
                                    levelName: healthcareItem.levelName,
                                    companyId: healthcareItem.companyId,
                                    level: healthcareItem.level,
                                    isFacilityOperatingAllLevel: healthcareItem.isFacilityOperatingAllLevel,
                                    isAllMarketLevel: false, // Optionally set this based on your needs
                                    children:
                                        healthcareItem.facilities?.map((facility: any) => {
                                            let isFacilitySelected =
                                                this.selectedKey ===
                                                `market_${facility.marketId}_facility_${facility.id}_companyId_${facility.companyId}_name_${facility.name}`;

                                            return {
                                                title: facility.name,
                                                key: `market_${facility.marketId}_facility_${facility.id}_companyId_${facility.companyId}_name_${facility.name}_thirveHealthLevel_${facility.isThriveHealthLevel}_marketName_${marketItem.marketName}`, // Use a unique key for each facility
                                                isLeaf: true,
                                                selected: isFacilitySelected,
                                                marketId: facility.marketId,
                                                levelName: facility.levelName,
                                                companyId: facility.companyId,
                                                level: facility.level,
                                                isAllMarketLevel: false, // Optionally set this based on your needs
                                                isFacilityOperatingAllLevel: facility.isFacilityOperatingAllLevel,
                                            };
                                        }) || [],
                                };
                            }) || [],
                        isLeaf: !marketItem.healthcare || marketItem.healthcare.length === 0,
                    };
                }) || [],
            isLeaf: data.length === 0, // Adjust based on whether there are any markets
        };

        return [allMarketNode];
    }

    //for redirect

    onNodeClick(event: any): void {
        sessionStorage.removeItem('isThriveHealth');
        let isThriveLevelLogin: any = sessionStorage.getItem('isThriveLevelLogin');

        if (isThriveLevelLogin == 'true') {
            sessionStorage.removeItem('companyId');
        }
        sessionStorage.setItem('thridLevelAssetName', event.nodes[0].parentNode._title);
        sessionStorage.setItem('thridLevelAssetUrl', event.nodes[0].parentNode.key);
        const node = event.node;
        if (node.origin.key && node.origin.key !== '#') {
            sessionStorage.setItem('fourthLevelAssetName', node.origin._title);
            sessionStorage.setItem('fourthLevelAssetUrl', node.origin.key);
            this.selectedKey = node.origin.key;
            this.updateSelected(this.treeNodes, this.selectedKey);
            this.router.navigateByUrl(node.origin.key);
        }
        if (node.origin.key == '/healthcare-system') {
            this.sidebarState = 'out';
            this.toggleSidebar();
        }
    }

    onNodeClickForRealData(event: any): void {
        const node = event.node;

        if (node.origin.key && node.origin.key !== '#') {
            this.selectedKey = node.origin.key;
            sessionStorage.removeItem('isFacilityOperatingAllLevel');

            sessionStorage.setItem('realDataAssetName', node.origin.title);
            sessionStorage.setItem('assetMenuIds', node.origin.position);
            sessionStorage.setItem('isAllMarketLevel', node.origin.isAllMarketLevel);
            sessionStorage.setItem('assetLevel', node.origin.level);
            sessionStorage.removeItem('isFacilityOperatingAllLevel');
            if (node.origin.isFacilityOperatingAllLevel != null) {
                sessionStorage.setItem('isFacilityOperatingAllLevel', node.origin.isFacilityOperatingAllLevel);
            }
            this.collapseAllNodes(this.treeNodes);
            this.updateSelected(this.treeNodes, this.selectedKey);
            this.expandNodeAndParentsWithoutSelecting(this.treeNodes, this.selectedKey);

            this.treeNodes = [...this.treeNodes];
            this.treeRefreshService.setMarketName(node.origin.title);
            sessionStorage.setItem('selectedJobKey', this.selectedKey);
            if (node.origin.isFacilityOperatingAllLevel != null) {
                sessionStorage.setItem('isFacilityOperatingAllLevel', node.origin.isFacilityOperatingAllLevel);
            }

            if (this.isPassFromQueryParams) {
                this.treeRefreshService.clearDates();
                this.router.navigate([this.secondLevelAssetUrl], {
                    queryParams: { id: btoa(this.selectedKey) },
                });
            } else {
                this.router.navigate([this.secondLevelAssetUrl]);
                this.treeRefreshService.clearDates();
                this.treeRefreshService.setSelectedNode(this.selectedKey);
            }
        }
    }

    private expandNodeAndParentsWithoutSelecting(nodes: NzTreeNodeOptions[], selectedKey: string): boolean {
        let expanded = false;

        nodes.forEach((node) => {
            if (node.key === selectedKey) {
                node.expanded = true; // Expand the exact node
                expanded = true;
            }

            if (node.children && node.children.length > 0) {
                const childExpanded = this.expandNodeAndParentsWithoutSelecting(node.children, selectedKey);
                if (childExpanded) {
                    node.expanded = true; // Expand the parent node
                    expanded = true;
                }
            }
        });

        return expanded;
    }

    restoreSelectionFromStorage(): void {
        const selectedKey = sessionStorage.getItem('selectedJobKey');
        if (selectedKey) {
            const matchedNode = this.filterTreeNodes(this.treeNodes, selectedKey);
            if (matchedNode) {
                this.selectedKey = matchedNode.key;
                this.collapseAllNodes(this.treeNodes);
                this.expandNodeAndParents(this.treeNodes, this.selectedKey);
                this.updateSelected(this.treeNodes, this.selectedKey);
            } else {
                console.error('Selected key not found in the tree structure.');
                sessionStorage.removeItem('selectedJobKey');
            }
        }
    }

    private ensureCorrectSelection(nodes: NzTreeNodeOptions[], selectedKey: string): void {
        nodes.forEach((node) => {
            if (node.key !== selectedKey) {
                node.selected = false;
            } else {
                node.selected = true;
                if (node.children) {
                    this.ensureCorrectSelection(node.children, selectedKey);
                }
            }
        });
    }

    toggleSidebar() {
        this.sidebarState = this.sidebarState === 'out' ? 'in' : 'out';
        let pageContent = this.sidebarState == 'in' ? 'page-content-main' : 'page-content';
        let pageWrapper = this.sidebarState == 'in' ? 'page-wrapper-main' : 'page-wrapper';
        this.pageContent.emit(pageContent);
        this.pageWrapper.emit(pageWrapper);
        sessionStorage.setItem('sidebarState', this.sidebarState);
    }

    switchTab(index: number): void {
        this.tabs.forEach((tab, i) => (tab.active = i === index));
        sessionStorage.setItem('activeTabIndex', index.toString());
        // Apply any other changes that need to occur when a tab is switched
    }

    private expandNodesBasedOnPosition(treeNodes: NzTreeNodeOptions[], position: string[]): void {
        const traverseAndExpand = (nodes: NzTreeNodeOptions[], positions: string[]): boolean => {
            if (!nodes.length) return false;

            const currentPosition = positions[0];
            const remainingPositions = positions.slice(1);
            let expandedAnyNode = false;

            nodes.forEach((node) => {
                if (node.position === currentPosition) {
                    node.expanded = true;
                    expandedAnyNode = true;

                    if (remainingPositions.length > 0) {
                        expandedAnyNode = traverseAndExpand(node.children || [], remainingPositions);
                    }
                }
            });

            return expandedAnyNode;
        };

        // Collapse all nodes first
        this.collapseAllNodes(treeNodes);

        // Expand nodes based on the provided position
        traverseAndExpand(treeNodes, position);

        // Ensure that only the relevant market is expanded and the rest are collapsed
        const selectedKey = sessionStorage.getItem('selectedJobKey');
        if (selectedKey) {
            this.expandNodeAndParents(treeNodes, selectedKey);
        }
    }

    // Method to expand only the selected node and its parents
    private expandNodeAndParents(nodes: NzTreeNodeOptions[], selectedKey: string): boolean {
        let expanded = false;

        nodes.forEach((node) => {
            if (node.key === selectedKey) {
                node.expanded = true; // Expand the exact node
                expanded = true;
            }

            if (node.children && node.children.length > 0) {
                const childExpanded = this.expandNodeAndParents(node.children, selectedKey);
                if (childExpanded) {
                    node.expanded = true; // Expand the parent node
                    expanded = true;
                }
            }
        });

        return expanded;
    }

    // Method to collapse all nodes
    private collapseAllNodes(nodes: NzTreeNodeOptions[]): void {
        nodes.forEach((node) => {
            node.expanded = false;
            if (node.children) {
                this.collapseAllNodes(node.children);
            }
        });
    }

    expandSelectedPath(treeNodes: any, selectedNode: any) {
        // First, collapse all nodes
        treeNodes.forEach((node: any) => this.collapseNode(node));

        // Expand the path to the selected node
        let node = selectedNode;
        while (node) {
            node.expanded = true;
            node = node.parent; // Assuming each node has a reference to its parent
        }
    }

    collapseNode(node: any) {
        node.expanded = false;
        if (node.children) {
            node.children.forEach((child: any) => this.collapseNode(child));
        }
    }

    // Method to update the selected node
    private updateSelected(nodes: NzTreeNodeOptions[], selectedKey: string): void {
        nodes.forEach((node) => {
            // Deselect all nodes initially
            node.selected = false;

            if (node.children) {
                this.updateSelected(node.children, selectedKey);
            }

            // Select only the node that matches the selectedKey
            if (node.key === selectedKey) {
                node.selected = true;
            }
        });
    }
}
