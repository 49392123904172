export interface StaffModel {
    id: number;
    profileUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    licenseTypeName: string;
    employmentTypeName: string;
    linkedIn: string;
    linkedin: string;
    address: string;
    license: license[];
    certificate: certificate[];
    education: education[];
    immunization: immunization[];
    salery: number;
    availability: string;
    shiftTimimg: string;
    timeZone: string;
    addresses: addresses[];
    resume: resume[];
    experience: experience[];
    expertise: expertise[];
}
export class resume {
    resumeName: string;
}
export class certificate {
    name: string;
    expiryDate: string;
    authority: string;
    varifyBy: string;
}
export class license {
    licenseName: string;
    expiryDate: string;
    instituteName: string;
    varifyBy: string;
}
export class education {
    degreeName: string;
    fieldName: string;
    gratuationYear: string;
    instituteName: string;
    varifyBy: string;
}
export class immunization {
    vaccineName: string;
    dose: number;
    vaccinetype: string;
    recieveDate: string;
    varifyBy: string;
}
export class addresses {
    addressLine1: string;
    // addressLine2: string;
    city: string;
    state: string;
    country: string;
    zipCode: number;
}
export class experience {
    hospitalName: string;
    jobTitle: string;
    unit: string;
    experience: string;
}
export class expertise {
    jobType: string;
    experience: string;
}

export enum ShiftFilters {
    'All' = 1,
    'Empty' = 2,
    'PartialyFilled' = 3,
    'Filled' = 4,
    'Overfilled' = 5,
    'SwapRequest' = 6,
    'CancelByFacility' = 7,
}

export enum RestrictionType {
    DNRAll = 1,
    DNRSelected = 2,
    SuspendAll = 3,
    SuspendSelected = 4,
    InactiveAll = 5,
    InactiveSelected = 6,
}

export class RestrictNurseModel {
    id: number;
    companyId: number;
    clinicianId: number;
    hospitalIds: string;
    suspendedTill: any;
    suspendedFrom: any;
    descrption: string;
    reasonId: number;
    RestrictionType: number;
    verifiedById: number;
    verifiedOn: any;
    companyIds: number;
    callingFrom: any;
}
export class InactiveNurseModel {
    id: number;
    clinicianId: number;
    hospitalIds: string;
    companyIds: string;
    description: string;
    reasonId: number;
    restrictionType: number;
    verifiedById: number;
    verifiedOn: Date;
}

export class ActiveNurseModel {
    clinicianId: number;
    companyIds: string;
    hospitalIds: string;
    description: string;
    verifiedById: number;
    verifiedOn: Date;
}

export class TerminateModel {
    id: number;
    clinicianId: number;
    hospitalIds: string;
    companyIds: string;
    description: string;
    reasonId: number;
    restrictionType: number;
    verifiedById: number;
    verifiedOn: Date;
}

export enum ThriveRestrictionType {
    'DNR from All healthcare systems (ban the user from Thrive platfrom)' = 1,
    'DNR from selected healthcare systems' = 2,
    'Suspend from All healthcare systems' = 3,
    'Suspend from selected healthcare systems' = 4,
}

export enum HealthcareRestrictionType {
    'DNR from All facilities in the healthcare system' = 1,
    'DNR from selected facilities' = 2,
    'Suspend from All facilities in the healthcare system' = 3,
    'Suspend from selected facilities' = 4,
}

export enum ThriveInactiveRestrictionType {
    'Inactive from All healthcare system' = 5,
    'Inactive from selected healthcare' = 6,
}

export enum HealthcareInactiveRestrictionType {
    'Inactive from All facilities in the healthcare system' = 5,
    'Inactive from selected facilities' = 6,
}

export enum ThriveTerminateRestrictionType {
    'Terminate from All healthcare system' = 9,
    'Terminate from selected healthcare' = 10,
}

export enum HealthcareTerminateRestrictionType {
    'Terminate from All facilities in the healthcare system' = 9,
    'Terminate from selected facilities' = 10,
}

export class NewNurseModel {
    jobId: number;
    id: number;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    profileUrl: string;
    address: string;
    cityId: number;
    stateId: number;
    zipCode: string;
    lat: any;
    long: any;
    driverLicense: DriverLicense | null;
}

export class DriverLicense {
    id: number;
    clinicianId: number;
    stateId: number;
    licenseNumber: string;
    issuedDate: Date;
    expiryDate: Date;
    fileName: string;
    fileUrl: string;
    thumbnail: string;
}
