import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const noWhitespaceValidator: any = (control: FormControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
};

export const removeSpaces = (control: AbstractControl) => {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
        control.setValue('');
    }
    return null;
};
export const spaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value && (control.value as string).includes(' ')) {
        return { hasSpace: true };
    }
    return null;
};
export function timeValidator(control: AbstractControl): ValidationErrors | null {
    const valid = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(control.value);
    return valid ? null : { invalidTimeFormat: true };
}
export const alphabeticWithSpacesValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    const alphabeticWithSpacesRegex = /^[a-zA-Z\s]*$/; // Regex to allow alphabetic characters and spaces

    if (value && !alphabeticWithSpacesRegex.test(value)) {
        return { alphabeticWithSpaces: true };
    }
    return null;
};

// Custom validator function to allow only characters and dot
export const onlyCharactersWithDotValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    // Regular expression to match only characters and dot
    const onlyCharactersWithDotRegex = /^[A-Za-z.&\s]+$/;
    // Test if the value matches the pattern
    if (value && !onlyCharactersWithDotRegex.test(value)) {
        return { onlyCharactersWithDotAndSpaces: true };
    }
    // Return validation result
    return null;
};

// Custom validator function to allow only characters and dot
export const onlyNumberAndAfterDotTwoDigitValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    const value = control.value as string;
    // Regular expression to match only characters and dot
    const onlyNumberAndAfterDotTwoDigitRegex =
        /^(?!0+(\.0+)?$)([1-9]\d{0,2}|0\d{0,1}|0\d{2}|00[1-9]|01)(?:\.\d{1,2})?$/;
    // Test if the value matches the pattern
    // Check if the value exceeds maximum length
    if (value && !onlyNumberAndAfterDotTwoDigitRegex.test(value)) {
        if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (integerPart.length > 3) {
                return { onlyNumberAndAfterDotTwoDigit: true };
            } else if (decimalPart && decimalPart.length > 2) {
                return { onlyNumberAndAfterDotTwoDigit: true };
            } else {
                return { onlyNumberAndAfterDotTwoDigit: true };
            }
        } else {
            if (value.length > 3) {
                return { onlyNumbeMaxLength: true };
            } else {
                return { onlyNumberAndAfterDotTwoDigit: true };
            }
        }
    }

    // Return null if the value is valid
    return null;
};

export const onlyNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value: any = control.value as string;
    // Regular expression to match only characters and dot
    const onlyNumberValidatorRegex = /^[0-9]*$/;
    // Test if the value matches the pattern
    if (value && !onlyNumberValidatorRegex.test(value)) {
        return { onlyNumber: true };
    } else if (value && value == 0) {
        return { onlyNumber: true };
    }
    // Return validation result
    return null;
};

// Custom Validator to check if BillRate is greater than Price

export function comparePricesWithBillRatesValidator(
    priceControlName: string,
    billRateControlName: string
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formGroup = control as FormGroup;
        const price = Number(formGroup.get(priceControlName)?.value);
        const billRate = Number(formGroup.get(billRateControlName)?.value);

        const errorKey = `${billRateControlName}LessThan${priceControlName}`;

        // If the bill rate is less than or equal to the price, return an error object
        if (billRate && price && billRate < price) {
            return { [errorKey]: true };
        }

        return null; // No validation errors
    };
}

export const addZipCodeValidation: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;

    const zipCodePattern = /^\d{5}(-\d{4})?$/; // 5 digits or 9-digit format with hyphen

    if (!value) {
        // If the control value is empty, check if it's required
        return Validators.required(control);
    }

    // Return error if the ZIP code pattern does not match
    return zipCodePattern.test(value) ? null : { invalidZipCode: true };
};
