import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { BACKGROUND_CHECK } from '../constants/api';

@Injectable({
    providedIn: 'root',
})
export class BackgroundCheckTypeService {
    constructor(private apiService: ApiService) {}

    addBackgroundCheckType(res: any): Observable<any> {
        return this.apiService.post(BACKGROUND_CHECK.POST_BACKGROUND_CHECK, res);
    }

    updateBackgroundCheckType(id: any, res: any): Observable<any> {
        return this.apiService.put(BACKGROUND_CHECK.PUT_BACKGROUND_CHECK, res);
    }

    deleteBackgroundCheckType(id: any): Observable<any> {
        return this.apiService.delete(BACKGROUND_CHECK.DELETE_BACKGROUND_CHECK, id);
    }

    getBackgroundCheckType(id: any): Observable<any> {
        return this.apiService.get(BACKGROUND_CHECK.POST_BACKGROUND_CHECK + '/' + id);
    }
}
