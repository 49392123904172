<div class="modal-content fixwidth overflow-hidden">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container>Timesheet</ng-container>
        </div>
        <div
            [ngClass]="{
                'badge-red': timeSheetDetails.statusName === 'Rejected',
                'badge-green': timeSheetDetails.statusName === 'Validated',
                'badge-blue': timeSheetDetails.statusName === 'Nurse Review',
                'badge-orange': timeSheetDetails.statusName === 'In Review'
            }"
        >
            {{ timeSheetDetails.statusName }}
        </div>
    </div>
    <form
        [formGroup]="timeSheetForm"
        class="form form-label-right overflow-auto"
        #contactFormDirective="ngForm"
        (ngSubmit)="submit()"
    >
        <div class="modal-body pt-0">
            <div class="mb-3 d-flex align-items-center justify-content-between">
                <div class="mb-3 mb-md-0 d-flex align-items-center">
                    <div>
                        <img
                            *ngIf="timeSheetDetails?.clinicianProfileUrl"
                            [src]="timeSheetDetails?.clinicianProfileUrl"
                            height="30px"
                            width="30px"
                            class="rounded-circle"
                        />
                        <img
                            *ngIf="!timeSheetDetails?.clinicianProfileUrl"
                            src="./assets/images/others/blank.png"
                            height="30px"
                            width="30px"
                            class="rounded-circle"
                        />
                    </div>
                    <div class="model-icon-content ms-2 text-secondary fw-normal">
                        {{ timeSheetDetails?.clinicianFirstName }} {{ timeSheetDetails?.clinicianLastName }}
                    </div>
                </div>

                <div>
                    <button
                        type="button"
                        class="btn btn-orange apply-btn btn-sm"
                        (click)="viewNurseRating(timeSheetDetails)"
                    >
                        View Nurse Rating
                    </button>
                </div>
            </div>
            <h5>Shift Information</h5>
            <div class="row g-3 mt-1 ms-1 border-bottom border-primary">
                <div class="col-md-3 my-2">
                    <label>Shift Date</label>
                    <p class="text-secondary fw-normal">
                        {{ timeSheetDetails.shiftDate | localDateTime : 'ordinal' }}
                    </p>
                </div>
                <div class="col-md-3 my-2">
                    <label>Shift Period</label>
                    <p class="text-secondary fw-normal">
                        {{ timeSheetDetails.shiftFromTime | localDateTime : 'shortTime' }} -
                        {{ timeSheetDetails.shiftToTime | localDateTime : 'shortTime' }}
                    </p>
                </div>
                <div class="col-md-3 my-2">
                    <label>Break Time</label>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.shiftBreakTime != null">
                        {{ timeSheetDetails.shiftBreakTime }}
                        {{ timeSheetDetails.isBreakTimePaid == false ? 'unpaid' : 'paid' }}
                    </p>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.shiftBreakTime == null">
                        {{ 'n/a' }}
                    </p>
                </div>
                <div class="col-md-3 my-2">
                    <label>Extended Time</label>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.extendedTime != null">
                        {{ timeSheetDetails.extendedTime }}
                    </p>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.extendedTime == null">
                        {{ 'n/a' }}
                    </p>
                </div>
                <div class="col-md-3 my-2">
                    <label>Extended Bonus</label>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.extendedBonus != null">
                        $ {{ timeSheetDetails.extendedBonus }}
                    </p>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.extendedBonus == null">
                        {{ 'n/a' }}
                    </p>
                </div>
                <div class="col-md-3 my-2">
                    <label>Pay Rate</label>
                    <p class="text-secondary fw-normal">
                        {{ '$' + '' + timeSheetDetails.shiftPayRate + '/hour' }}
                    </p>
                </div>
                <div class="col-md-3 my-2">
                    <label>Overtime Multiplier</label>
                    <p class="text-secondary fw-normal">
                        {{ timeSheetDetails.overtimeMultiplier }}
                    </p>
                </div>
                <!-- <div class="col-md-3 my-2">
                    <label>Late Call Bonus</label>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.lateCallBonus != null">
                        $ {{ timeSheetDetails.lateCallBonus }}
                    </p>
                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.lateCallBonus == null">
                        {{ 'n/a' }}
                    </p>
                </div> -->
            </div>

            <div>
                <h5 class="mt-3">Timesheet Information</h5>
                <div class="d-flex gap-4 align-items-center border-bottom border-primary">
                    <div class="ms-2">
                        <div class="row mt-2">
                            <div class="d-flex">
                                <div class="my-2 col-md-3">
                                    <label>Clock-In</label>
                                    <p class="text-secondary fw-normal">
                                        {{ timeSheetDetails?.clockIn | localDateTime : 'shortTime' }}
                                    </p>
                                </div>
                                <div class="my-2 col-md-3">
                                    <label>Clock-Out</label>
                                    <p class="text-secondary fw-normal">
                                        {{ timeSheetDetails?.clockOut | localDateTime : 'shortTime' }}
                                    </p>
                                </div>
                                <div class="my-2 col-md-3">
                                    <label>Total Paid Hrs</label>
                                    <p class="text-secondary fw-normal">
                                        {{ timeSheetDetails.totalPaidHours | minutesToHoursPipe }}
                                    </p>
                                </div>
                                <div class="my-2 col-md-3">
                                    <label>Total Hrs</label>
                                    <p class="text-secondary fw-normal">
                                        {{ timeSheetDetails.totalHours | minutesToHoursPipe }}
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="my-2 col-md-4">
                                    <label>Break time start</label>
                                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.breakIn != null">
                                        {{ timeSheetDetails.breakIn | localDateTime : 'shortTime' }}
                                    </p>
                                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.breakIn == null">
                                        {{ 'n/a' }}
                                    </p>
                                </div>
                                <div class="my-2 col-md-4">
                                    <label>Break time end</label>
                                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.breakOut != null">
                                        {{ timeSheetDetails.breakOut | localDateTime : 'shortTime' }}
                                    </p>
                                    <p class="text-secondary fw-normal" *ngIf="timeSheetDetails.breakOut == null">
                                        {{ 'n/a' }}
                                    </p>
                                </div>
                                <div class="my-2 col-md-4">
                                    <label>Total to be paid</label>
                                    <p class="text-secondary fw-normal">$ {{ timeSheetDetails.totalPayRate }}</p>
                                </div>
                            </div>

                            <div class="d-flex" *ngIf="timeSheetDetails?.verifiedBy">
                                <div class="my-2 col-md-4">
                                    <label>Verified On</label>
                                    <p class="text-secondary fw-normal">
                                        {{ timeSheetDetails?.verifiedOn | localDateTime : 'ordinal' }}
                                    </p>
                                </div>
                                <div class="my-2 col-md-4">
                                    <label>Verified By</label>
                                    <p class="text-secondary fw-normal">
                                        {{ timeSheetDetails?.verifiedBy }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="timeSheetDetails.timesheetFileUrl != null"
                        class="card col-5 rounded-3 cursor-pointer"
                        (click)="onOpenFile(timeSheetDetails?.timesheetFileUrl)"
                    >
                        <div class="row py-3">
                            <div class="col-2">
                                <img src="assets/images/file-logo.jpg" height="60px" />
                            </div>
                            <div class="col-9 ms-1">
                                <p class="fw-bolder form-content font-weight-normal">Timesheet File</p>
                                <p class="form-content font-weight-normal">
                                    Posted in {{ timeSheetDetails.fileCreatedOn | localDateTime : 'atDateTime' }}
                                </p>
                                <p class="text-secondary form-content font-weight-normal">
                                    {{ timeSheetDetails?.timesheetFileName }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-5 row py-3"
                        *ngIf="timeSheetDetails.timesheetFileUrl == null && timeSheetDetails.releasedBySystem"
                    >
                        <span class="col-12 ms-1 text-secondary">
                            <b>Note:</b> Timesheet is auto submitted by the system at the time of releasing nurse. So it
                            will not contain any timesheet file.</span
                        >
                    </div>
                    <div
                        class="col-5 row py-3"
                        *ngIf="timeSheetDetails.timesheetFileUrl == null && timeSheetDetails.clockOutBySystem"
                    >
                        <span class="col-12 ms-1 text-secondary">
                            <b>Note:</b> Timesheet is auto submitted by the system at the time of manual clock out by
                            facility user. so it will not contain any time sheet file.</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="model.statusName == 'In Review'">
                <h5 class="mb-2">Timesheet Operation</h5>
                <div class="d-flex mt-2 mb-2">
                    <div class="form-check form-check-inline" *ngFor="let item of disputeInfo">
                        <input
                            type="radio"
                            [checked]="selectedDispute == item.id"
                            (change)="onCheckboxChange(item.id)"
                            class="form-check-input cursor-pointer"
                            formControlName="requestType"
                        />
                        <label
                            class="form-check-label dispute-content cursor-pointer text-secondary fw-normal"
                            for="checkInline2"
                        >
                            {{ item.name }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="mt-3 d-flex" *ngIf="model.statusName == 'In Review'">
                <div class="w-100 d-flex align-items-end" *ngIf="selectedDispute !== 2">
                    <div class="form-group col-sm-5">
                        <div class="custom-dropdown-box dropdown-box">
                            <app-selectbox
                                class="dropdown-box"
                                formControlName="disputeReason"
                                fieldName="name"
                                [selectItems]="disputeReasonList"
                                [selectedFieldId]="selectedDisputeReason"
                                [formName]="timeSheetForm"
                                fieldId="id"
                                placeholder="(multi-select)"
                                (onChangeSelect)="onDisputeReasonChange($event)"
                                [isSearchable]="true"
                                requiredLableName="Reason"
                                [isRequired]="true"
                                controlName="stateId"
                                [isMultiple]="true"
                                lableName="Reason"
                            >
                            </app-selectbox>
                        </div>
                    </div>
                    <div class="col-md-6 ms-4 mb-2" *ngIf="selectedDispute === 1">
                        <input
                            type="checkbox"
                            id="flexCheckDefault"
                            class="form-check-input"
                            formControlName="canAskToReUpload"
                            (change)="onCheckboxReuploadChange($event)"
                        />
                        <label
                            class="cursor-pointer form-check-label ms-2 text-secondary fw-normal"
                            for="flexCheckDefault"
                        >
                            Ask to re-upload timesheet file
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3" *ngIf="model.statusName == 'In Review'">
                <h5 class="mb-2 required">Supporting Remarks</h5>
                <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    formControlName="description"
                ></textarea>
            </div>
            <div
                class="form-switch p-0 mb-1 mt-3 d-flex gap-2"
                *ngIf="model.statusName == 'In Review' && selectedDispute == 2"
            >
                <label class="text-secondary">Extra time will be paid by the facility</label>
                <app-inputbox
                    id="formSwitch1"
                    formControlName="isExtraTimePaid"
                    [parentForm]="timeSheetForm"
                    fieldName="isExtraTimePaid"
                    type="checkbox"
                    [isInputCheckbox]="true"
                    [checkbBoxValue]="isExtraTimePaid"
                    (onChangeSelect)="onCheckboxExtraTimePaidChanges($event)"
                ></app-inputbox>
            </div>

            <div>
                <div>
                    <div
                        *ngIf="
                            timeSheetDetails.requestType ||
                            timeSheetDetails.disputeReason ||
                            timeSheetDetails.description
                        "
                    >
                        <h5
                            class="mt-3 mb-3"
                            *ngIf="
                                timeSheetDetails.statusName === 'Nurse Review' ||
                                timeSheetDetails.statusName === 'Validated' ||
                                timeSheetDetails.statusName === 'Rejected'
                            "
                        >
                            Timesheet Operation
                        </h5>

                        <div class="mb-3 d-flex">
                            <div
                                class="col-md-5"
                                *ngIf="
                                    (model.statusName === 'Validated' || model.statusName === 'Rejected') &&
                                    timeSheetDetails.requestType
                                "
                            >
                                <h5>Request type</h5>
                                <p class="mt-2 text-secondary fw-normal">
                                    {{
                                        timeSheetDetails.requestType == 'Cancelation'
                                            ? 'Rejection'
                                            : timeSheetDetails.requestType
                                    }}
                                </p>
                            </div>

                            <div
                                class="col-md-5"
                                *ngIf="model.statusName !== 'In Review' && timeSheetDetails.disputeReason"
                            >
                                <h5 class="mt-3">
                                    {{
                                        timeSheetDetails.statusName == 'Nurse Review'
                                            ? 'Send Back To Nurse Reason'
                                            : 'Rejected Reason'
                                    }}
                                </h5>
                                <p class="mt-2 text-secondary fw-normal">{{ timeSheetDetails.disputeReason }}</p>
                            </div>
                        </div>

                        <div
                            *ngIf="
                                model.statusName == 'Nurse Review' ||
                                model.statusName == 'Validated' ||
                                model.statusName == 'Rejected'
                            "
                        >
                            <div
                                class="mb-3"
                                *ngIf="
                                    model.statusName !== 'In Review' &&
                                    timeSheetDetails.additionalInformationByThriveAdmin
                                "
                            >
                                <h5>Supporting Remarks</h5>
                                <p class="mt-2 text-secondary fw-normal">
                                    {{ timeSheetDetails.additionalInformationByThriveAdmin }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 d-flex" *ngIf="selectedDispute == 2">
                        <div class="col-md-3 form-group">
                            <label class="form-label required">Clock-in</label>
                            <p-calendar
                                [showTime]="true"
                                [timeOnly]="true"
                                placeholder="Clock-in"
                                formControlName="clockIn"
                                hourFormat="24"
                                [(ngModel)]="clockIn"
                                (Input)="handleInput('clockIn')"
                                (onSelect)="calculateTotalTime()"
                            ></p-calendar>
                            <div
                                *ngIf="
                                    timeSheetForm.get('clockIn')?.invalid &&
                                    (timeSheetForm.get('clockIn')?.dirty || timeSheetForm.get('clockIn')?.touched)
                                "
                            >
                                <small class="validation">Clock-In is required</small>
                            </div>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="form-label required">Clock-out</label>
                            <p-calendar
                                [showTime]="true"
                                [timeOnly]="true"
                                placeholder="Clock-out"
                                formControlName="clockOut"
                                hourFormat="24"
                                [(ngModel)]="clockOut"
                                (Input)="handleInput('clockOut')"
                                (onSelect)="calculateTotalTime()"
                            ></p-calendar>
                            <div
                                *ngIf="
                                    timeSheetForm.get('clockOut')?.invalid &&
                                    (timeSheetForm.get('clockOut')?.dirty || timeSheetForm.get('clockOut')?.touched)
                                "
                            >
                                <small class="validation">Clock-Out is required</small>
                            </div>
                        </div>
                        <div class="col-md-3 form-group" *ngIf="timeSheetDetails.breakIn">
                            <label class="form-label required">Break Start Time</label>
                            <p-calendar
                                [showTime]="true"
                                [timeOnly]="true"
                                placeholder="Break Start Time"
                                formControlName="breakIn"
                                hourFormat="24"
                                [(ngModel)]="breakIn"
                                (Input)="handleInput('breakIn')"
                            ></p-calendar>
                            <div
                                *ngIf="
                                    timeSheetForm.get('breakIn')?.invalid &&
                                    (timeSheetForm.get('breakIn')?.dirty || timeSheetForm.get('breakIn')?.touched)
                                "
                            >
                                <small class="validation">Break Start Time is required</small>
                            </div>
                        </div>
                        <div class="form-group col-md-3" *ngIf="timeSheetDetails.breakOut">
                            <label class="form-label required">Break End Time</label>

                            <p-calendar
                                [showTime]="true"
                                [timeOnly]="true"
                                formControlName="breakOut"
                                placeholder="Break End Time"
                                hourFormat="24"
                                [(ngModel)]="breakOut"
                                (Input)="handleInput('breakOut')"
                            ></p-calendar>
                            <div
                                *ngIf="
                                    timeSheetForm.get('breakOut')?.invalid &&
                                    (timeSheetForm.get('breakOut')?.dirty || timeSheetForm.get('breakOut')?.touched)
                                "
                            >
                                <small class="validation">Break Time End is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="hour && selectedDispute == 2">
                        <div class="col-sm-6">
                            <label class="mt-3">Total Hours</label>
                            <p class="mt-1 text-muted">{{ hour }}</p>
                        </div>
                    </div>

                    <div
                        class="row mt-3"
                        *ngIf="
                            model.statusName === 'Thrive Review' &&
                            model.canEditByHealthcare &&
                            timeSheetDetails.requestType == 'Cancelation'
                        "
                    >
                        <h5 class="mb-2">Thrive Admin decision</h5>
                        <div class="d-flex mt-2 mb-2">
                            <div class="form-check form-check-inline" *ngFor="let item of adminDecisionList">
                                <input
                                    type="radio"
                                    [checked]="adminDecision == item.id"
                                    (change)="onChangeofAdminDecision(item.id)"
                                    class="form-check-input cursor-pointer"
                                    formControlName="adminDecision"
                                />
                                <label
                                    class="form-check-label text-secondary cursor-pointer fw-normal"
                                    for="checkInline2"
                                >
                                    {{ item.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <h5 class="mb-2 mt-3">Timesheet Audit Information</h5>
                <table class="table table-bordered">
                    <tbody class="mt-2 table-content">
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Comment</th>
                        </tr>
                        <tr *ngFor="let item of timeSheetHistory">
                            <td class="w-20 text-secondary">
                                {{ item.date | localDateTime : 'ordinal' }}
                            </td>
                            <td class="w-15 text-secondary">
                                {{ item.date | localDateTime : 'shortTime' }}
                            </td>
                            <td class="w-20 text-secondary wrap-text">
                                {{ item.firstName + (item.lastName ? ' ' + item.lastName : '') }}
                            </td>
                            <td class="w-20 text-secondary">{{ item.roleName }}</td>
                            <td class="w-25 text-secondary wrap-text">{{ item.comment }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal-footer">
            <div>
                <button
                    *ngIf="timeSheetDetails.statusName === 'In Review'"
                    type="button"
                    class="btn btn-outline-primary fw-semibold mr-2"
                    (click)="modal.dismiss()"
                >
                    Cancel
                </button>
                <button
                    *ngIf="
                        (model.statusName === 'In Review' ||
                            timeSheetDetails.requestType === 'Rejected' ||
                            (timeSheetDetails.requestType === 'Rejected' && model.canEditByHealthcare)) &&
                        model.statusName !== 'Rejected' &&
                        model.statusName !== 'Validate'
                    "
                    type="submit"
                    class="btn btn-orange ms-2"
                    [disabled]="timeSheetForm.invalid"
                >
                    Submit
                </button>
            </div>

            <div
                *ngIf="
                    timeSheetDetails.statusName === 'Nurse Review' ||
                    timeSheetDetails.statusName === 'Rejected' ||
                    timeSheetDetails.statusName === 'Validated'
                "
            >
                <button type="button" class="w-100 btn btn-orange apply-btn" (click)="modal.dismiss()">Close</button>
            </div>
        </div>
    </form>
</div>
