<div>
    <label *ngIf="!isLabel" class="form-label">{{ label }}<span *ngIf="isRequired" class="required"></span></label>

    <input
        [class]="class"
        [name]="formField"
        [id]="formField"
        [disabled]="isDisabled"
        [value]="value"
        autocomplete="off"
        [placeholder]="placeholder"
        (input)="formatZipCode($event)"
        (blur)="touched()"
        [max]="max"
        [min]="min"
        [type]="type"
    />
</div>

<!-- Error message for invalid ZIP code format -->
<div *ngIf="parentForm.controls[fieldName].invalid && parentForm.controls[fieldName].touched">
    <div class="input-error" *ngIf="parentForm.controls[fieldName].errors?.required">ZIP code is required.</div>
    <div
        class="input-error"
        *ngIf="
            !parentForm.controls[fieldName].errors?.required && parentForm.controls[fieldName].errors?.invalidZipCode
        "
    >
        Invalid ZIP code format. Please enter a valid 5 or 9-digit ZIP code (e.g., 12345 or 12345-6789).
    </div>
</div>
