export class NotificationModel {
    constructor() {
        this.id = 0;
        this.title = null;
        this.description = null;
        this.companyIds = null;
        this.hospitalIds = null;
        this.departmentIds = null;
        this.staffIds = null;
        this.notificationType = 0;
        this.fromId = null;
        this.toId = null;
        this.status = false;
    }

    id: number;
    title: any;
    description: any;
    companyIds: any;
    hospitalIds: any;
    departmentIds: any;
    staffIds: any;
    notificationType: number;
    fromId: any;
    toId: any;
    status: boolean;

    setNotificationData(notification: any) {
        this.id = notification.id;
        this.title = notification.title.trim();
        this.description = notification.description;
        if (notification.companyIds != null) {
            this.companyIds = notification.companyIds.toString();
        }
        if (notification.hospitalIds != null) {
            this.hospitalIds = notification.hospitalIds.toString();
        }
        if (notification.departmentIds != null) {
            this.departmentIds = notification.departmentIds.toString();
        }
        if (notification.staffIds != null) {
            this.staffIds = notification.staffIds.toString();
        }
        this.notificationType = notification.notificationType;
        this.fromId = notification.fromId;
        this.toId = notification.toId;
        this.status = false;
    }
}

export enum NotificationType {
    WebNurseApplication = 24,
    WebShiftInvitationAccepted = 25,
    WebShiftInvitationDecline = 26,
    WebShiftCancellation = 27,
    WebShiftReminderMissingClockIn = 28,
    WebShiftReminderMissingClockOut = 29,
    WebLicenseExpiration = 30,
    WebLicenseExpired = 31,
    WebCredentialsExpiration = 32,
    WebCredentialsExpired = 33,
    WebNurseReleased = 34,
    WebNurseSuspended = 35,
    WebNurseDNR = 36,
}
