import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './custom-date-adapter';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
})
export class DatepickerComponent implements OnInit {
    @Input() fieldName: string;
    @Input() fieldId: string;
    @Input() selectedFieldDate: string;
    @Input() placeholder: string;
    @Input() controlName: string;
    @Input() formName: FormGroup;
    @Input() lableName: string;
    @Input() requiredLableName: string;
    @Input() isRequired: boolean = false;
    @Input() startFromNextDay: boolean = false;
    @Input() displayPastDate: boolean = false;

    todayDate: any;
    selectedDate = this.getNowUTC();
    public requiredClassName = '';
    public value: string;
    public changed: (value: Date) => void;
    public touched: () => void;
    public isDisabled: boolean;
    public isFocus: boolean;

    @Output() onDateSelect: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        if (this.isRequired) {
            this.requiredClassName = 'required';
        }
        const current = new Date();
        if (this.startFromNextDay) {
            current.setDate(current.getDate() + 1); // Increment current date by 1 if startFromNextDay is true
        }
        this.todayDate = {
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate(),
        };
    }

    get formField(): FormControl {
        return this.formName?.get(this.controlName) as FormControl;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    public onChange(event: any) {
        if (event == undefined) {
            return;
        }
        const dateString = '' + event.year + '-' + event.month + '-' + event.day + '';
        if (event.day == 'NaN') {
            this.onDateSelect.emit(null);
        } else {
            this.onDateSelect.emit(dateString);
        }
    }

    registerOnChange(fn: any): void {
        this.changed = fn;
    }

    registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onFocus() {
        this.isFocus = true;
    }

    onBlur() {
        this.isFocus = false;
    }

    getNowUTC() {
        const now = new Date();
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    openDatepicker(event: MouseEvent, datepicker: any): void {
        // Check if the click event is inside the datepicker navigation, month, or year selectors
        const target = event?.target as HTMLElement;

        const isClickInsideDatepicker =
            target.closest('.ngb-dp-navigation') ||
            target.closest('.ngb-dp-month') ||
            target.closest('.ngb-dp-year') ||
            target.closest('.ngb-dp-arrow') ||
            target.closest('select.form-select'); // Added selector for month dropdown

        if (isClickInsideDatepicker) {
            // Prevent the event from propagating further
            event.stopPropagation();
        } else {
            // Toggle the datepicker
            datepicker.toggle();
        }
    }
}
