import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicianDetailService } from 'src/app/service/clinicianDetail.service';
import { CreditialTemplateService } from 'src/app/service/credentialTemplate.service';
import { DateUtilService } from 'src/app/service/dateUtills.service';
import { MediaUploadService } from 'src/app/service/mediaUpload.service';
import { RegionService } from 'src/app/service/region.service';
import { BaseMasterFormComponent } from 'src/app/shared/components/base-master-form.component';
import { noWhitespaceValidator, removeSpaces } from 'src/app/utill/validator';

@Component({
    selector: 'app-upload-nurse-credential-model',
    templateUrl: './upload-nurse-credential-model.component.html',
    styleUrl: './upload-nurse-credential-model.component.scss',
})
export class UploadNurseCredentialModelComponent extends BaseMasterFormComponent implements OnInit {
    @Input() type: any;
    @Input() title: any;
    @Input() data: any = {};
    @Input() jobDetails: any;
    @Input() jobId: any;
    @ViewChild('fileInput') fileInput: ElementRef;
    nurseCredentialForm: FormGroup;
    File: any;
    fileUrl: any;
    fileName: any;
    fileSize: any = null;
    fileType: any;
    uploadedFile: any;
    credentialCategoryList: any = [];
    selectedCredentialCategory: any;
    credentialTypeList: any = [];
    selectedCredentialType: any;
    stateList: any = [];
    selectedState: any;
    selectedIssueDate: string;
    selectedExpiryDate: string;
    isCredentialDoesNotExpire: boolean = false;
    isDisable: boolean = false;
    validFileFormat = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    isSelectDisable: boolean = false;

    ngOnInit(): void {
        this.getStates();
        this.initForm();
        this.setFields(this.data);

        if (this.jobDetails?.credentialCategoryId) {
            this.credentialCategoryList = [
                {
                    id: this.jobDetails.credentialCategoryId,
                    name: this.jobDetails.credentialCategoryTypeName,
                },
            ];
            this.credentialTypeList = [
                {
                    id: this.jobDetails.credentialTypeId,
                    name: this.jobDetails.credentialTypeName,
                },
            ];

            this.isSelectDisable = true;
            this.selectedCredentialCategory = this.jobDetails.credentialCategoryId;
            this.selectedCredentialType = this.jobDetails.credentialTypeId;
            this.isCredentialDoesNotExpire = this.jobDetails?.isCredentialDoesNotExpire;
            if (!this.isCredentialDoesNotExpire) {
                this.nurseCredentialForm.controls.expiryDate.setValidators(Validators.required);
            } else {
                this.nurseCredentialForm.controls.expiryDate.clearValidators();
            }
            this.nurseCredentialForm.controls.expiryDate.updateValueAndValidity();

            this.nurseCredentialForm.controls.credentialTypeId.setValue(this.jobDetails.credentialTypeId);
            this.nurseCredentialForm.controls.credentialCategoryId.setValue(this.jobDetails.credentialCategoryId);
            this.nurseCredentialForm.controls.stateId.setValue(this.jobDetails.stateId);
            this.selectedState = this.jobDetails.stateId;
            // this.getCredentialTypeList();
        } else {
            this.getCredentialCategoryList();
        }
        this.storeInitialFormState();
    }

    constructor(
        public activeModal: NgbActiveModal,
        private regionService: RegionService,
        private credentialService: CreditialTemplateService,
        public fb: FormBuilder,
        private toastrService: ToastrService,
        public mediaUploadService: MediaUploadService,
        private dateUtilService: DateUtilService,
        private clinicianDetailService: ClinicianDetailService,
        confirmationModalService: NgbModal
    ) {
        super(confirmationModalService);
    }

    initForm() {
        this.nurseCredentialForm = this.fb.group({
            id: [0],
            clinicianId: [this.data.clinicianId],
            credentialTypeId: ['', Validators.compose([Validators.required])],
            credentialCategoryId: ['', Validators.compose([Validators.required])],
            credentialNumber: [null, Validators.compose([removeSpaces])],
            expiryDate: ['', Validators.compose([Validators.required])],
            issuedDate: [''],
            fileName: [''],
            fileUrl: [''],
            stateId: ['', Validators.compose([Validators.required])],
            priority: [1],
            isUploadByAdmin: [true],
        });
    }

    getStates() {
        this.regionService.stateAcronymSelectList().subscribe((response: any) => {
            this.stateList = response.result;
        });
    }

    getCredentialCategoryList() {
        this.credentialService.getCredentialCategoryForThrive().subscribe((response: any) => {
            this.credentialCategoryList = response.result;

            if (this.credentialCategoryList.length === 1) {
                this.selectedCredentialCategory = this.credentialCategoryList[0].id;
                this.nurseCredentialForm.controls.credentialCategoryId.setValue(this.selectedCredentialCategory);
                this.storeInitialFormState();
                this.getCredentialTypeList();
            }
        });
    }

    getCredentialTypeList() {
        this.credentialService
            .getCredentialTypeForThrive(this.selectedCredentialCategory)
            .subscribe((response: any) => {
                this.credentialTypeList = response.result;

                if (this.credentialTypeList.length === 1) {
                    this.selectedCredentialType = this.credentialTypeList[0].id;
                    this.nurseCredentialForm.controls.credentialTypeId.setValue(this.selectedCredentialType);
                    this.onCredentialTypeChange(this.selectedCredentialType);
                }
                this.storeInitialFormState();
            });
    }

    setFields(obj: any) {
        this.data = this.clinicianDetailService.setFields(obj);
    }

    onSelectFile(event: any) {
        const file = event.target.files[0];
        this.fileSize = file?.size;

        this.fileName = file.name;
        this.fileType = file.type;

        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.fileUrl = e.target.result;

            this.fileInput.nativeElement.value = null;

            var blob = this.dataURItoBlob(reader.result);
            let type: any = ['image/jpeg', 'image/jpg', 'image/png'];

            let fileExt = blob.type.split('/')[1];

            let selectedFileType: any = type.includes(this.fileType)
                ? 'image.' + fileExt
                : this.fileType == 'application/pdf'
                ? '.pdf'
                : '.doc';

            this.File = new File([blob], selectedFileType, {
                type: blob.type,
            });
        };

        reader.readAsDataURL(file);
    }

    dataURItoBlob(dataURI: any) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
        else byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    openSelectedFile() {
        // Extract the Base64 content (remove the Data URL prefix)
        const base64Content = this.fileUrl.split(',')[1];

        // Convert the Base64 content to a Blob
        const fileBlob = this.base64ToBlob(base64Content, this.fileType);

        // Create a Blob URL
        const fileBlobUrl = URL.createObjectURL(fileBlob);

        // Open the PDF in a new tab
        window.open(fileBlobUrl, '_blank');
    }

    base64ToBlob(base64: string, contentType: string): Blob {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    onCredentialCategoryChange(event: any) {
        this.selectedCredentialCategory = event;
        this.nurseCredentialForm.controls.credentialCategoryId.setValue(this.selectedCredentialCategory);

        this.credentialTypeList = [];
        this.selectedCredentialType = null;

        if (this.selectedCredentialCategory) {
            this.getCredentialTypeList();
        }
    }

    onCredentialTypeChange(event: any) {
        this.selectedCredentialType = event;

        let selectedCredentialType = this.credentialTypeList.filter((x: any) => x.id === event);
        this.isCredentialDoesNotExpire = selectedCredentialType[0]?.isCredentialDoesNotExpire;

        if (!this.isCredentialDoesNotExpire) {
            this.nurseCredentialForm.controls.expiryDate.setValidators(Validators.required);
        } else {
            this.nurseCredentialForm.controls.expiryDate.clearValidators();
        }
        this.nurseCredentialForm.controls.expiryDate.updateValueAndValidity();

        this.nurseCredentialForm.controls.credentialTypeId.setValue(this.selectedCredentialType);
    }

    onStateChange(event: any) {
        this.selectedState = event;
        this.nurseCredentialForm.controls.stateId.setValue(this.selectedState);
    }

    onDateSelectChange(event: any, type: any) {
        if (type == 'issue') {
            this.nurseCredentialForm.controls.issuedDate.setValue(event);
        } else {
            this.nurseCredentialForm.controls.expiryDate.setValue(event);
        }
    }

    submit() {
        if (
            this.nurseCredentialForm.invalid ||
            !this.File ||
            this.fileSize > 15000000 ||
            (this.fileType && !this.validFileFormat.includes(this.fileType))
        ) {
            return;
        }
        this.imageUploadProfile();
    }

    close() {
        this.dismissModal(this.activeModal);
    }

    formatNumber(number: any) {
        return number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }

    imageUploadProfile() {
        this.isDisable = true;

        this.mediaUploadService.mediaUpload(this.File, 'CredentialDoc').subscribe(
            (response) => {
                if (response.result.isSuccess) {
                    this.uploadedFile = response.result.fileUrl;
                    let nurseCredentialModel = this.nurseCredentialForm.value;
                    nurseCredentialModel.fileName = this.fileName;
                    nurseCredentialModel.thumbnail = response.result.thumbnail ? response.result.thumbnail : '';
                    nurseCredentialModel.fileUrl = response.result.fileUrl;
                    this.addCredential(nurseCredentialModel);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            },
            (err) => {
                this.isDisable = false;
                this.toastrService.error(err.error.message);
            }
        );
    }

    addCredential(nurseCredentialModel: any) {
        if (nurseCredentialModel.issuedDate) {
            nurseCredentialModel.issuedDate = nurseCredentialModel.issuedDate;
        } else {
            nurseCredentialModel.issuedDate = null;
        }

        if (!this.isCredentialDoesNotExpire) {
            nurseCredentialModel.expiryDate = nurseCredentialModel.expiryDate;
        } else {
            const today = new Date();
            let expiryDate = new Date(today.setFullYear(today.getFullYear() + 100));
            nurseCredentialModel.expiryDate = this.dateUtilService.convertDateCurrentTimeToUTC(expiryDate);
        }

        nurseCredentialModel.credentialNumber =
            nurseCredentialModel?.credentialNumber?.length == 0 ? null : nurseCredentialModel.credentialNumber;

        nurseCredentialModel.verifiedOn = new Date().toISOString();
        nurseCredentialModel.verifiedById = Number(sessionStorage.getItem('contactId'));
        if (this.jobDetails) {
            nurseCredentialModel.jobApplicantId = this.data.jobApplicantId;
            nurseCredentialModel.jobId = this.jobId;
        }
        this.credentialService.addNurseCredential(nurseCredentialModel).subscribe(
            (response: any) => {
                if (response.result) {
                    this.activeModal.close();
                    this.toastrService.success(response.message);
                } else {
                    this.isDisable = false;
                    this.toastrService.error(response.message);
                }
            },
            (err: any) => {
                this.isDisable = false;
                this.toastrService.error(err.error.message);
            }
        );
    }

    get form() {
        return { nurseCredentialForm :  this.nurseCredentialForm};
    }
}
