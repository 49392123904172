import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { EllipsisPipe } from 'src/app/pipes/ellipsis.pipe';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from 'src/app/service/chat.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ShiftInspectorDetailsComponent } from '../shift/shift-inspector-details/shift-inspector-details.component';
import { TreeRefreshService } from 'src/app/service/treeRefresh.service';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/service/navigation.service';
import { ViewProfileModelComponent } from '../job/view-profile-model/view-profile-model.component';
import { NotificationType } from 'src/app/model/notification.model';
import { DateUtilService } from 'src/app/service/dateUtills.service';
@Component({
    selector: 'app-receive-notification',
    templateUrl: './receive-notification.component.html',
    styleUrls: ['./receive-notification.component.scss'],
    providers: [TitleCasePipe, EllipsisPipe],
})
export class ReceiveNotificationComponent implements OnInit {
    defaultNavActiveId = 1;
    take: number = 12;
    allDataLoaded: boolean = false;
    notificationList: any[] = [];
    @ViewChild('scrollable') private scrollContainer: ElementRef<HTMLDivElement>;
    isLoading: boolean = false;
    unreadNotifications: any;
    todayDate: any;
    last15DayAgoDate: any;
    isCompanyLevelLogin: boolean = false;
    isAgencyLevelLogin: boolean = false;
    isFacilityLevelLogin: boolean = false;
    loginCompanyId: any;

    constructor(
        public modal: NgbActiveModal,
        private modalService: NgbModal,
        private notificationService: NotificationService,
        private treeRefreshService: TreeRefreshService,
        private router: Router,
        private navigationService: NavigationService
    ) {}

    ngOnInit() {
        this.loginCompanyId = sessionStorage.getItem('companyId');
        this.loginCompanyId = JSON.parse(this.loginCompanyId);
        this.loginCompanyId = this.loginCompanyId == null ? null : this.loginCompanyId;

        let isAgencyLevelLogin: any = sessionStorage.getItem('isAgencyLevelLogin');
        this.isAgencyLevelLogin = JSON.parse(isAgencyLevelLogin);

        let isCompanyLevelLogin: any = sessionStorage.getItem('isCompanyLevelLogin');
        this.isCompanyLevelLogin = JSON.parse(isCompanyLevelLogin);

        let isFacilityLevelLogin: any = sessionStorage.getItem('isFacilityLevelLogin');
        this.isFacilityLevelLogin = JSON.parse(isFacilityLevelLogin);

        // Get the current date
        this.todayDate = new Date().toISOString();
        this.last15DayAgoDate = new Date();

        // Subtract 15 days
        this.last15DayAgoDate.setDate(this.last15DayAgoDate.getDate() - 14);
        this.last15DayAgoDate = this.last15DayAgoDate.toISOString();

        this.getNotificationList();
    }

    dismissModel() {
        this.modal.close();
    }

    onNotificationDetails(data: any) {
        if (!data?.isRead) {
            this.markAsRead(data);
        }
        data.clinicianId = data?.params?.ClinicianId;
        data.shiftPositionId = data?.params?.ShiftClinicianId;
        data.shiftId = data?.params?.ShiftId;
        if (
            [
                NotificationType.WebShiftInvitationAccepted,
                NotificationType.WebShiftCancellation,
                NotificationType.WebShiftInvitationDecline,
                NotificationType.WebShiftReminderMissingClockOut,
                NotificationType.WebShiftReminderMissingClockIn,
            ].includes(data?.notificationType)
        ) {
            this.treeRefreshService.resetSelectedableIds();
            let ids = {
                shiftId: data?.params?.ShiftId ? Number(data?.params?.ShiftId) : '',
                shiftPositionId: Number(data?.params?.ShiftClinicianId),
                marketId: '',
            };
            this.navigationService.updateActiveMenu('Schedule', ids);
            this.modal.close();
            this.router.navigate(['/shift/view-shift']);
            this.treeRefreshService.setSelectedTableIds(ids);
        } else if (data?.notificationType == NotificationType.WebNurseApplication) {
            let ids = {
                jobId: data?.params?.JobId,
                marketId: '',
                defaultTab: Number(data?.params?.RedirectedToTab),
            };
            // Update the active menu in the navbar
            this.navigationService.updateActiveMenu('Pipelines', ids);
            this.modal.close();
            this.router.navigate(['/all-jobs/view-job']);
            this.treeRefreshService.setSelectedTableIds(ids);
        } else if (
            [
                NotificationType.WebNurseSuspended,
                NotificationType.WebNurseReleased,
                NotificationType.WebNurseDNR,
            ].includes(data?.notificationType)
        ) {
            const modalRef = this.modalService.open(ViewProfileModelComponent, {
                size: 'xl',
                backdrop: 'static',
                windowClass: 'my-custom-modal',
            });
            let model: any = {
                id: data?.params?.RestrictionId,
                clinicianLicences: [],
                clinicianId: data?.params?.ClinicianId,
            };

            modalRef.componentInstance.model = model;
            modalRef.componentInstance.id = data?.params?.ClinicianId;
            modalRef.componentInstance.hospitalId = data?.params?.HospitalId;
            modalRef.componentInstance.companyId = data?.params?.CompanyId;
            modalRef.componentInstance.marketId = '';
            modalRef.componentInstance.displayFor = 'restricted';
            modalRef.componentInstance.type = '';
        } else if (
            [
                NotificationType.WebLicenseExpiration,
                NotificationType.WebLicenseExpired,
                NotificationType.WebCredentialsExpiration,
                NotificationType.WebCredentialsExpired,
            ].includes(data?.notificationType)
        ) {
            const modalRef = this.modalService.open(ViewProfileModelComponent, {
                size: 'xl',
                backdrop: 'static',
                windowClass: 'my-custom-modal',
            });
            let model: any = {
                clinicianLicences: [],
                clinicianId: data?.params?.ClinicianId,
            };
            modalRef.componentInstance.model = model;
            modalRef.componentInstance.id = data?.params?.ClinicianId;
            modalRef.componentInstance.hospitalId = '';
            modalRef.componentInstance.companyId = '';
            modalRef.componentInstance.marketId = '';
            modalRef.componentInstance.displayFor = 'nurse';
            modalRef.componentInstance.type = '';
            modalRef.componentInstance.displayFor = 'notification';
        }
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(): void {
        const target = this.scrollContainer.nativeElement;
        if (target.scrollTop + target.clientHeight >= target.scrollHeight - 100) {
            this.loadMore();
        }
    }

    loadMore(): void {
        if (!this.isLoading && !this.allDataLoaded) {
            this.getNotificationList();
        }
    }

    getNotificationList() {
        this.isLoading = true;
        let obj = {
            pagedListCriteria: {
                skip: this.notificationList.length,
                take: this.take,
            },
            fromUserId: null,
            loginFrom: this.isAgencyLevelLogin
                ? 'AgencyLevel'
                : this.isCompanyLevelLogin
                ? 'HealthcareLevel'
                : this.isFacilityLevelLogin
                ? 'FacilityLevel'
                : 'ThriveLevel',
            companyId: this.loginCompanyId || null,
            hospitalId: null,
        };

        this.notificationService.getNotificationList(obj).subscribe(
            (response: any) => {
                this.unreadNotifications = [];
                if (response.result) {
                    if (response.pageDetail) {
                        this.take = response.pageDetail.take; // Update take value
                    }
                    if (response.result.length < this.take) {
                        this.allDataLoaded = true;
                    }
                    this.notificationList = [...this.notificationList, ...response.result];
                    this.unreadNotifications = this.notificationList.filter((item) => !item.isRead);
                }
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
                // Handle error
            }
        );
    }

    markAsRead(notification: any): void {
        const requestPayload = {
            notificationIds: notification.id.toString(),
            isRead: notification.isRead ? false : true,
            isDelete: false,
            isAllRead: false,
        };

        this.notificationService.updateNotificationStatus(requestPayload).subscribe((response: any) => {
            if (response.result) {
                this.resetNotificationList();
            }
        });
    }

    markAllAsRead(): void {
        const requestPayload = {
            notificationIds: null,
            isRead: true,
            isDelete: false,
            isAllRead: true,
        };

        this.notificationService.updateNotificationStatus(requestPayload).subscribe((response) => {
            if (response.result) {
                this.resetNotificationList();
            }
        });
    }

    deleteNotification(notification: any): void {
        const requestPayload = {
            notificationIds: notification.id.toString(),
            isRead: false,
            isDelete: true,
        };

        this.notificationService.deleteNotification(requestPayload).subscribe((response: any) => {
            if (response.result) {
                this.resetNotificationList();
            }
        });
    }

    resetNotificationList() {
        this.navigationService.updateNotificationCount(true);

        this.allDataLoaded = false;
        this.notificationList = [];
        this.getNotificationList();
    }
}
