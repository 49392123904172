import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from '../service/time-zone.service';

@Pipe({
    name: 'timezoneDifference',
})
export class TimezoneDifferencePipe implements PipeTransform {
    constructor(private timeZoneService: TimeZoneService) {}

    transform(facilityTimezone: any, date?: any): string {
        const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let storedTimezone: any = sessionStorage.getItem('timezoneName');

        let timezoneName: any = facilityTimezone
            ? facilityTimezone
            : storedTimezone !== 'null' && storedTimezone !== null
            ? storedTimezone
            : null;
        // If facility timezone is provided
        if (timezoneName) {
            // Get the IANA timezone for the facility
            const facilityIana = this.timeZoneService.getIanaTimeZone(timezoneName);

            // If the system timezone is different from the facility's IANA timezone
            if (systemTimezone !== facilityIana) {
                const timezoneShortName = timezoneName
                    ? this.timeZoneService.getTimeZoneShortName(timezoneName) || timezoneName
                    : null;

                // Get the short name of the facility timezone (e.g., 'EST', 'PST')
                const shortName: any = getTimezoneAbbreviation(date, facilityIana, timezoneShortName);
                return shortName != '' ? `(${shortName})` : ''; // Return the short name or an empty string if not available
            }
        }
        return ''; // Return empty string if the timezones are the same or no facility timezone provided
    }
}

export function getTimezoneAbbreviation(dates: any, timezone: any, timezoneShortName: any): string {
    const abbreviations = new Set<string>();

    // Ensure we always work with an array
    const dateArray = Array.isArray(dates) ? dates : [dates];

    dateArray.forEach((date) => {
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            timeZoneName: 'short',
        });

        const parts = formatter.formatToParts(new Date(date));
        const timeZoneAbbreviation = parts.find((part) => part.type === 'timeZoneName')?.value;

        if (timeZoneAbbreviation) {
            // Map to your custom short name if available
            const standardShortName = timezoneShortName;
            const daylightShortName = standardShortName.replace('ST', 'DT'); // Convert CST -> CDT, EST -> EDT

            // Check if abbreviation is in standard or daylight savings time
            if (timeZoneAbbreviation.includes('DT')) {
                abbreviations.add(daylightShortName);
            } else {
                abbreviations.add(standardShortName);
            }
        }
    });

    return Array.from(abbreviations).join('/');
}
