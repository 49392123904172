<div class="modal-content chat-main-model">
    <div class="modal-header">
        <div class="modal-title h4" id="example-modal-sizes-title-lg">
            <ng-container
                >Notifications ({{ last15DayAgoDate | localDateTime : 'ordinal' }} to
                {{ todayDate | localDateTime : 'ordinal' }})</ng-container
            >
        </div>
    </div>

    <div class="profile-model-nav">
        <div *ngIf="notificationList.length == 0">
            <div class="no-data-available">
                <figure>
                    <img src="assets/logo/NoListFound.png" alt="" />
                </figure>
                <h5>No Data Found</h5>
            </div>
        </div>
        <div *ngIf="notificationList.length > 0" #scrollable class="h-100 overflow-auto" (scroll)="onScroll()">
            <div class="document-list" *ngFor="let item of notificationList">
                <div
                    class="all-job-box card p-3 rounded-0 shadow-none h-100 d-flex flex-column cursor-pointer"
                    (click)="onNotificationDetails(item)"
                >
                    <div class="job-top-box d-flex align-items-center">
                        <!-- Text Information -->
                        <div class="job-top-info flex-grow-1">
                            <div class="d-flex text-secondary">
                                <p class="mb-2">
                                    <i
                                        *ngIf="!item?.isRead"
                                        class="fa fa-circle text-danger me-2"
                                        aria-hidden="true"
                                    ></i>
                                    <span>
                                        {{ item?.createdOn | localDateTime : 'ordinal' }} -
                                        {{ item?.createdOn | localDateTime : 'h:mm a' }}
                                    </span>
                                </p>
                            </div>
                            <label class="mb-2 text-secondary fw-bolder">
                                {{ item?.title }}
                            </label>
                            <p class="text-secondary fw-normal">
                                {{ item?.description }}
                            </p>
                        </div>
                        <div class="ms-auto d-flex text-secondary">
                            <div (click)="markAsRead(item); $event.stopPropagation()" class="unreadMsg">
                                <i class="fas fa-bell" *ngIf="!item?.isRead" title="Mark as read"></i>
                                <i class="fa-regular fa-bell" *ngIf="item?.isRead" title="Mark as unread"></i>
                            </div>
                            <div class="ms-1">|</div>
                            <div (click)="deleteNotification(item); $event.stopPropagation()" class="ms-1 deleteMsg">
                                <i class="fas fa-trash"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            class="btn btn-outline-primary backBtn fw-semibold px-4 px-md-3 mr-3 mr-md-4 ms-2"
            type="button"
            (click)="dismissModel()"
        >
            Close
        </button>
        <button
            class="btn btn-primary fw-semibold px-4 px-md-3 mr-3 mr-md-4"
            type="button"
            (click)="markAllAsRead()"
            *ngIf="unreadNotifications?.length > 0"
        >
            Mark all as read
        </button>
    </div>
</div>
