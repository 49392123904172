export enum applicantPipelineVerificationStatus {
    InProgress = 1,
    Approved = 2,
    Declined = 3,
}

export class VerficationModel {
    id: 0;
    jobId: 0;
    clinicianId: 0;
    status: 1;
    canApply: true;
    reApplyDate: any;
    verifiredon: any;
    feedback: string;
    comment: string;
    internalComment: string;
    fileName: string;
    fileUrl: string;
    thumbnail: string;
    jobApplicantId: any;
    verificationFileName: any;
    verificationFileUrl: any;
    isCallingFromRenewal: boolean;
    verifiedById: any;
    verifiedOn: any;
}
export class DocumentationVerficationModel {
    id: 0;
    jobId: 0;
    clinicianId: 0;
    status: string;
    canApply: true;
    reApplyDate: any;
    feedback: string;
    sentBackNotes: string;
    internalComment: string;
    isVerified: boolean;
    verifiedDate: any;
    jobApplicantId: any;
}
export class InterviewModel {
    id: 0;
    jobId: 0;
    clinicianId: 0;
    status: any;
    canReApplyForJob: boolean;
    reApplyDate: any;
    verifiedOn: any;
    feedback: string;
    internalComment: string;
    startTime: any;
    endTime: any;
    instructionId: number;
    report: string;
    date: any;
}

export enum InterviewStatus {
    NotScheduled = 1,
    Scheduled = 2,
    InProgress = 3,
    Pending = 4,
    Approved = 5,
    Declined = 6,
}

export enum JobApplicantDocumentationStatus {
    Pending = 1,
    Signed = 2,
    Approved = 3,
    SentBack = 4,
    Declined = 5,
}

export enum NurseLevel {
    Level1 = 1,
    Level2 = 2,
    Level3 = 3,
}

export enum ClinicianTimeSheetStatus {
    Validated = 2,
    InDispute = 3,
    NurseReview = 4,
    ThriveReview = 5,
    Canceled = 6,
}

export enum Category {
    Job = 1,
    Shift = 2,
    Timesheet = 3,
    DNR = 4,
    Suspension = 5,
    Investigation = 6,
    Payment = 7,
    General = 8,
    Inactive = 9,
}

export class RejectApplicantModel {
    id: 0;
    canApply: true;
    reApplyDate: any;
}
