<div class="profile-modal-content modal-content fixwidth">
    <div class="modal-header p-3 d-md-flex flex-column flex-md-row">
        <h4>View {{ ' ' + label }}</h4>
    </div>
    <div class="modal-body p-3 pt-0">
        <div class="profile-model-box">
            <div class="profile-model-img">
                <figure>
                    <img
                        *ngIf="data?.clinicianProfileUrl || data?.profileUrl"
                        [src]="data?.clinicianProfileUrl || data?.profileUrl"
                        alt=""
                    />
                    <img
                        *ngIf="!data?.clinicianProfileUrl && !data?.profileUrl"
                        src="./assets/images/others/blank.png"
                    />
                </figure>
            </div>
            <div class="profile-model-info">
                <h4>{{ data?.clinicianFirstName }} {{ data?.clinicianLastName }}</h4>
                <p class="text-primary">{{ data?.clinicianCityName }}, {{ data.clinicianStateName }}</p>
                <p class="text-primary">
                    <a *ngIf="data?.clinicianEmail" href="mailto:{{ data?.clinicianEmail }}" class="nurse-email">{{
                        data?.clinicianEmail
                    }}</a>
                    {{ data?.clinicianEmail ? ' | ' : ' ' }}
                    {{ formatNumber(data?.clinicianPhoneNumber ?? '') }}
                </p>
                <div class="label-list">
                    <label
                        class="badge fw-bolder"
                        [ngStyle]="{
                            'background-color': type?.chipBackgroundColor,
                            color: type?.chipFontColor
                        }"
                        *ngFor="let type of data?.clinicianLicences"
                        >{{ type?.acronym }}</label
                    >
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="type == 'license'">
            <div class="col-3">
                <div class="form-group mb-3">
                    <label class="form-label">License Type</label>
                    <p>{{ applicantDetails?.licenseType }}</p>
                </div>
            </div>
            <div class="col-3" *ngIf="!applicantDetails?.isItCompatStateLicense">
                <div class="form-group mb-3">
                    <label class="form-label">State</label>
                    <p>{{ applicantDetails?.stateAcronym }}</p>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group mb-3">
                    <label class="form-label">License Number</label>
                    <p>{{ applicantDetails?.licenseNumber }}</p>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group mb-3">
                    <label class="form-label">Is Compact?</label>
                    <p>{{ applicantDetails?.isItCompatStateLicense ? 'Yes' : 'No' }}</p>
                </div>
            </div>
            <div class="col-3" *ngIf="applicantDetails?.issuedDate">
                <div class="form-group mb-3">
                    <label class="form-label">Issue Date</label>
                    <div class="d-flex align-items-center">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{ applicantDetails?.issuedDate | localDateTime : 'ordinal' }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3" *ngIf="applicantDetails?.expiryDate">
                <div class="form-group mb-3">
                    <label class="form-label">Expiration date</label>
                    <div class="d-flex align-items-center">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{ applicantDetails?.expiryDate | localDateTime : 'ordinal' }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-3" *ngIf="applicantDetails?.fileName">
                <div class="card col-7 rounded-3 cursor-pointer" (click)="onOpenFile(applicantDetails?.fileUrl)">
                    <div class="row py-3">
                        <div class="col-2">
                            <img src="assets/images/file-logo.jpg" height="60px" />
                        </div>
                        <div class="col-9 ms-1">
                            <p class="fw-bolder">License File</p>
                            <p class="text-secondary">
                                {{ applicantDetails?.fileName }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="card col-7 mt-1 rounded-3 cursor-pointer"
                    *ngIf="applicantDetails?.verificationFileUrl"
                    (click)="onOpenFile(applicantDetails?.verificationFileUrl)"
                >
                    <div class="row py-3">
                        <div class="col-2">
                            <img src="assets/images/file-logo.jpg" height="60px" />
                        </div>
                        <div class="col-9 ms-1">
                            <p class="fw-bolder">License Verification File</p>
                            <p (click)="onOpenFile(applicantDetails?.verificationFileUrl)">
                                {{ applicantDetails?.verificationFileName }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="type == 'education'">
            <div class="col-6">
                <div class="form-group mb-3">
                    <label class="form-label">Graduation Type</label>
                    <p>{{ applicantDetails?.graduationTypeName }}</p>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-3">
                    <label class="form-label">Course</label>
                    <p>{{ applicantDetails?.courseName }}</p>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-3">
                    <label class="form-label">In Progress?</label>
                    <p>{{ applicantDetails?.onGoing ? 'Yes' : 'No' }}</p>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-3">
                    <label class="form-label">Institution</label>
                    <p>{{ applicantDetails?.university }}</p>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group mb-3">
                    <label class="form-label">State</label>
                    <p>{{ applicantDetails?.stateAcronym }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group mb-3">
                    <label class="form-label">Start Date</label>
                    <div class="d-flex align-items-center">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{ applicantDetails?.startDate | localDateTime : 'ordinal' }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="applicantDetails?.endDate">
                <div class="form-group mb-3">
                    <label class="form-label">Finish date</label>
                    <div class="d-flex align-items-center">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{ applicantDetails?.endDate | localDateTime : 'ordinal' }}
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="card col-7 mt-3 cursor-pointer"
                *ngIf="applicantDetails?.fileName"
                (click)="onOpenFile(applicantDetails?.fileUrl)"
            >
                <div class="row py-3">
                    <div class="col-2 ms-3">
                        <img src="assets/images/file-logo.jpg" height="60px" />
                    </div>
                    <div class="col-9">
                        <p class="fw-bolder">Certification File</p>
                        <p class="cursor-pointer text-secondary text-break">
                            {{ applicantDetails?.fileName }}
                        </p>
                    </div>
                </div>
            </div>

            <!-- <div
                class="card col-7 mt-3 rounded-3 cursor-pointer"
                *ngIf="applicantDetails?.verificationFileUrl"
                (click)="onOpenFile(applicantDetails?.verificationFileUrl)"
            >
                <div class="row py-3">
                    <div class="col-2 ms-3">
                        <img src="assets/images/file-logo.jpg" height="60px" />
                    </div>
                    <div class="col-9">
                        <p class="fw-bolder">Certification Verification File</p>
                        <p>
                            {{ applicantDetails?.verificationFileName }}
                        </p>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="row g-3" *ngIf="type == 'experience'">
            <div class="col-md-6">
                <h5>Job Title</h5>
                <p>{{ applicantDetails?.title }}</p>
            </div>
            <div class="col-md-6">
                <h5>Company Name</h5>
                <p>{{ applicantDetails?.companyName }}</p>
            </div>
            <div class="col-md-12">
                <h5>Specialities</h5>
                <p>
                    {{ specialities }}
                </p>
            </div>
            <div class="col-md-12">
                <h5 class="mb-2">Contact Information</h5>
                <div
                    class="d-flex"
                    *ngIf="
                        applicantDetails?.reference1FirstName ||
                        applicantDetails?.reference1LastName ||
                        applicantDetails?.reference1PhoneNumber ||
                        applicantDetails?.reference1ContactBestTime
                    "
                >
                    <p>
                        Reference1 - {{ applicantDetails?.reference1FirstName | titlecase }}
                        {{
                            (applicantDetails?.reference1LastName | titlecase) +
                                ' | ' +
                                formatNumber(applicantDetails?.reference1PhoneNumber ?? ' ')
                        }}
                    </p>
                    <p class="ms-1" *ngIf="applicantDetails?.reference1ContactBestTime">
                        ({{ applicantDetails?.reference1ContactBestTime }})
                    </p>
                </div>
                <div
                    class="d-flex"
                    *ngIf="
                        applicantDetails?.reference2FirstName ||
                        applicantDetails?.reference2LastName ||
                        applicantDetails?.reference2PhoneNumber ||
                        applicantDetails?.reference2ContactBestTime
                    "
                >
                    <p>
                        Reference2 - {{ applicantDetails?.reference2FirstName | titlecase }}
                        {{
                            (applicantDetails?.reference2LastName | titlecase) +
                                ' | ' +
                                formatNumber(applicantDetails?.reference2PhoneNumber ?? ' ')
                        }}
                    </p>
                    <p class="ms-1" *ngIf="applicantDetails?.reference2ContactBestTime">
                        ({{ applicantDetails?.reference2ContactBestTime }})
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="type == 'backgroundCheck'">
            <div class="col-4">
                <div class="form-group mb-3">
                    <label class="form-label">Range</label>
                    <p>{{ applicantDetails?.backgroundCheckRangeName }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group mb-3">
                    <label class="form-label">State</label>
                    <p>{{ applicantDetails?.states }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group mb-3">
                    <label class="form-label">Background CheckType</label>
                    <p>{{ applicantDetails?.backgroundCheckName }}</p>
                </div>
            </div>
            <div
                class="col-6 cursor-pointer"
                (click)="onOpenFile(applicantDetails?.fileUrl)"
                *ngIf="applicantDetails?.fileName"
            >
                <div class="card mt-3 rounded-3">
                    <div class="row py-3">
                        <div class="col-2 ms-3">
                            <img src="assets/images/file-logo.jpg" height="60px" />
                        </div>
                        <div class="col-9">
                            <p class="fw-bolder">Background Check File</p>
                            <p class="text-secondary text-break">
                                {{ applicantDetails?.fileName }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="type == 'credential'">
            <div class="col-5">
                <div class="form-group mb-3">
                    <label class="form-label">Credential Name</label>
                    <p>{{ applicantDetails?.credentialTypeName }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group mb-3">
                    <label class="form-label">Category</label>
                    <p>{{ applicantDetails?.credentialCategoryTypeName }}</p>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group mb-3">
                    <label class="form-label">State</label>
                    <p>{{ applicantDetails?.stateAcronym }}</p>
                </div>
            </div>
            <div class="col-5">
                <div class="form-group mb-3">
                    <label class="form-label">Credential Number</label>
                    <p>{{ applicantDetails?.credentialNumber ?? '-' }}</p>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group mb-3">
                    <label class="form-label">Issue date</label>
                    <div class="d-flex align-items-center" *ngIf="applicantDetails?.issuedDate">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{ applicantDetails?.issuedDate | localDateTime : 'ordinal' }}
                        </p>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="!applicantDetails?.issuedDate">-</div>
                </div>
            </div>
            <div class="col-3" *ngIf="applicantDetails?.expiryDate">
                <div class="form-group mb-3">
                    <label class="form-label">Expiration date</label>
                    <div class="d-flex align-items-center">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{
                                applicantDetails?.isCredentialDoesNotExpire
                                    ? 'n/a'
                                    : (applicantDetails?.expiryDate | localDateTime : 'ordinal')
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div
                    class="card col-7 rounded-3 cursor-pointer"
                    *ngIf="applicantDetails?.fileName"
                    (click)="onOpenFile(applicantDetails?.fileUrl)"
                >
                    <div class="row py-3">
                        <div class="col-2 ms-3">
                            <img src="assets/images/file-logo.jpg" height="60px" />
                        </div>
                        <div class="col-9">
                            <p class="fw-bolder">Credential File</p>
                            <p class="cursor-pointer text-secondary text-break description">
                                {{ applicantDetails?.fileName }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <div
                    class="card col-7 mt-1 rounded-3 cursor-pointer"
                    *ngIf="applicantDetails?.verificationFileUrl"
                    (click)="onOpenFile(applicantDetails?.verificationFileUrl)"
                >
                    <div class="row py-3">
                        <div class="col-2 ms-3">
                            <img src="assets/images/file-logo.jpg" height="60px" />
                        </div>
                        <div class="col-9">
                            <p class="fw-bolder">Credential Verification File</p>
                            <p>
                                {{ applicantDetails?.verificationFileName }}
                            </p>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-6 mt-3" *ngIf="applicantDetails?.verifiedDate">
                <div class="form-group mb-3">
                    <label class="form-label"> Verified On</label>
                    <p>
                        {{ applicantDetails?.verifiedDate | localDateTime : 'ordinal' }}
                        {{ applicantDetails?.verifiedDate | localDateTime : 'h:mm a' }}
                    </p>
                </div>
            </div>
            <div class="col-6 mt-3" *ngIf="applicantDetails?.verifyByName">
                <div class="form-group mb-3">
                    <label class="form-label"> Verified by</label>
                    <p>{{ applicantDetails?.verifyByName }}</p>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="type == 'EmployementForm'">
            <div class="col-6">
                <div class="form-group mb-3">
                    <label class="form-label"> Employment Form Type</label>
                    <p>{{ applicantDetails?.formTypeName }}</p>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group mb-3">
                    <label class="form-label">Uploaded Date</label>
                    <div class="d-flex align-items-center">
                        <span>
                            <i class="feather icon-calendar" aria-hidden="true"></i>
                        </span>
                        <p class="ms-2">
                            {{
                                applicantDetails?.signingDate
                                    ? (applicantDetails?.signingDate | localDateTime : 'ordinal')
                                    : (applicantDetails?.uploadedOn | localDateTime : 'ordinal')
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card col-6 mt-3 rounded-3">
                    <div class="row py-3">
                        <div class="col-2 ms-3">
                            <img src="assets/images/file-logo.jpg" height="60px" />
                        </div>
                        <div class="col-9" (click)="onOpenFile(applicantDetails?.fileUrl)">
                            <p class="fw-bolder">{{ applicantDetails?.fileName }}</p>
                            <p class="cursor-pointer text-secondary text-break description">
                                Uploaded in {{ applicantDetails?.uploadedOn | localDateTime : 'ordinal' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" *ngIf="type != 'experience'">
            <div class="col-md-4 col-6">
                <div class="d-flex align-items-center">
                    <span class="fw-bolder">Status: </span>
                    <p class="ms-2 text-muted fw-bolder" *ngIf="applicantDetails?.statusName == 'Unverified'">
                        {{ applicantDetails?.statusName }}
                    </p>
                    <p class="ms-2 text-success fw-bolder" *ngIf="applicantDetails?.statusName == 'Approved'">
                        {{ applicantDetails?.statusName }}
                    </p>
                    <p class="ms-2 text-danger fw-bolder" *ngIf="applicantDetails?.statusName == 'Declined'">
                        {{ applicantDetails?.statusName }}
                    </p>
                    <p class="ms-2 text-warning fw-bolder" *ngIf="applicantDetails?.statusName == 'In Progress'">
                        {{ applicantDetails?.statusName }}
                    </p>
                    <p
                        class="ms-2"
                        *ngIf="applicantDetails?.statusName == 'Signed' || applicantDetails?.statusName == 'Send Back'"
                    >
                        {{ applicantDetails?.statusName }}
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-6" *ngIf="applicantDetails?.expiredOn && type == 'backgroundCheck'">
                <div class="d-flex align-items-center">
                    <span class="fw-bolder">Expired At: </span>
                    <p class="ms-2">
                        {{
                            applicantDetails?.isExpire
                                ? (applicantDetails?.expiredOn | localDateTime : 'ordinal')
                                : 'n/a'
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div
            class="d-flex mt-3"
            *ngIf="
                applicantDetails?.statusName == 'Declined' &&
                displayFor != 'renew' &&
                type != 'EmployementForm' &&
                type != 'experience'
            "
        >
            <label class="form-label">Restriction: </label>
            <p class="ms-2" *ngIf="!applicantDetails?.canApply">
                {{ 'Nurse can not apply again for this job' }}
            </p>
            <p class="ms-2" *ngIf="applicantDetails?.canApply && applicantDetails?.reApplyDate">
                Nurse can apply again only after this date:
                {{ applicantDetails?.reApplyDate | localDateTime : 'ordinal' }}
            </p>
            <p class="ms-2" *ngIf="applicantDetails?.canApply && !applicantDetails?.reApplyDate">
                {{ 'Nurse can apply again for this job at any time' }}
            </p>
        </div>
        <div class="row mt-3" *ngIf="applicantDetails?.feedback && displayFor != 'renew'">
            <label class="form-label">Feedback for Nurse : </label>
            <p class="text-break description">{{ applicantDetails?.feedback }}</p>
        </div>
        <div class="row mt-3" *ngIf="applicantDetails?.comment">
            <label class="form-label">{{ displayFor == 'renew' ? 'Comments :' : 'Internal Comments :' }} </label>
            <p class="text-break description">{{ applicantDetails?.comment }}</p>
        </div>
    </div>
    <div class="modal-footer p-3">
        <button class="btn btn-primary fw-semibold px-4 px-md-5 m-0" (click)="modal.close()">Close</button>
    </div>
</div>
